import { FLAGS } from "Constants/Images";
import { Button, Col, Collapse, Image, Row, Space, Typography } from "antd";
import card from "Assets/Images/card.png";
import { BsFillCircleFill } from "react-icons/bs";
import { colors } from "Constants/Style";
import { accountingFormat } from "Utils";
import moment from "moment";
import { FORMATS } from "Constants/Formats";

function FundsWithdrawn({ id, alert_json_object, onClear = () => { } }) {
  const status = 'Success';
  const date = alert_json_object?.date?.data;
  const name = alert_json_object?.cardholder?.data;
  const cardNumber = alert_json_object?.card_number?.data;
  const amount = alert_json_object?.amount?.data;
  const currency = alert_json_object?.ccy_code?.data;
  const title = alert_json_object?.title;

  return (
    <Collapse ghost className="bg-white">
      <Collapse.Panel className="full-percent-width-pannel-header" header={
        <>
          <Row align="middle" justify="space-between" gutter={16}>
            <Col>
              <Space align="center">
                <Image src={card} preview={false} height={64} />
                <Space direction="vertical" size={0}>
                  <Typography.Text className="fs-18px medium">{title}</Typography.Text>
                  <Typography.Text className="light-green muli semi-bold fs-18px">{name}</Typography.Text>
                  <Typography.Text className="light-green muli semi-bold fs-18px">{String(cardNumber)?.length < 5 && "**** ****"} {cardNumber}</Typography.Text>
                </Space>
              </Space>
            </Col>
            <Col flex='auto' className="right-align-text">
              <Button type="primary" className="right-align-text big-rounded bg-green">
                <Space size='small'>
                  <BsFillCircleFill size={8} color={colors.successDot} />
                  {status}
                </Space>
              </Button>
            </Col>
          </Row>
        </>
      }
        showArrow={false}>
        <Row justify="space-between" gutter={12}>
          <Col>
            <Space direction="vertical" size={0}>
              <Typography.Text className="medium fs-18px">Amount Sent to Wallet</Typography.Text>
              <Typography.Text className="light-green muli semi-bold fs-18px">{accountingFormat(amount)}</Typography.Text>
            </Space>
          </Col>
          <Col>
            <Space direction="vertical" size={0}>
              <Typography.Text className="medium fs-18px">Currency</Typography.Text>
              <Space align="center">
                {FLAGS[currency] && <Image src={FLAGS[currency]} preview={false} height={36} />}
                <Typography.Text className="light-green muli semi-bold fs-18px">{currency}</Typography.Text>
              </Space>
            </Space>
          </Col>
        </Row>
        <Row>
          <Col>
            <Space direction="vertical" size={0}>
              <Typography.Text className="medium fs-18px">Transaction Date</Typography.Text>
              <Typography.Text className="light-green muli semi-bold fs-18px">
                {date && moment(date).isValid() ? moment(date).format(FORMATS.date) : ''}
              </Typography.Text>
            </Space>
          </Col>
        </Row>
        <Row justify="end">
          <Col>
            <Button type="primary" onClick={() => onClear(id)}>Clear</Button>
          </Col>
        </Row>
      </Collapse.Panel>
    </Collapse>
  )
}

export default FundsWithdrawn;