import { useConfigurationState } from "App/Pages/Accounting/Configuration/store";
import { Button, Col, Row, Typography } from "antd";
import MappingRow from "App/Components/AccountingTrackingCodeMapping/MappingRow";
import { useAccountTrackingCategories } from "App/Pages/Accounting/Configuration/query/useAccountTrackingCategories";
import { useAccountingTrackingCodes } from "App/Pages/Accounting/Configuration/query/useAccountingTrackingCodes";
import { uniqueId } from 'lodash';

function AccountingTrackingCodeMapping({disabled = false}) {
    const trackingCodeMap = useConfigurationState(state => state.trackingCodeMap);
    const addTrackingCodeMapping = useConfigurationState(state => state.addTrackingCodeMapping);
    const delTrackingCodeMapping = useConfigurationState(state => state.delTrackingCodeMapping);
    const syncCardTrans = useConfigurationState(state => state.syncCardTrans);
    const setState = useConfigurationState(state => state.setState);
    const syncExpenses = useConfigurationState(state => state.syncExpenses);
    const { data: trackingCodes, isLoading: trackingCodesLoading } = useAccountingTrackingCodes();
    const { data: trackingCategories, isLoading: trackingCategoriesLoading } = useAccountTrackingCategories();
    const mapRowsLoading = trackingCategoriesLoading || trackingCodesLoading;
    const handleMapRowChange = (data) => {
        setState({
            trackingCodeMap: trackingCodeMap.map((map) => map.id === data.mapId ? ({ ...map, [data.key]: data.val }) : map)
        });
    }

    const handleAddCategoryMapping = () => {
        addTrackingCodeMapping({
            tracking_code_uuid: null,
            codat_tracking_category_id: null,
            id: Date.now() + uniqueId(),
        });
    }

    const handleMapRowDelete = (idx) => {
        delTrackingCodeMapping(idx);
    }

    const getAvailableTrackingCodes = (currentTrackingCode) => {
        const selectedTrackingCodes = (trackingCodeMap || [])
          .filter((row) => row?.tracking_code_uuid !== currentTrackingCode).map(row => row?.tracking_code_uuid);
        return (trackingCodes || []).filter(row => !selectedTrackingCodes.includes(row?.uuid));
    }

    const getAvailableTrackingCategories = (currentTrackingCategories) => {
        const selectedTrackingCategory = (trackingCodeMap || [])
          .filter((row) => row?.codat_tracking_category_id !== currentTrackingCategories).map(row => row?.codat_tracking_category_id);
          return (trackingCategories || [])?.response?.results.filter(row => !selectedTrackingCategory.includes(row?.id));
    }

    return (
        <>
            {(syncCardTrans && syncExpenses) ? (<>
                {/* Category mappings header ================== */}
                <Row gutter={16}>
                    <Col span={8}>
                        <Typography.Text className="fs-20px dark-green bold">Volopa Tracking Codes</Typography.Text>
                    </Col>
                    <Col span={8}>
                        <Typography.Text className="fs-20px dark-green bold">Tracking Categories</Typography.Text>
                    </Col>
                </Row>

                {/* Category mapping rows ==================== */}

                {trackingCodeMap?.length ? trackingCodeMap.map((mapping, idx) =>
                    <MappingRow
                        key={mapping.id}
                        mapId={mapping.id}
                        trackingCategories={getAvailableTrackingCategories(mapping.codat_tracking_category_id)}
                        trackingCategory={mapping.codat_tracking_category_id}
                        trackingCodes={getAvailableTrackingCodes(mapping.tracking_code_uuid)}
                        trackingCode={mapping.tracking_code_uuid}
                        onChange={(data) => handleMapRowChange(data)}
                        onDelete={(idx) => handleMapRowDelete(idx)}
                        loading={mapRowsLoading}
                    />
                ) : null}
                <Row style={{ margin: '1rem 0' }}>
                    <Col span={8}>
                        <Button disabled={disabled} onClick={handleAddCategoryMapping} size="large">+ Add Tracking Code</Button>
                    </Col>
                </Row>
            </>) : null}
        </>
    )

}

export default AccountingTrackingCodeMapping;