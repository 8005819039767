const pageContent = [
    // {
    //     title: `VOLOPA LIFESTYLE CARD TERMS & CONDITIONS`,
    //     url: 'https://www.volopa.com/terms/PC.pdf'
    // },
    {
        title: `VOLOPA LIFESTYLE CORPORATE CARD TERMS & CONDITIONS`,
        url: 'https://www.volopa.com/terms/CEPC.pdf'
    },
    // {
    //     title: `VOLOPA LIFESTYLE PROGRAMME TERMS & CONDITIONS`,
    //     url: 'https://www.volopa.com/terms/VLP.pdf'
    // },
    {
        title: `USE OF PERSONAL INFORMATION AND PRIVACY POLICY`,
        url: 'https://www.volopa.com/use-personal-information'
    },
    {
        title: `VOLOPA TERMS & CONDITIONS (BANK TO BANK)`,
        url: 'https://www.volopa.com/terms/VBtB.pdf'
    },
];


export { pageContent }