import { Space, Row, Col, Button, Checkbox, Input, Typography, Image, InputNumber, notification, Popconfirm } from "antd";
import { FLAGS } from "Constants/Images";
import CustomTable from "App/Components/CustomTable";
import { usePrepaidCardsQuery } from "App/Pages/Cards/query";
import { useCardsState } from "App/Pages/Cards/Cards/store";
import create from "zustand";
import { useEffect } from "react";
import { useApiRequest } from "Hooks/API";
import { debounce } from "lodash";
import { useQueryClient } from "@tanstack/react-query";
import { useTxLimitsMutation } from "Hooks/Mutations";
import CustomPagination from "App/Components/CustomPagination";
import { pluralize, accountingFormat, numberFormat } from "Utils";

const useBulkCardTxLimitsState = create((set, get) => ({
	selectedCards: [],
	editCardId: null,
	selectAll: false,
	loading: false,
	confirmModal: false,
	posAmount: '',
	atmAmount: '',
	singleCard: {
		posAmount: '',
		posMaxTrxId: null,
		atmAmount: '',
		atmMaxTrxId: null,
	},
	setState: (data) => set(data),
	resetState: () => set({
		posAmount: '',
		atmAmount: '',
		selectedCards: [],
		editCardId: null,
		loading: false,
		singleCard: {
			posAmount: '',
			posMaxTrxId: null,
			atmAmount: '',
			atmMaxTrxId: null,
		},
	}),
}));

function BulkTxLimits() {

	const selectedCards = useBulkCardTxLimitsState(state => state.selectedCards);
	const selectAll = useBulkCardTxLimitsState(state => state.selectAll);
	const editCardId = useBulkCardTxLimitsState(state => state.editCardId);
	const loading = useBulkCardTxLimitsState(state => state.loading);
	const posAmount = useBulkCardTxLimitsState(state => state.posAmount);
	const atmAmount = useBulkCardTxLimitsState(state => state.atmAmount);
	const singleCard = useBulkCardTxLimitsState(state => state.singleCard);
	const setState = useBulkCardTxLimitsState(state => state.setState);
	const resetState = useBulkCardTxLimitsState(state => state.resetState);


	const cards = useCardsState(state => state.prepaidCardsQueryData);
	const setCardsState = useCardsState(state => state.setState);
	const currentPage = useCardsState(state => state.currentPage);
	const limit = useCardsState(state => state.limit);

	const txLimitsMutation = useTxLimitsMutation(payload => payload);

	const apiRequest = useApiRequest();

	const queryClient = useQueryClient();


	const { isLoading: cardsQueryIsFetching, data: prepaidCards } = usePrepaidCardsQuery();

	//
	useEffect(() => {
		return () => {
			resetState();

			setCardsState({
				cardSearchFilter: null,
				busy: false,
				currentPage: 1,
				limit: 10,
				totalPages: 0,
			});
		}
		// eslint-disable-next-line
	}, []);

	//
	useEffect(() => {
		if (cards) {
			setState({ selectAll: selectedCards.length === cards.length });
		}
		// eslint-disable-next-line
	}, [selectedCards, cards]);

	//
	const handleSelectCard = (evt, card) => {
		let posMaxTrxId = card?.prepaid_card_max_transaction?.find(item => item.max_transaction_type_id === 1)?.id || null;
		let atmMaxTrxId = card?.prepaid_card_max_transaction?.find(item => item.max_transaction_type_id === 2)?.id || null;

		setState({
			selectedCards: evt.target.checked
				? [...selectedCards, {
					prepaid_card_id: card.prepaid_card_id,
					// record id of max_transaction
					posMaxTrxId,
					atmMaxTrxId,
				}]
				: selectedCards.filter(item => item.prepaid_card_id !== card.prepaid_card_id),
		});

	}

	//
	const handleSelectAllCards = (evt) => {
		setState({
			selectedCards: evt.target.checked
				? cards.reduce((acc, card) => {
					let posMaxTrxId = card?.prepaid_card_max_transaction?.find(item => item.max_transaction_type_id === 1)?.id || null;
					let atmMaxTrxId = card?.prepaid_card_max_transaction?.find(item => item.max_transaction_type_id === 2)?.id || null;

					acc.push({
						prepaid_card_id: card.prepaid_card_id,
						posMaxTrxId,
						atmMaxTrxId,
					});
					return acc;
				}, [])
				: [],
		});
	}

	//
	const handleSingleLimitChange = (val, type, maxTrx) => {
		let maxTrxData = maxTrx?.find(item => item.max_transaction_type_id === (type === 'pos' ? 1 : 2));
		setState({
			selectedCards: [],
			singleCard: {
				...singleCard,
				[`${type}Amount`]: val,
				[`${type}MaxTrxId`]: maxTrxData?.id || null,
			}
		});
	};

	//
	const cancelEditMode = () => {
		resetState();
	}

	//
	const handleEditClick = (record) => {
		let pos = record?.prepaid_card_max_transaction?.find(item => item.max_transaction_type_id === 1);
		let atm = record?.prepaid_card_max_transaction?.find(item => item.max_transaction_type_id === 2);

		setState({
			editCardId: record?.prepaid_card_id,
			selectedCards: [],
			singleCard: {
				posAmount: pos?.amount || '',
				posMaxTrxId: pos?.id,
				atmAmount: atm?.amount || '',
				atmMaxTrxId: atm?.id || null,
			}
		});
	}

	//
	const handleSaveSingleCardLimit = async (record) => {
		let posMaxTrxData = record?.prepaid_card_max_transaction?.find(item => item.max_transaction_type_id === 1);
		let atmMaxTrxData = record?.prepaid_card_max_transaction?.find(item => item.max_transaction_type_id === 2);

		let mutations = [];

		if (!posMaxTrxData) {
			// If there is not a type 1 (pos) record
			// we POST if the amount is > 0
			// to insert new to db
			if (singleCard.posAmount > 0) {
				// post pos
				const mutationData = {
					typeId: 1,
					tid: null,
					cardId: record.prepaid_card_id,
					method: 'POST',
					amount: singleCard.posAmount,
				}
				mutations.push(txLimitsMutation.mutateAsync(mutationData));
			}
		} else {
			// if there is a type 1 (pos) record
			// and the amount has changed and > 0
			// we PUT, if = 0 we DEL the record
			if (posMaxTrxData.amount !== singleCard.posAmount) {
				if (singleCard.posAmount > 0) {
					// put pos
					const mutationData = {
						typeId: 1,
						tid: posMaxTrxData.id,
						cardId: record.prepaid_card_id,
						method: 'PUT',
						amount: singleCard.posAmount,
					}
					mutations.push(txLimitsMutation.mutateAsync(mutationData));
				} else {
					// del pos
					const mutationData = {
						typeId: 1,
						tid: posMaxTrxData.id,
						cardId: record.prepaid_card_id,
						method: 'DELETE',
						amount: singleCard.posAmount,
					}
					mutations.push(txLimitsMutation.mutateAsync(mutationData));
				}
			}
		}

		if (!atmMaxTrxData) {
			if (singleCard.atmAmount > 0) {
				// post atm
				const mutationData = {
					typeId: 2,
					tid: null,
					cardId: record.prepaid_card_id,
					method: 'POST',
					amount: singleCard.atmAmount,
				}
				mutations.push(txLimitsMutation.mutateAsync(mutationData));
			}
		} else {
			if (atmMaxTrxData.amount !== singleCard.atmAmount) {
				if (singleCard.atmAmount > 0) {
					// put atm
					const mutationData = {
						typeId: 2,
						tid: atmMaxTrxData.id,
						cardId: record.prepaid_card_id,
						method: 'PUT',
						amount: singleCard.atmAmount,
					}
					mutations.push(txLimitsMutation.mutateAsync(mutationData));
				} else {
					// del atm
					const mutationData = {
						typeId: 2,
						tid: atmMaxTrxData.id,
						cardId: record.prepaid_card_id,
						method: 'DELETE',
						amount: singleCard.atmAmount,
					}
					mutations.push(txLimitsMutation.mutateAsync(mutationData));
				}
			}
		}

		if (mutations.length) {
			setState({ loading: true });

			try {

				const responses = await Promise.all(mutations);
				if (responses.some(res => res?.response?.data?.error)) {
					resetState();
					notification.error({ message: 'There was an error saving some of your settings. Please try again.', placement: 'top' });
				} else {
					notification.success({ message: 'Transaction limits updated!', placement: 'top' });
					queryClient.invalidateQueries({ queryKey: ['_prepaid_cards'], exact: true });
				}
			} catch (err) {
				// @todo
			} finally {
				setTimeout(() => resetState(), 300);
			}
		}
	}

	//
	const handleBulkLimitAmountChange = (val, maxTrxType) => {
		setState({
			[maxTrxType]: val,
		});
	}

	//
	const handleApplyToSelected = (maxTrxtypeId) => {
		const amount = maxTrxtypeId === 1 ? posAmount : atmAmount;

		// eslint-disable-next-line
		if (!amount || amount == 0) {
			return;
		}

		mutateBulkLimits(amount, maxTrxtypeId, 'POST');
	}

	const handleApplyToSelectedConfirm = (maxTrxtypeId) => {
		const amount = maxTrxtypeId === 1 ? posAmount : atmAmount;

		mutateBulkLimits(amount, maxTrxtypeId, 'DELETE');
	}

	//
	const mutateBulkLimits = async (amount, maxTrxtypeId, method) => {
		const cardData = selectedCards.map(cData => {
			if (maxTrxtypeId === 1) {
				const { posMaxTrxId: maxTrxId, prepaid_card_id } = cData;
				return {
					prepaid_card_id,
					maxTrxId,
				};
			} else {
				const { atmMaxTrxId: maxTrxId, prepaid_card_id } = cData;
				return {
					prepaid_card_id,
					maxTrxId,
				};
			}
		});

		try {
			setState({ loading: true });

			let payload = {
				cardData,
			}

			if (method !== 'DELETE') {
				payload.max_transaction_type_id = maxTrxtypeId;
				payload.amount = amount;
			}

			const res = await apiRequest(
				'prepaidcard/bulk/maxtransaction',
				method,
				payload
			);

			if (res?.response?.data?.error) {
				resetState();
				notification.error({ message: 'There was an error saving some of your settings. Please try again.', placement: 'top' });
			} else {
				notification.success({ message: 'Transaction limits updated!', placement: 'top' });
				queryClient.invalidateQueries({ queryKey: ['_prepaid_cards'], exact: true });
			}
		} catch (err) {
			notification.error({ message: 'There was an error saving some of your settings. Please try again.', placement: 'top' });
		} finally {
			setTimeout(() => resetState(), 300);
		}
	}

	//
	const cardTableCols = [
		{
			title: <Space>
				Select All
				<Checkbox checked={selectAll} onChange={handleSelectAllCards} />
			</Space>,
			dataIndex: 'prepaid_card_id',
			key: 'selectAll',
			align: 'center',
			render: (value, record) => <Checkbox
				checked={!!selectedCards.find(item => item.prepaid_card_id === value)}
				onChange={(evt) => handleSelectCard(evt, record)}
			/>,
			width: 150,
		},
		{
			title: 'Card',
			dataIndex: 'card_number',
			key: 'card',
			render: (value, record) => <Space direction='vertical' size={0}>
				{record?.card_holder?.name}
				{`**** **** **** ${value}`}
			</Space>
		},
		{
			title: 'Currency',
			dataIndex: 'base_currency',
			key: 'currency',
			render: (value) => <Space size={4}>
				{FLAGS[value] && <Image src={FLAGS[value]} alt={value} preview={false} width={36} />}
				{value}
			</Space>
		},
		{
			title: 'Maximum ATM Withdrawal',
			dataIndex: ['prepaid_card_max_transaction'],
			key: 'Withdrawal',
			render: (value, record) => <>
				{editCardId === record.prepaid_card_id
					? <InputNumber
						min={0}
						controls={false}
						defaultValue={singleCard.atmAmount}
						onChange={(val) => handleSingleLimitChange(val, 'atm', value)}
						formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
						parser={(val) => numberFormat(val)}
					/>
					: accountingFormat(record?.prepaid_card_max_transaction?.find(item => item.max_transaction_type_id === 2)?.amount) || '-'
				}
			</>
		},
		{
			title: 'Maximum Purchase Amount',
			dataIndex: ['prepaid_card_max_transaction'],
			key: 'maximumPurchaseAmount',
			render: (value, record) => <>
				{editCardId === record.prepaid_card_id
					? <InputNumber
						min={0}
						controls={false}
						defaultValue={singleCard.posAmount}
						onChange={(val) => handleSingleLimitChange(val, 'pos', value)}
						formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
						parser={(val) => numberFormat(val)}
					/>
					: accountingFormat(record?.prepaid_card_max_transaction?.find(item => item.max_transaction_type_id === 1)?.amount) || '-'
				}
			</>
		},
		{
			title: '',
			key: 'cancel',
			width: 88,
			render: (v, record) => <>
				{editCardId === record?.prepaid_card_id ? <Button onClick={() => cancelEditMode()}>Cancel</Button> : ''}
			</>,
			align: 'right'
		},
		{
			title: '',
			key: 'action',
			width: 75,
			render: (v, record) => (<>
				{editCardId === record?.prepaid_card_id
					? <Button
						style={{ width: '62px' }}
						type='primary'
						onClick={() => handleSaveSingleCardLimit(record)}
						disabled={loading || cardsQueryIsFetching}
					>
						Save
					</Button>
					: <Button
						style={{ width: '62px' }}
						type='primary'
						onClick={() => handleEditClick(record)}
						disabled={loading}
					>
						Edit
					</Button>
				}
			</>),
			align: 'right'
		}
	];

	//
	const handlePageChange = (page) => {
		setCardsState({ currentPage: page });
	}

	//
	const handleSearch = debounce((evt) => {
		if (evt.target.value?.length > 2) {
			setCardsState({ cardSearchFilter: evt.target.value.toLowerCase() });
		} else {
			setCardsState({ cardSearchFilter: null });
		}

		setCardsState({ currentPage: 1 });
	}, 400);

	//
	const handlePageSizeChange = (current, size) => {
		setCardsState({ limit: size, currentPage: 1 });
	}

	return (
		<>
			<Row className='m-t-20' gutter={[120, 24]}>
				<Col>
					<Space wrap>
						<Typography.Text className='fs-18px medium'>Maximum ATM Withdrawal</Typography.Text>
						<InputNumber
							min={0}
							controls={false}
							defaultValue={atmAmount}
							style={{ width: '160px' }}
							placeholder='Enter Limit Value'
							onChange={(val) => handleBulkLimitAmountChange(val, 'atmAmount')}
							formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
							parser={(val) => numberFormat(val)}
						/>
						{/* handleApplyToSelected(the type id) */}
						<Popconfirm
							pre
							title={'Setting no value or 0 will delete the limits on selected cards!'}
							onConfirm={() => handleApplyToSelectedConfirm(2)}
							okText="Yes, delete limits"
							cancelText="Cancel"
							disabled={atmAmount > 0}
						>
							<Button disabled={!selectedCards.length} onClick={() => handleApplyToSelected(2)} type='primary'>Apply to Selected</Button>
						</Popconfirm>
					</Space>
				</Col>
				<Col>
					<Space wrap>
						<Typography.Text className='fs-18px medium'>Maximum Purchase Amount</Typography.Text>
						<InputNumber
							min={0}
							controls={false}
							defaultValue={posAmount}
							style={{ width: '160px' }}
							placeholder='Enter Limit Value'
							onChange={(val) => handleBulkLimitAmountChange(val, 'posAmount')}
							formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
							parser={(val) => numberFormat(val)}
						/>
						{/* handleApplyToSelected(the type id) */}
						<Popconfirm
							title={'Setting no value or 0 will delete the limits on selected cards!'}
							onConfirm={() => handleApplyToSelectedConfirm(1)}
							okText="Yes, delete limits"
							cancelText="Cancel"
							disabled={posAmount > 0}
						>
							<Button disabled={!selectedCards.length} onClick={() => handleApplyToSelected(1)} type='primary'>Apply to Selected</Button>
						</Popconfirm>
					</Space>
				</Col>
			</Row>

			<Row className="m-t-20">
				<Col xxl={4}>
					<Input.Search onChange={handleSearch} placeholder="Search By Card Holder" />
				</Col>
			</Row>

			<Row className="m-t-10">
				<Col span={24}>
					<CustomPagination
						loading={loading}
						onPageChange={handlePageChange}
						total={prepaidCards?.pager?.count ?? 1}
						pageSize={limit}
						current={currentPage}
						onPageSizeChange={handlePageSizeChange}
						singlePageMessage={`${prepaidCards?.pager?.count ?? 1} ${pluralize(prepaidCards?.pager?.count ?? 1, 'card', 'cards')} found.`}
					/>
					<CustomTable
						columns={cardTableCols}
						dataSource={prepaidCards?.data || []}
						styleAllRows
						className="spaced-rows"
						headerColor="green"
						rowKey="prepaid_card_id"
						loading={loading}
					/>
					<CustomPagination
						loading={loading}
						onPageChange={handlePageChange}
						total={prepaidCards?.pager?.count ?? 1}
						pageSize={limit}
						current={currentPage}
						onPageSizeChange={handlePageSizeChange}
						singlePageMessage={`${prepaidCards?.pager?.count ?? 1} ${pluralize(prepaidCards?.pager?.count ?? 1, 'card', 'cards')} found.`}
					/>
				</Col>
			</Row>
		</>
	)
}

export default BulkTxLimits;
