import { useMutation } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useDisablePaymentTypesMutation = ({cardId, typeId, method, recordId}) => {
    const apiRequest = useApiRequest();

    const mutation = useMutation({
        mutationFn: ({cardId, typeId, method, recordId}) => {
            return apiRequest(
                `${APIROUTES.prepaidcard_disabledtxtype}${method === 'DELETE' ? ('/-' + recordId) : ''}`,
                method,
                method === 'POST' ? {
                    prepaid_card_id: parseInt(cardId),
                    opt_disable_type_id: typeId
                }: null
            );
        },
        mutationKey: ['_prepaidcard_disabledtxtype_put_post', cardId, typeId, method],
        onMutate: () => {},
        onError: (error) => {},
    });

    return mutation;
}

export { useDisablePaymentTypesMutation };
