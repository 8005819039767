import { Button, Card, Col, Form, Image, Input, message, Row, Typography } from "antd";
import { encode } from "base-64";
import { Link, Navigate, useNavigate } from "react-router-dom";
import logo from 'Assets/Images/logo.png';
import one from './store/images/one.png';
import two from './store/images/two.png';
import three from './store/images/three.png';
import { useLoginState } from "App/Pages/Login/store";
import URLS from "Routes/constants";
import { apiRequest } from "Hooks/API";
import { makeAccessTokenExpiryTime } from "Utils";

function Login() {
  const loading = useLoginState(state => state.loading);
  const twoFactorAuthentication = useLoginState(state => state.twoFactorAuthentication);
  const twoFAkey = useLoginState(state => state.twoFAkey);
  const user = useLoginState(state => state.user);
  const setState = useLoginState(state => state.setState);
  const refreshToken = localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_REFRESH_TOKEN_KEY);
  const navigate = useNavigate();

  const submitForm = async (formData) => {
    const username = formData.username;
    const password = formData.password;
    const credentialPayload = { username, password };

    setState({ loading: true });
    // If two-factor authentication is enabled, proceed with verification
    if (twoFactorAuthentication) {
      const payload = { username: user, code: formData.code, key: twoFAkey };

      // Send the verification code to the server for validation
      const twoFAResponse = await apiRequest('oauth2/app/user/verify/code', 'POST', payload);

      // Check if the verification was successful
      if (twoFAResponse && twoFAResponse.data && twoFAResponse.data.details) {
        // Extract clientId and secret from the response
        const clientId = twoFAResponse.data.details.clientId;
        const secret = twoFAResponse.data.details.secret;

        // Create an authorization header using clientId and secret
        const authorization = 'Basic ' + encode(clientId + ':' + secret);
        const path = 'oauth2/token';

        // Prepare data for token request
        const data = {
          scope: 'app',
          grant_type: 'client_credentials'
        };

        // Request a new token using client credentials
        const res = await apiRequest(path, 'POST', data, authorization);

        // Check if the token request was successful
        if (res) {
          // Store the refresh token and clientId in localStorage
          localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_REFRESH_TOKEN_KEY, res.refresh_token);
          localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_O2_CLIENT_ID_KEY, clientId);

          // Update the state with the new access token and roleId
          setState({
            accessToken: {
              token: res.access_token,
              type: res.token_type,
              expires: makeAccessTokenExpiryTime(res.expires_in),
            },
            roleId: res?.role_id,
          });

          setState({ loading: false, twoFactorAuthentication: false });
          // Navigate to the WalletDashboard URL
          navigate(URLS.WalletDashboard);
        }
      } else {
        // If the verification failed, show an error message
        message.error(`Authentication failed, ${twoFAResponse?.response?.data?.error?.message}`);
      }

      // Update the state to stop loading
      setState({ loading: false });

      // Return from the function
      return;
    }

    const credentialResponse = await apiRequest('oauth2/app/user/credentials', 'POST', credentialPayload);
    if (credentialResponse && credentialResponse?.data?.["2fa_enabled"]) {
      setState({ loading: false, twoFactorAuthentication: true, user: username, twoFAkey: credentialResponse?.data?.key })
      return;
    }
    if (credentialResponse && credentialResponse.data && credentialResponse.data.details) {
      const clientId = credentialResponse.data.details.clientId;
      const secret = credentialResponse.data.details.secret;
      const authorization = 'Basic ' + encode(clientId + ':' + secret);
      const path = 'oauth2/token';
      const data = {
        scope: 'app',
        grant_type: 'client_credentials'
      };

      const res = await apiRequest(path, 'POST', data, authorization);

      if (res) {
        localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_REFRESH_TOKEN_KEY, res.refresh_token);
        localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_O2_CLIENT_ID_KEY, clientId);

        setState({
          accessToken: {
            token: res.access_token,
            type: res.token_type,
            expires: makeAccessTokenExpiryTime(res.expires_in),
          },
          roleId: res?.role_id,
        });

        navigate(URLS.WalletDashboard);
      }
    } else {
      message.error('Login failed, please check your username and password');
    }
    setState({ loading: false });
  }

  if (!!refreshToken) {
    return <Navigate to={URLS.WalletDashboard} />
  }

  return (
    <Row className="full-height" align="middle" justify="center" style={{ overflow: 'hidden' }}>
      <Col xxl={6} xl={9} lg={12} md={12} sm={18} xs={22}>
        <img src={one} className='one' alt="1" />
        <img src={two} className='two' alt="2" />
        <img src={three} className='three' alt="3" />
        <Card hoverable >
          <Row justify="center" className="m-t-100">
            <Col>
              <Image src={logo} alt='logo' preview={false} width={214} />
            </Col>
          </Row>
          <Row justify="center" className="m-t-10">
            <Col>
              <Typography.Text className="fs-18px medium">Welcome Back!</Typography.Text>
            </Col>
          </Row>
          <Row className="m-t-30" justify="center">
            <Col span={18}>
              <Form
                layout="vertical"
                requiredMark={false}
                onFinish={submitForm}>
                {!twoFactorAuthentication ?
                  (<>
                    <Form.Item
                      name='username'
                      rules={[
                        {
                          required: true,
                          message: 'Please Enter Your Username/email'
                        }
                      ]}
                    >
                      <Input size="large" placeholder="Username/email" className="b-g placeholder-green" />
                    </Form.Item>
                    <Form.Item
                      name='password'
                      rules={[
                        {
                          required: true,
                          message: 'Please Enter Your Password'
                        }
                      ]}>
                      <Input.Password size="large" placeholder="Enter Password" className="b-g placeholder-green" />
                    </Form.Item>
                  </>
                  ) : (
                    <>
                      <Row justify="center">
                        <Col>
                          <Form.Item className="center">
                            <Typography.Text className='dark-green medium fs-18px'>Authenticator Code</Typography.Text>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row justify="center">
                        <Col>
                          <Form.Item
                            name='code'
                            rules={[
                              {
                                required: true,
                                message: 'Enter Authenticator Code'
                              }
                            ]}>
                            <Input maxLength={6} style={{ width: 250 }} size="large" controls={false} placeholder="Enter Authenticator Code" className="b-g placeholder-green"
                              onKeyDown={(event) => {
                                if (event.key !== 'Backspace' && event.key !== 'Delete' && !/[0-9\b]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row justify="center">
                        <Col>
                          <Form.Item>
                            <Typography.Text className='dark-green medium fs-15px'>Please fill in the Authentication code field.</Typography.Text>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )
                }
                <Row justify="center">
                  <Col>
                    <Button type="primary" htmlType="submit" className="right-align-text" loading={loading}>Login</Button>
                  </Col>
                </Row>
                <Row justify="center" className="m-t-10">
                  <Col>
                    <Link to={URLS.ForgotPassword} className="dark-green fs-18px bold underline">Forgot password</Link>
                  </Col>
                </Row>
                <Row justify="center" className="m-t-5">
                  <Col>
                    <Link onClick={() => window.location.href = process.env.REACT_APP_SIGNUP_URL} className="dark-green fs-18px bold underline">Signup here</Link>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}

export default Login;
