import { Row, Col, Image, Typography, Form, Select, InputNumber, Input, Space, Button, notification, Spin, Card } from 'antd';
import PageDescription from 'App/Components/PageDescription';
import logo from 'Assets/Images/logo.png';
import CardDetails from 'App/Components/CardDetails';
import CardBalanceListing from 'App/Components/CardBalanceListing';
import { usePrepaidcardBalanceQuery } from "Hooks/Queries";
import { useParams } from "react-router-dom";
import { usePrepaidCardQuery, useCurrenciesQuery } from "Hooks/Queries";
import Spinner from 'App/Components/Spinner';
import { useEffect } from 'react';
import { useCardWithdrawRequestMutation, useCardWithdrawConfirmMutation } from 'Hooks/Mutations';
import create from "zustand";
import { useAppState } from 'Hooks/Shared';
import { useQueryClient } from "@tanstack/react-query";
import { FLAGS } from 'Constants/Images';
import FundWithdrawConfirmSuccessModal from "App/Components/FundWithdrawConfirmModal";
import { useFundWithdrawConfirmModalState } from "App/Components/FundWithdrawConfirmModal/store";
import CompanyWalletBalance from 'App/Components/CompanyWalletBalance';
import { accountingFormat, numberFormat } from 'Utils';

const useCardWithdrawState = create((set, get) => ({
    transferAmount: '',
    transferCurrency: '',
    transferTransferId: '',
    description: '',
    currencyOpts: [],
    commonCurr: [],
    loading: false,
    setState: (data) => set(data),
    validate: () => !!get().transferCurrency && !!get().transferAmount,
}));

function NewCardWithdrawal() {

    const { cardId } = useParams();
    const { data: card, isLoading, isFetching, isRefetching } = usePrepaidCardQuery(cardId);
    const { data: currenciesData } = useCurrenciesQuery();
    const { data: cardBalanceData } = usePrepaidcardBalanceQuery(cardId);
    const transferAmount = useCardWithdrawState(state => state.transferAmount);
    const transferCurrency = useCardWithdrawState(state => state.transferCurrency);
    const description = useCardWithdrawState(state => state.description);
    const currencyOpts = useCardWithdrawState(state => state.currencyOpts);
    const commonCurr = useCardWithdrawState(state => state.commonCurr);
    const loading = useCardWithdrawState(state => state.loading);
    const validate = useCardWithdrawState(state => state.validate);
    const setState = useCardWithdrawState(state => state.setState);
    const cardWithdrawRequestMutation = useCardWithdrawRequestMutation(payload => payload);
    const cardWithdrawConfirmMutation = useCardWithdrawConfirmMutation(payload => payload);
    const appState = useAppState();
    const queryClient = useQueryClient();
    const setModalState = useFundWithdrawConfirmModalState(state => state.setState)

    //
    useEffect(() => {
        if (cardBalanceData && currenciesData && card) {
            setState({
                currencyOpts: currenciesData
                    .sort((a, b) => a.currency.localeCompare(b.currency)),
                commonCurr: currenciesData
                    .filter((data) => data.sort_order_buy !== 999)
                    .sort((a, b) => a.sort_order_buy - b.sort_order_buy)
            });
        }

        // eslint-disable-next-line
    }, [cardBalanceData, currenciesData, card]);

    //
    const handleCurrencyChange = (val) => {
        setState({ transferCurrency: val });
    }

    //
    const handleAmountChange = (val) => {
        setState({ transferAmount: Number(val) });
    }

    const handleDescriptionChange = (evt) => {
        setState({ description: evt.target.value });
    }

    //
    const resetForm = () => {
        setState({
            transferAmount: '',
            description: '',
            transferCurrency: '',
        });
    }

    //
    const handleRequestWithdraw = async () => {
        setState({ loading: true });

        await cardWithdrawRequestMutation.mutateAsync(
            {
                transferCurrency,
                transferAmount: Number(transferAmount),
                description,
                prepaidCardId: Number(cardId),
                toPrepaidCardId: appState?.companyWalletId,
            },
            {
                onSuccess: (resData) => {
                    if (resData?.response?.data?.error) {
                        notification.error({ message: resData?.response?.data?.error?.message, description: 'Amount exceeds your card balance in this currency', placement: 'top' });
                        return;
                    }
                    handleWithdrawConfirmMutation(Number(cardId), resData?.data?.transfer_id);
                },
                onError: (err) => {
                    notification.error({ message: err?.message, description: 'Amount exceeds your card balance in this currency', placement: 'top' });
                },
                onSettled: () => setState({ loading: false })
            }
        );
    }

    //
    const handleWithdrawConfirmMutation = async (prepaidCardId, transferTransferId) => {
        await cardWithdrawConfirmMutation.mutateAsync(
            {
                prepaidCardId,
                transferTransferId,
            },
            {
                onSuccess: (resData) => {
                    if (resData?.response?.data?.error) {
                        notification.error({ message: 'Something Went Wrong', description: 'There was an issue withdrawing card\'s funds', placement: 'top' });
                        return;
                    }
                    resetForm();
                    queryClient.invalidateQueries({ queryKey: ['_users_card_balance', cardId], exact: true });
                    /* The above code is setting the state of a modal component to show a success message
                    with details of a manual fund that has been processed. */
                    setModalState({
                        showModal: true,
                        type: 'success',
                        title: 'Withdrawal Complete',
                        message: 'Funds have now been withdrawn to the Company Wallet',
                        currency: transferCurrency,
                        amount: Number(transferAmount),
                        description: description,
                        moreBtnTxt: 'Withdraw More'
                    })
                },
                onError: () => {
                    notification.error({ message: 'Something Went Wrong', description: 'There was an issue withdrawing card\'s funds', placement: 'top' });
                },
                onSettled: () => setState({ loading: false })
            }
        );
    }
    /**
        * The function sets the state of a modal to display a confirmation message with details of a
        * manual withdrawal request.
        */
    const onClickConfirm = () => {
        setModalState({
            showModal: true,
            type: 'confirm',
            title: 'Confirm card withdrawal',
            message: 'Please confirm that you would like to make the following withdrawal to your Company Wallet',
            onConfirm: handleRequestWithdraw,
            currency: transferCurrency,
            amount: Number(transferAmount),
            description: description
        })
    }

    return (
        <>
            <Spin spinning={loading}>
                <FundWithdrawConfirmSuccessModal />
                <Row>
                    <Col span={24}>
                        <PageDescription title='New Card Withdrawal' text='- Withdraw From Your Prepaid Card To Your Company Wallet' />
                    </Col>
                </Row>

                <Row className='m-t-20' gutter={[12, 12]}>
                    {isLoading || isFetching || isRefetching ? <Spinner /> :
                        <Col xxl={12} xl={10} lg={12} md={16} sm={20} xs={24}>
                            <Row>
                                <Col xxl={16} xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <CardDetails
                                        cardHolderName={`${card?.card_holder?.title} ${card?.card_holder?.first_name} ${card?.card_holder?.last_name}`}
                                        cardNumber={`**** **** **** ${card?.card_number}`}
                                        expiryDate={`${card?.expiry_month} ${card?.expiry_year}`}
                                        cardType={card?.card_program_id || card?.card_type}
                                    >
                                        <Row justify='center' className='m-t-40 m-b-40'>
                                            <Col>
                                                <Image src={logo} preview={false} width={180} />
                                            </Col>
                                        </Row>
                                    </CardDetails>
                                </Col>
                            </Row>
                            <Row className='m-t-20'>
                                <Col span={24}>
                                    <Typography.Text className='dark-green fs-25px medium'>Create New Card Withdrawal</Typography.Text>
                                    <Row>
                                        <Col xxl={10} xl={15} lg={15} md={15} sm={15} xs={24}>
                                            <Form layout='vertical'>
                                                <Row>
                                                    <Col>
                                                        <Typography.Text className='dark-green muli semi-bold fs-18px'>Withdraw Card By Amount</Typography.Text>
                                                    </Col>
                                                </Row>
                                                <Row gutter={6} className='m-t-5'>
                                                    <Col flex='90px'>
                                                        <Form.Item>
                                                            <Select value={transferCurrency} optionLabelProp="children" className='select-b-g' onChange={handleCurrencyChange}>
                                                                <Select.OptGroup label="Common">
                                                                {commonCurr
                                                                .map((curr,key) =>
                                                                    <Select.Option value={curr.currency} key={key} className='p-l-10'>
                                                                        <Space size={4} align="center">
                                                                            {!!FLAGS[curr.currency] && (
                                                                                <Image
                                                                                    src={FLAGS[curr.currency]}
                                                                                    preview={false}
                                                                                    width={20}
                                                                                    alt={curr.currency}
                                                                                />
                                                                            )}
                                                                            {curr.currency}
                                                                        </Space>
                                                                    </Select.Option>
                                                                )}
                                                                    </Select.OptGroup>
                                                                 <Select.OptGroup label="Other">
                                                                 {currencyOpts
                                                                 .map((curr, key) =>
                                                                    <Select.Option value={curr.currency} key={
                                                                        key +
                                                                        (commonCurr
                                                                            ? commonCurr.length
                                                                            : 0)
                                                                        } className='p-l-10'>
                                                                        <Space size={4} align="center">
                                                                            {!!FLAGS[curr.currency] && (
                                                                                <Image
                                                                                    src={FLAGS[curr.currency]}
                                                                                    preview={false}
                                                                                    width={20}
                                                                                    alt={curr.currency}
                                                                                />
                                                                            )}
                                                                            {curr.currency}
                                                                        </Space>
                                                                    </Select.Option>
                                                                )}
                                                                </Select.OptGroup>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col flex='auto'>
                                                        <Form.Item>
                                                            <InputNumber
                                                                className='b-g full-percent-width'
                                                                controls={false}
                                                                placeholder='Enter Withdrawal Amount'
                                                                min={0}
                                                                value={transferAmount}
                                                                onChange={handleAmountChange}
                                                                formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
                                                                parser={(val) => numberFormat(val)}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Typography.Text className='dark-green muli semi-bold fs-18px'>Description</Typography.Text>
                                                    </Col>
                                                </Row>
                                                <Row className='m-t-5'>
                                                    <Col flex='auto'>
                                                        <Form.Item>
                                                            <Input
                                                                className='b-g'
                                                                placeholder='Enter Description'
                                                                value={description}
                                                                onChange={handleDescriptionChange}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Space>
                                                    <Button onClick={resetForm} type='primary'>Reset</Button>
                                                    <Button loading={loading} disabled={!validate()} onClick={onClickConfirm} type='primary'>{loading ? 'Requesting...' : 'Confirm'}</Button>
                                                </Space>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    }
                    <Col xxl={12} xl={14} lg={16} md={20} sm={24} xs={24}>
                        <Row>
                            <Col span={24}>
                                <Card hoverable className="b-g hover-no-border">
                                    <Space direction="vertical" size={50} className='full-percent-width'>
                                        <div>
                                            <Typography.Text className="medium fs-18px dark-green">Card Balance</Typography.Text>
                                            <CardBalanceListing cardId={cardId} rowType="filled" />
                                        </div>
                                        <div>
                                            <Typography.Text className="medium fs-18px dark-green m-t-30">Company Wallet Balance</Typography.Text>
                                            <CompanyWalletBalance />
                                        </div>
                                    </Space>


                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Spin>
        </>
    );
}

export default NewCardWithdrawal;
