import create from "zustand";

const useSpecificPaymentHistoryState = create(set => ({
    volopaAccountHolderName: undefined,
    volopaAccountSortCode: undefined,
    volopaAccountNumber: undefined,
    volopaAccountCountry: undefined,
    volopaAccountAddress: undefined,
    fileStore: [],
    initData: { fileStore: [] },
    note: undefined,
    ref: undefined,
    fundingDetails: {
        volopaAccountHolderName: '',
        volopaAccountSortCode: '',
        volopaAccountNumber: '',
        volopaAccountCountry: '',
        volopaAccountAddress: '',
        volopaAccountNumberType: '',
        volopaAccountName: '',
        volopaAccountSortCodeLabel: '',
        volopaAccountDetails: '',
        fundingLimitReached: false
    },
    openMt103Modal: false,
    activateLoading: false,
    mt103details: '',
    hideMt103Button: false,
    volopaAccountDetails: undefined,
    fundingLimitReached: false,
    showPaymentsOver: false,
    showPaymentsUnder: false,
    saveLoading: false,
    cancelLoading: false,
    setState: obj => set(obj),
    resetState: () => set({
        volopaAccountHolderName: undefined,
        volopaAccountSortCode: undefined,
        volopaAccountNumber: undefined,
        volopaAccountCountry: undefined,
        volopaAccountAddress: undefined,
        fileStore: [],
        initData: { fileStore: [] },
        note: undefined,
        ref: undefined,
        fundingDetails: {
            volopaAccountHolderName: '',
            volopaAccountSortCode: '',
            volopaAccountNumber: '',
            volopaAccountCountry: '',
            volopaAccountAddress: '',
            volopaAccountNumberType: '',
            volopaAccountName: '',
            volopaAccountSortCodeLabel: '',
            volopaAccountDetails: '',
            fundingLimitReached: false
        },
        openMt103Modal: false,
        activateLoading: false,
        mt103details: '',
        hideMt103Button: false,
        volopaAccountDetails: undefined,
        fundingLimitReached: false,
        showPaymentsOver: false,
        showPaymentsUnder: false,
        saveLoading: false,
        cancelLoading: false,
    })
}));

export { useSpecificPaymentHistoryState };