const pageContent = [
    {
        title: "Volopa's Delivery Timescales",
        text: [
            'The information below provides a guide for when your recipient should receive the payment you made with Volopa.',
            'The timescales below are based on receipt of funding by the stated cut-off and assume that the correct recipient details were provided. These timescales can be impacted by your bank and any intermediary banks used to process the payment.'
        ]
    }
];

const tableData = [
    {
        currency: 'CAD',
        priority: 0,
        regular: 0
    },
    {
        currency: 'EUR',
        priority: 0,
        regular: 0
    },
    {
        currency: 'GBP',
        priority: 0,
        regular: 0
    },
    {
        currency: 'USD',
        priority: 0,
        regular: 0
    },
    {
        currency: 'AUD',
        priority: 1,
        regular: 2
    },
    {
        currency: 'CHF',
        priority: 0,
        regular: 'N/A'
    },
    {
        currency: 'DKK',
        priority: 0,
        regular: 0
    },
    {
        currency: 'HKD',
        priority: 1,
        regular: 1
    },
    {
        currency: 'HUF',
        priority: 0,
        regular: 0
    },
    {
        currency: 'JPY',
        priority: 1,
        regular: 'N/A'
    },
    {
        currency: 'NOK',
        priority: 0,
        regular: 0
    },
    {
        currency: 'NZD',
        priority: 1,
        regular: 'N/A'
    },
    {
        currency: 'PLN',
        priority: 0,
        regular: 0
    },
    {
        currency: 'SEK',
        priority: 0,
        regular: 0
    },
    {
        currency: 'THB',
        priority: 1,
        regular: 'N/A'
    },
    {
        currency: 'ZAR',
        priority: 0,
        regular: 'N/A'
    }
]

export { pageContent, tableData }