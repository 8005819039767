import { Space, Row, Col, Button, Checkbox, Input, Typography, Image, InputNumber, notification } from "antd";
import { FLAGS } from "Constants/Images";
import CustomTable from "App/Components/CustomTable";
import { useEffect } from "react";
import { useBulkDailyLimitsState } from "Hooks/Store";
import { useCardsState } from "App/Pages/Cards/Cards/store";
import { accountingFormat, numberFormat, pluralize } from "Utils";
import { usePrepaidCardsQuery } from "App/Pages/Cards/query";
import CustomPagination from "App/Components/CustomPagination";
import { debounce } from "lodash";
import { useCardsDailyLimitsBulkUpsertMutation } from "Hooks/Mutations";
import { useQueryClient } from "@tanstack/react-query";

function BulkDailyLimits() {
	const selectedCards = useBulkDailyLimitsState(state => state.selectedCards);
	const atmAmount = useBulkDailyLimitsState(state => state.atmAmount);
	const posAmount = useBulkDailyLimitsState(state => state.posAmount);
	const loading = useBulkDailyLimitsState(state => state.loading);
	const setState = useBulkDailyLimitsState(state => state.setState);
	const resetState = useBulkDailyLimitsState(state => state.reset);

	const { isLoading: cardsQueryIsFetching, data: prepaidCards } = usePrepaidCardsQuery();

	const cards = useCardsState(state => state.prepaidCardsQueryData);
	const setCardsState = useCardsState(state => state.setState);
	const currentPage = useCardsState(state => state.currentPage);
	const limit = useCardsState(state => state.limit);

	const cardsDailyLimitsBulkUpsertMutation = useCardsDailyLimitsBulkUpsertMutation(payload => payload);
	const queryClient = useQueryClient();

	//
	useEffect(() => {
		return () => {
			resetState();
			setCardsState({
				cardSearchFilter: null,
				currentPage: 1,
				limit: 10,
				totalPages: 0,
			});
		}
		// eslint-disable-next-line
	}, []);

	//
	const removeLimitsFor = async (card, type) => {
		if (card?.shared_balance_limits) {
			const payload = {
				prepaidCards: [
					{
						prepaid_card_id: card.prepaid_card_id,
						limits_id: card?.shared_balance_limits?.id || null,
					}
				],
			}

			if (type === 'atm') {
				payload.max_atm_day_amount = 0;
			}

			if (type === 'pos') {
				payload.max_pos_day_amount = 0;
			}

			setState({ loading: true });
			try {
				const res = await cardsDailyLimitsBulkUpsertMutation.mutateAsync(payload);
				if (res?.response?.data?.error) {
					notification.error({ message: 'Not all changes where saved. Please check and try again.', placement: 'top' });
					return;
				}
				queryClient.invalidateQueries({ queryKey: ['_prepaid_cards'], exact: true });
				notification.success({ message: `Daily limits updated for card *${card.card_number}`, placement: 'top' });
			} catch (err) {
				notification.error({ message: 'Not all changes where saved. Please check and try again.', placement: 'top' });
			} finally {
				resetState();
				setState({ loading: false });
			}
		}
	}

	//
	const handleSelectAllCards = () => {
		setState({
			selectedCards: !selectedCards.length ? cards.reduce((acc, card) => {
				acc.push(card.prepaid_card_id);
				return acc;
			}, []) : [],
		})
	}

	//
	const handleSelectSingleCard = (evt, cardId) => {
		setState({
			selectedCards: evt.target.checked
				? [...selectedCards, cardId]
				: selectedCards.filter(id => id !== cardId),
		});
	}

	//
	const handlePageChange = (page) => {
		setCardsState({ currentPage: page });
	}

	//
	const handleSearch = debounce((evt) => {
		if (evt.target.value?.length > 2) {
			setCardsState({ cardSearchFilter: evt.target.value.toLowerCase() });
		} else {
			setCardsState({ cardSearchFilter: null });
		}

		setCardsState({ currentPage: 1 });
	}, 400);

	//
	const handlePageSizeChange = (current, size) => {
		setCardsState({ limit: size, currentPage: 1 });
	}

	//
	const handleChangeAmount = (val, key) => {
		setState({
			atmAmount: key === 'atmAmount' ? val : '',
			posAmount: key === 'posAmount' ? val : '',
		});
	}

	//
	const handleApplyToSelected = async (type) => {
		const cardsPayload = getCardsPayload();

		const payload = {
			prepaidCards: cardsPayload,
		}

		if (type === 'atm') {
			payload.max_atm_day_amount = atmAmount;
		}

		if (type === 'pos') {
			payload.max_pos_day_amount = posAmount;
		}

		setState({ loading: true });
		try {
			const res = await cardsDailyLimitsBulkUpsertMutation.mutateAsync(payload);
			if (res?.response?.data?.error) {
				notification.error({ message: 'Not all changes where saved. Please check and try again.', placement: 'top' });
				return;
			}
			queryClient.invalidateQueries({ queryKey: ['_prepaid_cards'], exact: true });
			notification.success({ message: `Daily ${type} limits updated for selected cards`, placement: 'top' });
		} catch (err) {
			notification.error({ message: 'Not all changes where saved. Please try again.', placement: 'top' });
		} finally {
			resetState();
			setState({ loading: false });
		}

	}

	//
	const handleRemoveFromSelected = async (type) => {
		const cardsPayload = getCardsPayload();

		const payload = {
			prepaidCards: cardsPayload,
		}

		if (type === 'atm') {
			payload.max_atm_day_amount = 0;
		}

		if (type === 'pos') {
			payload.max_pos_day_amount = 0;
		}

		setState({ loading: true });
		try {
			const res = await cardsDailyLimitsBulkUpsertMutation.mutateAsync(payload);
			if (res?.response?.data?.error) {
				notification.error({ message: 'Not all changes where saved. Please check and try again.', placement: 'top' });
				return;
			}
			queryClient.invalidateQueries({ queryKey: ['_prepaid_cards'], exact: true });
			notification.success({ message: `Daily ${type} limits removed for selected cards`, placement: 'top' });
		} catch (err) {
			notification.error({ message: 'Not all changes where saved. Please check and try again.', placement: 'top' });
		} finally {
			resetState();
			setState({ loading: false });
		}
	}

	//
	const getCardsPayload = () => {
		return cards
			?.filter(card => selectedCards.includes(card.prepaid_card_id))
			?.reduce((acc, card) => {
				acc.push({
					prepaid_card_id: card.prepaid_card_id,
					limits_id: card?.shared_balance_limits?.id || null,
				});

				return acc;
			}, []);
	}

	//
	const cardsTableCols = [
		{
			title: <Space>Select All {<Checkbox onChange={handleSelectAllCards} checked={!!selectedCards.length} />}</Space>,
			dataIndex: 'prepaid_card_id',
			key: 'selectAll',
			render: (value) => <Checkbox onChange={(evt) => handleSelectSingleCard(evt, value)} checked={selectedCards.includes(value)} />,
			align: 'center',
			width: 150,
		},
		{
			title: 'Card',
			dataIndex: 'prepaid_card_id',
			key: 'card',
			render: (v, record) => <Space direction='vertical' size={0}>
				{record?.card_holder?.name}
				{`**** **** **** ${record?.card_number}`}
			</Space>
		},
		{
			title: 'Daily ATM Limit',
			dataIndex: ['shared_balance_limits', 'max_atm_day_amount'],
			key: 'dailyATMLimit',
			render: (value) => value > 0 ? accountingFormat(value) : '-'
		},
		{
			title: 'Daily POS Limit',
			dataIndex: ['shared_balance_limits', 'max_pos_day_amount'],
			key: 'dailyPOSLimit',
			render: (value) => value > 0 ? accountingFormat(value) : '-'
		},
		{
			title: 'Currency',
			dataIndex: 'base_currency',
			key: 'currency',
			render: (value) => <Space size={4}>
				{FLAGS[value] && <Image src={FLAGS[value]} alt={value} preview={false} width={36} />}
				{value}
			</Space>
		},
		{
			title: '',
			dataIndex: 'prepaid_card_id',
			key: 'action',
			render: (v, record) => <Space direction='vertical'>
				<Button onClick={() => removeLimitsFor(record, 'atm')} loading={loading} disabled={!parseFloat(record?.shared_balance_limits?.max_atm_day_amount) > 0} type='primary'>Remove ATM Limit</Button>
				<Button onClick={() => removeLimitsFor(record, 'pos')} loading={loading} disabled={!parseFloat(record?.shared_balance_limits?.max_pos_day_amount) > 0} type='primary'>Remove POS Limit</Button>
			</Space>,
			align: 'right'
		}
	];

	//
	return (
		<>
			{/* <Row className="m-t-20" justify="center">
				<Col xl={8}>
					<Alert description="This page is still work in progress." showIcon message="WORK IN PROGRESS" type="error" />
				</Col>
			</Row> */}
			<Row className="m-t-20" justify="space-between" gutter={[16, 24]}>
				<Col>
					<Space wrap>
						<Typography.Text className="fs-18px medium">Daily ATM Limit</Typography.Text>
						<InputNumber
							value={atmAmount}
							onChange={(val) => handleChangeAmount(val, 'atmAmount')}
							min={0}
							style={{ width: '160px' }}
							placeholder="Enter Limit Value"
							controls={false}
							formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
							parser={(val) => numberFormat(val)}
						/>
						<Button onClick={() => handleApplyToSelected('atm')} loading={loading} disabled={!selectedCards.length || !atmAmount} type="primary">Apply to Selected</Button>
					</Space>
				</Col>
				<Col>
					<Space wrap>
						<Typography.Text className="fs-18px medium">Daily POS Limit</Typography.Text>
						<InputNumber
							value={posAmount}
							onChange={(val) => handleChangeAmount(val, 'posAmount')}
							min={0}
							style={{ width: '160px' }}
							placeholder="Enter Limit Value"
							controls={false}
							formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
							parser={(val) => numberFormat(val)}
						/>
						<Button onClick={() => handleApplyToSelected('pos')} loading={loading} disabled={!selectedCards.length || !posAmount} type="primary">Apply to Selected</Button>
					</Space>
				</Col>
				<Col>
					<Space direction="vertical">
						<Button onClick={() => handleRemoveFromSelected('atm')} loading={loading} disabled={!selectedCards.length} type="primary">Remove ATM Limit From Selected</Button>
						<Button onClick={() => handleRemoveFromSelected('pos')} loading={loading} disabled={!selectedCards.length} type="primary">Remove POS Limit From Selected</Button>
					</Space>
				</Col>
			</Row>

			<Row className="m-t-20">
				<Col xxl={4}>
					<Input.Search onChange={handleSearch} placeholder="Search By Card Holder" />
				</Col>
			</Row>

			<Row className="m-t-10">
				<Col span={24}>
					<CustomPagination
						loading={cardsQueryIsFetching || loading}
						onPageChange={handlePageChange}
						total={prepaidCards?.pager?.count ?? 1}
						pageSize={limit}
						current={currentPage}
						onPageSizeChange={handlePageSizeChange}
						singlePageMessage={`${prepaidCards?.pager?.count ?? 1} ${pluralize(prepaidCards?.pager?.count ?? 1, 'card', 'cards')} found.`}
					/>
					<CustomTable
						columns={cardsTableCols}
						dataSource={prepaidCards?.data || []}
						styleAllRows
						className="spaced-rows"
						headerColor="green"
						rowKey="prepaid_card_id"
						loading={cardsQueryIsFetching || loading}
					/>
					<CustomPagination
						loading={cardsQueryIsFetching || loading}
						onPageChange={handlePageChange}
						total={prepaidCards?.pager?.count ?? 1}
						pageSize={limit}
						current={currentPage}
						onPageSizeChange={handlePageSizeChange}
						singlePageMessage={`${prepaidCards?.pager?.count ?? 1} ${pluralize(prepaidCards?.pager?.count ?? 1, 'card', 'cards')} found.`}
					/>
				</Col>
			</Row>
		</>
	)
}

export default BulkDailyLimits;
