//useFrequentPrePaidCardsQuery

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { notification } from "antd";
import { useFundingHistoryDetail } from "../store";

const useCancelFunding = () => {
    const apiRequest = useApiRequest();
    const queryClient = useQueryClient();
    const setState = useFundingHistoryDetail(state => state.setState)

    const mutation = useMutation({
        mutationFn: ({ fundingID }) => {
            return apiRequest(`${APIROUTES.post.cancel_funding_history}/fund`, 'POST', {
                id: fundingID
            });
        },
        mutationKey: ['_funding_detail'],
        onMutate: () => { },
        onSuccess: (data, context) => {
            if (data?.response?.data?.error) {
                notification.error({ message: data?.response?.data?.error?.message, placement: 'top' });
            } else {
                queryClient.invalidateQueries({ queryKey: ['_funding_details', context.fundingID], exact: true });
                setState({
                    modalOpen: false,
                    modalOpenConfirmed: true
                });
            }
        },
        onError: (error) => {
            notification.error({ message: error.message, placement: 'top' });
        },
    });

    return mutation;
}

export { useCancelFunding };