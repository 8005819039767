import { Button, Checkbox, Col, Row, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { APIROUTES } from "Constants/ApiRoutes";
import { useApiRequest } from "Hooks/API";
import PageDescription from "App/Components/PageDescription";
import CustomTable from "App/Components/CustomTable";

const { Text } = Typography;

function UserRolesAndPermissions() {
  const apiRequest = useApiRequest();
  const [getUsersList, setUsersList] = useState([]);

  useEffect(() => {
    const fetchQuery = async () => {
      const { data } = await apiRequest(APIROUTES.get.users_manage);

      const newArrayOfObj = data.filter((item, index) => {
        return item.first_name !== null;
      });

      setUsersList(newArrayOfObj);
    };

    fetchQuery();
  }, [apiRequest]);

  var final_array = [];

  getUsersList.forEach((element) => {
    var newentries = {};
    if (element.user.role.permission_name === "Business User") {
      newentries["manage_primary_admins"] = false;
      newentries["manage_admin_business_users_card_user"] = false;
      newentries["signup_to_additional_services"] = false;
      newentries["view_latest_bills"] = false;
      newentries["manage_refund_account_details"] = false;
      newentries["manage_payments"] = true;
      newentries["manage_company_wallet"] = false;
      newentries["set_cards_controls"] = false;
      newentries["manage_transaction_categorization"] = false;
      newentries["first_name"] = element.first_name;
      newentries["userRole"] = element.user.role.permission_name;
      newentries["id"] = element.id;
      final_array.push(newentries);
    } else if (element.user.role.permission_name === "Administrator") {
      newentries["manage_primary_admins"] = false;
      newentries["manage_admin_business_users_card_user"] = true;
      newentries["signup_to_additional_services"] = true;
      newentries["view_latest_bills"] = true;
      newentries["manage_refund_account_details"] = true;
      newentries["manage_payments"] = true;
      newentries["manage_company_wallet"] = true;
      newentries["set_cards_controls"] = true;
      newentries["manage_transaction_categorization"] = true;
      newentries["userRole"] = element.user.role.permission_name;
      newentries["id"] = element.id;

      newentries["first_name"] = element.first_name;
      final_array.push(newentries);
    } else if (element.user.role.permission_name === "Primary Administrator") {
      newentries["manage_primary_admins"] = true;
      newentries["manage_admin_business_users_card_user"] = true;
      newentries["signup_to_additional_services"] = true;
      newentries["view_latest_bills"] = true;
      newentries["manage_refund_account_details"] = true;
      newentries["manage_payments"] = true;
      newentries["manage_company_wallet"] = true;
      newentries["set_cards_controls"] = true;
      newentries["manage_transaction_categorization"] = true;
      newentries["userRole"] = element.user.role.permission_name;
      newentries["id"] = element.id;

      newentries["first_name"] = element.first_name;
      final_array.push(newentries);
    } else {
      newentries["manage_primary_admins"] = false;
      newentries["manage_admin_business_users_card_user"] = false;
      newentries["signup_to_additional_services"] = false;
      newentries["view_latest_bills"] = false;
      newentries["manage_refund_account_details"] = false;
      newentries["manage_payments"] = false;
      newentries["manage_company_wallet"] = false;
      newentries["set_cards_controls"] = false;
      newentries["manage_transaction_categorization"] = false;
      newentries["userRole"] = element.user.role.permission_name;

      newentries["first_name"] = element.first_name;
      final_array.push(newentries);
    }
  });

  const tableProps = {
    columns: [
      {
        title: "User ID",
        dataIndex: "first_name",
        key: "id",
        width: 200,
        sorter: (a, b) =>
          a.first_name &&
          b.first_name &&
          a.first_name.localeCompare(b.first_name),
      },
      {
        title: "User Role",
        dataIndex: "userRole",
        key: "id",
        width: 200,
        sorter: (a, b) =>
          a.user.role && b.user.role && a.user.role.localeCompare(b.user.role),
        render: (val) => (
          <Text>{val}</Text>
          // <Text>{val.role.permission_name}</Text>
          // <Select
          //   bordered={false}
          //   style={{ width: "200px" }}
          //   className="fs-18px medium"
          //   key={val.role.id}
          //   value={val.role.permission_name}
          // >
          //    */}
          //   {/* {val.map((item, index) => {
          //     return ( */}
          //   <Select.Option key={val.role.id} value={val.role.name}>
          //     {val.role.permission_name}
          //   </Select.Option>
          //   {/* );
          //   })} */}
          // </Select>
        ),
      },

      {
        title: "Manage: Primary Administrators",
        dataIndex: "manage_primary_admins",
        key: "manage_primary_admins",
        align: "center",
        render: (val) => <Checkbox checked={val} />,
      },

      {
        title: "Manage: Administrators Business Users Card Users",
        dataIndex: "manage_admin_business_users_card_user",
        key: "manage_admin_business_users_card_user",
        align: "center",
        render: (val) => <Checkbox checked={val} />,
      },
      {
        title: "Signup to Additional Services",
        dataIndex: "signup_to_additional_services",
        key: "signup_to_additional_services",
        align: "center",
        render: (val) => <Checkbox checked={val} />,
      },
      {
        title: "Access to View Latest Bills",
        dataIndex: "view_latest_bills",
        key: "accessToViewBills",
        align: "center",
        render: (val) => <Checkbox checked={val} />,
      },
      {
        title: "Manage: Refund Account Details",
        dataIndex: "manage_refund_account_details",
        key: "manage_refund_account_details",
        align: "center",
        render: (val) => <Checkbox checked={val} />,
      },
      {
        title: "Manage: Payments",
        dataIndex: "manage_payments",
        key: "manage_payments",
        align: "center",
        render: (val) => <Checkbox checked={val} />,
      },
      {
        title: "Manage: Company Wallet",
        dataIndex: "manage_company_wallet",
        key: "manage_company_wallet",
        align: "center",
        render: (val) => <Checkbox checked={val} />,
      },
      {
        title: "Set Card Controls",
        dataIndex: "set_cards_controls",
        key: "set_cards_controls",
        align: "center",
        render: (val) => <Checkbox checked={val} />,
      },
      {
        title: "Manage: Transaction Categorisation",
        dataIndex: "manage_transaction_categorization",
        key: "manage_transaction_categorization",
        align: "center",
        render: (val) => <Checkbox checked={val} />,
      },
    ],
    dataSource: final_array,
    // dataSource: [
    //   {
    //     userID: "Nathan Mcdonald",
    //     userRole: "Primary Admin",

    //     primaryAdministrators: true,
    //     administratorsBusinessUsersCardUsers: true,
    //     signupToAdditionalServices: true,
    //     accessToViewBills: true,
    //     refundAccountDetails: true,
    //     payments: true,
    //     comapnyWallet: true,
    //     setCardControls: true,
    //     transactionCategorisation: true,
    //     key: 0,
    //   },

    //   {
    //     userID: "Ali Al Bajati",
    //     userRole: ["Primary Admin", "Admin", "Business User", "Card User"],

    //     primaryAdministrators: true,
    //     administratorsBusinessUsersCardUsers: true,
    //     signupToAdditionalServices: true,
    //     accessToViewBills: true,
    //     refundAccountDetails: true,
    //     payments: true,
    //     comapnyWallet: true,
    //     setCardControls: true,
    //     transactionCategorisation: true,
    //     key: 1,
    //   },

    //   {
    //     userID: "Harry Malstorm",
    //     userRole: ["Primary Admin", "Admin", "Business User", "Card User"],

    //     primaryAdministrators: true,
    //     administratorsBusinessUsersCardUsers: true,
    //     signupToAdditionalServices: true,
    //     accessToViewBills: true,
    //     refundAccountDetails: true,
    //     payments: true,
    //     comapnyWallet: true,
    //     setCardControls: true,
    //     transactionCategorisation: true,
    //     key: 2,
    //   },
    // ],
  };
  return (
    <>
      <Row>
        <Col span={24}>
          <PageDescription
            title="Manage User"
            text="- Manage your users access to your company prepaid cards and/or international bank payments platform, alongside maintaining user details."
          />
        </Col>
      </Row>
      <Row className="m-t-20">
        <Col span={24}>
          <CustomTable {...tableProps} />
        </Col>
      </Row>
      <Row justify="end" className="m-t-40">
        <Col>
          <Button type="primary">Save Permissions</Button>
        </Col>
      </Row>
    </>
  );
}

export default UserRolesAndPermissions;
