import FullScreenModal from "App/Components/FullScreenModal";
import PageDescription from 'App/Components/PageDescription';
import { Button, Card, Col, Image, Input, Row, Space, Typography } from "antd";
import CustomTable from "App/Components/CustomTable";
import { FLAGS } from "Constants/Images";
import { useNavigate } from "react-router-dom";
import URLS from "Routes/constants";
import { useCardsState } from "App/Pages/Cards/Cards/store";
import { debounce } from "lodash";
import { accountingFormat } from "Utils";
import { useEffect } from "react";
import CustomPagination from "../CustomPagination";
import { useActivePrepaidCardsQuery } from "App/Pages/Cards/query/useActivePrepaidCardsQuery";
import CardImage from "App/Components/CardImage";

function CardsDashboardModal({
    show = false,
    title = '',
    type = '',
    onToggle = () => { }
}) {

    const navigate = useNavigate();
    const { isLoading, isFetching } = useActivePrepaidCardsQuery(show);
    const currentPage = useCardsState(state => state.currentPage);
    const limit = useCardsState(state => state.limit);
    const busy = useCardsState(state => state.busy);
    const setState = useCardsState(state => state.setState);
    const prepaidCardsQueryData = useCardsState(state => state.prepaidCardsQueryData);

    const urlTo = (id) => {
        if (type === 'convert') {
            return `${URLS.ConvertCardBalances}/${id}`;
        } else if (type === 'fund') {
            return `${URLS.NewCardFund}/${id}`;
        } else { // withdraw NewCardFund
            return `${URLS.NewCardWithdrawal}/${id}`;
        }
    }

    useEffect(() => {
        !show && setState({ cardSearchFilter: null });

        return () => {
            setState({ cardSearchFilter: null, currentPage: 1, limit: 10, prepaidCardsQueryData: [] });
        }

        // eslint-disable-next-line
    }, [show]);

    //
    const columns = [
        {
            title: 'Card',
            dataIndex: 'card',
            key: 'card',
            width: 300,
            render: (v, record, i) => <Card className="b-2-g body-height-130 m-10 pointer" bodyStyle={{ padding: '0' }}>
                <Row justify="center" className="m-t-10">
                    <Col>
                        <CardImage cardProgram={record?.card_type} width={162} />
                    </Col>
                </Row>
            </Card>
        },
        {
            title: 'Name',
            dataIndex: ['card_holder', 'name'],
            key: 'card_holder_name',
            render: (value) => <Space direction="vertical" size={0}>
                <Typography.Text className="fs-18px regular">Card Holder Name</Typography.Text>
                <Typography.Text className="fs-18px medium dark-green">{value}</Typography.Text>
            </Space>
        },
        {
            title: 'Number',
            dataIndex: 'card_number',
            key: 'card_number',
            render: value => <Space direction="vertical" size={0}>
                <Typography.Text className="fs-18px regular">Card Number</Typography.Text>
                <Typography.Text className="fs-18px medium dark-green">**** **** **** {value}</Typography.Text>
            </Space>
        },
        {
            title: 'Expiry',
            dataIndex: 'expiry_date',
            key: 'expiry_date',
            render: (value) => <Space direction="vertical" size={0}>
                <Typography.Text className="fs-18px regular">Expiry Date</Typography.Text>
                <Typography.Text className="fs-18px medium dark-green">{value}</Typography.Text>
            </Space>
        },
        {
            title: 'Balances',
            dataIndex: 'balances',
            key: 'balances',
            onCell: () => ({
                colSpan: 3,
            }),
            render: (value) => <Space size={26}>
                <Space align="center" justify="between" size={4}>
                    {FLAGS.GBP && <Image src={FLAGS.GBP} preview={false} width={30} alt={`GBP`} />}
                    {'GBP'}
                    {accountingFormat(value.GBP)}
                </Space>
                <Space align="center" justify="between" size={4}>
                    {FLAGS.EUR && <Image src={FLAGS.EUR} preview={false} width={30} alt={`EUR`} />}
                    {'EUR'}
                    {accountingFormat(value.EUR)}
                </Space>
                <Space align="center" justify="between" size={4}>
                    {FLAGS.USD && <Image src={FLAGS.USD} preview={false} width={30} alt={`USD`} />}
                    {'USD'}
                    {accountingFormat(value.USD)}
                </Space>
            </Space>
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            render: (_value, record) => <Button onClick={() => {
                onToggle();
                navigate(urlTo(record.prepaid_card_id))
            }} type="primary">Confirm</Button>
        }
    ];

    //
    const handleCardSearch = debounce((evt) => {

        if (evt.target.value.length > 2) {
            setState({ cardSearchFilter: evt.target.value.toLowerCase(), currentPage: 1 });
        }

        if (evt.target.value.length < 1) {
            setState({ cardSearchFilter: null });
        }
    }, 400);

    return (
        <FullScreenModal open={show} onCancel={onToggle}>
            <Row className="m-t-40">
                <Col span={24}>
                    <PageDescription {...title} />
                </Col>
            </Row>
            <Row className="m-t-10">
                <Col xxl={4}>
                    <Input.Search onChange={handleCardSearch} placeholder="Search Card Holder / Card number" />
                </Col>
            </Row>
            <Row className="m-t-10">
                <Col span={24}>
                    <CustomTable rowKey="prepaid_card_id" loading={isLoading || isFetching} columns={columns} dataSource={prepaidCardsQueryData?.data ?? []} styleAllRows className='spaced-rows' showHeader={false} />
                    <CustomPagination
                        loading={busy}
                        onPageChange={(page) => setState({ currentPage: page })}
                        total={prepaidCardsQueryData?.pager?.count ?? 1}
                        pageSize={limit}
                        current={currentPage}
                        onPageSizeChange={(_, size) => setState({ limit: size, currentPage: 1 })}
                    />
                </Col>
            </Row>
        </FullScreenModal>
    )
}

export default CardsDashboardModal;
