import { FORMATS } from "Constants/Formats";
import { colors } from "Constants/Style";
import { Button, Col, Collapse, Row, Space, Typography } from "antd";
import moment from "moment";
import { BsFillCircleFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";

function UserAdded({ id, alert_json_object, onClear = () => { } }) {
  const status = 'Success';
  const date = alert_json_object?.date?.data;
  const name = alert_json_object?.name?.data;
  const role = alert_json_object?.role?.data;
  const title = alert_json_object?.title;

  return (
    <Collapse ghost className="bg-white">
      <Collapse.Panel className="full-percent-width-pannel-header" header={
        <>
          <Row align="middle" justify="space-between" gutter={16}>
            <Col>
              <Space align="center">
                <FaUser style={{ marginBottom: '-3px' }} size={64} className="dark-grey" />
                <Space direction="vertical" size={0}>
                  <Typography.Text className="fs-18px medium">{title}</Typography.Text>
                  <Typography.Text className="light-green muli semi-bold fs-18px">{name}</Typography.Text>
                </Space>
              </Space>
            </Col>
            <Col flex='auto' className="right-align-text">
              <Button type="primary" className="right-align-text big-rounded bg-green">
                <Space size='small'>
                  <BsFillCircleFill size={8} color={colors.successDot} />
                  {status}
                </Space>
              </Button>
            </Col>
          </Row>
        </>
      }
        showArrow={false}>
        <Row justify="space-between" gutter={12}>
          <Col>
            <Space direction="vertical" size={0}>
              <Typography.Text className="medium fs-18px">Role</Typography.Text>
              <Typography.Text className={"muli semi-bold fs-18px " + (
                role === "Business User"
                  ? "light-green"
                  : role === "Card User"
                    ? "dark-green"
                    : role === "Primary Administrator"
                      ? "purple"
                      : role === "Administrator"
                        ? "error-hover"
                        : ""
              )}>{role}</Typography.Text>
            </Space>
          </Col>
          <Col>
            <Space direction="vertical" size={0}>
              <Typography.Text className="medium fs-18px">Date Approved</Typography.Text>
              <Typography.Text className="light-green muli semi-bold fs-18px">
                {date && moment(date).isValid() ? moment(date).format(FORMATS.date) : ''}
              </Typography.Text>
            </Space>
          </Col>
        </Row>
        <Row justify="end" className="m-t-40">
          <Col>
            <Button type="primary" onClick={() => onClear(id)}>Clear</Button>
          </Col>
        </Row>
      </Collapse.Panel>
    </Collapse>
  )
}

export default UserAdded;