import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useCardTxLimitQuery = (cardId) => {
    const apiRequest = useApiRequest();

    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
        // max_transaction_type_id 1 - pos, 2 - atm
        const { data } = await apiRequest(`${APIROUTES.prepaidcard_maxtransaction}?prepaidCardId=${cardId}`);
        return data;
    }

    // eslint-disable-next-line no-unused-vars
    const _fetchQuery = async () => {
        return new Promise(resolve => {
            setTimeout(resolve([]), 1200);
        });
    }

    const query = useQuery({
        queryKey: ['_prepaidcard_maxtransaction_by_card_id', cardId],
        queryFn: fetchQuery,
        enabled: !!cardId,
        select: (resData) => {
            return ({
                pos: resData?.find(item => item.max_transaction_type_id === 1) || null,
                atm: resData?.find(item => item.max_transaction_type_id === 2) || null,
            })
        }
    });

    return query;
}

export { useCardTxLimitQuery };