/* eslint-disable react-hooks/exhaustive-deps */
import CustomPagination from "App/Components/CustomPagination";
import CustomTable from "App/Components/CustomTable";
import PageDescription from "App/Components/PageDescription";
import { useDraftPaymentsQuery } from "App/Pages/Payments/PaymentHistory/DraftPayments/query";
import { useDraftPaymentsState } from "App/Pages/Payments/PaymentHistory/DraftPayments/store";
import { FORMATS } from "Constants/Formats";
import { FLAGS } from "Constants/Images";
import { OPT_TTC_SELF_QUOTE_STATUS_ID } from "Constants/Opts";
import URLS from "Routes/constants";
import { accountingFormat } from "Utils";
import { Button, Col, DatePicker, Image, Input, Row, Space, Typography } from "antd";
import { debounce } from "lodash";
import moment from "moment";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function DraftPayments() {
  const navigate = useNavigate();
  const { data, isFetching } = useDraftPaymentsQuery();
  const limit = useDraftPaymentsState(s => s.limit);
  const current = useDraftPaymentsState(s => s.current);
  const total = useDraftPaymentsState(s => s.total);
  const setState = useDraftPaymentsState(s => s.setState);
  const resetState = useDraftPaymentsState(s => s.resetState);

  const handleDateChange = (_, dateFilter) => {
    setState({ dateFilter, current: 1 })
  }

  useEffect(() => {
    return () => { resetState() }
  }, []);

  const columns = [
    {
      title: <DatePicker bordered={false} style={{ width: '20px', padding: '0px' }} inputReadOnly onChange={handleDateChange} />,
      width: 20
    },
    {
      title: 'Submission Date',
      dataIndex: 'tcc_quote_b_b_self',
      key: 'submissionDate',
      render: (quote) => quote?.quote_create_time && moment(quote?.quote_create_time).format(FORMATS.date)
    },
    {
      title: 'Type',
      dataIndex: 'tcc_quote_b_b_self',
      key: 'type',
      render: quote => quote?.single_payment ? 'Single' : 'Batch'
    },
    {
      title: 'Payment Status',
      dataIndex: 'tcc_quote_b_b_self',
      key: 'paymentStatus',
      render: (quote) => quote?.opt_tcc_self_quote_status_id === OPT_TTC_SELF_QUOTE_STATUS_ID?.DRAFTED ?
        <Typography.Text type='warning'>Pending Approval</Typography.Text> :
        quote?.opt_tcc_self_quote_status_id === OPT_TTC_SELF_QUOTE_STATUS_ID.SCHEDULED_EXPIRED ?
          <Typography.Text type='danger'>Expired</Typography.Text> :
          <Typography.Text type="success">Scheduled for {quote?.delivery_date && moment(quote?.delivery_date).format(FORMATS.date)}</Typography.Text>
    },
    {
      title: 'Recipient',
      dataIndex: 'tcc_beneficiary_full',
      key: 'recipient',
      render: val => val?.beneficiary_entity_type === 'individual' ?
        val?.name :
        val?.beneficiary_company_name
    },
    {
      title: 'Amount sent to Volopa',
      dataIndex: 'tcc_quote_b_b_self',
      key: 'amountSent',
      align: 'left',
      render: (quote) => <Space>
        <div style={{ marginRight: '15px' }}></div>
        {FLAGS[quote?.sell_currency] && <Image src={FLAGS[quote?.sell_currency]} alt={quote?.sell_currency} preview={false} width={36} />}
        {accountingFormat(quote?.calc_client_send)}
        {quote?.sell_currency}
      </Space>
    },
    {
      title: 'Amount paid to Recipient',
      dataIndex: 'beneficiary_amt',
      key: 'amountPaid',
      align: 'left',
      render: (amount, row) => <Space>
        <div style={{ marginRight: '25px' }}></div>
        {FLAGS[row?.beneficiary_ccy] &&
          <Image
            src={FLAGS[row?.beneficiary_ccy]}
            alt={row?.beneficiary_ccy}
            preview={false} width={36}
          />
        }
        {accountingFormat(amount)}
        {row?.beneficiary_ccy}
      </Space>
    },
    {
      dataIndex: 'id',
      key: 'action',
      render: (id, row) =>
        <Button
          type='primary'
          onClick={() =>
            row?.tcc_quote_b_b_self?.opt_tcc_self_quote_status_id === OPT_TTC_SELF_QUOTE_STATUS_ID?.DRAFTED ?
              (
                row?.tcc_quote_b_b_self?.single_payment === 1 ?
                  navigate(`${URLS.NewPayment}/${row?.tcc_quote_b_b_self?.uuid}`) : navigate(`${URLS.PaymentSummary}/${row?.tcc_quote_b_b_self?.uuid}`)
              ) : navigate(`${URLS.SpecificPaymentHistory}/${id}`)
          }
          disabled={row?.tcc_quote_b_b_self?.opt_tcc_self_quote_status_id === OPT_TTC_SELF_QUOTE_STATUS_ID.SCHEDULED_EXPIRED}
        >
          Review
        </Button>,
      align: 'right'
    }
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <PageDescription title="Draft Payments" text='- Overview of international payments pending workflow approvals' />
        </Col>
      </Row>
      <Row className="m-t-10" justify="space-between">
        <Col xxl={4}>
          <Input.Search placeholder="Search" onChange={debounce((e => setState({ search: e?.target?.value })), 1000)} />
        </Col>
        <Col>
          <Button type="primary" onClick={() => navigate(URLS.PaymentHistory)}>Payment History</Button>
        </Col>
      </Row>
      <Row className="m-t-10">
        <Col span={24}>
          <CustomTable
            columns={columns}
            loading={isFetching}
            dataSource={data}
            rowKey="id"
          />
          <CustomPagination
            onPageChange={(current) => setState({ current })}
            onPageSizeChange={(current, limit) => setState({ current, limit })}
            pageSize={limit}
            total={total}
            current={current}
            loading={isFetching}
          />
        </Col>
      </Row>
    </>
  )
}

export { DraftPayments };