import { Button, Col, Row } from 'antd';
import PageDescription from 'App/Components/PageDescription';
import { useNavigate } from 'react-router-dom';
import URLS from 'Routes/constants';
import { pageContent } from './store/data';

function Terms() {
    const navigate = useNavigate();
    return (
        <>
            <Row>
                <Col span={24}>
                    <PageDescription title='Terms & Conditions' />
                </Col>
            </Row>
            <Row className='m-t-10' justify='end'>
                <Col>
                    <Button type='primary' onClick={() => navigate(URLS.Help)}>Return to Main Page</Button>
                </Col>
            </Row>
            <Row className='m-t-20' gutter={[12, 12]}>
                {pageContent && pageContent.length > 0 && pageContent.map((item, key) => (
                    <Col span={24} key={key}>
                        <a href={item.url} target='__blank'>
                            <PageDescription
                                title={item.title}
                                text={item.text}
                                defaultStyle={false}
                            />
                        </a>
                    </Col>
                ))}
            </Row>
        </>
    );
}

export default Terms;