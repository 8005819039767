import create from "zustand";

const usePaymentReportsState = create((set, get) => ({
    currentPage: 1,
    limit: 10,
    totalPages: 0,
    searchQuery: '',
    selected_account_statement_ids: [],
	selected_expense_report_ids: [],
	modalVisible: false,
	selectedFilter: null,
	selectedFromMonth: null,
	selectedToMonth: null,
	loading: false,
	downloadModal: false,
	downloadUrl: null,
	clearFilters: false,
	offset: 0,
	openFilter: false,
	selectedReports: [],
	format: 'xlsx',
    setState: (data) => set(data),
}));

export { usePaymentReportsState }