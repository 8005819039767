/* eslint-disable react-hooks/exhaustive-deps */
import { useDownloadAttachmentState } from "App/Pages/Cards/TransactionHistory/StatementsAndReports/DownloadAttachment/store";
import { useExtendedApiRequest } from "Hooks/ExtendedAPI";
import { urlObjectCreator } from "Utils";
import { Col, Row, Spin, Typography, notification } from "antd";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

function DownloadAttachment() {
  const { uuid } = useParams();
  const loading = useDownloadAttachmentState(s => s.loading);
  const setState = useDownloadAttachmentState(s => s.setState);
  const extendedApiRequest = useExtendedApiRequest();

  useEffect(() => {
    fetchAttachment();
  }, [])

  const fetchAttachment = async () => {
    setState({ loading: true });
    try {
      const res = await extendedApiRequest({
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        path: `/file/file?uuid=${uuid}`,
        method: 'GET',
        config: {
          responseType: "blob",
        },
        fullResponse: true,
      })

      if (res.status === 200) {
        const href = urlObjectCreator(res.data);
        const ext = res?.data?.type?.split('/')?.[1];
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `attachment.${ext}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }
      else {
        throw new Error('Something went wrong while fetching your attachment');
      }
    } catch (err) {
      notification.error({ placement: 'top', message: 'Something went wrong', description: 'Attachment not found' });
    } finally {
      setState({
        loading: false
      });
    }
  }

  return (
    <Row className="full-height" align="middle" justify="center">
      <Col className="center-align-text">
        {loading && <>
          <Spin size="large" tip={<Typography.Text className="medium fs-24px dark-green">Fetching your attachment. Please do not close this page.</Typography.Text>} />
        </>
        }
      </Col>
    </Row>
  );
}

export { DownloadAttachment };