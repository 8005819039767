import create from "zustand";

const useRateCheckerState = create((set) => ({
  timeout: null,
  fromCurrencies: null,
  toCurrencies: null,
  commonFromCurrencies: null,
  commonToCurrencies: null,
  rate: null,
  rateUid: null,
  loadingRate: false,
  loadingConvert: false,
  seconds: null,
  selectedFromCurrency: null,
  selectedToCurrency: null,
  toValue: null,
  fromValue: null,
  side: null,
  rateDone: null,
  setState: (data) => set(data),
}));

export { useRateCheckerState };