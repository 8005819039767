const dataSource = [
    {
        natureOfRequest: 'Shareholder Heirarchy',
        requestDescription: 'Please Upload a document that displays the corporate heirarchy for Capcom',
        documentUpload: false,
        status: 'Incomplete'
    },
    {
        natureOfRequest: 'Shareholder Heirarchy',
        requestDescription: 'Please Upload a document that displays the corporate heirarchy for Capcom',
        documentUpload: true,
        status: 'Submitted'
    }
];

export { dataSource };