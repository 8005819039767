import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useConfigurationState } from "App/Pages/Accounting/Configuration/store";
import { useLoginState } from "App/Pages/Login/store";

const useDeleteAccountingConfigMutation = () => {
    const apiRequest = useApiRequest();
    const queryClient = useQueryClient();
    const setDisconnectConfirmModalState = useConfigurationState(state => state.setDisconnectConfirmModalState);
    const getAuthHeader = useLoginState(state => state.getAuthHeader);
    const fetchConfig = useLoginState(state => state.fetchConfig);

    const mutation = useMutation({
        mutationFn: () => {
            return apiRequest(APIROUTES.delete_accounting_config, 'DELETE');
        },
        mutationKey: '_delete_accounting_config',
        onMutate: () => {
            setDisconnectConfirmModalState('loading', true);
        },
        onSuccess: async (data) => {
            if (data?.response?.status === 400) {
                throw Error(data);
            }
            const auth = await getAuthHeader();
            fetchConfig(auth);
            queryClient.invalidateQueries({ queryKey: ['_accounting_company_map'], exact: true });
            setDisconnectConfirmModalState('message', 'Your Volopa account has been successfuly disconnected.');
            setDisconnectConfirmModalState('loading', false);
        },
        onError: (error) => {
            setDisconnectConfirmModalState('loading', false);
        },
    });

    return mutation;
}

export { useDeleteAccountingConfigMutation };