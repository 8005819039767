import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
// eslint-disable-next-line
import { notification } from "antd";
import { useManageSpecificCardState } from "App/Pages/Cards/Cards/ManageSpecificCard/store";

const useEnableDisableManualPayments = ({cardId, method}) => {
    const apiRequest = useApiRequest();
    const queryClient = useQueryClient();
    const setManageCardState = useManageSpecificCardState((s) => s.setState);

    const mutation = useMutation({
        mutationFn: ({cardId, method}) => {
            const url = method !== 'DELETE' ? `${APIROUTES.post.prepaidcard_user_pan_features}` : `${APIROUTES.del.prepaidcard_user_pan_features}`;

            return apiRequest(url, method, {
                prepaid_card_id: parseInt(cardId),
                card_feature_id: 7
            });
        },
        mutationKey: ['_prepaidcard_user_pan_features', cardId, method],
        onMutate: () => {},
        onSuccess: (data, context) => {
            if(data?.data) {
                queryClient.invalidateQueries({ queryKey: ['_prepaidcard', context.cardId], exact: true});
                setManageCardState({
                    modalContent: 'returnToDashboard',
                    modalOpen: true,
                    returnToDashboardCardHeading: `CARD ${context.method === 'DELETE' ? 'DISABLE': 'ENABLE'}`,
                    returnToDashboardCardDescription: `Manual Payment has been ${context.method === 'DELETE' ? 'disabled' : 'enabled'} on CARD *${context.cardId}`,
                    returnToDashboardCardBold: '',
                });
			}else {
                if(data?.response?.data?.error?.error_number === 3) {
                    notification.error({ message: "This card can't be Enable/Disable by current user.", placement: 'top' });
                } else {
                    notification.error({ message: data?.response?.data?.error?.message, placement: 'top' });
                }
            }
           
        },
        onError: (error) => {
            if(error?.error_number === 3) {
                notification.error({ message: "This card can't be Enable/Disable by current user.", placement: 'top' });
            } else {
                notification.error({ message: error?.message, placement: 'top' });
            }
        },
    });

    return mutation;
}

export { useEnableDisableManualPayments };