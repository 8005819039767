import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useSpecificUserAccessQuery = (userId) => {
  const apiRequest = useApiRequest();

  const fetchQuery = async () => {
    const data = await Promise.all([apiRequest(`${APIROUTES.get.user_level_access}${userId}`), apiRequest(`${APIROUTES.get.user_access}${userId}`)]);
    return data;
  }

  const query = useQuery({
    queryKey: ['_user_access', userId],
    queryFn: fetchQuery,
    enabled: !!userId,
    select: (res) => {
      let key = 0;
      const serviceDropdown = res?.[0]?.data?.grantable?.map((val) => (
        {
          id: key++,
          type: 'service',
          type_id: val?.opt_user_level_access_id,
          account_name: val?.option
        }
      ));
      const accountDropdown = res?.[1]?.data?.dropdown?.map((val) => (
        {
          id: key++,
          type: 'account',
          type_id: val?.id,
          account_name: val?.account_name
        }
      ));
      key = 0
      const serviceExisting = res?.[0]?.data?.granted?.map((val) => (
        {
          id: key++,
          type: 'service',
          type_id: val?.user_level_access_id,
          account_name: val?.option
        }
      ));
      const accountExisting = res?.[1]?.data?.existing?.map((val) => (
        {
          id: key++,
          type: 'account',
          type_id: val?.id,
          account_name: val?.account_name
        }
      ));
      let response = {
        dropdown: [...serviceDropdown, ...accountDropdown],
        existing: [...serviceExisting, ...accountExisting]
      }
      return response
    }
  });

  return query;
}

export { useSpecificUserAccessQuery };