import { AiOutlineLeftCircle, AiFillRightCircle } from 'react-icons/ai';
import { Card, Carousel, Col, Row, Space, Typography, Spin, Grid, Empty } from "antd";
import { useRef } from 'react';
import { useFrequentPrepaidCardsQuery } from "App/Pages/Cards/query";
import URLS from "Routes/constants";
import { useNavigate } from 'react-router-dom';
import CardImage from 'App/Components/CardImage';

const { useBreakpoint } = Grid;

const FrequentCardsCarousel = () => {
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();
  const { data: frequentCards, isLoading: frequentCardsLoading } = useFrequentPrepaidCardsQuery();
  const slidesToShow = frequentCards?.length < 3 ? frequentCards?.length : (breakpoint.lg === false ? (breakpoint.sm === false ? 1 : 2) : 3);
  const slider = useRef();

  return (
    <Card hoverable className='default-cursor'>
      {
        frequentCardsLoading
          ? (
            <Spin size="large" />
          ) : (<>
            {frequentCards?.length > slidesToShow &&
              <Row>
                <Col span={24} className='right-align-text'>
                  <Space size='middle' className='dark-green' style={{ fontSize: '15px' }}>
                    <AiOutlineLeftCircle size={18} onClick={() => slider.current.prev()} />
                    <AiFillRightCircle size={18} onClick={() => slider.current.next()} />
                  </Space>
                </Col>
              </Row>
            }
            <Row style={{ overflow: 'hidden' }}>
              <Col style={{ minWidth: '300px' }} span={24}>
                {frequentCards?.length ?
                  <Carousel
                    slidesToShow={breakpoint.lg === false ? (breakpoint.md === false ? 1 : 2) : 3}
                    dots={false}
                    ref={ref => { slider.current = ref }}
                  >

                    {frequentCards?.map((card) => (
                      <div key={card.prepaid_card_id}>
                        <div
                          onClick={() => navigate(`${URLS.ManageSpecificCard}/${card.prepaid_card_id}`)}
                          className='m-r-20 m-l-20 pointer'
                        >
                          <Typography.Text className="fs-18px medium p-l-20">Manage</Typography.Text>
                          <Card className="b-2-grey body-height-130 m-10" bodyStyle={{ padding: '0' }}>
                            <Row justify="center" className='m-t-20'>
                              <Col>
                                <CardImage cardProgram={card?.card_type} width={162} />
                              </Col>
                            </Row>
                          </Card>
                          <Typography.Text className="fs-18px medium dark-green p-l-20">{card.cardholder_name}</Typography.Text>
                          <Typography.Text className="fs-18px medium dark-green right-align-text p-r-20">
                            **** **** **** {card.card_number}
                          </Typography.Text>
                        </div>
                      </div>
                    ))}

                    {/* if data length is smaller than slidesToShow,
                    	Carousel acts weird so we add empty divs to match up */}
                    {frequentCards.length === 1 && <div><div className="m-r-20"><div className="body-height-130 m-10"></div></div></div>}
                    {frequentCards.length === 1 && <div><div className="m-r-20"><div className="body-height-130 m-10"></div></div></div>}
                    {frequentCards.length === 2 && <div><div className="m-r-20"><div className="body-height-130 m-10"></div></div></div>}
                  </Carousel>
                  :
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
              </Col>
            </Row>
          </>)
      }
    </Card>
  )
}

export default FrequentCardsCarousel;
