import { cloneDeep } from "lodash";
import create from "zustand";

const useCompanyWalletBalanceState = create((set, get) => ({
    balances: [],
    allVisible: false,
    total: 0,
    visibleBalances: () => {

        let balanceDataFirst = cloneDeep(get().balances).filter((data) => data.rate === 1);
        let balanceDataRest = cloneDeep(get().balances).filter((data) => data.rate !== 1);
        let balanceData = [];
        Array.prototype.push.apply(balanceDataFirst, balanceDataRest);
        balanceData = balanceDataFirst;

        if (get().allVisible) {
            return balanceData ?? [];
        }

        return !!balanceData.length ? (balanceData.length > 6 ? balanceData.slice(0, 6) : balanceData) : [];
    },
    setState: data => set(data)
}));

export { useCompanyWalletBalanceState };