import create from "zustand";

const useFxForwardsState = create(set => ({
  selectedFiles: [],
  dowloading: false,
  limit: 10,
  currentPage: 1,
  setState: (data) => set(data),
  resetState: () => set({
    selectedFiles: [],
    downoading: false,
    limit: 10,
    currentPage: 1,
  })
}));

export { useFxForwardsState };