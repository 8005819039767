const MESSAGES = {
  company_wallet_status: {
    title: "Company Wallet User Access:",
    message: [
      "Your User Profile has not been permitted access to the Company Wallet.",
      "Please contact your Administrator to grant you the necessary permissions."
    ]
  },
  payments_status: {
    title: "International Payments User Access:",
    message: [
      "Your User Profile has not been permitted access to International Payments.",
      "Please contact your Administrator to grant you the necessary permissions"
    ]
  },
  cards_status: {
    title: "Cards User Access:",
    message: [
      "Your User Profile has not been permitted access to Cards.",
      "Please contact your Administrator to grant you the necessary permissions"
    ]
  },
  fx_forwards_status: {
    title: "FX Forwards User Access:",
    message: [
      "You User Profile has not been permitted access to FX Forwards.",
      "Please contact your Administrator to grant you the necessary permissions"
    ]
  },
  accounting_status: {
    title: "Accounting User Access:",
    message: [
      "Your User Profile has not been permitted access to Accounting.",
      "Please contact your Administrator to grant you the necessary permissions."
    ]
  },
  other: {
    title: [
      "Oops! Looks like something went wrong.",
      "Please contact Volopa Support"
    ]
  }
}

export { MESSAGES }