import { Button, Checkbox, notification } from "antd";
import { useEffect } from "react";
import { FaRegTimesCircle, FaRegCheckCircle } from "react-icons/fa";
import { useDisabledPaymentTypesQuery } from "Hooks/Queries";
import { isEqual } from "lodash";
import { useDisablePaymentTypesMutation } from "Hooks/Mutations";
import { useQueryClient } from "@tanstack/react-query";
import { OPT_DISABLED_PAYMENT_TYPES } from "Constants/Opts";
import { useCardPaymentTypesState } from "Hooks/Store";
import CustomTable from "App/Components/CustomTable";
import { colors } from "Constants/Style";


function AuthorisedPaymentTypes({ cardId, disabledFlag = false }) {
  const editMode = useCardPaymentTypesState(state => state.editMode);
  const setState = useCardPaymentTypesState(state => state.setState);
  const initialData = useCardPaymentTypesState(state => state.initialData);
  const disabled = useCardPaymentTypesState(state => state.disabled);
  const paymentTypeIds = useCardPaymentTypesState(state => state.paymentTypeIds);
  const loading = useCardPaymentTypesState(state => state.loading);
  const resetCardPaymentTypesState = useCardPaymentTypesState(state => state.reset);

  const disabledPaymentTypesQuery = useDisabledPaymentTypesQuery(cardId);
  const disablePaymentTypesMutation = useDisablePaymentTypesMutation(payload => payload);
  const queryClient = useQueryClient();

  //
  useEffect(() => {
    return () => {
      resetCardPaymentTypesState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  useEffect(() => {
    if (disabledPaymentTypesQuery.data) {
      setState({
        disabled: disabledPaymentTypesQuery?.data?.reduce((acc, item) => { acc.push({ typeId: item.opt_disable_type_id, id: item.id }); return acc }, []),
        initialData: disabledPaymentTypesQuery?.data?.reduce((acc, item) => { acc.push({ typeId: item.opt_disable_type_id, id: item.id }); return acc }, []),
      });
    }

    // eslint-disable-next-line
  }, [disabledPaymentTypesQuery.data]);

  //
  const initStateChanged = () => {
    return !isEqual(
      initialData,
      disabled
    );
  }

  //
  const handleActionBtnClick = async () => {
    // Def rethink the implementation...
    if (initStateChanged()) {
      const mutations = [];
      paymentTypeIds.forEach(typeId => {
        const existed = initialData.find(item => item.typeId === typeId);
        const inDisabled = disabled.find(item => item.typeId === typeId);
        const action = inDisabled ? (existed ? null : 'POST') : (existed ? 'DELETE' : null);

        if (!action) return;

        mutations.push(disablePaymentTypesMutation.mutateAsync(
          {
            cardId,
            typeId: typeId,
            recordId: existed?.id || null,
            method: action,
          },
          { onMutate: () => setState({ loading: true }), }
        ));
      });

      try {
        await Promise.all(mutations);
        notification.success({ message: 'Payment types updated!', placement: 'top' });
      } catch (err) {
        notification.error({ message: 'Not all changes where saved. Please check and try again.', placement: 'top' });
      } finally {
        queryClient.invalidateQueries({ queryKey: ['_prepaid_cards'], exact: true });
        queryClient.invalidateQueries({ queryKey: ['_prepaidcard_disabledtxtype_by_card_id', cardId] });
      }
    }

    setState({ loading: false, editMode: !editMode });
  }

  //
  const handleCheckboxChange = (evt, key) => {
    setState({
      disabled: evt.target.checked
        ? [...disabled.filter(item => item.typeId !== OPT_DISABLED_PAYMENT_TYPES[key])]
        : [...disabled, { id: null, typeId: OPT_DISABLED_PAYMENT_TYPES[key] }]
    });
  }

  //
  const columns = [
    {
      title: "ATM Withdrawals",
      dataIndex: "ATMWithdrawals",
      key: "ATMWithdrawals",
      align: "center",
      width: 200,
      render: (value) => !editMode
        ? (value ? <FaRegCheckCircle size={24} color={colors.success} style={{ marginBottom: '-5px', transform: 'translateY(1px)' }} /> : <FaRegTimesCircle size={24} color={colors.danger} style={{ marginBottom: '-5px' }} />)
        : <Checkbox defaultChecked={value} onChange={(evt) => handleCheckboxChange(evt, 'atmWithdraw')}></Checkbox>,
    },
    {
      title: "E-Commerce",
      dataIndex: "ECommerce",
      key: "ECommerce",
      align: "center",
      render: (value) => !editMode
        ? (value ? <FaRegCheckCircle size={24} color={colors.success} style={{ marginBottom: '-5px', transform: 'translateY(1px)' }} /> : <FaRegTimesCircle size={24} color={colors.danger} style={{ marginBottom: '-5px' }} />)
        : <Checkbox defaultChecked={value} onChange={(evt) => handleCheckboxChange(evt, 'ecom')}></Checkbox>,
    },
    {
      title: "Contactless",
      dataIndex: "contactless",
      key: "contactless",
      align: "center",
      render: (value) => !editMode
        ? (value ? <FaRegCheckCircle size={24} color={colors.success} style={{ marginBottom: '-5px', transform: 'translateY(1px)' }} /> : <FaRegTimesCircle size={24} color={colors.danger} style={{ marginBottom: '-5px' }} />)
        : <Checkbox defaultChecked={value} onChange={(evt) => handleCheckboxChange(evt, 'contactless')}></Checkbox>,
    },
    {
      title: "",
      dataIndex: "key",
      key: "action",
      render: () => (
        <Button type="primary" loading={loading} onClick={handleActionBtnClick} disabled={disabledFlag}>
          {editMode ? (initStateChanged() ? 'Save' : 'Cancel') : 'Edit'}
        </Button>
      ),
      align: "right",
    },
  ];

  //
  const dataSource = [
    {
      ATMWithdrawals: disabled.find(item => item.typeId === OPT_DISABLED_PAYMENT_TYPES.atmWithdraw) ? false : true,
      ECommerce: disabled.find(item => item.typeId === OPT_DISABLED_PAYMENT_TYPES.ecom) ? false : true,
      contactless: disabled.find(item => item.typeId === OPT_DISABLED_PAYMENT_TYPES.contactless) ? false : true,
      key: 0,
    },
  ];

  return (
    <>
      <CustomTable loading={loading} columns={columns} dataSource={dataSource} />
    </>
  );
}

export default AuthorisedPaymentTypes;
