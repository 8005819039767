import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useAccountingTransactionCards = () => {
    const apiRequest = useApiRequest();

    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
        let url = `${APIROUTES.get.accounting_transaction_cards}?$with=All`;
        const data = await apiRequest(url);
        return data;
    }


    const query = useQuery({
        queryKey: ['_accounting_transaction_cards_all'],
        queryFn: fetchQuery,
    });

    return query;
}

export { useAccountingTransactionCards };