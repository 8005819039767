import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useTrackingCodesQuery = () => {
  const apiRequest = useApiRequest();
  const fetchQuery = async () => {
    const { data } = await apiRequest(`${APIROUTES.get.tracking_codes}?$limit=-1`);
    return data;
  }

  const query = useQuery({
    queryKey: ['_tracking_codes'],
    queryFn: fetchQuery
  });

  return query;
}

export { useTrackingCodesQuery };