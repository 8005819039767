import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { FLAGS } from "Constants/Images";
import { useConvertBalancesState } from "App/Pages/Wallet/ConvertBalances/store";

const useCurrenciesQuery = () => {
    const apiRequest = useApiRequest();
    const setConvertBalancesState = useConvertBalancesState((state) => state.setState);


    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
        const { data } = await apiRequest(`${APIROUTES.get.currency}?$limit=500&active=1`);
        return data;
    }

    // eslint-disable-next-line no-unused-vars
    const _fetchQuery = async () => {
        return new Promise(resolve => {
            setTimeout(resolve([]), 1200);
        });
    }

    const query = useQuery({
        queryKey: ['_currency'],
        queryFn: fetchQuery,
        onSuccess: (resData) => {
            setConvertBalancesState({ currencies: resData });
        },
        select: (resData) => {
            return resData
                .filter((data) => data.active_card === 1)
                .sort((a, b) => a.currency.localeCompare(b.currency))
                .map((val) => {
                    return ({
                        ...val,
                        flag: FLAGS[val.currency]
                    });
                });
        }
    });

    return query;
}

export { useCurrenciesQuery };
