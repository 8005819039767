import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useRecipientDetailsQuery = (id, refund) => {
  const apiRequest = useApiRequest();

  // eslint-disable-next-line no-unused-vars
  const fetchQuery = async () => {
    const route = refund ? APIROUTES.get.refund_recipient : APIROUTES.get.recipient_details;
    const { data } = await apiRequest(`${route}${id}`);
    return data;
  }


  const query = useQuery({
    queryKey: ['_recipient_details' + id + refund],
    queryFn: fetchQuery,
    refetchOnMount: 'always',
    enabled: !!id
  });

  return query;
}

export { useRecipientDetailsQuery };