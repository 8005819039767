import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useOverrideCodatTaxIdMutation = ({catId, itemId}) => {
    const apiRequest = useApiRequest();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: ({taxId, itemId}) => {
            return apiRequest(`${APIROUTES.put.expenses_override_codat_tax_id}${itemId}`, 'PUT', {overrideCodatTaxId: taxId});
        },
        mutationKey: ['_put_expenses_override_codat_tax_id'],
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['_get_accounting_transactions_expenses'], exact: false});
        }
    });

    return mutation;
}

export { useOverrideCodatTaxIdMutation };