import create from 'zustand';

const useAddNewUserState = create(set => ({
    orderCard: true,
    residentialDelivery: false,
    role_id: '',
    user_email_address: '',
    currency_list: '',
    setState: obj => set(obj)
}));

export { useAddNewUserState };