import create from "zustand";
import { accountingFormat } from "Utils";

const useCardAutofundState = create((set, get) => ({
    editMode: false,
    setEditMode: val => set({ editMode: val }),
    setState: (data) => set(data),
    amountBelow: '',
    amountBelowTxt: () => get().amountBelow >= 0 ? accountingFormat(get().amountBelow) : undefined,
    amountLoad: '',
    amountLoadTxt: () => get().amountLoad > 0 ? accountingFormat(get().amountLoad) : undefined,
    amountLoadTo: '',
    amountLoadToTxt: () => get().amountLoadTo > 0 ? accountingFormat(get().amountLoadTo) : undefined,
    autoloadType: '',
    initialData: null,
    validate: () => {
        return !!get().amountBelow >= 0 && (!!get().amountLoad > 0 || !!get().amountLoadTo > 0) && !!get().autoloadType > 0;
    },
    reset: () => set({
        amountBelow: '',
        amountLoad: '',
        amountLoadTo: '',
        autoloadType: '',
        initialData: null,
    })
}));

export { useCardAutofundState }