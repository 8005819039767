import { useNavbarState } from "App/Components/NavBar/store";
import { usePersonalCardsDashState } from "App/Pages/Cards/PersonalCardsDashboard/store";
import { usePrepaidCardsQuery } from "App/Pages/Cards/query";
import { APIROUTES } from "Constants/ApiRoutes";
import { NOTIFICATIONS } from "Constants/Common";
import { useApiRequest } from "Hooks/API";
import URLS from "Routes/constants";
import { accountingFormat } from "Utils";
import { Button, Card, Col, Row, Space, Typography } from "antd";
import { BsFillCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function CardTransaction({ acknowledged, alert_json_object, id, platform_alert_format_id }) {
  const title = alert_json_object?.title;
  const status = "Declined";
  const currency = alert_json_object?.ccy_code?.data;
  const amount = alert_json_object?.amount?.data;
  const merchantName = alert_json_object?.merchant_name?.data;
  const cardId = alert_json_object?.card?.data || alert_json_object?.card_id?.data;
  const apiRequest = useApiRequest();
  const setNavbarState = useNavbarState(state => state.setState);
  const navigate = useNavigate();

  const { data: cards, isLoading: cardsLoading } = usePrepaidCardsQuery(true, true);
  const setPersonalCardsState = usePersonalCardsDashState((state) => state.setState);

  const handleAction = () => {
    apiRequest(`${APIROUTES.put.notifications}${id}`, 'PUT', { acknowledged: 1 });
    setNavbarState({ notificationsOpen: false });
    const currentCardIdx = cards?.data?.findIndex(card => +card?.prepaid_card_id === +cardId);
    setPersonalCardsState({ currentCardIdx });
    navigate(`${URLS.ManageSpecificCard}/${cardId}`)
  }
  return (
    <Card className='bg-extra-light-grey'>
      <Row className={acknowledged ? 'm-l-20' : ''}>
        <Col flex='auto'>
          <Space size={10}>
            {!acknowledged && <BsFillCircleFill size={10} className='light-green' />}
            <Space direction="vertical" size={0}>
              <Typography.Text className="fs-20px regular">{title}</Typography.Text>
              {platform_alert_format_id === NOTIFICATIONS.cardTransaction && <Typography.Text className="fs-20px bold" type={status === 'Declined' && 'danger'}>{status}</Typography.Text>}
            </Space>
          </Space>
        </Col>
      </Row>
      <Row className="m-t-5">
        <Col flex='auto' className='m-l-20'>
          <Typography.Text className="fs-20px regular">
            Your transaction for <span className='bold'>{currency} {accountingFormat(amount)}</span> at <span className="bold">{merchantName} </span>
            was {status.toLowerCase()} because
            {
              platform_alert_format_id === NOTIFICATIONS.cardTransaction ?
                <> your card is <Typography.Text className="bold" type="danger">Frozen</Typography.Text></> :
                platform_alert_format_id === NOTIFICATIONS.dailySpendLimit ?
                  <> you have exceeded your daily spend limit. Please try again later</> :
                  platform_alert_format_id === NOTIFICATIONS.dailyTransactionLimit ?
                    <> you have exceeded your daily transaction limit. Please try again later</> :
                    platform_alert_format_id === NOTIFICATIONS.contactlessLimit ?
                      <> it <Typography.Text className="bold" type="danger">exceeded</Typography.Text> the contactless limits. Please try the transaction again with CHIP & PIN.</> :
                      platform_alert_format_id === NOTIFICATIONS.manualPayment ?
                        <> it was processed as a Manual Payment. Please contact your administrator to enable this payment type for you</> :
                        platform_alert_format_id === NOTIFICATIONS.swipeAndSign &&
                        <> it was processed as a Swipe & Sign transaction. Please contact your administrator to enable this payment type for you</>
            }
          </Typography.Text>
        </Col>
      </Row>
      {
        platform_alert_format_id === NOTIFICATIONS.cardTransaction &&
        <Row className="m-t-5">
          <Col flex='auto' className='m-l-20'>
            <Typography.Text className="fs-20px regular">
              <span className='bold'>Unfreeze</span> your card and try again
            </Typography.Text>
          </Col>
        </Row>
      }
      <Row justify='end' className="m-t-10">
        <Col>
          <Button type='primary' loading={cardsLoading} onClick={handleAction}>Manage Card</Button>
        </Col>
      </Row>
    </Card>
  );
}

export default CardTransaction;