/* eslint-disable react-hooks/exhaustive-deps */
import { useLoginState } from "App/Pages/Login/store";
import { apiRequest } from "Hooks/API";
import URLS from "Routes/constants";
import { makeAccessTokenExpiryTime } from "Utils";
import { Col, Row, Spin, notification } from "antd";
import { encode } from "base-64";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

function Impersonate() {
  const { token } = useParams();
  const setState = useLoginState(state => state.setState);
  const fetchConfig = useLoginState(state => state.fetchConfig);
  const navigate = useNavigate();

  useEffect(() => {
    validateToken();
  }, []);

  const validateToken = async () => {
    localStorage.clear(process.env.REACT_APP_LOCALSTORAGE_REFRESH_TOKEN_KEY);
    localStorage.clear(process.env.REACT_APP_LOCALSTORAGE_O2_CLIENT_ID_KEY);

    setState({
      accessToken: {
        token: undefined,
        type: undefined,
        expires: undefined,
      },
      roleId: undefined,
    });
    const payload = {
      hash: token
    };
    const res = await apiRequest('oauth2/impersonate-user', 'POST', payload);
    if (res?.data?.details?.clientId && res?.data?.details?.secret) {
      const clientId = res?.data?.details?.clientId;
      const secret = res?.data?.details?.secret;
      const authorization = 'Basic ' + encode(clientId + ':' + secret);
      const path = 'oauth2/token';
      const data = {
        scope: 'app',
        grant_type: 'client_credentials'
      };

      const tokenRes = await apiRequest(path, 'POST', data, authorization);
      if (tokenRes) {
        localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_REFRESH_TOKEN_KEY, tokenRes?.refresh_token);
        localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_O2_CLIENT_ID_KEY, clientId);

        setState({
          accessToken: {
            token: tokenRes.access_token,
            type: tokenRes.token_type,
            expires: makeAccessTokenExpiryTime(tokenRes?.expires_in),
          },
          roleId: tokenRes?.role_id,
        });

        await fetchConfig(`${tokenRes?.token_type} ${tokenRes?.access_token}`);
        navigate(URLS.WalletDashboard);
      } else {
        handleError();
      }
    } else {
      handleError();
    }
  }

  const handleError = () => {
    notification.error({ message: 'Error', description: 'User simulation failed', placement: 'top' });
    navigate(URLS.Login);
  }

  return (
    <Row className="full-height" align="middle" justify='center'>
      <Col>
        <Spin size="large" spinning={true} tip='Fetching user details....' />
      </Col>
    </Row>
  );
}

export { Impersonate };