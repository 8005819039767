import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { notification } from "antd";

const useCardAutofundUpdateMutation = ({autoloadId, payload, cardId}) => {
    const apiRequest              = useApiRequest();
    const queryClient             = useQueryClient();

    const mutation = useMutation({
        mutationFn: ({autoloadId, payload, cardId}) => {
            return apiRequest(`${APIROUTES.put.prepaidcard_autoload}/-${autoloadId}`, 'PUT', payload);
        },
        mutationKey: ['_prepaidcard_autoload_update', autoloadId],
        onMutate: () => {},
        onSuccess: (data, context) => {
            queryClient.invalidateQueries({ queryKey: ['_prepaidcard_autoload', context.cardId], exact: true});
            notification.success({ message: 'Autofund amounts saved!',  placement: 'top' });
        },
        onError: (error) => {
            notification.error({ message: 'Something went wrong. Please try again.',  placement: 'top' });
        },
    });

    return mutation;
}

export { useCardAutofundUpdateMutation };