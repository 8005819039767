import { Button, Card, Col, Dropdown, Image, Input, Menu, Radio, Row, Space, Typography, notification, InputNumber } from "antd";
import PageDescription from "App/Components/PageDescription";
import { FLAGS } from "Constants/Images";
import CustomTable from "App/Components/CustomTable";
import { useNavigate } from "react-router-dom";
import URLS from "Routes/constants";
import { useCardsState } from "./store";
import FullScreenModal from "App/Components/FullScreenModal";
import CardDetails from "App/Components/CardDetails";
import { usePrepaidCardsQuery } from "App/Pages/Cards/query";
import { debounce } from "lodash"
import { useEffect } from "react";
import CustomPagination from "App/Components/CustomPagination";
import { pluralize, accountingFormat, zeroPadStartExpiryDate } from "Utils";
import { DownOutlined } from "@ant-design/icons";
import { useManageSpecificCardState } from "App/Pages/Cards/Cards/ManageSpecificCard/store";
import { useDisabledPaymentTypesQuery, useCardTxLimitQuery } from "Hooks/Queries";
import { isEqual } from "lodash";
import { useDisablePaymentTypesMutation, useTxLimitsMutation } from "Hooks/Mutations";
import { useQueryClient } from "@tanstack/react-query";
import CardBalanceListing from "App/Components/CardBalanceListing";
import { OPT_DISABLED_PAYMENT_TYPES } from "Constants/Opts";
import CardImage from "App/Components/CardImage";


// @todo: reduce line count, refactor to components
function Cards() {

	const navigate = useNavigate();

	const modalVisible = useCardsState(state => state.modalVisible);
	const modalType = useCardsState(state => state.modalType);
	const busy = useCardsState(state => state.busy);
	const currentPage = useCardsState(state => state.currentPage);
	const limit = useCardsState(state => state.limit);
	const setState = useCardsState(state => state.setState);

	const setManageSpecificCardState = useManageSpecificCardState(state => state.setState);
	const modalActionBusy = useManageSpecificCardState(state => state.modalActionBusy);
	const manageCard = useManageSpecificCardState(state => state.manageCard);
	const disabledPaymentTypes = useManageSpecificCardState(state => state.disabledPaymentTypes);
	const initDisabledPaymentTypes = useManageSpecificCardState(state => state.initDisabledPaymentTypes);
	const txLimitPosAmount = useManageSpecificCardState(state => state.txLimitPosAmount);
	const txLimitPos = useManageSpecificCardState(state => state.txLimitPos);
	const txLimitAtmAmount = useManageSpecificCardState(state => state.txLimitAtmAmount);
	const txLimitAtm = useManageSpecificCardState(state => state.txLimitAtm);
	const initTxLimitData = useManageSpecificCardState(state => state.initTxLimitData);

	const disabledPaymentTypesQuery = useDisabledPaymentTypesQuery(manageCard?.prepaid_card_id);
	const disablePaymentTypesMutation = useDisablePaymentTypesMutation(payload => payload);
	const cardTxLimitQuery = useCardTxLimitQuery(manageCard?.prepaid_card_id);
	const txLimitsMutation = useTxLimitsMutation(payload => payload);
	const queryClient = useQueryClient();

	const { isLoading: prepaidCardsLoading, data: prepaidCards } = usePrepaidCardsQuery();

	//
	const resetStates = () => {
		/* This is used reset state at unmounting of component */
		setState({ cardSearchFilter: null, modalType: undefined, modalVisible: false, currentPage: 1, limit: 10, prepaidCardsQueryData: [] });
		setManageSpecificCardState({
			fullScreenModalOpen: false,
			modalOpen: false,
			modalContent: undefined,
			manageCard: null,
			modalActionBusy: false,
			disabledPaymentTypes: [],
			initDisabledPaymentTypes: [],
		});
	}

	useEffect(() => {
		return () => {
			resetStates();
		}
		// eslint-disable-next-line
	}, []);

	//
	useEffect(() => {
		if (disabledPaymentTypesQuery.data) {
			const reduced = disabledPaymentTypesQuery?.data?.reduce((acc, item) => { acc.push({ typeId: item.opt_disable_type_id, id: item.id }); return acc }, []);

			setManageSpecificCardState({
				disabledPaymentTypes: reduced,
				initDisabledPaymentTypes: reduced,
			});
		}
		// eslint-disable-next-line
	}, [disabledPaymentTypesQuery.data]);

	//
	useEffect(() => {
		if (cardTxLimitQuery.data) {
			setManageSpecificCardState({
				txLimitPosAmount: cardTxLimitQuery.data?.pos?.amount || undefined,
				txLimitAtmAmount: cardTxLimitQuery.data?.atm?.amount || undefined,
				txLimitPos: !!cardTxLimitQuery.data?.pos?.amount,
				txLimitAtm: !!cardTxLimitQuery.data?.atm?.amount,
				initTxLimitData: {
					txLimitPosAmount: cardTxLimitQuery.data?.pos?.amount || undefined,
					txLimitAtmAmount: cardTxLimitQuery.data?.atm?.amount || undefined,
					txLimitPos: !!cardTxLimitQuery.data?.pos?.amount,
					txLimitAtm: !!cardTxLimitQuery.data?.atm?.amount,
				}
			});
		}
		// eslint-disable-next-line
	}, [cardTxLimitQuery.data]);

	//
	const initTxLimitsStateChanged = () => {
		return !isEqual(
			initTxLimitData,
			{ txLimitPosAmount, txLimitAtmAmount, txLimitPos, txLimitAtm }
		);
	}

	//
	const inputPrefix = <Image src={FLAGS.GBP} alt='GBP' width={24} preview={false} />;

	/**
	 * Actions dropdown menu items
	 * Passing the record of table row to get card id and stuff.
	 *
	 * @todo Maybe memoize
	 */
	const cardActions = (record) => (
		<Menu
			onClick={(evt) => handleAction(evt, record)}
			items={[
				{
					key: `Manage Card__${URLS.ManageSpecificCard}/${record.prepaid_card_id}`,
					label: 'Manage Card',
				},
				{
					key: `Convert Balances__${URLS.ConvertCardBalances}/${record.prepaid_card_id}`,
					label: 'Convert Balances',
				},
				{
					key: `Fund Card from Company Wallet__${URLS.NewCardFund}/${record.prepaid_card_id}`,
					label: 'Fund Card from Company Wallet',
				},
				{
					key: `Withdraw Funds to Company Wallet__${URLS.NewCardWithdrawal}/${record.prepaid_card_id}`,
					label: 'Withdraw Funds to Company Wallet',
				},
				{
					key: `Authorised Payment Types__${URLS.ManageSpecificCard}/${record.prepaid_card_id}`,
					label: 'Authorised Payment Types',
				},
				{
					key: `Auto Fund__${URLS.ManageSpecificCard}/${record.prepaid_card_id}`,
					label: 'Auto Fund',
				},
				{
					key: `Transaction Limits__${URLS.ManageSpecificCard}/${record.prepaid_card_id}`,
					label: 'Transaction Limits',
				}
			]} />
	);

	/**
	 * Card table columns
	 *
	 */
	const columns = [
		{
			title: 'Card',
			dataIndex: 'card',
			key: 'card',
			width: 300,
			render: (v, record, i) => <Card bodyStyle={{ padding: '0' }} className="b-2-g body-height-130 m-10 pointer" onClick={() => navigate(`${URLS.ManageSpecificCard}/${record.prepaid_card_id}`)}>
				<Row justify="center" className="m-t-10">
					<Col>
						<CardImage cardProgram={record.card_type} height={130} />
					</Col>
				</Row>
			</Card>
		},
		{
			title: 'Name',
			dataIndex: ['card_holder', 'name'],
			key: 'card_holder_name',
			render: (value, record) => <Space direction="vertical" size={0}>
				<Typography.Text className="fs-18px regular">Card Holder Name</Typography.Text>
				<Typography.Text className="fs-18px medium dark-green">{value}</Typography.Text>
				{/* <Typography.Text className="fs-18px medium dark-green">{record?.registered ? 'yes' : 'no'}</Typography.Text> */}
			</Space>
		},
		{
			title: 'Number',
			dataIndex: 'card_number',
			key: 'card_number',
			render: (value, record) => <Space direction="vertical" size={0}>
				<Typography.Text className="fs-18px regular">Card Number</Typography.Text>
				<Typography.Text className="fs-18px medium dark-green">**** **** **** {value}</Typography.Text>
			</Space>
		},
		{
			title: 'Expiry',
			dataIndex: 'expiry_date',
			key: 'expiry_date',
			render: (value, record) => <Space direction="vertical" size={0}>
				{record?.card_status_id === 1 ? (
					<Typography.Text className="fs-18px bold" type="danger">NOT ACTIVATED</Typography.Text>
				) : record?.card_status_id === 7 ? (
					<Typography.Text className="fs-18px bold" type="danger">CANCELLED</Typography.Text>
				) : record?.card_status_id === 9 ? (
					<Typography.Text className="fs-18px bold" type="danger">LOST/STOLEN</Typography.Text>
				) : (
					<>
						<Typography.Text className="fs-18px regular">Expiry Date</Typography.Text>
						<Typography.Text className="fs-18px medium dark-green">{zeroPadStartExpiryDate(value)}</Typography.Text>
					</>
				)}
			</Space>
		},
		{
			title: 'Balances',
			dataIndex: 'balances',
			key: 'balances',
			onCell: () => ({
				colSpan: 3,
			}),
			render: (value) => <Space size={26}>
				<Space size={4}>
					{FLAGS.GBP && <Image src={FLAGS.GBP} preview={false} width={30} alt={`GBP`} />}
					{'GBP'}
					{accountingFormat(value.GBP)}
				</Space>
				<Space size={4}>
					{FLAGS.EUR && <Image src={FLAGS.EUR} preview={false} width={30} alt={`EUR`} />}
					{'EUR'}
					{accountingFormat(value.EUR)}
				</Space>
				<Space size={4}>
					{FLAGS.USD && <Image src={FLAGS.USD} preview={false} width={30} alt={`USD`} />}
					{'USD'}
					{accountingFormat(value.USD)}
				</Space>
			</Space>
		},
		{
			title: '',
			dataIndex: 'action',
			key: 'action',
			render: (value, record) => <Dropdown overlay={cardActions(record)} trigger={['click']}>
				<Button type="primary"><Space>
					Actions
					<DownOutlined />
				</Space></Button>
			</Dropdown>
		}
	];

	//
	const handlePaymentTypesChange = (evt, key) => {
		setManageSpecificCardState({
			disabledPaymentTypes: evt.target.value
				? [...disabledPaymentTypes.filter(item => item.typeId !== OPT_DISABLED_PAYMENT_TYPES[key])]
				: [...disabledPaymentTypes, { id: null, typeId: OPT_DISABLED_PAYMENT_TYPES[key] }]
		});
	}

	//
	const paymentTypesStateChanged = () => {
		return !isEqual(
			disabledPaymentTypes,
			initDisabledPaymentTypes,
		);
	}

	//
	const handleDisablePaymentTypesMutation = async () => {
		// Def rethink the implementation...
		if (paymentTypesStateChanged()) {
			const mutations = [];

			[
				OPT_DISABLED_PAYMENT_TYPES.atmWithdraw,
				OPT_DISABLED_PAYMENT_TYPES.contactless,
				OPT_DISABLED_PAYMENT_TYPES.ecom
			].forEach(typeId => {
				const existed = initDisabledPaymentTypes.find(item => item.typeId === typeId);
				const inDisabled = disabledPaymentTypes.find(item => item.typeId === typeId);
				const action = inDisabled ? (existed ? null : 'POST') : (existed ? 'DELETE' : null);

				if (!action) return;

				mutations.push(disablePaymentTypesMutation.mutateAsync(
					{
						cardId: manageCard?.prepaid_card_id,
						typeId: typeId,
						recordId: existed?.id || null,
						method: action,
					},
					{ onMutate: () => setManageSpecificCardState({ modalActionBusy: true }), }
				));
			});

			try {
				await Promise.all(mutations);
				notification.success({ message: 'Payment types updated!', placement: 'top' });
			} catch (err) {
				notification.error({ message: 'Not all changes where saved. Please check and try again.', placement: 'top' });
			} finally {
				queryClient.invalidateQueries({ queryKey: ['_prepaidcard_disabledtxtype_by_card_id', manageCard?.prepaid_card_id], exact: true });
				resetStates();
			}
		}
	}

	//
	const handleTxLimitsMutations = async () => {
		if (initTxLimitsStateChanged()) {
			setManageSpecificCardState({ modalActionBusy: true });

			const mutations = [];

			// Def rethink the implementation...
			const atmAction = txLimitAtmAmount > 0 ? (cardTxLimitQuery.data?.atm?.amount > 0 ? 'PUT' : 'POST') : (cardTxLimitQuery.data?.atm?.amount > 0 ? 'DELETE' : null);
			const posAction = txLimitPosAmount > 0 ? (cardTxLimitQuery.data?.pos?.amount > 0 ? 'PUT' : 'POST') : (cardTxLimitQuery.data?.pos?.amount > 0 ? 'DELETE' : null);

			if (atmAction) mutations.push(handleTxLimitAtmAction(atmAction));
			if (posAction) mutations.push(handleTxLimitPosAction(posAction));

			try {
				const responses = await Promise.all(mutations);
				if (responses.some(res => res?.response?.data?.error)) {
					setManageSpecificCardState({
						txLimitPosAmount: cardTxLimitQuery.data?.pos?.amount,
						txLimitAtmAmount: cardTxLimitQuery.data?.atm?.amount,
						txLimitPos: !!cardTxLimitQuery.data?.pos?.amount,
						txLimitAtm: !!cardTxLimitQuery.data?.atm?.amount,
					});
					notification.error({ message: 'There was an error saving some of your settings. Please try again.', placement: 'top' });
				} else {
					notification.success({ message: 'Transaction limits updated!', placement: 'top' });
				}
			} catch (err) {
				// @todo
			} finally {
				queryClient.invalidateQueries({ queryKey: ['_prepaidcard_maxtransaction_by_card_id', manageCard?.prepaid_card_id], exact: true });
				setManageSpecificCardState({ modalActionBusy: false });
			}
		}
	}

	//
	const handleTxLimitAtmAction = (action) => {
		return txLimitsMutation.mutateAsync({
			typeId: 2,
			tid: cardTxLimitQuery.data?.atm?.id,
			cardId: manageCard?.prepaid_card_id,
			method: action,
			amount: Number(txLimitAtmAmount),
		});
	}

	// @todo: combine with handleTxLimitAtmAction
	const handleTxLimitPosAction = (action) => {
		return txLimitsMutation.mutateAsync({
			typeId: 1,
			tid: cardTxLimitQuery.data?.pos?.id,
			cardId: manageCard?.prepaid_card_id,
			method: action,
			amount: Number(txLimitPosAmount)
		});
	}

	//
	const handleTxLimitTypeStatusChange = (evt, key) => {
		let amounts = {
			txLimitAtmAmount: txLimitAtmAmount,
			txLimitPosAmount: txLimitPosAmount,
		}

		setManageSpecificCardState({
			[key]: evt.target.value,
			[`${key}Amount`]: !evt.target.value ? undefined : amounts[`${key}Amount`]
		});
	}

	//
	const handleTxLimitAmountChange = (val, key) => {
		setManageSpecificCardState({
			[key]: val,
		});
	}

	//
	const modalContent = {
		authorisePaymentTypes: (
			<>
				<Row className="m-t-20">
					<Col xxl={8} xl={11} lg={13} md={18} sm={24} xs={24}>
						<CardDetails
							cardHolderName={manageCard?.card_holder?.name}
							cardNumber={`**** **** **** ${manageCard?.card_number}weqw`}
							expiryDate={zeroPadStartExpiryDate(manageCard?.expiry_date)}
							cardType={manageCard?.card_program_id || manageCard?.card_type}
						/>
					</Col>
				</Row>
				<Row className="m-t-20" gutter={[20, 20]}>
					<Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
						<Row>
							<Col>
								<Typography.Text className="dark-green fs-25px medium">Authorised Payment Types</Typography.Text>
							</Col>
						</Row>
						<Row className="m-t-5">
							<Col>
								<Typography.Text className="fs-18px medium">ATM Withdrawals</Typography.Text>
							</Col>
						</Row>
						<Row className="m-t-5">
							<Col>
								<Radio.Group
									onChange={(evt) => handlePaymentTypesChange(evt, 'atmWithdraw')}
									value={disabledPaymentTypes.find(item => item.typeId === OPT_DISABLED_PAYMENT_TYPES.atmWithdraw) ? false : true}
								>
									<Space size={36}>
										<Radio value={true} className='b-2-grey circular p-l-2 p-r-2' />
										<Radio value={false} className='b-2-grey circular p-l-2 p-r-2 danger' />
									</Space>
								</Radio.Group>
							</Col>
						</Row>
						<Row className="m-t-20">
							<Col>
								<Typography.Text className="fs-18px medium">E-Commerce</Typography.Text>
							</Col>
						</Row>
						<Row className="m-t-5">
							<Col>
								<Radio.Group
									onChange={(evt) => handlePaymentTypesChange(evt, 'ecom')}
									value={disabledPaymentTypes.find(item => item.typeId === OPT_DISABLED_PAYMENT_TYPES.ecom) ? false : true}
								>
									<Space size={36}>
										<Radio value={true} className='b-2-grey circular p-l-2 p-r-2' />
										<Radio value={false} className='b-2-grey circular p-l-2 p-r-2 danger' />
									</Space>
								</Radio.Group>
							</Col>
						</Row>
						<Row className="m-t-20">
							<Col>
								<Typography.Text className="fs-18px medium">Contactless</Typography.Text>
							</Col>
						</Row>
						<Row className="m-t-5">
							<Col>
								<Radio.Group
									onChange={(evt) => handlePaymentTypesChange(evt, 'contactless')}
									value={disabledPaymentTypes.find(item => item.typeId === OPT_DISABLED_PAYMENT_TYPES.contactless) ? false : true}
								>
									<Space size={36}>
										<Radio value={true} className='b-2-grey circular p-l-2 p-r-2' />
										<Radio value={false} className='b-2-grey circular p-l-2 p-r-2 danger' />
									</Space>
								</Radio.Group>
							</Col>
						</Row>
					</Col>
					<Col xxl={12} xl={12} lg={18} md={20} sm={24} xs={24}>
						<Row>
							<Col>
								<Typography.Text className="dark-green fs-25px medium">Card Balance</Typography.Text>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<CardBalanceListing cardId={manageCard?.prepaid_card_id} />
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className="m-t-40" justify="end">
					<Col>
						<Button disabled={!paymentTypesStateChanged()} loading={modalActionBusy} type="primary" onClick={handleDisablePaymentTypesMutation}>Save</Button>
					</Col>
				</Row>
			</>
		),
		transactionLimits: (
			<>
				{/* No longer in use */}
				<Row className="m-t-20">
					<Col xxl={8} xl={11} lg={13} md={18} sm={24} xs={24}>
						<CardDetails
							cardHolderName={manageCard?.card_holder?.name}
							cardNumber={`**** **** **** ${manageCard?.card_number}`}
							expiryDate={zeroPadStartExpiryDate(manageCard?.expiry_date)}
							cardType={manageCard?.card_program_id || manageCard?.card_type}
						/>
					</Col>
				</Row>
				<Row className="m-t-20" gutter={[12, 20]}>
					<Col xxl={12} xl={13} lg={17} md={21} sm={24} xs={24}>
						<Row>
							<Col>
								<Typography.Text className="fs-25px dark-green medium">Set Maximum Transaction Limits</Typography.Text>
							</Col>
						</Row>
						<Row className="m-t-5">
							<Col>
								<Typography.Text className="fs-18px medium">Single Purchase Limit</Typography.Text>
							</Col>
						</Row>
						<Row className="m-t-5" gutter={[12, 12]}>
							<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
								<Radio.Group
									value={txLimitPos}
									onChange={(evt) => handleTxLimitTypeStatusChange(evt, 'txLimitPos')}
								>
									<Space size={36}>
										<Radio value={true} className='b-2-grey circular p-l-2 p-r-2' />
										<Radio value={false} className='b-2-grey circular p-l-2 p-r-2 danger' />
									</Space>
								</Radio.Group>
							</Col>
							<Col xxl={6} xl={7} lg={7} md={7} sm={10} xs={24}>
								<InputNumber
									placeholder="Enter Limit Amount"
									size="large"
									step={0.01}
									prefix={inputPrefix}
									onChange={(val) => handleTxLimitAmountChange(val, 'txLimitPosAmount')}
									precision={2}
									style={{ width: '100%', border: 'none' }}
									value={txLimitPosAmount}
									disabled={!txLimitPos}
								/>
							</Col>
						</Row>
						<Row className="m-t-20">
							<Col>
								<Typography.Text className="fs-18px medium">Single ATM Withdraw Limit</Typography.Text>
							</Col>
						</Row>
						<Row className="m-t-5" gutter={[12, 12]}>
							<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
								<Radio.Group
									value={txLimitAtm}
									onChange={(evt) => handleTxLimitTypeStatusChange(evt, 'txLimitAtm')}
								>
									<Space size={36}>
										<Radio value={true} className='b-2-grey circular p-l-2 p-r-2' />
										<Radio value={false} className='b-2-grey circular p-l-2 p-r-2 danger' />
									</Space>
								</Radio.Group>
							</Col>
							<Col xxl={6} xl={7} lg={7} md={7} sm={10} xs={24}>
								<InputNumber
									placeholder="Enter Limit Amount"
									size="large"
									prefix={inputPrefix}
									precision={2}
									style={{ width: '100%', border: 'none' }}
									value={txLimitAtmAmount}
									onChange={(val) => handleTxLimitAmountChange(val, 'txLimitAtmAmount')}
									disabled={!txLimitAtm}
								/>
							</Col>
						</Row>
					</Col>
					<Col xxl={12} xl={13} lg={16} md={21} sm={24} xs={24}>
						<Row>
							<Col>
								<Typography.Text className="dark-green fs-25px medium">Card Balance</Typography.Text>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<CardBalanceListing cardId={manageCard?.prepaid_card_id} />
							</Col>
						</Row>
					</Col>
				</Row>
				<Row justify="end" className="m-t-20">
					<Col>
						<Button disabled={!initTxLimitsStateChanged()} loading={modalActionBusy} onClick={handleTxLimitsMutations} type="primary">Save</Button>
					</Col>
				</Row>
			</>
		)
	}

	/**
	 * When a action dropdown iten is clicked
	 *
	 */
	const handleAction = (menuItem, card) => {
		setManageSpecificCardState({ manageCard: card });

		if (menuItem.key.includes('modal__')) {
			setState({
				modalVisible: true,
				modalType: menuItem.key.replace('modal__', ''),
			});

		} else {
			const url = menuItem.key.substring(menuItem.key.indexOf('__') + 2)
			navigate(url);
		}
	}

	/**
	 * Handle modal cancel btn
	 *
	 */
	const handleOnCancel = () => {
		setState({
			modalVisible: false,
			modalType: undefined
		});
	}

	//
	const handleCardSearch = debounce((evt) => {
		if (evt.target.value.length > 2) {
			setState({ cardSearchFilter: evt.target.value.toLowerCase() });
		}

		if (evt.target.value.length < 1) {
			setState({ cardSearchFilter: null });
		}

		setState({ currentPage: 1 });
	}, 400);

	//
	const handlePageChange = (page) => {
		setState({ currentPage: page });
	}

	//
	const handlePageSizeChange = (current, size) => {
		setState({ limit: size, currentPage: 1 });
	}

	//
	return (
		<>
			<FullScreenModal
				open={modalVisible}
				onCancel={handleOnCancel}
			>
				{modalContent[modalType]}
			</FullScreenModal>

			<Row>
				<Col span={24}>
					<PageDescription title='Cards' text='- Search and Manage Specific Cards' />
				</Col>
			</Row>
			<Row className="m-t-10">
				<Col xxl={4}>
					<Input.Search onChange={handleCardSearch} placeholder="Search Card Holder / Card number" />
				</Col>
			</Row>

			<Row className="m-t-10">
				<Col span={24}>
					<CustomPagination
						loading={busy || prepaidCardsLoading}
						onPageChange={handlePageChange}
						total={prepaidCards?.pager?.count ?? 1}
						pageSize={limit}
						current={currentPage}
						onPageSizeChange={handlePageSizeChange}
						singlePageMessage={`${prepaidCards?.pager?.count ?? 1} ${pluralize(prepaidCards?.pager?.count ?? 1, 'card', 'cards')} found.`}
					/>
					<CustomTable
						showHeader={false}
						columns={columns}
						dataSource={prepaidCards?.data ?? []}
						styleAllRows
						className='spaced-rows'
						loading={busy || prepaidCardsLoading}
						rowKey="prepaid_card_id"
						scroll={{ x: 1230 }}
					/>
					<CustomPagination
						loading={busy || prepaidCardsLoading}
						onPageChange={handlePageChange}
						total={prepaidCards?.pager?.count ?? 1}
						pageSize={limit}
						current={currentPage}
						onPageSizeChange={handlePageSizeChange}
						singlePageMessage={`${prepaidCards?.pager?.count ?? 1} ${pluralize(prepaidCards?.pager?.count ?? 1, 'card', 'cards')} found.`}
					/>
				</Col>
			</Row>
		</>
	)
}

export default Cards;
