import { Button, Card, Col, Form, Input, Row, Select, Space, Tooltip } from "antd";
import PageDescription from "App/Components/PageDescription";
import SwitchWithText from "App/Components/SwitchWithText";
import { useRequestCardState } from "App/Pages/Settings/CompanyAccount/ManageUsers/ManageSpecificUser/RequestCard/store";
import { colors } from "Constants/Style";
import { FaInfoCircle, FaPen } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import URLS from "Routes/constants";


function RequestCard() {
    const currency = useRequestCardState(state => state.currency);
    const setState = useRequestCardState(state => state.setState);
    const navigate = useNavigate();

    const handleValuesChange = (value) => {
        if (value.currency) {
            setState({ currency: true })
        }
    }

    const handleFinish = () => {
        navigate(URLS.ManageSpecificUser);
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <PageDescription title='Order New Card' text='- Add User |Details underneath so it is clear who the card is being ordered for' />
                </Col>
            </Row>
            <Form
                layout="vertical"
                onValuesChange={handleValuesChange}
                onFinish={handleFinish}
            >
                <Row className="m-t-20" gutter={24}>
                    <Col xxl={4} xl={5} lg={6} md={8} sm={10} xs={24}>
                        <Form.Item
                            name='currency'
                            label='Select Currency'
                        >
                            <Select
                                placeholder='Currency'
                                filterOption={true}
                                optionFilterProp='children'
                                showSearch
                                className="b-g"
                            >
                                <Select.Option value='EUR'>EUR</Select.Option>
                                <Select.Option value='GBP'>GBP</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xxl={4} xl={5} lg={6} md={8} sm={10} xs={24}>
                        <Form.Item
                            name='cardType'
                            label='Select Card Type'
                        >
                            <Select
                                placeholder='Card Type'
                                filterOption={true}
                                optionFilterProp='children'
                                showSearch
                                className="b-g"
                            >
                                <Select.Option value='Physical'>Physical</Select.Option>
                                <Select.Option value='Virtual'>Virtual</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    {currency && (
                        <Col xxl={4} xl={5} lg={6} md={8} sm={10} xs={24}>
                            <Form.Item
                                name='companyName'
                                label={
                                    <Space>
                                        Printed Company Name
                                        <Tooltip title='Max 26 Characters' placement="right" color={colors.danger}>
                                            <Link to=''><FaInfoCircle /></Link>
                                        </Tooltip>
                                    </Space>
                                }
                            >
                                <Input
                                    placeholder="Printed Company Name"
                                    className="b-g p-r-2 p-t-2 p-b-2"
                                    suffix={<Card className="bg-dark-green white small-rounded" bodyStyle={{ padding: '2px 10px 1px 10px' }}><FaPen /></Card>}
                                />
                            </Form.Item>
                        </Col>
                    )}
                </Row>
                {currency && (
                    <Row>
                        <Col>
                            <Form.Item
                                name='deliverToResidential'
                                label='Deliver Card to The Users Residential Address?'
                                initialValue={true}
                                valuePropName='checked'
                            >
                                <SwitchWithText />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Row className="m-t-20" justify="end">
                    <Col>
                        <Button type="primary" htmlType="submit">{currency ? 'Request' : 'Back'}</Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

export default RequestCard;