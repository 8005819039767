import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useSpecificPaymentHistoryQuery = (mapId) => {
  const apiRequest = useApiRequest();

  // eslint-disable-next-line no-unused-vars
  const fetchQuery = async () => {
    const { data } = await apiRequest(`${APIROUTES.get.payment_history}/-${mapId}?$with=All`);
    return data;
  }

  return useQuery({
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    queryKey: ['_payment_history' + mapId],
    queryFn: fetchQuery,
    select: res => res
  });
}

export { useSpecificPaymentHistoryQuery };