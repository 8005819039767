import { NOTIFICATIONS } from "Constants/Common";

const dataSource = [
    {
        notification: 'Fund Wallet Reminders',
        description: 'Set a minimum Wallet balance in your base currency. We will send you a notification to fund your company wallet whenever the wallet balance falls below this amount',
        key: NOTIFICATIONS.topUpWallet
    },
    {
        notification: 'Enhanced Transaction Notifications',
        description: 'Send enhanced transaction information to your cardholders whenever their cards are used. Add an email address to copy in on all the notifications',
        key: NOTIFICATIONS.enhancedTransactions
    },
    {
        notification: 'Card Freeze Notifications',
        description: 'Notify your cardholders whenever their card transactions are declined due their card being frozen',
        key: NOTIFICATIONS.cardTransaction
    },
    {
        notification: 'Attach Receipts Notifications',
        description: 'Remind your cardholders to attach a receipt when they make a transaction',
        key: NOTIFICATIONS.attachReceipt
    },
    {
        notification: 'Workflow Approvals',
        description: 'Set workflows for your International Payments. Decide when an approval is required and who can approve International Payments',
        key: NOTIFICATIONS.workflowApprovals
    },
    {
        notification: 'Payment Report Notifications',
        description: 'Receive Notification when your monthly payment reports are available for download',
        key: NOTIFICATIONS.paymentReports
    },
    {
        notification: 'Online Transactions Verification Methods',
        description: 'Set the preferred method for your cardholders to verify online/e-commerce transactions',
        key: NOTIFICATIONS.formatPushNotification
    }
];

export { dataSource };