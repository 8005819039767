import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useRegisteredCardsQuery = (userId) => {
  const apiRequest = useApiRequest();

  const fetchQuery = async () => {
    const { data } = await apiRequest(`${APIROUTES.get.registered_cards}?userId=${userId}&$with=prepaidCard,prepaidCard.optCardStatus`);
    return data;
  }

  const query = useQuery({
    queryKey: ['_registered_cards', userId],
    queryFn: fetchQuery,
    enabled: !!userId
  });

  return query;
}

export { useRegisteredCardsQuery };