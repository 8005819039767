import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useGetAllCards = ({txnTypeIds = null} = {}) => {
    const apiRequest = useApiRequest();

    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
        let url = `${APIROUTES.get.users_transaction_cards}?$with=All`;
        const data = await apiRequest(url);
        return data;
    }


    const query = useQuery({
        queryKey: ['_cards_all'],
        queryFn: fetchQuery,
        onSuccess: () => {}
    });

    return query;
}

export { useGetAllCards };