import create from "zustand";

const useCardBalanceState = create((set, get) => ({
    balances: [],
    allVisible: false,
    total: 0,
    visibleBalances: () => {
        const balanceData = get().balances;

        if(get().allVisible) {
            return balanceData ?? [];
        }

        return !!balanceData.length && balanceData.length > 6 ? balanceData.slice(0,6) : [];
    },
    setState: data => set(data)
}));

export { useCardBalanceState };