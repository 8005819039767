import create from 'zustand';

const useNavbarState = create((set, get) => ({
	drawerOpen: false,
	modulesOpen: false,
	notificationsOpen: false,
	notificationDot: false,
	modules: [],
	moduleItems: [],
	activeModuleKey: null,
	activeModuleItemKey: null,
	drawerMenuItems: [],
	notifications: undefined,
	logoUrl: undefined,
	getCurrentModule: () => {
		const modItems = get().moduleItems;
		const activeModKey = get().activeModuleKey;

		return modItems?.[activeModKey]?.find(item =>
			item.key === get().activeModuleItemKey) || {
			url: '/',
			label: '',
		}
	},
	setState: data => set(data),
}));

export { useNavbarState };
