import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useNotificationsState } from "App/Pages/Notifications/store";

const useNotificationsQuery = () => {
  const apiRequest = useApiRequest();
  const currentPage = useNotificationsState(state => state.currentPage);
  const limit = useNotificationsState(state => state.limit);

  // eslint-disable-next-line no-unused-vars
  const fetchQuery = async () => {
    const res = await apiRequest(`${APIROUTES.get.notifications}?$with=All&$pageCount&$skip=${(currentPage - 1) * limit}&$limit=${limit}&alertType=N&accepted_level=5`);
    return res || [];
  }

  const query = useQuery({
    queryKey: ['_notifications', currentPage, limit],
    queryFn: fetchQuery
  });

  return query;
}

export { useNotificationsQuery };