import { useNavbarState } from 'App/Components/NavBar/store';
import { APIROUTES } from 'Constants/ApiRoutes';
import { useApiRequest } from 'Hooks/API';
import URLS from 'Routes/constants';
import { accountingFormat } from 'Utils';
import { Card, Row, Col, Typography, Button, Space } from 'antd'
import getSymbolFromCurrency from 'currency-symbol-map';
import { BsFillCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

function TopUpWallet({ alert_json_object, acknowledged, id }) {
  const title = alert_json_object?.title;
  const currency = alert_json_object?.ccy_code?.data;
  const amount = alert_json_object?.amount?.data;
  const setNavbarState = useNavbarState(state => state.setState);
  const navigate = useNavigate();
  const apiRequest = useApiRequest();

  const handleAction = () => {
    apiRequest(`${APIROUTES.put.notifications}${id}`, 'PUT', { acknowledged: 1 });
    setNavbarState({ notificationsOpen: false });
    navigate(URLS.FundWallet)
  }
  return (
    <Card className='bg-extra-light-grey'>
      <Row className={acknowledged ? 'm-l-20' : ''}>
        <Col flex='auto'>
          <Space size={10}>
            {!acknowledged && <BsFillCircleFill size={10} className='light-green' />}
            <Typography.Text className="fs-20px bold" type="danger">{title}</Typography.Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col flex='auto' className='m-l-20'>
          <Typography.Text className="fs-20px regular">
            Your Volopa Company Wallet balance is now below <span className='bold'>{getSymbolFromCurrency(currency)}{accountingFormat(amount)}</span>
          </Typography.Text>
        </Col>
      </Row>
      <Row justify='end'>
        <Col>
          <Button type='primary' onClick={handleAction}>Fund Wallet</Button>
        </Col>
      </Row>
    </Card>
  );
}

export default TopUpWallet;