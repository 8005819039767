import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { notification } from "antd";

const useCardAutofundCreateMutation = ({payload}) => {
    const apiRequest              = useApiRequest();
    const queryClient             = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload) => {
            return apiRequest(APIROUTES.post.prepaidcard_autoload, 'POST', payload);
        },
        mutationKey: ['_prepaidcard_autoload_create'],
        onMutate: () => {},
        onSuccess: (data, context) => {
            queryClient.invalidateQueries({ queryKey: ['_prepaidcard_autoload', context.cardId], exact: true});
            notification.success({ message: 'Autofund amounts saved!.',  placement: 'top' });
        },
        onError: (error) => {},
    });

    return mutation;
}

export { useCardAutofundCreateMutation };