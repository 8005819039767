import { useAccountingCompanyMapQuery } from "App/Pages/Accounting/Configuration/query";
import { Navigate, Outlet } from "react-router-dom";
import Spinner from "App/Components/Spinner";
import URLS from "Routes/constants";

const AccountingConnectedGuard = ({
    or = URLS.AccountingConnect,
}) => {
    const { data, isLoading } = useAccountingCompanyMapQuery();

    return !!isLoading
        ? <Spinner full/>
        : (data?.[0]?.status_id === 3 || data?.[0]?.status_id === 2 ? <Outlet /> : <Navigate to={or} replace={true} />);
}

export default AccountingConnectedGuard;
