import { useConfigurationState } from "App/Pages/Accounting/Configuration/store";
import { Button, Col, Row, Typography } from "antd";
import {
    useTransactionCategoriesQuery,
    useCodatAccountCategoriesQuery,
    useTaxRatesQuery,
} from "App/Pages/Accounting/Configuration/query";
import MappingRow from "App/Components/AccountingCategoryMapping/MappingRow";

function AccountingCategoryMapping({disabled = false}) {
    const categoryMap = useConfigurationState(state => state.categoryMap);
    const addCategoryMapping = useConfigurationState(state => state.addCategoryMapping);
    const delCategoryMapping = useConfigurationState(state => state.delCategoryMapping);
    const syncCardTrans = useConfigurationState(state => state.syncCardTrans);
    const setState = useConfigurationState(state => state.setState);
    const syncExpenses = useConfigurationState(state => state.syncExpenses);
    const { data: transactionCategories, isLoading: transactionCategoriesLoading } = useTransactionCategoriesQuery();
    const { data: codatCategories, isLoading: codatCategoriesLoading } = useCodatAccountCategoriesQuery();
    const { data: taxRatesQuery, isLoading: taxRatesQueryLoading } = useTaxRatesQuery();
    const mapRowsLoading = codatCategoriesLoading || transactionCategoriesLoading || taxRatesQueryLoading;

    const handleMapRowChange = (data) => {
        setState({
            categoryMap: categoryMap.map((map) => map.id === data.mapId ? ({ ...map, [data.key]: data.val }) : map)
        });
    }

    const handleAddCategoryMapping = () => {
        addCategoryMapping({
            transaction_category_id: null,
            codat_category_id: null,
            codat_tax_id: null,
            id: Date.now() + categoryMap.length + 1,
        });
    }

    const handleMapRowDelete = (idx) => {
        delCategoryMapping(idx);
    }

    return (
        <>
            {(syncCardTrans && syncExpenses) ? (<>
                {/* Category mappings header ================== */}
                <Row gutter={16}>
                    <Col span={8}>
                        <Typography.Text className="fs-20px dark-green bold">Volopa Category</Typography.Text>
                    </Col>
                    <Col span={8}>
                        <Typography.Text className="fs-20px dark-green bold">Account Code</Typography.Text>
                    </Col>
                    <Col span={8}>
                        <Typography.Text className="fs-20px dark-green bold">VAT Code</Typography.Text>
                    </Col>
                </Row>

                {/* Category mapping rows ==================== */}

                {categoryMap?.length ? categoryMap.map((mapping, idx) =>
                    <MappingRow
                        key={mapping.id}
                        mapId={mapping.id}
                        transactionCategories={transactionCategories}
                        transactionCategory={mapping.transaction_category_id}
                        codatCategories={codatCategories?.response?.results}
                        codatCategory={mapping.codat_category_id}
                        taxRates={taxRatesQuery?.response?.results}
                        taxRate={mapping.codat_tax_id}
                        onChange={(data) => handleMapRowChange(data)}
                        onDelete={(idx) => handleMapRowDelete(idx)}
                        loading={mapRowsLoading}
                    />
                ) : null}
                <Row style={{ margin: '1rem 0' }}>
                    <Col span={8}>
                        <Button disabled={disabled} onClick={handleAddCategoryMapping} size="large">+ Add category</Button>
                    </Col>
                </Row>
            </>) : null}
        </>
    )

}

export default AccountingCategoryMapping;