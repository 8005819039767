import create from "zustand";

const usePaymentSummaryState = create(set => ({
    fundingCurrency: undefined,
    proceed: false,
    recipientDetails: undefined,
    currencies: undefined,
    commonCurrencies: undefined,
    firstDate: undefined,
    fundingMethod: 'Push Funds',
    rate: undefined,
    weConvert: 0,
    commissionPercentage: undefined,
    minimumCommission: undefined,
    displayRate: "-",
    displayRateInversed: "-",
    seconds: 0,
    fullPageSpinner: false,
    quoteAccepted: false,
    quoteUuid: undefined,
    totalFees: 0,
    volopaAccountHolderName: undefined,
    volopaAccountSortCode: undefined,
    volopaAccountNumber: undefined,
    volopaAccountCountry: undefined,
    volopaAccountAddress: undefined,
    volopaAccountName: undefined,
    volopaAccountPaymentRef: undefined,
    volopaAccountIban: undefined,
    volopaAccountSwift: undefined,
    volopaAccountNumberType: undefined,
    volopaAccountSortCodeLabel: 'Sort Code',
    volopaAccountDetails: undefined,
    fundingLimitReached: false,
    totalAMount: 0,
    youSendTotal: 0,
    showPaymentsUnder: false,
    showPaymentsOver: false,
    approvalRequired: false,
    recipientLoader: false,
    quoteLoading: false,
    deletingDraft: false,
    dates: undefined,
    scheduled: undefined,
    disabledFundingDropdown: false,
    modalType: 'pendingFunds',
    checkRateLoading: false,
    modalOpen: false,
    TransactionComplete: false,
    AmountExceed: false,
    setState: obj => set(obj),
    resetState: () => set({
        TransactionComplete: false,
        modalOpen: false,
        disabledFundingDropdown: false,
        modalType: 'pendingFunds',
        checkRateLoading: false,
        scheduled: undefined,
        dates: undefined,
        deletingDraft: false,
        quoteLoading: false,
        fundingCurrency: undefined,
        proceed: false,
        recipientDetails: undefined,
        currencies: undefined,
        commonCurrencies: undefined,
        firstDate: undefined,
        fundingMethod: 'Push Funds',
        rate: undefined,
        weConvert: 0,
        commissionPercentage: undefined,
        minimumCommission: undefined,
        displayRate: "-",
        displayRateInversed: "-",
        seconds: 0,
        fullPageSpinner: false,
        quoteAccepted: false,
        quoteUuid: undefined,
        totalFees: 0,
        volopaAccountHolderName: undefined,
        volopaAccountSortCode: undefined,
        volopaAccountNumber: undefined,
        volopaAccountCountry: undefined,
        volopaAccountAddress: undefined,
        volopaAccountName: undefined,
        volopaAccountPaymentRef: undefined,
        volopaAccountIban: undefined,
        volopaAccountSwift: undefined,
        volopaAccountNumberType: undefined,
        volopaAccountSortCodeLabel: 'Sort Code',
        volopaAccountDetails: undefined,
        fundingLimitReached: false,
        totalAMount: 0,
        youSendTotal: 0,
        showPaymentsUnder: false,
        showPaymentsOver: false,
        approvalRequired: false,
        recipientLoader: false,
    })
}));

export { usePaymentSummaryState };