import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { OPT_TTC_SELF_QUOTE_STATUS_ID } from "Constants/Opts";
import { useDraftPaymentsState } from "App/Pages/Payments/PaymentHistory/DraftPayments/store";

const useDraftPaymentsQuery = () => {
  const apiRequest = useApiRequest();
  const limit = useDraftPaymentsState(s => s.limit);
  const current = useDraftPaymentsState(s => s.current);
  const dateFilter = useDraftPaymentsState(s => s.dateFilter);
  const search = useDraftPaymentsState(s => s.search);
  const setState = useDraftPaymentsState(s => s.setState);

  // eslint-disable-next-line no-unused-vars
  const fetchQuery = async () => {
    const pagination = `&$pageCount&$skip=${(current - 1) * limit}&$limit=${limit}`;
    const quoteId = `&quoteStatusIdIn=${OPT_TTC_SELF_QUOTE_STATUS_ID.DRAFTED},${OPT_TTC_SELF_QUOTE_STATUS_ID.SCHEDULED},${OPT_TTC_SELF_QUOTE_STATUS_ID.SCHEDULED_EXPIRED}`;
    const fromDate = dateFilter && `${dateFilter} 00:00:00`;
    const toDate = dateFilter && `${dateFilter} 23:59:59`;
    const date = dateFilter ? `&fromQuoteDate=${fromDate}&toQuoteDate=${toDate}` : '';
    const searchQuery = search ? `&search=${search}` : '';
    const { data, pager } = await apiRequest(`${APIROUTES.get.payment_history}?$with=All${pagination}${quoteId}${date}${searchQuery}`);
    setState({ total: pager?.count })
    return data;
  }

  const query = useQuery({
    queryKey: ['_draft_payments', current, limit, dateFilter, search],
    queryFn: fetchQuery,
  });

  return query;
}

export { useDraftPaymentsQuery };