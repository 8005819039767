import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useFxForwardsState } from "App/Pages/FxForwards/FxForwardsDashboard/store";

const useFxFilesQuery = () => {
  const apiRequest = useApiRequest();
  const limit = useFxForwardsState(s => s.limit);
  const currentPage = useFxForwardsState(s => s.currentPage);

  const fetchQuery = async () => {
    const res = await apiRequest(`${APIROUTES.get.fx_files}?$pageCount&$skip=${(currentPage - 1) * limit}&$limit=${limit}`);
    return res;
  }

  const query = useQuery({
    queryKey: ['_fx_files', currentPage, limit],
    queryFn: fetchQuery,
  });

  return query;
}

export { useFxFilesQuery };