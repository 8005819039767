/* eslint-disable react-hooks/exhaustive-deps */
import { Space, Switch, Typography } from "antd";
import { useEffect, useState } from "react";

function SwitchWithText(props) {
    const defaultChecked = props.defaultChecked;
    const checked = props.checked;
    const onChange = props.onChange;
    const onOff = props.onOff;
    const id = props.id;
    const [stateChecked, setState] = useState(undefined);

    const handleSwitchChange = (val) => {
        onChange !== undefined && onChange(val);
        checked === undefined && setState(val);
    }

    useEffect(() => {
        setState(checked);
    }, [checked]);

    return (
        <>
            <Space direction="vertical" size={4}>
                <Space size={30}>
                    {(defaultChecked && stateChecked === undefined) || stateChecked ? (
                        onOff ? (
                            <>
                                <Typography.Text className="fs-18px regular">Off</Typography.Text>
                                <Typography.Text className="fs-18px bold light-green">ON</Typography.Text>
                            </>
                        ) : (
                            <>
                                <Typography.Text className="fs-18px regular">No</Typography.Text>
                                <Typography.Text className="fs-18px bold light-green">YES</Typography.Text>
                            </>
                        )
                    ) : (
                        onOff ? (
                            <>
                                <Typography.Text className="fs-18px bold" type="danger">OFF</Typography.Text>
                                <Typography.Text className="fs-18px regular">On</Typography.Text>
                            </>
                        ) : (
                            <>
                                <Typography.Text className="fs-18px bold" type="danger">NO</Typography.Text>
                                <Typography.Text className="fs-18px regular">Yes</Typography.Text>
                            </>
                        )
                    )}
                </Space>
                <Switch defaultChecked={defaultChecked} checked={stateChecked} onChange={handleSwitchChange} id={id} />
            </Space>
        </>
    )
}

export default SwitchWithText;