/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Avatar, Button, Col, Form, Input, Modal, notification, Row, Select, Space, Spin, Typography } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaTrashAlt, FaRegUser, FaPen } from "react-icons/fa";
import moment from "moment";
import { useManageSpecificUserState } from "App/Pages/Settings/CompanyAccount/ManageUsers/ManageSpecificUser/store";
import URLS from "Routes/constants";
import CustomTable from "App/Components/CustomTable";
import PageDescription from "App/Components/PageDescription";
import { useRegisteredCardsQuery, useSpecificUserAccessQuery, useSpecificUserQuery } from "Hooks/Queries";
import { FORMATS } from "Constants/Formats";
import { useSelectSearch } from "Hooks/Search";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useEffect } from "react";
import { useLoginStore } from "App/Pages/Login/store";
import { useSpecificUserMutation } from "Hooks/Mutations";
import { isPossiblePhoneNumber } from "libphonenumber-js";

function ManageSpecificUser() {
    const addAccessOpen = useManageSpecificUserState(state => state.addAccessOpen);
    const revokeAccessOpen = useManageSpecificUserState(state => state.revokeAccessOpen);
    const modalOpen = useManageSpecificUserState(state => state.modalOpen);
    const userDeleted = useManageSpecificUserState(state => state.userDeleted);
    const addAccessLoading = useManageSpecificUserState(state => state.addAccessLoading);
    const revokeAccessLoading = useManageSpecificUserState(state => state.revokeAccessLoading);
    const revokeAccessItem = useManageSpecificUserState(state => state.revokeAccessItem);
    const deleteLoading = useManageSpecificUserState(state => state.deleteLoading);
    const deleted = useManageSpecificUserState(state => state.deleted);
    const editRole = useManageSpecificUserState(state => state.editRole);
    const role = useManageSpecificUserState(state => state.role);
    const email = useManageSpecificUserState(state => state.email);
    const editTelephoneMobile = useManageSpecificUserState(state => state.editTelephoneMobile);
    const telephoneMobile = useManageSpecificUserState(state => state.telephoneMobile);
    const setState = useManageSpecificUserState(state => state.setState);
    const { userId } = useParams();
    const { data: userData, isFetching: loading, refetch: refetchUser } = useSpecificUserQuery(userId);
    const { data: cardData, isFetching: cardLoading, refetch: refetchCard } = useRegisteredCardsQuery(userId);
    const { data: accessData, isFetching: accessLoading, refetch: refetchAccess } = useSpecificUserAccessQuery(userId);
    const userUpdate = useSpecificUserMutation(userId);
    const navigate = useNavigate();
    const selectSearch = useSelectSearch();
    const apiRequest = useApiRequest();
    const config = useLoginStore(state => state.config)

    //
    const pageDescriptionProps = {
        title: 'Manage Specific User',
        text: [
            'User Permissions are a function of the user role and the user access to the products.',
            'The user role controls what functionality the iser has for the products assigned to them.',
            'There are four user roles that can be assigned to each user:',
            '1. Primary Administrator',
            '2. Administrator',
            '3. Business User',
            '4. Card User',
            <>Click <Link to={URLS.UserPermissions} className='underline'>here</Link> to read more about how user permissions work and for a breakdown of what each role can do.</>
        ]
    };

    let userDetails = [
        {
            columns: [
                {
                    title: 'User ID',
                    dataIndex: 'user_id',
                    key: 'userID',
                    width: 200,
                    render: (text) => <Space><Avatar icon={<FaRegUser />} /> {text} </Space>
                },
                {
                    title: <Space>
                        Role
                        {
                            !editRole && <Button type="primary" size="small" onClick={() => setState({ editRole: true })}>
                                <FaPen style={{ marginBottom: '-2px' }} />
                            </Button>
                        }
                    </Space>,
                    dataIndex: 'user',
                    key: 'role',
                    width: 200,
                    render: a =>
                        editRole ? <Select
                            name="role_id"
                            placeholder='Select Role'
                            className='select-b-g'
                            showSearch
                            value={role}
                            onChange={(val) => setState({ role: val })}
                            filterOption={(input, option) =>
                                option?.key?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                            }
                        >
                            {Object.entries(config.user_role_opts).length > 0 && (
                                Object.entries(config.user_role_opts).map(([role, value]) => (
                                    <Select.Option key={role} value={value}>
                                        <Typography.Text
                                            className={
                                                role === "Business User"
                                                    ? "light-green"
                                                    : role === "Card User"
                                                        ? "dark-green"
                                                        : role === "Primary Administrator"
                                                            ? "purple"
                                                            : role === "Administrator"
                                                                ? "error-hover"
                                                                : undefined}
                                        >
                                            {role}
                                        </Typography.Text>
                                    </Select.Option>
                                ))
                            )}
                        </Select> : <Typography.Text
                            className={
                                a?.role?.permission_name === "Business User"
                                    ? "light-green"
                                    : a?.role?.permission_name === "Card User"
                                        ? "dark-green"
                                        : a?.role?.permission_name === "Primary Administrator"
                                            ? "purple"
                                            : a?.role?.permission_name === "Administrator"
                                                ? "error-hover"
                                                : ""
                            }
                        >
                            {a?.role?.permission_name || "-"}
                        </Typography.Text>
                },
                {
                    title: <Space>
                        Email
                        {
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => navigate(`${URLS.ChangeUserEmail}/${userId}`)}>
                                <FaPen style={{ marginBottom: '-2px' }} />
                            </Button>
                        }
                    </Space>,
                    dataIndex: 'user',
                    key: 'email',
                    render: user => user?.email,
                    width: 300
                },
                {
                    title: 'Date Created',
                    dataIndex: 'create_time',
                    key: 'dateCreated',
                    render: val => val ? moment(val).format(FORMATS.date) : '-',
                    width: 300

                },
                {
                    title: 'User Status',
                    dataIndex: 'user',
                    key: 'userStatus',
                    // width: 300,
                    render: val => <Typography.Text className={
                        val?.status_id === 1
                            ? "dark-green"
                            : val?.status_id === 2
                                ? "error"
                                : val?.status_id === 3
                                    ? "purple"
                                    : val?.status_id === 4
                                        ? "warning"
                                        : val?.status_id === 5
                                            ? "error"
                                            : ""
                    }
                    >
                        {val?.status_id === 1
                            ? "Active"
                            : val?.status_id === 2
                                ? "Inactive"
                                : val?.status_id === 3
                                    ? "Pending"
                                    : val?.status_id === 4
                                        ? "Awaiting KYC"
                                        : val?.status_id === 5
                                            ? "Failed KYC"
                                            : ""}
                    </Typography.Text>
                },
                // {
                //     title: '',
                //     dataIndex: 'key',
                //     key: 'action',
                //     align: 'right',
                //     render: () => <Button type="primary"><FaPen /></Button>
                // }
            ]
        },
        {
            columns: [
                {
                    title: 'First Name',
                    dataIndex: 'first_name',
                    key: 'firstName',
                    render: val => val || '-',
                    width: 200
                },
                {
                    title: 'Last Name',
                    dataIndex: 'last_name',
                    key: 'lastName',
                    render: val => val || '-',
                    width: 200
                },
                {
                    title: 'Date Of Birth',
                    dataIndex: 'dob',
                    key: 'dateOfBirth',
                    width: 300,
                    render: text => text ? moment(text).format(FORMATS.date) : '-',
                },
                {
                    title: 'Company',
                    dataIndex: 'company_name',
                    key: 'company',
                    render: val => val || '-',
                    width: 300
                },
                {
                    title: <Space>
                        Telephone Mobile
                        {
                            !editTelephoneMobile && <Button type="primary" size="small" onClick={() => setState({ editTelephoneMobile: true })}>
                                <FaPen style={{ marginBottom: '-2px' }} />
                            </Button>
                        }
                    </Space>,
                    dataIndex: 'telephone_mobile',
                    key: 'telephoneMobile',
                    render: text => editTelephoneMobile ?
                        <Input className="center" value={telephoneMobile} onChange={(val) => setState({ telephoneMobile: val?.target?.value })} /> : text,
                    width: 250
                },
                {
                    title: 'Telephone Work',
                    dataIndex: 'telephone_work',
                    key: 'telephoneWork',
                    render: val => val || '-',
                    width: 320
                },
                {
                    title: '',
                    dataIndex: 'key',
                    key: 'action',
                    align: 'right',
                    render: () => undefined //<Button type="primary"><FaPen /></Button>
                }
            ]
        },
        {
            columns: [
                {
                    title: 'Residential Address',
                    dataIndex: 'address',
                    key: 'residentialAddress',
                    render: (val, obj) => `${val} ${obj.address_line_2}`,
                    width: 400
                },
                {
                    title: 'Country',
                    dataIndex: 'country',
                    key: 'country',
                    render: val => val?.name || '-',
                    width: 300
                },
                {
                    title: 'City/Town',
                    dataIndex: 'city',
                    key: 'cityTown',
                    render: val => val || '-',
                    width: 300
                },
                {
                    title: 'Postal Code',
                    dataIndex: 'postcode',
                    key: 'postalCode',
                    render: val => val || '-',
                    // width: 300
                },
                // {
                //     title: '',
                //     dataIndex: 'key',
                //     key: 'action',
                //     align: 'right',
                //     render: () => <Button type="primary"><FaPen /></Button>
                // }
            ]
        }
    ];

    const cardColumns = [
        {
            title: 'Card Number',
            dataIndex: 'prepaid_card',
            key: 'cardNumber',
            render: val => val?.card_number ? `**** **** **** ${val?.card_number}` : '-',
            width: 400
        },
        {
            title: 'Card Status',
            dataIndex: 'prepaid_card',
            key: 'cardStatus',
            width: 300,
            render: val => < Typography.Text
                type={
                    val?.card_status_id === 2 || val?.card_status_id === 8
                        ? "success"
                        : val?.card_status_id === 6 || val?.card_status_id === 10
                            ? "warning"
                            : val?.card_status_id === 5 || val?.card_status_id === 4 ||
                                val?.card_status_id === 1 || val?.card_status_id === 3 ||
                                val?.card_status_id === 9 || val?.card_status_id === 7
                                ? "danger"
                                : ""
                }
            >
                {val?.card_status_id === 2 ? "Active" : val?.card_status_id === 8
                    ? "Renewed"
                    : val?.card_status_id === 6
                        ? "Suspended"
                        : val?.card_status_id === 5 ? "Expired" : val?.card_status_id === 4 ?
                            "Cancelled" : val?.card_status_id === 1 ? "Not Activated" : val?.card_status_id === 3 ?
                                "Blocked" : val?.card_status_id === 9 ? "Lost/Stolen" : val?.card_status_id === 7
                                    ? "Destroyed" : val?.card_status_id === 10 ? "Expiring"
                                        : "-"}
            </Typography.Text >
        },
        {
            title: 'Card Expiry Date',
            dataIndex: 'prepaid_card',
            key: 'cardExpiryDate',
            render: val => val?.expiry_month && val?.expiry_year ? moment(`${val?.expiry_month} ${val?.expiry_year}`, 'MM YYYY').format(FORMATS.cardDate) : '-',
            width: 300
        },
        {
            // title: 'User Details',
            dataIndex: 'userDetails',
            key: 'userDetails',
            // width: 300,
            render: text => undefined
        },
        // {
        //     title: '',
        //     dataIndex: 'key',
        //     key: 'action',
        //     render: () => <Space size={48}><Button>Replace Card</Button><Button type="primary">Renew Card</Button></Space>
        // }
    ];

    useEffect(() => setState({ deleted: userData && userData[0]?.user?.deleted }), [userData]);
    const handleDeleteUser = () => {
        setState({ modalOpen: true });
    };

    const handleOnFinish = async (obj) => {
        setState({ deleteLoading: true });
        obj.delete = userId;
        try {
            const res = await apiRequest(APIROUTES.del.user, 'DELETE', obj);
            if (res?.success) {
                refetchUser();
                refetchCard();
                refetchAccess();
                setState({ userDeleted: true });
            } else {
                throw new Error(res);
            }
        } catch (err) {
            notification.error({ placement: 'top', message: 'Something went wrong', description: 'Please try again.' });
        } finally {
            setState({ deleteLoading: false })
        }
    };

    const handleReturn = () => {
        setState({ userDeleted: false, modalOpen: false });
        navigate(URLS.ManageUsers);
    }

    const handleAccessForm = async data => {
        setState({ addAccessLoading: true })
        const item = accessData?.dropdown?.[data?.product];

        const body = item?.type === 'service' ?
            {
                opt_user_level_access_id: item?.type_id,
                target_user_id: userId
            } :
            {
                target_user_id: userId,
                account_id: item?.type_id
            }

        const route = item?.type === 'service' ? APIROUTES.post.user_level_access : APIROUTES.post.user_access;

        try {
            await apiRequest(route, 'POST', body);
        } catch {
            notification.error({ message: 'Something Went Wrong', description: 'There was an issue with updating user access. Please try again', placement: 'top' });
        }

        setState({ addAccessLoading: false, addAccessOpen: false });
        refetchAccess();
    }

    const handleRevokeAccess = async () => {
        setState({ revokeAccessLoading: true });
        const item = accessData?.existing?.[revokeAccessItem?.id];
        const body = item?.type === 'account' &&
        {
            target_user_id: userId,
            user_access_id: item?.type_id
        };
        const route = item?.type === 'service' ? `${APIROUTES.del.user_level_access}${item?.type_id}` : APIROUTES.del.user_access

        try {
            const res = await apiRequest(route, 'DELETE', body);
            if (res?.name === "AxiosError")
                throw new Error();
        } catch {
            notification.error({ message: 'Something Went Wrong', description: 'There was an issue with updating user access. Please try again', placement: 'top' });
        }
        setState({ revokeAccessLoading: false, revokeAccessOpen: false });
        refetchAccess();
    }

    const handleSave = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!telephoneMobile || !isPossiblePhoneNumber(telephoneMobile)) {
            notification.info({
                message: !telephoneMobile
                    ? 'Telephone Mobile is Mandatory'
                    : 'Incorrect Telephone Mobile',
                description: !telephoneMobile
                    ? 'Please type Telephone Mobile'
                    : 'Please type correct Telephone Mobile',
                placement: 'top',
            });
            return;
        } else if (!emailPattern.test(email)) {
            notification.info({ message: 'Incorrect Email', description: 'Please type correct email', placement: 'top' })
            return;
        }
        userUpdate.mutateAsync({
            targetUserId: parseInt(userId),
            roleId: role,
            userEmail: email,
            telephoneMobile: telephoneMobile
        })
    }

    return (
        <>
            <Modal
                open={modalOpen}
                destroyOnClose
                footer={false}
                closable={false}
            >
                {userDeleted ? (
                    <>
                        <Row>
                            <Col span={24}>
                                <Typography.Text type="danger" className="muli semi-bold fs-18px">User Deleted</Typography.Text>
                            </Col>
                        </Row>
                        <Row className="m-t-10">
                            <Col span={24}>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text className="muli semi-bold dark-green fs-16px">
                                        User Account has been deleted.
                                    </Typography.Text>
                                    <Typography.Text className="muli semi-bold dark-green fs-16px">
                                        All funds have been transfered from any associated cards back into your company wallet.
                                    </Typography.Text>
                                </Space>
                            </Col>
                        </Row>
                        <Row className="m-t-20">
                            <Col>
                                <Button type="primary" onClick={handleReturn}>Return to manage users</Button>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <>
                        <Row>
                            <Col span={24}>
                                <Typography.Text type="danger" className="muli semi-bold fs-18px">Delete User</Typography.Text>
                            </Col>
                        </Row>
                        <Form
                            layout="vertical"
                            onFinish={handleOnFinish}
                            requiredMark={false}
                        >
                            <Row className="m-t-10">
                                <Col span={24}>
                                    <Form.Item
                                        name='$password'
                                        label={
                                            <Typography.Text className="muli semiBold fs-16px dark-green">
                                                Please enter your password to confirm user deletion
                                            </Typography.Text>
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your password.'
                                            }
                                        ]}
                                    >
                                        <Row>
                                            <Col span={12}>
                                                <Input.Password placeholder="Enter Password" className="b-g" />
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name='delete_reason'
                                        label={
                                            <Typography.Text type="danger" className="muli semiBold fs-16px">
                                                Deleting a user will also cancel all associated cards. This action cannot be undone.
                                            </Typography.Text>
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter the reason for deletion.'
                                            }
                                        ]}
                                    >
                                        <Input placeholder="Enter reason for deletion" className="b-g" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button type="primary" onClick={() => setState({ modalOpen: false })}>Close</Button>
                                </Col>
                                <Col flex="auto">
                                    <Row justify='end'>
                                        <Col>
                                            <Button type="primary" danger htmlType="submit" loading={deleteLoading}>Delete User</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </>
                )}
            </Modal>
            <Modal
                open={addAccessOpen}
                onCancel={() => setState({ addAccessOpen: false })}
                destroyOnClose
                footer={false}
            >
                <Row gutter={[24, 12]} align='middle'>
                    <Col>
                        <Typography.Text className="dark-green medium fs-28px">User Access</Typography.Text>
                    </Col>
                </Row>
                <>
                    <Form
                        onFinish={handleAccessForm}
                        requiredMark={false}
                        layout='vertical'
                    >
                        <Row gutter={12}>
                            <Col flex='auto'>
                                <Form.Item
                                    label='Product'
                                    name='product'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a product'
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder='Select Product'
                                        loading={accessLoading}
                                        filterOption={selectSearch}
                                        showSearch
                                    >
                                        {
                                            accessData?.dropdown?.length > 0 &&
                                            accessData?.dropdown?.map(val => <Select.Option value={val.id} key={val.id}>{val.account_name}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="space-around">
                            <Col>
                                <Button type="primary" danger onClick={() => setState({ addAccessOpen: false })}>Cancel</Button>
                            </Col>
                            <Col>
                                <Button type="primary" htmlType="submit" loading={addAccessLoading}>Grant Access</Button>
                            </Col>
                        </Row>
                    </Form>
                </>
            </Modal>
            <Modal
                open={revokeAccessOpen}
                onCancel={() => setState({ revokeAccessOpen: false })}
                destroyOnClose
                footer={false}
            >
                <Row>
                    <Col>
                        <Typography.Text className="dark-green muli semi-bold fs-18px">Revoke Access</Typography.Text>
                    </Col>
                </Row>
                <Row className="m-t-10">
                    <Col>
                        <Typography.Text className="dark-green muli semi-bold fs-16px">Are you sure you want to revoke access from “{revokeAccessItem?.account_name}”?</Typography.Text>
                    </Col>
                </Row>
                <Row justify="space-around" className="m-t-20">
                    <Col>
                        <Button type="primary" onClick={() => setState({ revokeAccessOpen: false })}>Close</Button>
                    </Col>
                    <Col>
                        <Button type="primary" danger onClick={handleRevokeAccess} loading={revokeAccessLoading}>Revoke</Button>
                    </Col>
                </Row>
            </Modal>
            <Row>
                <Col span={24}>
                    <PageDescription {...pageDescriptionProps} />
                </Col>
            </Row>
            <Row justify="end" className="m-t-20">
                {deleted ?
                    <Col span={24}>
                        <Alert type="error" message="User Deleted" banner />
                    </Col> :
                    <Col>
                        <Space>
                            {/* <Button type="primary" onClick={() => navigate(URLS.RequestCard)}>Request Card</Button> */}
                            <Button type="primary" danger onClick={handleDeleteUser}>
                                <Space><FaTrashAlt /> Delete User</Space>
                            </Button>
                        </Space>
                    </Col>
                }

            </Row>
            {userDetails && userDetails.length > 0 && userDetails.map((item, key) => (
                <Row className={key === 0 ? 'm-t-10' : 'm-t-20'} key={key}>
                    <Col span={24}>
                        <CustomTable rowKey="user_id" columns={item.columns} dataSource={userData} loading={loading} scroll={{ x: 970 }} />
                    </Col>
                </Row>
            ))}
            <Row className='m-t-20'>
                <Col span={24}>
                    <CustomTable rowKey="id" columns={cardColumns} dataSource={cardData} loading={cardLoading} scroll={{ x: 970 }} />
                </Col>
            </Row>
            <Row className="m-t-20" gutter={[12, 12]}>
                <Col flex='150px'>
                    <Typography.Text className="fs-18px medium">User Access</Typography.Text>
                </Col>
                <Col>
                    <Button type="primary" disabled={deleted} onClick={() => setState({ addAccessOpen: true })}>
                        Add User Access
                    </Button>
                </Col>
                <Col flex='auto'>
                    <Row justify='end' gutter={24}>
                        <Col>
                            <Button type='primary' danger onClick={() => navigate(URLS.ManageUsers)}>Return</Button>
                        </Col>
                        <Col>
                            <Button type='primary' disabled={deleted} loading={userUpdate.isLoading} onClick={handleSave}>Save</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="m-t-20">
                <Col flex='auto'>
                    {
                        accessLoading ?
                            <Row>
                                <Col flex='150px'>
                                    <Typography.Text className="fs-18px regular">Product</Typography.Text>
                                </Col>
                                <Col>
                                    <Spin spinning />
                                </Col>
                            </Row> :
                            accessData?.existing?.length > 0 && (
                                accessData.existing.map((val, key) =>
                                    <Row gutter={12} className={key > 0 ? 'm-t-20' : ''} key={key}>
                                        <Col flex='150px'>
                                            <Typography.Text className="fs-18px regular">Product</Typography.Text>
                                        </Col>
                                        <Col flex='350px'>
                                            <Typography.Text className="fs-18px medium">{val.account_name}</Typography.Text>

                                        </Col>
                                        {
                                            userData?.[0]?.user?.role?.permission_name !== "Primary Administrator" && !deleted && val?.type_id &&
                                            <Col>
                                                <Typography.Text
                                                    className="link danger pointer fs-18px medium"
                                                    key={key}
                                                    onClick={() => setState({ revokeAccessOpen: true, revokeAccessItem: val })}
                                                >X Revoke Access</Typography.Text>
                                            </Col>
                                        }
                                    </Row>
                                )
                            )
                    }
                </Col>
            </Row>
        </>
    );
}

export default ManageSpecificUser;