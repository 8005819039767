import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
// eslint-disable-next-line
import { notification } from "antd";
import { useManageSpecificCardState } from "App/Pages/Cards/Cards/ManageSpecificCard/store";

const useDestroyCardMutation = ({cardId}) => {
    const apiRequest = useApiRequest();
    const queryClient = useQueryClient();
    const setManageCardState = useManageSpecificCardState((s) => s.setState);

    const mutation = useMutation({
        mutationFn: ({cardId, pass}) => {
            return apiRequest(`${APIROUTES.post.gps_destroycard}`, 'POST', {
                prepaid_card_id: cardId,
                additional_info: 'web-app',
                ip: '1.1.1.1',
                $password: pass
            });
        },
        mutationKey: ['_gps_destroycard', cardId],
        onMutate: () => {},
        onSuccess: (data, context) => {
            if(data?.data?.success) {
                queryClient.invalidateQueries({ queryKey: ['_prepaidcard', context.cardId], exact: true});
                setManageCardState({
                    modalContent: 'cancelCardReturnToDashboard',
                    modalOpen: true,
                });
			}else {
                notification.error({ message: data?.response?.data?.error?.message, placement: 'top' });
            }
        },
        onError: (error) => {
            notification.error({ message: error.message,  placement: 'top' });
        },
    });

    return mutation;
}

export { useDestroyCardMutation };