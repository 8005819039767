/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Input, Modal, notification, Popconfirm, Row, Space, Typography } from "antd";
import CustomTable from "App/Components/CustomTable";
import PageDescription from "App/Components/PageDescription";
import { usePersonalProfileState } from "App/Pages/Settings/PersonalProfile/store";
import { APIROUTES } from "Constants/ApiRoutes";
import { useApiRequest } from "Hooks/API";
import { useEffect } from "react";
import { FaPen } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import URLS from "Routes/constants";
import { isPossiblePhoneNumber } from "libphonenumber-js";

function PersonalProfile() {
    const editTelephoneWork = usePersonalProfileState(state => state.editTelephoneWork);
    const editTelephoneMobile = usePersonalProfileState(state => state.editTelephoneMobile);
    const modalOpen = usePersonalProfileState(state => state.modalOpen);
    const setState = usePersonalProfileState(state => state.setState);
    const profileData = usePersonalProfileState(state => state.profileData);
    const telephoneWork = usePersonalProfileState(state => state.telephoneWork);
    const telephoneMobile = usePersonalProfileState(state => state.telephoneMobile);
    const secondaryEmail = usePersonalProfileState(state => state.secondaryEmail);
    const pass = usePersonalProfileState(state => state.pass);
    const passNew = usePersonalProfileState(state => state.passNew);
    const btnLoading = usePersonalProfileState(s => s.btnLoading);
    const loading2fa = usePersonalProfileState(s => s.loading2fa);
    const profileLoading = usePersonalProfileState(s => s.profileLoading);
    const passwordValidation = usePersonalProfileState(s => s.passwordValidation);
    const updateLoading = usePersonalProfileState(s => s.updateLoading);
    const userId = usePersonalProfileState(s => s.userId);
    const navigate = useNavigate();
    const apiRequest = useApiRequest();

    const updateTelephoneWork = (val) => {
        setState({ telephoneWork: val.target.value });
    }
    const updateTelephoneMobile = (val) => {
        setState({ telephoneMobile: val.target.value });
    }

    const disable2fa = async () => {
        setState({ loading2fa: true });
        await apiRequest(`${APIROUTES.post.disable_2fa}`, 'POST', undefined, undefined, undefined, true);
        setState({ loading2fa: false })
        getPersonalProfile();
    }

    useEffect(() => setState({ editTelephoneMobile: false, editTelephoneWork: false }), [])

    const columns = [
        [
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                width: 300,
                align: 'left',
                render: text => text ? text : '-'
            },
            {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
                width: 300,
                align: 'left',
            },
            {
                title: 'Middle Name',
                dataIndex: 'middleName',
                key: 'middleName',
                width: 300,
                align: 'left',
            },
            {
                title: 'Last Name',
                dataIndex: 'lastName',
                key: 'lastName',
                width: 300,
                align: 'left',
            },
            {
                // title: !edit && <Button type="primary" onClick={() => setState({ edit: true })}>Edit Profile</Button>,
                key: 'key',
                align: 'right'
            }
        ],
        [
            {
                title: 'Address Line 1',
                dataIndex: 'addressLine1',
                key: 'addressLine1',
                width: 300,
                align: 'left',
            },
            {
                title: 'Address Line 2',
                dataIndex: 'addressLine2',
                key: 'addressLine2',
                width: 300,
                align: 'left',
            },
            {
                title: 'City',
                dataIndex: 'city',
                key: 'city',
                width: 300,
                align: 'left',
            },
            {
                title: 'Postal Code',
                dataIndex: 'postalCode',
                key: 'postalCode',
                width: 300,
                align: 'left',
            },
            {
                title: 'County/Province',
                dataIndex: 'countyProvince',
                key: 'countyProvince',
                width: 335,
                align: 'left',
            },
            {
                title: 'Country',
                dataIndex: 'country',
                key: 'country',
                align: 'left',
            }
        ],
        [
            {
                title: <Space>
                    Telephone - Work
                    {
                        !editTelephoneWork && <Button type="primary" size="small" onClick={() => setState({ editTelephoneWork: true })}>
                            <FaPen style={{ marginBottom: '-2px' }} />
                        </Button>
                    }
                </Space>,
                dataIndex: 'telephoneWork',
                key: 'telephoneWork',
                width: 300,
                render: text => editTelephoneWork ?
                <Input value={telephoneWork} onChange={(val) => updateTelephoneWork(val)} /> : text,
                align: 'left',
            },
            {
                title: <Space>
                    Telephone - Mobile
                    {
                        !editTelephoneMobile && <Button type="primary" size="small" onClick={() => setState({ editTelephoneMobile: true })}>
                            <FaPen style={{ marginBottom: '-2px' }} />
                        </Button>
                    }
                </Space>,
                dataIndex: 'telephoneMobile',
                key: 'telephoneMobile',
                width: 300,
                render: text => editTelephoneMobile ?
                <Input value={telephoneMobile} onChange={(val) => updateTelephoneMobile(val)} /> : text,
                align: 'left',
            },
            {
                title: <Space>Email <Button type="primary" size="small" onClick={() => navigate(`${URLS.ChangeEmail}/${userId}`)}><FaPen style={{ marginBottom: '-2px' }} /></Button></Space>,
                dataIndex: 'primaryEmail',
                key: 'primaryEmail',
                width: 300,
                align: 'left',
            },
            {
                key: 'space'
            }
        ],
        [
            {
                title: 'Security',
                dataIndex: 'security',
                key: 'security',
                width: 300,
                align: 'left',
            },
            {
                title: 'Password',
                dataIndex: 'password',
                key: 'password',
                width: 300,
                align: 'left',
            },
            {
                title: 'Two Factor Authenticator',
                dataIndex: 'twoFactorAuthenticator',
                key: 'twoFactorAuthenticator',
                width: 600,
                render: val => <Typography.Text type={val ? 'success' : 'danger'} className='medium'>{val ? 'Enabled' : 'Disabled'}</Typography.Text>,
                align: 'left',
            },
            {
                title: <Button type="primary" onClick={() => { setState({ modalOpen: true }) }}>Change Password</Button>,
                key: 'key',
                align: 'left',
                width: 335
            },
            {
                title:
                    profileData?.[3]?.[0]?.twoFactorAuthenticator ?
                        <Popconfirm
                            okText='Disable 2FA'
                            cancelText='Cancel'
                            title='Are you sure you wish to disable 2FA?'
                            okButtonProps={{ danger: true, loading: loading2fa }}
                            onConfirm={disable2fa}
                        >
                            <Button type="primary" danger>Disable Two Factor</Button>
                        </Popconfirm> :
                        <Button type="primary" onClick={() => navigate(URLS.EnableTwoFactor)}>Enable Two Factor</Button>,
                key: 'key',
                align: 'left'
            }
        ]
    ]

    useEffect(() => {
        getPersonalProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPersonalProfile = async () => {
        setState({ profileLoading: true });
        let requestUrl = 'users-manage/self';
        let response = await apiRequest(requestUrl);
        let profileData = [];
        if (response && response?.data) {
            profileData = [
                [
                    {
                        title: response?.data?.opt_honorific?.option,
                        firstName: response?.data?.first_name,
                        middleName: response?.data?.middle_name,
                        lastName: response?.data?.last_name,
                        key: 0
                    }
                ],
                [
                    {
                        addressLine1: response?.data?.address,
                        addressLine2: response?.data?.address_line_2,
                        city: response?.data?.city,
                        postalCode: response?.data?.postcode,
                        country: response?.data?.country?.name,
                        key: 0
                    }
                ],
                [
                    {
                        telephoneWork: response?.data?.telephone_work,
                        telephoneMobile: response?.data?.telephone_mobile,
                        primaryEmail: response?.data?.user?.email,
                        secondaryEmail: response?.data?.email_secondary,
                        key: 0
                    }
                ],
                [
                    {
                        password: '**********',
                        twoFactorAuthenticator: !!response?.data?.user?.secret_2FA,
                        key: 0
                    }
                ]
            ];
            setState({
                profileData: profileData,
                telephoneWork: response?.data?.telephone_work,
                telephoneMobile: response?.data?.telephone_mobile,
                primaryEmail: response?.data?.user?.email,
                secondaryEmail: response?.data?.email_secondary,
                userId: response?.data?.user_id
            });
        } else {
            notification.error({ message: 'Something Went Wrong', description: 'There was an issue with loading your profile', placement: 'top' });
        }
        setState({ profileLoading: false });
    }

    const handleOnFinish = async () => {
        setState({
            btnLoading: true
        });
        const data = {};
        data.oldPassword = pass;
        data.newPassword = passNew;
        const passResponse = await apiRequest("users/password", "PUT", data);
        if (passResponse?.response?.data?.error?.error_number === 351) {
            setState({
                passwordValidation: {
                    status: 'error',
                    help: 'Invalid Password',
                },
                btnLoading: false
            })
        } else {
            if (passResponse?.data?.message === "password changed") {
                notification.success({ message: 'Password Updated Succesfully', description: 'Password Updated Succesfully', placement: 'top' });
            } else {
                notification.error({ message: 'Something Went Wrong', description: 'There was an issue with updating you password', placement: 'top' });
            }
            setState({ modalOpen: false, btnLoading: false });
        }
    }

    const update = async () => {
        if (!telephoneMobile || !isPossiblePhoneNumber(telephoneMobile)) {
            notification.info({
                message: !telephoneMobile
                    ? 'Telephone Mobile is Mandatory'
                    : 'Incorrect Telephone Mobile',
                description: !telephoneMobile
                    ? 'Please type Telephone Mobile'
                    : 'Please type correct Telephone Mobile',
                placement: 'top',
            });
            return;
        }
        if(telephoneWork && !isPossiblePhoneNumber(telephoneWork)) {
            notification.info({ message: 'Incorrect Telephone Work', description: 'Please type correct Telephone Work', placement: 'top' })
            return;
        }
        setState({ updateLoading: true })
        const data = {};
        // data.email_secondary = secondaryEmail;

        data.telephone_mobile = telephoneMobile;
        data.telephone_work = telephoneWork;
        const profileResponse = await apiRequest("users-manage/self", "PUT", data);
        if (profileResponse && profileResponse.details) {
            setState({ secondaryEmail, telephoneMobile, telephoneWork });
            getPersonalProfile();
            notification.success({ message: 'Profile Updated Succesfully', description: 'Profile Updated Succesfully', placement: 'top' });
        } else {
            notification.error({ message: 'Something Went Wrong', description: 'There was an issue with updating profile', placement: 'top' });
        }
        setState({
            editTelephoneMobile: false,
            editTelephoneWork: false,
            updateLoading: false
        });
    }

    const updatePass = async (val) => {
        setState({
            pass: val.target.value,
            passwordValidation: undefined
        });
    }

    const updatePassNew = async (val) => {
        setState({ passNew: val.target.value });
    }

    return (
        <>
            <Modal
                open={modalOpen}
                footer={false}
                destroyOnClose
                onCancel={() => setState({ modalOpen: false })}
            >
                <Row justify="center">
                    <Col>
                        <Typography.Text className="fs-20px muli semi-bold dark-green">
                            Change Password
                        </Typography.Text>
                    </Col>
                </Row>
                <Row className="m-t-20" justify="center">
                    <Col span={20}>
                        <Form
                            layout="vertical"
                            requiredMark={false}
                            onFinish={handleOnFinish}>
                            <Form.Item
                                name='password'
                                validateStatus={passwordValidation?.status}
                                help={passwordValidation?.help}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Your Existing Password'
                                    }
                                ]}
                            >
                                <Input.Password onChange={(val) => updatePass(val)} placeholder="Existing Password" />
                            </Form.Item>
                            <Form.Item
                                name='newPassword'
                                dependencies={['password']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your new password'
                                    },
                                    {
                                        pattern: /\d/,
                                        message: 'Password must include at least one number (0-9)'
                                    },
                                    {
                                        pattern: /[A-Z]/,
                                        message: 'Password must include at least one Uppercase character (A-Z)'
                                    },
                                    {
                                        pattern: /[a-z]/,
                                        message: 'Password must include at least one lowercase character (a-z)'
                                    },
                                    {
                                        min: 8,
                                        message: 'Password must contain at least 8 characters'
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || !getFieldValue('password') || getFieldValue('password') !== value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error("New password can't be the same as your existing password"));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password onChange={(val) => updatePassNew(val)} placeholder="New Password" />
                            </Form.Item>
                            <Form.Item
                                name='confirmPassword'
                                dependencies={['newPassword']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Confirm Your New Password'
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || !getFieldValue('newPassword') || getFieldValue('newPassword') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),
                                ]}>
                                <Input.Password placeholder="Confirm New Password" />
                            </Form.Item>
                            <Row justify="center">
                                <Col>
                                    <Button type="primary" htmlType="su-bmit" loading={btnLoading}>Submit</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Modal>
            <Row>
                <Col span={24}>
                    <PageDescription title='Personal Profile' />
                </Col>
            </Row>
            {columns.map((val, key) => (
                <Row className="m-t-20" key={key}>
                    <Col span={24}>
                        <CustomTable
                            columns={val}
                            dataSource={profileData[key]}
                            headerColor='green'
                            rowClassName='fs-18px regular'
                            scroll={{ x: 900 }}
                            loading={profileLoading}
                        />
                    </Col>
                </Row>
            ))}
            {(editTelephoneWork || editTelephoneMobile) && (
                <Row className="m-t-20" justify="end" gutter={24}>
                    <Col>
                        <Button type='primary' onClick={() => setState({ editTelephoneMobile: false, editTelephoneWork: false })} danger>Cancel</Button>
                    </Col>
                    <Col>
                        <Button type='primary' onClick={() => update()} loading={updateLoading}>Save</Button>
                    </Col>
                </Row>
            )}
        </>
    )
}

export default PersonalProfile;