import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const usePaymentDetailsQuery = (uuid) => {
  const apiRequest = useApiRequest();

  // eslint-disable-next-line no-unused-vars
  const fetchQuery = async () => {
    const { data } = await apiRequest(`${APIROUTES.get.derive_quote}?$with=All&uuid=${uuid}`);
    return data[0] || null;
  }

  const query = useQuery({
    queryKey: ['_payment_details', uuid],
    queryFn: fetchQuery,
    refetchOnWindowFocus: false,
    enabled: !!uuid
  });

  return query;
}

export { usePaymentDetailsQuery };