import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useTransactionsHistoryState } from "App/Pages/Cards/TransactionHistory/store";

const useTransactionsHistoryQuery = ({txnTypeIds = null} = {}) => {
    const apiRequest = useApiRequest();
    const currentPage = useTransactionsHistoryState(state => state.currentPage);
    const limit = useTransactionsHistoryState(state => state.limit);
    const searchQuery = useTransactionsHistoryState(state => state.searchQuery);
    const currencyId = useTransactionsHistoryState(state => state.currencyId);
    const transactionCategoryId = useTransactionsHistoryState(state => state.transactionCategoryId);
    const withAttachments = useTransactionsHistoryState(state => state.withAttachments);
    const prepaidCardId = useTransactionsHistoryState(state => state.prepaidCardId);
    const transactionTypeId = useTransactionsHistoryState(state => state.transactionTypeId);

    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
		let qString = `?$with=All&$pageCount&$exclude=true&$skip=${(currentPage - 1) * limit}&$limit=${limit}`;

		if(!!searchQuery) {
			qString += `&search=${encodeURIComponent(searchQuery)}`;
		}

		if(!!txnTypeIds) {
			qString += `&transactionTypeId=${encodeURIComponent(txnTypeIds)}`;
		}
        if(!!currencyId) {
			qString += `&currencyId=${encodeURIComponent(currencyId)}`;
		} 
        if(!!transactionCategoryId) {
			qString += `&transactionCategoryId=${encodeURIComponent(transactionCategoryId)}`;
		} 
        if(withAttachments !== null) {
			qString += `&withAttachments=${encodeURIComponent(withAttachments)}`;
		}
        if(!!prepaidCardId) {
			qString += `&prepaidCardId=${encodeURIComponent(prepaidCardId)}`;
		}
        if(!!transactionTypeId) {
			qString += `&transactionTypeId=${encodeURIComponent(transactionTypeId)}`;
		} 

        let url = `${APIROUTES.get.transaction_settled}${qString}`;
        const data = await apiRequest(url);
        return data;
    }

    // eslint-disable-next-line no-unused-vars
    const _fetchQuery = async () => {
        return new Promise(resolve => {
            setTimeout(resolve([]), 1200);
        });
    }

    const query = useQuery({
        queryKey: ['_transaction_settled', {currentPage, limit, searchQuery, txnTypeIds, currencyId, transactionCategoryId, withAttachments, prepaidCardId, transactionTypeId}],
        queryFn: fetchQuery,
        onSuccess: () => {}
    });

    return query;
}

export { useTransactionsHistoryQuery };
