import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useOverrideCodatCatIdMutation = ({catId, itemId}) => {
    const apiRequest = useApiRequest();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: ({catId, itemId}) => {
            return apiRequest(`${APIROUTES.put.expenses_override_codat_category_id}${itemId}`, 'PUT', {overrideCodatCategoryId: catId});
        },
        mutationKey: '_put_expenses_override_codat_category_id',
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['_get_accounting_transactions_expenses'], exact: false});
        }
    });

    return mutation;
}

export { useOverrideCodatCatIdMutation };