import { Button, Checkbox, Col, Form, Image, Row, Space, Typography, Card, InputNumber } from "antd";
import CardDetails from "App/Components/CardDetails";
import PageDescription from "App/Components/PageDescription";
import { FLAGS } from "Constants/Images";
import { useParams } from "react-router-dom";
import CardBalanceListing from "App/Components/CardBalanceListing";
import { useCardAutofundQuery } from "Hooks/Queries";
import { useCardAutofundState } from "Hooks/Store";
import { usePrepaidCardQuery } from "Hooks/Queries";
import { useEffect } from "react";
import { isEqual } from "lodash";
import { useCardAutofundCreateMutation, useCardAutofundUpdateMutation, useCardAutofundDelMutation } from "Hooks/Mutations";

function AutoFund() {
    // No longer in use
    const { cardId } = useParams();
    const setState = useCardAutofundState(state => state.setState);
    const amountBelow = useCardAutofundState(state => state.amountBelow);
    const amountLoad = useCardAutofundState(state => state.amountLoad);
    const amountLoadTo = useCardAutofundState(state => state.amountLoadTo);
    const autoloadType = useCardAutofundState(state => state.autoloadType);
    const initialData = useCardAutofundState(state => state.initialData);
    const validate = useCardAutofundState(state => state.validate);
    const { data: autoFundData } = useCardAutofundQuery(cardId);
    const { data: card } = usePrepaidCardQuery(cardId);
    const cardAutofundCreateMutation = useCardAutofundCreateMutation(payload => payload);
    const cardAutofundUpdateMutation = useCardAutofundUpdateMutation(payload => payload);
    const cardAutofundDelMutation = useCardAutofundDelMutation(payload => payload);

    //
    useEffect(() => {
        if (!!autoFundData) {
            setState({
                amountBelow: autoFundData?.amountBelow,
                amountLoad: autoFundData?.amountLoad,
                amountLoadTo: autoFundData?.amountLoadTo,
                autoloadType: autoFundData?.autoloadType,
                initialData: {
                    amountBelow: autoFundData?.amountBelow,
                    amountLoad: autoFundData?.amountLoad,
                    amountLoadTo: autoFundData?.amountLoadTo,
                    autoloadType: autoFundData?.autoloadType,
                }
            });
        }

        // eslint-disable-next-line
    }, [autoFundData]);

    //
    const initStateChanged = () => {
        return !isEqual(
            initialData,
            {
                amountBelow,
                amountLoad,
                amountLoadTo,
                autoloadType,
            }
        );
    }

    //
    const handleAutoFundTypeChange = (evt) => {
        setState({
            autoloadType: evt.target.checked ? evt.target.value : '',
        });
    }

    //
    const handleInputChange = (val, key) => {
        setState({
            [key]: val,
        });
    }

    const handleCancelChanges = () => {
        setState({
            amountBelow: initialData.amountBelow,
            amountLoad: initialData.amountLoad,
            amountLoadTo: initialData.amountLoadTo,
            autoloadType: initialData.autoloadType,
        })
    }

    //
    const handleOnConfirm = () => {

        if (initStateChanged()) {
            if (autoFundData?.notSet) {
                create();
            } else {
                update();
            }
        }
    }

    //
    const update = () => {
        if (validate()) {
            cardAutofundUpdateMutation.mutateAsync({
                autoloadId: autoFundData?.id,
                cardId,
                payload: {
                    autoloadTypeId: autoloadType,
                    amountBelow: amountBelow,
                    amountLoad: autoloadType === 1 ? amountLoad : amountLoadTo,
                }
            });
        }
    }

    //
    const create = () => {
        if (validate()) {
            cardAutofundCreateMutation.mutateAsync({
                prepaid_card_id: cardId,
                autoload_type_id: autoloadType,
                amount_below: amountBelow,
                amount_load: autoloadType === 1 ? amountLoad : amountLoadTo,
            });
        }
    }

    //
    const destroy = () => {
        if (autoFundData?.id) {
            cardAutofundDelMutation.mutateAsync({
                autoloadId: autoFundData?.id,
                cardId,
            });
        }
    }

    //
    const handleRemoveAutoFund = () => {
        destroy();
    }

    //
    const inputPrefix = <Image src={FLAGS.GBP} alt='GBP' width={24} preview={false} />;

    return (
        <>
            <Row>
                <Col span={24}>
                    <PageDescription title='Auto Fund' text='- Setup Parameters for Auto Funding on Selected Card' />
                </Col>
            </Row>
            <Row className="m-t-20" gutter={16}>
                <Col xxl={12} xl={10} lg={12} md={16} sm={20} xs={24}>
                    <Row>
                        <Col xxl={16} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <CardDetails
                                cardHolderName={`${card?.card_holder?.title} ${card?.card_holder?.first_name} ${card?.card_holder?.last_name}`}
                                cardNumber={`**** **** **** ${card?.card_number}`}
                                expiryDate={`${card?.expiry_month} ${card?.expiry_year}`}
                                cardType={card?.card_program_id || card?.card_type}
                            />
                        </Col>
                    </Row>
                    <Row className="m-t-10">
                        <Col flex='20px' />
                        <Col>
                            <Typography.Text className="fs-25px medium dark-green">Auto Fund Trigger</Typography.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form layout="vertical">
                                <Row>
                                    <Col flex='20px' />
                                    <Col>
                                        <Typography.Text className="muli semi-bold fs-18px dark-green">
                                            GBP Value Total Drops Below
                                        </Typography.Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col flex='20px' />
                                    <Col xxl={8} xl={12} lg={12} md={12} sm={12}>
                                        <Form.Item>
                                            <InputNumber
                                                placeholder="Enter Trigger Value"
                                                style={{ width: '100%' }}
                                                size="large"
                                                prefix={inputPrefix}
                                                precision={2}
                                                step={0.01}
                                                value={amountBelow > 0 ? amountBelow : 'Not Set'}
                                                onChange={(val) => handleInputChange(val, 'amountBelow')}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col flex='20px' />
                                    <Col>
                                        <Typography.Text className="fs-25px medium dark-green">Define Auto Fund</Typography.Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col flex='20px' />
                                    <Col>
                                        <Typography.Text className="muli semi-bold fs-18px dark-green">
                                            Fund Card By Amount
                                        </Typography.Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col flex='20px'>
                                        <Form.Item>
                                            <Checkbox
                                                className="m-t-10"
                                                onChange={handleAutoFundTypeChange}
                                                checked={autoloadType === 1}
                                                value={1}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xxl={8} xl={12} lg={12} md={12} sm={12}>
                                        <Form.Item>
                                            <InputNumber
                                                placeholder="Enter Amount Value"
                                                style={{ width: '100%' }}
                                                size="large"
                                                prefix={inputPrefix}
                                                precision={2}
                                                step={0.01}
                                                value={amountLoad > 0 ? amountLoad : 'Not Set'}
                                                onChange={(val) => handleInputChange(val, 'amountLoad')}
                                                disabled={autoloadType !== 1}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col flex='20px' />
                                    <Col>
                                        <Typography.Text className="muli semi-bold fs-18px dark-green">
                                            Fund Card Up to GBP Value
                                        </Typography.Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col flex='20px'>
                                        <Form.Item>
                                            <Checkbox
                                                className="m-t-10"
                                                onChange={handleAutoFundTypeChange}
                                                checked={autoloadType === 2}
                                                value={2}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xxl={8} xl={12} lg={12} md={12} sm={12}>
                                        <Form.Item>
                                            <InputNumber
                                                placeholder="Enter Amount Value"
                                                style={{ width: '100%' }}
                                                size="large"
                                                prefix={inputPrefix}
                                                value={amountLoadTo > 0 ? amountLoadTo : 'Not Set'}
                                                precision={2}
                                                step={0.01}
                                                disabled={autoloadType !== 2}
                                                onChange={(val) => handleInputChange(val, 'amountLoadTo')}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col flex='20px' />
                                    <Col>
                                        <Space>
                                            <Form.Item><Button onClick={handleCancelChanges} disabled={!initStateChanged()} type="primary">Cancel</Button></Form.Item>
                                            <Form.Item>
                                                <Button
                                                    onClick={handleRemoveAutoFund}
                                                    disabled={!!autoFundData?.notSet}
                                                    type="danger"
                                                    loading={cardAutofundDelMutation.isLoading}
                                                >
                                                    Remove
                                                </Button>
                                            </Form.Item>
                                            <Form.Item>
                                                <Button
                                                    onClick={handleOnConfirm}
                                                    disabled={!validate() || !initStateChanged()}
                                                    type="primary"
                                                    loading={cardAutofundCreateMutation.isLoading || cardAutofundUpdateMutation.isLoading}
                                                >
                                                    Confirm
                                                </Button>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Col>
                <Col xxl={12} xl={14} lg={16} md={20} sm={24} xs={24}>
                    <Card hoverable className="b-g hover-no-border" data-testid="container">
                        <Typography.Text className="medium fs-18px dark-green">Card Balance</Typography.Text>
                        <CardBalanceListing cardId={cardId} />
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default AutoFund;
