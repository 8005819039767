/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Image, Input, Modal, Row, Space, Typography, notification, Card, InputNumber } from "antd";
import { useEffect } from "react";
import PageDescription from "App/Components/PageDescription";
import RateChecker from "App/Components/RateChecker";
import { useConvertBalancesState } from "App/Pages/Wallet/ConvertBalances/store";
import { FLAGS } from "Constants/Images";
import { useNavigate } from "react-router-dom";
import URLS from "Routes/constants";
import { useLoginStore } from "App/Pages/Login/store";
import { useApiRequest } from "Hooks/API";
import { useRateCheckerState } from "App/Components/RateChecker/store";
import { useBalanceListingState } from "App/Components/BalanceListing/store";
import cloneDeep from 'lodash/cloneDeep';
import CompanyWalletBalance from "App/Components/CompanyWalletBalance";
import { accountingFormat } from "Utils";
import { useQueryClient } from "@tanstack/react-query";

function ConvertBalances() {
  const modalOpen = useConvertBalancesState((state) => state.modalOpen);
  const queryClient = useQueryClient();
  const rateCheckerLoading = useConvertBalancesState((state) => state.rateCheckerLoading);
  const message = useConvertBalancesState((state) => state.message);
  const setState = useConvertBalancesState((state) => state.setState);
  const setBalanceListingState = useBalanceListingState((state) => state.setState);
  const setRatechekcerState = useRateCheckerState((state) => state.setState);
  const config = useLoginStore((state) => state.config);
  const currencies = useConvertBalancesState((state) => state.currencies);

  const navigate = useNavigate();
  const apiRequest = useApiRequest();
  const selectedFromCurrency = useRateCheckerState(
    (state) => state.selectedFromCurrency
  );
  const selectedToCurrency = useRateCheckerState(
    (state) => state.selectedToCurrency
  );
  const toValue = useRateCheckerState((state) => state.toValue);
  const fromValue = useRateCheckerState((state) => state.fromValue);
  const side = useRateCheckerState((state) => state.side);
  const rate = useRateCheckerState((state) => state.rate);
  const rateUid = useRateCheckerState((state) => state.rateUid);
  const pageDescription = [
    "- Your company wallet stores all the funds that you can load your prepaid cards with",
    "- Easily exchange the currencies on your Company Wallet at the market rate",
  ];

  let balanceListingParams = {
    data: [
      {
        columns: [
          {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
            render: (text, record) => (
              <Space size={3}>
                {record.flag && <Image src={FLAGS[record.flag]} preview={false} alt={text} width={45} />}
                {text}
              </Space>
            )
          },
          {
            title: 'Available to Spend',
            dataIndex: 'availableToSpend',
            key: 'availableToSpend'
          },
          {
            title: 'Rate',
            dataIndex: 'rate',
            key: 'rate'
          },
          {
            title: 'GBP Value',
            dataIndex: 'GBPValue',
            key: 'GBPValue'
          },
        ]
      }
    ]
  }

  useEffect(() => {
    getCurrencies();
    getBalances();
    setRatechekcerState({ loadingRate: false });
    return () => {
      setRatechekcerState({
        selectedFromCurrency: undefined,
        selectedToCurrency: undefined,
        toValue: undefined,
        fromValue: undefined
      });
    }
  }, []);

  const handleOnFinish = (resetRateChecker) => {

    createQuote(resetRateChecker);
  };

  const createQuote = async (resetRateChecker) => {
    setRatechekcerState({ loadingConvert: true });
    const walletResponse = await apiRequest("tcc/company/card");
    if (walletResponse && walletResponse.data) {
      const data = {};
      data.prepaidCardId = walletResponse.data.prepaid_card_id;
      data.ccySell = selectedFromCurrency;
      data.ccyBuy = selectedToCurrency;
      data.dealSide = side;
      data.dealAmount = side === "buy" ? parseFloat(Number(toValue).toFixed(4)) : parseFloat(Number(fromValue).toFixed(4));
      data.rateUid = rateUid;
      const quoteResponse = await apiRequest("exchange/quote", "POST", data);
      if (quoteResponse.response && quoteResponse.response.data.error) {
        if (quoteResponse.response.status === 400) {
          setState({ message: quoteResponse.response.data.error.message });
          setState({ modalOpen: true });
        } else {
          notification.error({ message: 'Error Code: ' + quoteResponse.response.data.error.error_number, description: quoteResponse.response.data.error.message, placement: 'top' });
        }
      }
      if (quoteResponse.data && quoteResponse.data.success === true) {
        setState({ message: 'success' });
        setState({ modalOpen: true });
      }
    } else {
      notification.error({ message: 'Error: ' + walletResponse.response.data.error.message, placement: 'top' });
    }
    queryClient.invalidateQueries("_company_wallet_breakdown");
    setRatechekcerState({ loadingConvert: false });
    resetRateChecker();
  };

  const getCurrencies = async () => {
    setState({ rateCheckerLoading: true });
    const response = await apiRequest("currency?$limit=500&active=1");
    if (response && response.data) {
      let cur = response.data
        .filter((data) => data.active_card === 1)
        .sort((a, b) => a.currency.localeCompare(b.currency));
      cur = cur.map((val) => {
        val.flag = FLAGS[val.currency];
        return val;
      });
      setState({ currencies: cur });
    }
    setState({ rateCheckerLoading: false });
  };

  const getBalances = async () => {
    let response = await apiRequest("company/wallet/breakdown");
    if (response && response.data) {
      response = response.data.breakdown.map((val, key) => {
        val.key = key;
        val.flag = val.currency;
        val.availableToSpend = val?.amount_available;
        val.GBPValue = val?.amount_equivalent;
        return val;
      })
      setState({ balances: response });
      balanceListingParams.data[0].data = response;
    }

    let balancesShown = cloneDeep(balanceListingParams.data);
    balancesShown = balancesShown.data ? balancesShown.data.slice(0, 6) : balanceListingParams.data;
    setBalanceListingState({
      allVisible: false,
      balanceDataShown: balancesShown,
    });

  };

  return (
    <>
      <Modal
        destroyOnClose
        open={modalOpen}
        footer={false}
        closable={false}
        className="b-g"
      >
        <Row justify="center">
          <Col>
            {
              message === 'success' &&
              <Typography.Text type="success" className="fs-18px medium">
                Conversion Complete
              </Typography.Text>
            }
            {
              (message !== 'success' && message !== undefined) &&
              <Typography.Text type="danger" className="fs-18px medium">
                {message}
              </Typography.Text>
            }

          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <Typography.Text className="muli fs-15px semi-bold light-green">
              Fx Rate 1 {selectedFromCurrency} = {rate} {selectedToCurrency}
            </Typography.Text>
          </Col>
        </Row>
        <Row className="m-t-10">
          <Col flex="auto">
            <Row>
              <Col>
                <Typography.Text className="muli semi-bold fs-18px dark-green">
                  Converted To
                </Typography.Text>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col flex='90px'>
                <Input
                  readOnly
                  value={selectedToCurrency}
                  prefix={
                    <Image
                      src={FLAGS[selectedToCurrency]}
                      preview={false}
                      alt={selectedToCurrency}
                      width={20}
                    />
                  }
                  className="default-cursor"
                />
              </Col>
              <Col flex='auto'>
                <InputNumber readOnly value={accountingFormat(toValue)} className="default-cursor full-percent-width" />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="m-t-20">
          <Col flex="auto">
            <Row>
              <Col>
                <Typography.Text className="muli semi-bold fs-18px dark-green">
                  Converted From
                </Typography.Text>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col flex='90px'>
                <Input
                  readOnly
                  value={selectedFromCurrency}
                  prefix={
                    <Image
                      src={FLAGS[selectedFromCurrency]}
                      preview={false}
                      alt={selectedFromCurrency}
                      width={20}
                    />
                  }
                  className="default-cursor"
                />
              </Col>
              <Col flex='auto'>
                <InputNumber readOnly value={accountingFormat(fromValue)} className="default-cursor full-percent-width" />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="m-t-40" justify="space-around" gutter={[8, 8]}>
          <Col>
            <Space size={96}>
              <Button
                type="primary"
                onClick={() => {
                  setState({
                    modalOpen: false,
                    message: undefined
                  });
                  setRatechekcerState({
                    selectedFromCurrency: undefined,
                    selectedToCurrency: undefined,
                    toValue: undefined,
                    fromValue: undefined,
                  });
                  navigate(URLS.WalletDashboard);
                }}
              >
                Dashboard
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setState({ modalOpen: false, message: undefined })
                  setRatechekcerState({
                    selectedFromCurrency: undefined,
                    selectedToCurrency: undefined,
                    toValue: undefined,
                    fromValue: undefined,
                    rate: undefined,
                  });
                }}
              >
                Convert More
              </Button>
            </Space>
          </Col>
        </Row>
      </Modal>
      <Row>
        <Col span={24}>
          <PageDescription title="Convert Balances" text={pageDescription} />
        </Col>
      </Row>
      <Row className="m-t-10" gutter={[16, 10]} justify="space-between">
        <Col span={6} xs={24} sm={24} md={12} lg={6}>
          <Space direction="vertical" size={5} className="full-percent-width">
            <Typography.Text className="fs-18px medium dark-green">
              Currency Conversion
            </Typography.Text>
            <RateChecker
              currencies={currencies}
              timeOut={config && config.card_expires_in}
              onFinish={resetRateChecker => handleOnFinish(resetRateChecker)}
              loading={rateCheckerLoading}
            />
          </Space>
        </Col>
        <Col span={12} lg={12} xs={24}>
          <Space direction="vertical" size={5} className="full-percent-width">
            <Typography.Text className="medium fs-18px dark-green">
              Company Wallet Balance
            </Typography.Text>
            <Card hoverable className="b-g hover-no-border" data-testid="container">
              <CompanyWalletBalance />
            </Card>
          </Space>
        </Col>
      </Row>
    </>
  );
}

export default ConvertBalances;
