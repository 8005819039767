import { useCardImageQuery } from "Hooks/Queries";
import { Empty, Image, Spin } from "antd";

export default function CardImage({ cardProgram, width, height }) {
  const { data, isLoading: imageLoading } = useCardImageQuery(cardProgram);
  return (
    imageLoading ?
      <Spin spinning /> :
      data?.url ?
        <Image src={data?.url} preview={false} {...(width && { width: width })} {...(height && { height: height })} alt="Card Image" /> :
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  )
}