import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
// eslint-disable-next-line
import { notification } from "antd";
import { useTransactionsBankFeedsState } from "../store";

const useResetXeroBankFeeds = () => {
    const apiRequest = useApiRequest();
    const queryClient = useQueryClient();
    const page = useTransactionsBankFeedsState(state => state.page);
    const limit = useTransactionsBankFeedsState(state => state.limit);
    const syncStatus = useTransactionsBankFeedsState(state => state.syncStatus);

    const mutation = useMutation({
        mutationFn: () => {
            return apiRequest(APIROUTES.put.sync_feed_reset, 'PUT');
        },
        mutationKey: ['_sync_feed_reset'],
        onMutate: () => {},
        onSuccess: (data) => {
            if(data?.success) {
				notification.success({ message: data?.data,  placement: 'top' });
                queryClient.invalidateQueries({ queryKey: ['_get_accounting_transactions_bank_feeds', page, limit, syncStatus], exact: true});
			} else {
                notification.error({ message: data?.response?.data?.error?.message,  placement: 'top' });
            }
        },
        onError: (error) => {
            notification.error({ message: error.message,  placement: 'top' });
        },
    });

    return mutation;
}

export { useResetXeroBankFeeds };