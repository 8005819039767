import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useTransactionsBankFeedsState } from "App/Pages/Accounting/Transactions/BankFeeds/store";
import { useRefreshTransactionsQuery } from "App/Pages/Accounting/Transactions/query/useRefreshTransactionsQuery";

function useAccountingBankFeedsQuery() {
    const apiRequest = useApiRequest();
    const page = useTransactionsBankFeedsState(state => state.page);
    const limit = useTransactionsBankFeedsState(state => state.limit);
    const syncStatus = useTransactionsBankFeedsState(state => state.syncStatus);
    const setState = useTransactionsBankFeedsState(state => state.setState);
    const { isFetching, isLoading } = useRefreshTransactionsQuery();

    const fetchQuery = async () => {
        const bankFeeds = await apiRequest(
            `${APIROUTES.get_accounting_transactions_bank_feeds}&$skip=${(page - 1) * limit}&$limit=${limit}&inSynchId=${syncStatus}`
        );
        return bankFeeds;
    }

    const query = useQuery({
        queryKey: ['_get_accounting_transactions_bank_feeds', page, limit, syncStatus],
        queryFn: fetchQuery,
        // Keeping pages in cache so we don't call
        //the server for already fetched pages if component is still mounted
        keepPreviousData: true,
        onSuccess: (data) => {
            setState({
                totalPages: data?.pager?.count,
            });
        },
        enabled: !isFetching && !isLoading
    });

    return query;
}

export { useAccountingBankFeedsQuery };