import { Col, Row, Space, Typography, Card } from "antd";
import PageDescription from "App/Components/PageDescription";
import RateCheckerRefactor from "App/Components/RateCheckerRefactor";
import { useCurrenciesQuery, usePrepaidCardQuery } from "Hooks/Queries";
import { useParams } from "react-router-dom";
import CompanyWalletBalance from "App/Components/CompanyWalletBalance";
import CardBalanceListing from "App/Components/CardBalanceListing";
import CardDetails from "App/Components/CardDetails";

function ConvertBalances() {
    const currenciesQuery = useCurrenciesQuery();
    const { cardId } = useParams();
    const { data: card, isLoading } = usePrepaidCardQuery(cardId);


    //
    return (
        <>
            <Row>
                <Col span={24}>
                    <PageDescription
                        title='Convert Balances'
                        text={[
                            '- Easily exchange the currencies on your card at the market rate.',
                        ]}
                    />
                </Col>
            </Row>
            <Row className="m-t-20" gutter={[12, 12]}>
                <Col xxl={12} xl={10} lg={12} md={16} sm={20} xs={24}>
                    <Row>
                        <Col xxl={16} xl={24} lg={24} md={24} sm={24} xs={24}>
                            <CardDetails
                                cardHolderName={`${card?.card_holder?.title} ${card?.card_holder?.first_name} ${card?.card_holder?.last_name}`}
                                cardNumber={`**** **** **** ${card?.card_number}`}
                                expiryDate={`${String(card?.expiry_month).padStart(2, '0')} / ${card?.expiry_year}`}
                                isLoading={isLoading}
                                cardType={card?.card_program_id}
                            />
                        </Col>
                    </Row>
                    <Row className="m-t-20">
                        <Col xxl={11} xl={17} lg={18} md={18} sm={18} xs={24}>
                            <Space direction="vertical" size={5} className='full-percent-width'>
                                <Typography.Text className="fs-18px medium dark-green">Currency Conversion</Typography.Text>
                                <RateCheckerRefactor
                                    currencies={currenciesQuery?.data || []}
                                    timeOut={30}
                                    cardId={cardId}
                                    loading={currenciesQuery.isLoading || currenciesQuery.isFetching}
                                />
                            </Space>
                        </Col>
                    </Row>
                </Col>
                <Col xxl={12} xl={14} lg={16} md={20} sm={24} xs={24}>
                    <Row>
                        <Col span={24}>
                            <Card hoverable className="b-g hover-no-border">
                                <Space direction="vertical" size={50} className='full-percent-width'>
                                    <div>
                                        <Typography.Text className="medium fs-18px dark-green">Card Balance</Typography.Text>
                                        <CardBalanceListing cardId={cardId} rowType="filled" />
                                    </div>
                                    <div>
                                        <Typography.Text className="medium fs-18px dark-green m-t-30">Company Wallet Balance</Typography.Text>
                                        <CompanyWalletBalance />
                                    </div>
                                </Space>


                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default ConvertBalances;