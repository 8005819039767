import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

function useBillPaymentBankAccountsQuery() {
    const apiRequest = useApiRequest();

    const fetchQuery = async () => {
        const { data } = await apiRequest(`${APIROUTES.get_bill_payment_bank_accounts}`);
        return data;
    }

    const query = useQuery({
        queryKey: ['_get_bill_payment_bank_accounts'],
        queryFn: fetchQuery,
		onSuccess: (data) => {
			return {
				...data,
				response: {
					...data.response,
					results: data?.response?.results?.sort((a, b) => a.accountName.localeCompare(b.accountName, 'en', {numeric: true}))
				}
			}
		}
    });

    return query;
}

export { useBillPaymentBankAccountsQuery };
