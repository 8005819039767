import create from 'zustand';

const usePersonalCardsDashState = create((set, get) => ({
	currentCardIdx: 0,
	currentCard: null,
	fullScreenModalOpen: false,
	modalHeading: undefined,
	modalDescription: undefined,
	modalBoldText: undefined,
	modalType: null,
	activateLoading: false,
	setState: (data) => set(data),
}));

export {
	usePersonalCardsDashState
}