import { Button, InputNumber, notification } from "antd";
import { useEffect } from "react";
import { useCardTxLimitQuery } from "Hooks/Queries";
import create from "zustand";
import { isEqual } from "lodash";
import { accountingFormat, numberFormat } from "Utils";
import { useTxLimitsMutation } from "Hooks/Mutations";
import { useQueryClient } from "@tanstack/react-query";
import CustomTable from "App/Components/CustomTable";


export const useTxLimitState = create((set) => ({
  editMode: false,
  setState: (data) => set(data),
  posAmount: 0,
  atmAmount: 0,
  initialData: null,
  loading: false,
}));

function TransactionLimit({ cardId, disabled }) {
  const editMode = useTxLimitState(state => state.editMode);
  const atmAmount = useTxLimitState(state => state.atmAmount);
  const posAmount = useTxLimitState(state => state.posAmount);
  const initialData = useTxLimitState(state => state.initialData);
  const loading = useTxLimitState(state => state.loading);
  const setState = useTxLimitState(state => state.setState);
  const cardTxLimitQuery = useCardTxLimitQuery(cardId);
  const txLimitsMutation = useTxLimitsMutation(payload => payload);
  const queryClient = useQueryClient();

  //
  useEffect(() => {
    if (cardTxLimitQuery.data) {
      setState({
        posAmount: cardTxLimitQuery.data?.pos?.amount,
        atmAmount: cardTxLimitQuery.data?.atm?.amount,
        initialData: {
          posAmount: cardTxLimitQuery.data?.pos?.amount,
          atmAmount: cardTxLimitQuery.data?.atm?.amount,
        }
      });
    }

    // eslint-disable-next-line
  }, [cardTxLimitQuery.data]);

  //
  const initStateChanged = () => {
    return !isEqual(
      initialData,
      { posAmount, atmAmount }
    );
  }

  //
  const handleActionBtnClick = async () => {
    if (initStateChanged()) {
      const mutations = [];

      // Def rethink the implementation
      // Need to check if init data changed individually for trx types
      const atmAction = atmAmount > 0 ? (cardTxLimitQuery.data?.atm?.amount > 0 ? 'PUT' : 'POST') : (cardTxLimitQuery.data?.atm?.amount > 0 ? 'DELETE' : null);
      const posAction = posAmount > 0 ? (cardTxLimitQuery.data?.pos?.amount > 0 ? 'PUT' : 'POST') : (cardTxLimitQuery.data?.pos?.amount > 0 ? 'DELETE' : null);

      if (atmAction) mutations.push(handleAtmAction(atmAction));
      if (posAction) mutations.push(handlePosAction(posAction));

      try {
        setState({ loading: true });
        const responses = await Promise.all(mutations);
        if (responses.some(res => res?.response?.data?.error)) {
          setState({
            posAmount: cardTxLimitQuery.data?.pos?.amount,
            atmAmount: cardTxLimitQuery.data?.atm?.amount,
          });
          notification.error({ message: 'There was an error saving some of your settings. Please try again.', placement: 'top' });
        } else {
          notification.success({ message: 'Transaction limits updated!', placement: 'top' });
        }
      } catch (err) {
        // @todo
      } finally {
        queryClient.invalidateQueries({ queryKey: ['_prepaidcard_maxtransaction_by_card_id', cardId], exact: true });
        setState({ loading: false, editMode: !editMode });
      }
    } else {
      setState({ editMode: !editMode });
    }
  }

  //
  const handleAtmAction = (action) => {
    return txLimitsMutation.mutateAsync({
      typeId: 2,
      tid: cardTxLimitQuery.data?.atm?.id,
      cardId,
      method: action,
      amount: atmAmount,
    });
  }

  //
  const handlePosAction = (action) => {
    return txLimitsMutation.mutateAsync({
      typeId: 1,
      tid: cardTxLimitQuery.data?.pos?.id,
      cardId,
      method: action,
      amount: posAmount
    });
  }

  //
  const columns = [
    {
      title: "Maximum ATM Withdrawal",
      dataIndex: "maximumATMWithdrawal",
      key: "maximumATMWithdrawal",
    },
    {
      title: "Maximum Purchase Amount",
      dataIndex: "maximumPurchaseAmount",
      key: "maximumPurchaseAmount",
    },
    {
      title: "",
      dataIndex: "key",
      key: "action",
      render: () => (
        <Button type="primary" onClick={handleActionBtnClick} disabled={disabled}>
          {editMode ? (initStateChanged() ? 'Save' : 'Cancel') : 'Edit'}
        </Button>
      ),
      align: "right",
    },
  ];
  //
  const dataSource = [
    {
      maximumATMWithdrawal: !editMode
        ? (atmAmount ? accountingFormat(atmAmount) : 'Not Set')
        : <InputNumber
          min={0}
          className="full-percent-width"
          controls={false}
          value={atmAmount || null}
          onChange={(val) => onAmountChange(val, 'atmAmount')}
          formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
          parser={(val) => numberFormat(val)}
        />,
      maximumPurchaseAmount: !editMode
        ? (posAmount ? accountingFormat(posAmount) : 'Not Set')
        : <InputNumber
          min={0}
          className="full-percent-width"
          controls={false}
          value={posAmount || null}
          onChange={(val) => onAmountChange(val, 'posAmount')}
          formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
          parser={(val) => numberFormat(val)}
        />,
      key: 0,
    },
  ];

  //
  const onAmountChange = (val, key) => {
    setState({
      [key]: Number(val),
    });
  }

  return (
    <>
      <CustomTable loading={loading} dataSource={dataSource} columns={columns} />
    </>
  );
}

export default TransactionLimit;
