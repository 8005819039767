import { useMutation } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { notification } from "antd";
import { useTransactionState } from "App/Pages/Cards/TransactionHistory/SpecificCardTransaction/store";

const useTransactionExpenseMutation = (expenseId) => {
    const apiRequest = useApiRequest();
    const expenseNotes = useTransactionState(state => state.expenseNotes);
    const projectId = useTransactionState(state => state.projectId);
    // const expenseId = useTransactionState(state => state.expenseId);

    const mutation = useMutation({
        mutationFn: (expenseId) => {
            return apiRequest(APIROUTES.put.expense + expenseId, 'PUT', {
                notes: expenseNotes,
                project_id: projectId
                // submitted: 0,
                // expense_type_id: expenseTypeId,
                // expense_type_description: expenseTypeDesc,
            });
        },
        mutationKey: ['_expense', expenseId],
        onSuccess: (data) => {
            if (data?.response?.status === 400) {
                throw Error(data);
            }
        },
        onError: (error) => {
            notification.error({ message: 'Something Went Wrong', description: 'There was an issue saving transaction details.', placement: 'top' });
        },
    });

    return mutation;
}

export { useTransactionExpenseMutation };