import create from "zustand";

const useDraftPaymentsState = create(set => ({
  limit: 10,
  current: 1,
  totalPages: 1,
  dateFilter: undefined,
  search: undefined,
  setState: obj => set(obj),
  resetState: () => set({
    limit: 10,
    current: 1,
    totalPages: 1,
    dateFilter: undefined,
    search: undefined,
  })
}))

export { useDraftPaymentsState };