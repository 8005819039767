import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
// eslint-disable-next-line
import { notification } from "antd";
import { useManageUsersState } from "../../store";
import { useState } from "../../components/DeleteConfirmationModal";

const useUserDelete = () => {
    const apiRequest = useApiRequest();
    const queryClient= useQueryClient()
    const currentPage = useManageUsersState(state => state.currentPage);
    const limit = useManageUsersState(state => state.limit);
    const searchFilter = useManageUsersState(state => state.searchFilter);
    const statusIdIn = useManageUsersState(state => state.statusIdIn);
    const sort = useManageUsersState(s => s.sort);
    const setState= useState(s => s.setState)

    const mutation = useMutation({
        mutationFn: ({user_id, password}) => {

            return apiRequest(APIROUTES.del.user, 'DELETE', {
                $password: password,
                delete: user_id, 
                delete_reason:"it was bothering me" // TODO: It shouldn't be hardcode
            });
        },
        mutationKey: ['_user_delete'],
        onMutate: () => {},
        onSuccess: (data) => {
            if(data?.success) {
                queryClient.invalidateQueries(['_users_list', currentPage, limit, searchFilter, sort, statusIdIn])
                setState({ modalType: 'success' })
            } else {
                notification.error({ placement: 'top', message: data?.response?.data?.error?.message });
            }
        },
        onError: (error) => {
            notification.error({ placement: 'top', message: 'Something went wrong', description: 'Please try again.' });
        },
    });

    return mutation;
}

export { useUserDelete };