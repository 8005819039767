import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useManageUsersState } from "App/Pages/Settings/CompanyAccount/ManageUsers/store";

const useUserListQuery = () => {
  const apiRequest = useApiRequest();
  const currentPage = useManageUsersState(state => state.currentPage);
  const limit = useManageUsersState(state => state.limit);
  const searchFilter = useManageUsersState(state => state.searchFilter);
  const statusIdIn = useManageUsersState(state => state.statusIdIn);
  const sort = useManageUsersState(s => s.sort);

  // eslint-disable-next-line no-unused-vars
  const fetchQuery = async () => {
    const res = await apiRequest(`${APIROUTES.get.users_manage}&$pageCount&$skip=${(currentPage - 1) * limit}&$limit=${limit}${searchFilter ? `&searchCustom=${searchFilter}` : ''}${sort ? `&sortColumn=${sort}` : ''}${statusIdIn ? `&statusIdIn=${statusIdIn}` : ''}`);
    return res || [];
  }

  const query = useQuery({
    queryKey: ['_users_list', currentPage, limit, searchFilter, sort, statusIdIn],
    queryFn: fetchQuery
  });

  return query;
}

export { useUserListQuery };