/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Image, Input, Row, Space, notification } from "antd";
import PageDescription from "App/Components/PageDescription";
import { FLAGS } from "Constants/Images";
import CustomTable from "App/Components/CustomTable";
import { useNavigate } from 'react-router-dom';
import URLS from "Routes/constants";
import { useApiRequest } from "Hooks/API";
import { useEffect } from "react";
import { useRecipientListState } from "App/Pages/Payments/RecipientList/store";
import moment from 'moment';
import debounce from 'lodash/debounce'
import { accountingFormat } from "Utils";
import { useNewPaymentState } from "App/Pages/Payments/NewPayment/store";
import CustomPagination from "App/Components/CustomPagination";

function RecipientList() {
    const setState = useRecipientListState((state) => state.setState);
    const recipientsData = useRecipientListState((state) => state.recipientsData);
    const total = useRecipientListState((state) => state.total);
    const limit = useRecipientListState((state) => state.limit);
    const currentPage = useRecipientListState((state) => state.currentPage);
    const recipientsLoading = useRecipientListState(state => state.recipientsLoading);
    const setnewPaymentState = useNewPaymentState(state => state.setState);

    const navigate = useNavigate();
    const apiRequest = useApiRequest();

    useEffect(() => {
        getReciepients();
        return () => {
            setState({ currentPage: 1, limit: 10, })
        }
    }, []);

    useEffect(() => {
        const skip = (currentPage - 1) * limit;
        getReciepients(undefined, skip, limit)
    }, [currentPage, limit])


    const searchInputChanged = (event) => {
        getReciepients(event.target.value);
    };

    const getReciepients = async (searchTerm = undefined, skip = 0, limit = 10) => {
        setState({ recipientsLoading: true });
        let requestUrl = `tcc/beneficiary?$with=tccContact.tccAccount,getLastPaymentInfo&$limit=${limit}&$skip=${skip}&$pageCount`;
        if (searchTerm !== undefined) {
            requestUrl += '&name=%' + searchTerm + '%';
        }
        let response = await apiRequest(requestUrl);
        if (response && response.data) {
            const count = response?.pager?.count
            response = response.data.map((val, key) => {
                let temp = {
                    name: val.name !== "undefined undefined" ? val.name : val.beneficiary_company_name,
                    bank: {
                        name: val.bank_name,
                        number: val.iban ? val.iban && "**** ***** " + val.iban.substr(-4) : (val.account_number && "**** ***** " + val.account_number.substr(-4)),
                        country: val.bank_country
                    },
                    currency: val.currency,
                    lastPayment: {
                        date: val.get_last_payment_info && moment.utc(val.get_last_payment_info.created_at).format('MMMM D, YYYY'),
                        amount: val.get_last_payment_info && val.get_last_payment_info.amount
                    },
                    key: key,
                    fullDetails: val
                };
                val = temp;
                return val;
            })
            setState({ recipientsData: response, total: count });
        } else {
            notification.error({ message: 'Something Went Wrong', description: 'There was an issue with loading recipients', placement: 'top' });
        }
        setState({ recipientsLoading: false });
    }

    const columns = [
        {
            title: 'Name/Nickname',
            dataIndex: 'name',
            key: 'name',
            align: "center",
        },
        {
            title: 'Bank',
            dataIndex: 'bank',
            key: 'bank',
            render: text => <Space direction="vertical" size={0}>
                {text.name}
                {text.number}
                {text.country}
            </Space>
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
            render: text => <Space size={4}>
                {FLAGS[text] && <Image src={FLAGS[text]} alt={text} width={36} preview={false} />}
                {text}
            </Space>
        },
        {
            title: 'Last Payment',
            dataIndex: 'lastPayment',
            key: 'lastPayment',
            render: text => <Space direction="vertical" size={0}>
                {text.date}
                {accountingFormat(text.amount)}
            </Space>
        },
        {
            title: <Button type="primary" size="small" onClick={() => navigate(URLS.AddRecipient)}>Add Recipient</Button>,
            dataIndex: 'key',
            key: 'action',
            align: 'center',
            render: (text, obj) => <Button type="primary" onClick={(e) => { e.stopPropagation(); paySpecificRecipient(obj) }}>Pay</Button>
        }
    ];

    const paySpecificRecipient = (recipient) => {
        setnewPaymentState({ recipient: recipient.fullDetails, resetRecipient: false });
        navigate(URLS.NewPayment);
    }

    //
    const handlePageSizeChange = (current, size) => {
        setState({ limit: size, currentPage: 1 });
    }

    //
    const handlePageChange = (page) => {
        setState({ currentPage: page });
    }


    return (
        <>
            <Row>
                <Col span={24}>
                    <PageDescription title='Recipient List' text='- Overview of bank payment recipients' />
                </Col>
            </Row>
            <Row className="m-t-10">
                <Col xxl={4}>
                    <Input.Search placeholder="Search Recipient Name/Nickname" onChange={debounce(searchInputChanged, 1000)} />
                </Col>
            </Row>
            <Row className="m-t-10">
                <Col xxl={13} xl={14} lg={17} md={21} sm={24} xs={24}>
                    <CustomTable
                        columns={columns}
                        dataSource={recipientsData}
                        headerColor='green'
                        loading={recipientsLoading}
                        onRow={record => ({ onClick: () => navigate(`${URLS.RecipientDetails}/${record?.fullDetails?.id}`) })}
                        rowClassName='medium fs-18px pointer'
                    />
                    <CustomPagination
                        loading={recipientsLoading}
                        onPageChange={handlePageChange}
                        total={total}
                        pageSize={limit}
                        current={currentPage}
                        onPageSizeChange={handlePageSizeChange}
                    />
                </Col>
            </Row>
        </>
    )
}

export default RecipientList;
