import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useConfigurationState } from "../store/index";
import axios from "axios";

const useAccountingConfigurationQuery = () => {
    const apiRequest = useApiRequest();
    const setState = useConfigurationState(state => state.setState);
    const statusId = useConfigurationState(state => state.statusId);

    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
        const { data } = await apiRequest(`${APIROUTES.get_accounting_config_all}`);
        return data;
    }



    // @todo delete
    // eslint-disable-next-line no-unused-vars
    const _fetchQuery = async () => {
        const { data } = await axios.get(`http://localhost:5001/accounting/getAllConfiguration`);
        return data;
    }

    const query = useQuery({
        queryKey: ['_get_accounting_config_all'],
        queryFn: fetchQuery,
        enabled: statusId === 3,
        onSuccess: (data) => {
            const state = {
                syncExpenses: data?.configuration?.expenses,
                syncBankFeeds: data?.configuration?.feed,
                allCards: data?.configuration?.all_cards,
                selectedCards: data?.cards,
                categoryMap: data?.categories,
                codatBankAccountId: data?.account?.codat_bank_account_id,
                codatBillPaymentBankAccountId: data?.account?.codat_bill_payment_bank_account_id,
                clientCodatCompanyMapId: data?.configuration?.client_codat_company_map_id,
                trackingCodeMap :  data?.tracking_codes_map.map(item => ({ 
                    codat_tracking_category_id: item?.codat_tracking_category_id,
                    tracking_code_uuid: item?.tracking_code?.uuid,
                    id : item?.tracking_code?.uuid
                }))
            }
            setState(state);
        },
        onError: () => {
            setState({
                allCards: 1,
                syncCardTrans: true,
            });
        }
    });

    return query;
}

export { useAccountingConfigurationQuery };
