import { Card, Carousel, Col, Row, Space, Typography, Grid } from "antd";
import TotalCompanyBalance from "App/Components/TotalCompanyBalance";
import { useRef } from "react";
import { AiFillRightCircle, AiOutlineLeftCircle } from "react-icons/ai";

const { useBreakpoint } = Grid;

function DashboardCarousel({ cards }) {
  const slider = useRef();
  const breakpoint = useBreakpoint();
  const slidesToShow = cards?.length < 3 ? cards?.length : (breakpoint.lg === false ? (breakpoint.sm === false ? 1 : 2) : 3);

  return (
    <>
      <Row>
        <Col span={24}>
          <Card className="bg-gradient big-rounded dashboard-carousel" data-testid='container' bodyStyle={{ padding: '1.25vw' }}>
            <TotalCompanyBalance />

            {!!cards && (
              <>
                <Row justify="end" className="m-t-20" style={{ marginRight: '4.17vw' }}>
                  {cards.length > slidesToShow && (
                    <Col className='right-align-text m-r-12'>
                      <Space size='middle' className='dark-green' style={{ fontSize: '15px' }}>
                        <AiOutlineLeftCircle onClick={() => slider.current.prev()} size={18} className='pointer' />
                        <AiFillRightCircle size={18} onClick={() => slider.current.next()} className='pointer' data-testid='button' />
                      </Space>
                    </Col>
                  )}
                </Row>
                <Row className='m-b-40' style={{ overflow: 'hidden', marginRight: '4.17vw', marginLeft: '4.17vw' }}>
                  <Col style={{ minWidth: '250px' }} span={24}>
                    <Carousel
                      slidesToShow={slidesToShow}
                      ref={ref => { slider.current = ref }}
                      dots={false}
                    >
                      {cards.map((item, key) => (
                        <div key={key}>
                          <div className="m-t-10 m-r-12 m-b-12 m-l-10">
                            <Card hoverable className="b-g center-align-text hover-no-border" {...item.cardProps} bodyStyle={{ padding: '1.25vw' }}>
                              <Space direction="vertical">
                                {item.image}
                                <Typography.Text className="muli semi-bold fs-18px">{item.text}</Typography.Text>
                              </Space>
                            </Card>
                          </div>
                        </div>
                      ))}
                    </Carousel>
                  </Col>
                </Row>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default DashboardCarousel;
