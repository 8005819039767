/* eslint-disable react-hooks/exhaustive-deps */
import { AiFillRightCircle, AiOutlineLeftCircle } from "react-icons/ai";
import { Card, Carousel, Col, Row, Space, Spin, Typography } from "antd";
import { useEffect, useRef } from "react";
import { useAlertsState } from "App/Components/AlertsCarousel/store";
import PaymentSent from "App/Components/AlertsCarousel/Components/PaymentSent";
import WalletFunded from "App/Components/AlertsCarousel/Components/WalletFunded";
import BalancesConverted from "App/Components/AlertsCarousel/Components/BalancesConverted";
import CardFunded from "App/Components/AlertsCarousel/Components/CardFunded";
import FundsWithdrawn from "App/Components/AlertsCarousel/Components/FundsWithdrawn";
import UserAdded from "App/Components/AlertsCarousel/Components/UserAdded";
import AccountingSystem from "App/Components/AlertsCarousel/Components/AccountingSystem";
import { Link } from "react-router-dom";
import { useAlertsQuery } from "App/Components/AlertsCarousel/Hooks/useAlertsQuery";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

function AlertsCarousel() {
    const slider = useRef();
    const setState = useAlertsState(state => state.setState);
    const alerts = useAlertsState(state => state.alerts);
    const isDeleting = useAlertsState(state => state.isDeleting);
    const { data, isFetching, refetch } = useAlertsQuery();
    const apiRequest = useApiRequest();

    useEffect(() => {
        setState({ alerts: data });
    }, [data]);

    const handleOnClear = async (id) => {
        setState({ isDeleting: true });
        await apiRequest(APIROUTES.del.notifications, 'POST', { ids: [id] });
        setState({ isDeleting: false });
        refetch();
    }

    const handleClearAll = async () => {
        setState({ isDeleting: true });
        await apiRequest(APIROUTES.del.notifications, 'POST', { ids: alerts?.map(item => item?.id) });
        setState({ isDeleting: false });
        refetch();
    }

    return (
        <Spin spinning={isFetching || isDeleting}>
            <Row>
                <Col span={24}>
                    <Card className="bg-gradient big-rounded">
                        <Row>
                            <Col span={24}>
                                <Typography.Text level={4} className='dark-green medium fs-25px'>
                                    Recent Activity - <Typography.Text type='danger'>{alerts?.length > 100 ? '100+' : (alerts?.length || 0)}</Typography.Text>
                                </Typography.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={21}>
                                {alerts?.length > 0 &&
                                    <Link className='medium fs-18px' to='' onClick={handleClearAll}>
                                        Mark all as read
                                    </Link>
                                }
                            </Col>
                            <Col span={3}>
                                {alerts?.length > 1 && (
                                    <Space size='middle' className='dark-green'>
                                        <Link onClick={() => slider.current.prev()}>
                                            <AiOutlineLeftCircle size={18} />
                                        </Link>
                                        <Link onClick={() => slider.current.next()}>
                                            <AiFillRightCircle size={18} />
                                        </Link>
                                    </Space>
                                )}
                            </Col>
                        </Row>
                        <Row className="m-t-5">
                            <Col span={24}>
                                {alerts?.length > 0 ? (
                                    <Carousel
                                        dots={false}
                                        ref={ref => { slider.current = ref }}>
                                        {
                                            alerts.map((item, key) => (
                                                <div key={key}>
                                                    {item?.platform_alert_format_id === 9 && <WalletFunded {...item} onClear={handleOnClear} />}
                                                    {item?.platform_alert_format_id === 10 && <BalancesConverted {...item} onClear={handleOnClear} />}
                                                    {item?.platform_alert_format_id === 11 && <CardFunded {...item} onClear={handleOnClear} />}
                                                    {item?.platform_alert_format_id === 12 && <FundsWithdrawn {...item} onClear={handleOnClear} />}
                                                    {item?.platform_alert_format_id === 13 && <PaymentSent {...item} onClear={handleOnClear} />}
                                                    {item?.platform_alert_format_id === 14 && <UserAdded {...item} onClear={handleOnClear} />}
                                                    {item?.platform_alert_format_id === 15 && <AccountingSystem {...item} onClear={handleOnClear} />}
                                                </div>
                                            ))
                                        }
                                    </Carousel>
                                ) :
                                    <Card>
                                        <Typography.Text className="muli semi-bold fs-18px">No Recent Activities</Typography.Text>
                                    </Card>
                                }
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Spin>
    );
}

export default AlertsCarousel;
