import { Button, Card, Col, Row, Space, Typography } from 'antd';
import CustomTable from 'App/Components/CustomTable';
import PageDescription from 'App/Components/PageDescription';
import { useNavigate } from 'react-router-dom';
import URLS from 'Routes/constants';
import { pageContent } from './store/data';
import { useGetPaymentCutOff } from './query/useGetPaymentCutOff';
import { useMemo } from 'react';

function BankToBankDeliveryTimescales() {
    const navigate = useNavigate();
    const paymentCutoffPage = useGetPaymentCutOff();
    const htmlString = paymentCutoffPage?.data?.data?.content?.rendered;
    const replacePTags = (str) => {
        // Replace all opening <p> tags with an empty string, Replace all closing </p> tags with a newline character
        return str.replace(/<p>/g, '').replace(/<\/p>/g, '\n');;
    };
    const rowsData = useMemo(() => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;

        const sections = tempElement.querySelectorAll(".elementor-section");
        const data = [];

        // Loop through each section in the sections array
        sections.forEach(section => {
        // Initialize an empty object to store the row data
            const rowData = {};

        // Select the ul element within the current section
            const ul = section.querySelector("ul");
        // Select all p elements within the current section
            const pTags = section.querySelectorAll("section .elementor-widget-container");

        // Check if the ul element exists and has no classes
            if (ul && ul.classList.length === 0) {
            // Select the first anchor element within the ul
                const linkElement = ul.querySelector('a');
            // Get the href attribute of the anchor element, or an empty string if it doesn't exist
                const link = linkElement?.getAttribute('href') ?? '';
            // Get the trimmed text content of the anchor element, or an empty string if it doesn't exist
                const text = linkElement?.textContent?.trim() ?? '';
            // Store the link and text as a currency object in rowData
                rowData.currency = {link, text};
            }

    // Check if rowData has a currency property with a non-empty text
        if (rowData.hasOwnProperty('currency') && rowData['currency'].text) {
            // Check if there are any p elements in pTags
            if (pTags.length) {
                // Extract and trim the text content of the first four p elements, if they exist
                const createVolopa = pTags[1]?.textContent?.trim() ?? '';
                const priority = pTags[2]?.textContent?.trim() ?? '';
                const regular = pTags[3]?.textContent?.trim() ?? '';
                const maximumPaymentAmounts = replacePTags(pTags[4]?.innerHTML?.trim() ?? '');
                // Store the extracted values in rowData with appropriate keys
                rowData.createVolopaBookingBy = createVolopa;
                rowData.priority = priority;
                rowData.regular = regular;
                rowData.maximumPaymentAmounts = maximumPaymentAmounts;
            }

            // Check if rowData has any keys (is not empty)
            if (Object.keys(rowData).length > 0) {
                // Push the rowData object to the data array
                data.push(rowData);
            }            
          }
        });
        return data;
    }, [htmlString]);
    
    const columns = [
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
            render: text => <Space size={4}>
                <Typography.Link style={{ color: 'inherit', textDecoration: 'underline'}} href={text?.link}>{text?.text}</Typography.Link>
            </Space>
        },
        {
            title: 'Create Volopa Booking By',
            dataIndex: 'createVolopaBookingBy',
            key: 'createVolopaBookingBy'
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority'
        },
        {
            title: 'Regular',
            dataIndex: 'regular',
            key: 'regular'
        },
        {
            title: 'Maximum Payment Amounts',
            dataIndex: 'maximumPaymentAmounts',
            key: 'maximumPaymentAmounts'
        }
    ]
    return (
        <>
            <Row>
                <Col span={24}>
                    <PageDescription title='Bank to Bank Delivery Timescales' />
                </Col>
            </Row>
            <Row className='m-t-10' justify='end'>
                <Col>
                    <Button type='primary' onClick={() => navigate(URLS.UserGuides)}>Return to User Guides</Button>
                </Col>
            </Row>
            <Row className='m-t-20' gutter={[12, 12]}>
                {pageContent && pageContent.length > 0 && pageContent.map((item, key) => (
                    <Col span={24} key={key}>
                        <PageDescription
                            title={item.title}
                            text={item.text}
                            defaultStyle={false}
                        />
                    </Col>
                ))}
            </Row>
            <Row className='m-t-20'>
                <Col span={12}>
                    <Card hoverable className='b-g'>
                        <Row justify='end'>
                            <Col span={15}>
                                <Typography.Text className='dark-green muli semi-bold fs-18px'>Number of Days after Settlement</Typography.Text>
                            </Col>
                        </Row>
                        <CustomTable
                            columns={columns}
                            dataSource={rowsData}
                            headerColor='green'
                            headerFont='muli'
                            loading={paymentCutoffPage?.isLoading}
                            rowType='filled' />
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default BankToBankDeliveryTimescales;
