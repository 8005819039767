import create from "zustand";

const useTransactionsBankFeedsState = create((set, get) => ({
    loading: false,
    page: 1,
    totalPages: 1,
    limit: 10,
    selectedToSync: [],
    syncStatus: '1',
    toSync: [],
    isSyncing: false,
    syncStatuses: [
        {
            id: 1,
            name: 'View Synced Transactions',
            val: '1',
        },
        {
            id: 235,
            val: '2,3,5',
            name: 'View Unsynced Transactions',
        },
    ],
    setState: (data) => set(() => data),
}));

export { useTransactionsBankFeedsState };