import { Pagination, Spin } from "antd";

const placeholderStyles = {
    marginTop: '30px',
    marginBottom: '20px',
    padding: '8px 18px',
    background: 'rgb(238, 238, 238)',
    borderRadius: '8px',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
}

const paginationStyles = {
    marginTop: '30px',
    marginBottom: '20px',
    padding: '8px 18px',
    background: '#eee',
    borderRadius: '8px'
};

function CustomPagination({
    loading = false,
    total = 1,
    pageSize = 10,
    current = 1,
    singlePageMessage = '',
    onPageChange = () => {},
    onPageSizeChange = () => {},
	pageSizeOptions = [10, 20, 50, 100],
}) {
    return <>
        {loading
            ? <div style={placeholderStyles}>
                <Spin size="small"/>
            </div>
            : <Pagination
                style={paginationStyles}
                onChange={(page, pageSize) => onPageChange(page, pageSize)}
                onShowSizeChange={(current, size) => onPageSizeChange(current, size)}
                pageSize={pageSize}
                total={total}
                current={current}
                showSizeChanger
				pageSizeOptions={pageSizeOptions}
            />
        }
    </>
}

export default CustomPagination;
