/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Typography, Card, Space, Image, Spin, Grid, Button } from 'antd'
import { useWalletBreakdownState } from 'App/Components/WalletBreakdown/store';
import { FLAGS } from 'Constants/Images';
import { useEffect } from 'react';
import { accountingFormat } from "Utils";
const { useBreakpoint } = Grid;

function WalletBreakdown({ balances = [], loading = false }) {
    const itemSize = useWalletBreakdownState(state => state.itemSize);
    const colSize = useWalletBreakdownState(state => state.colSize);
    const allVisible = useWalletBreakdownState(state => state.allVisible);
    const fullWidth = useWalletBreakdownState(state => state.fullWidth);
    const allToggle = useWalletBreakdownState(state => state.allToggle);
    const allZero = useWalletBreakdownState(state => state.allZero);
    const balanceCount = useWalletBreakdownState(s => s.balanceCount);
    const setState = useWalletBreakdownState(state => state.setState);
    const breakpoint = useBreakpoint();

    useEffect(() => {
        let allToggle = false;
        let balanceCount = 0;
        balances?.forEach((item) => {
            if (+item?.cleared === 0) allToggle = true;
            if (+item.cleared !== 0) balanceCount++;
        })
        setState({
            allToggle,
            fullWidth: balanceCount > 6,
            allZero: balanceCount === 0,
            balanceCount
        });
    }, [balances]);

    useEffect(() => {
        if (breakpoint.md === false) {
            setState({
                colSize: !allVisible && !fullWidth ? (balanceCount > 1 || allZero ? 24 : 12) : 24,
                itemSize: !allVisible && !fullWidth ? (balanceCount > 1 || allZero ? 12 : 24) : 12
            });
        } else {
            let col = !allVisible && !fullWidth ? (balanceCount > 1 || allZero ? 16 : 8) : 24;
            let item = !allVisible && !fullWidth ? (balanceCount > 1 || allZero ? 12 : 24) : 8;
            setState({
                colSize: col,
                itemSize: item
            });
        }
    })

    const showAll = () => {
        let col = allVisible ? (breakpoint.md === false ? 24 : (balanceCount > 1 || allZero ? 16 : 8)) : 24;
        let item = allVisible ? 12 : (breakpoint.md === false ? (balanceCount > 1 || allZero ? 12 : 24) : 8);
        setState({
            allVisible: !allVisible,
            colSize: col,
            itemSize: item
        });
    };
    return (
        <Row>
            <Col span={24}>
                <Typography.Text className='dark-green medium fs-25px'>Wallet Breakdown</Typography.Text>
                <Row>
                    <Col span={colSize}>
                        <Spin spinning={loading}>
                            {allToggle &&
                                <Row justify='end'>
                                    <Col>
                                        <Button type='link' className='medium fs-18px' onClick={showAll}>Show {allVisible ? 'Less' : 'All'}</Button>
                                    </Col>
                                </Row>
                            }
                            <Card className='bg-gradient big-rounded' bodyStyle={{ padding: '12px' }}>
                                <Row gutter={[16, 8]}>
                                    {balances && balances.map((item, key) => (
                                        ((!allVisible && (+item.cleared !== 0 || (allZero && key < 6))) || allVisible) && (
                                            <Col span={itemSize} key={key}>
                                                <Card className='b-g ant-card-body-p-5 small-rounded'>
                                                    <Row align='middle' justify='space-between' gutter={16}>
                                                        <Col>
                                                            <Space>
                                                                {FLAGS[item?.currency] && <Image src={FLAGS[item?.currency]} preview={false} width='36px' />}
                                                                <Typography.Text className='dark-green medium fs-18px'>{item?.currency}</Typography.Text>
                                                            </Space>
                                                        </Col>
                                                        <Col className='right-align-text' flex='auto'>
                                                            <Typography.Text className='dark-green medium fs-16px'>{accountingFormat(item?.available_amount)}</Typography.Text>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        )
                                    ))}
                                </Row>
                            </Card>
                        </Spin>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default WalletBreakdown;