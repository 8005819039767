import { FLAGS } from 'Constants/Images'
import { Button, Card, Col, Image, Modal, Row, Space, Typography } from 'antd'
import React from 'react'
import { useFundWithdrawConfirmModalState } from './store'
import { useNavigate } from 'react-router-dom'
import { accountingFormat } from "Utils";

const FundWithdrawConfirmModal = () => {
    const showModal = useFundWithdrawConfirmModalState(state => state.showModal)
    const onConfirm =  useFundWithdrawConfirmModalState(state => state.onConfirm)
    const type =  useFundWithdrawConfirmModalState(state => state.type)
    const title = useFundWithdrawConfirmModalState(state => state.title)
    const message = useFundWithdrawConfirmModalState(state => state.message)
    const description = useFundWithdrawConfirmModalState(state => state.description)
    const currency = useFundWithdrawConfirmModalState(state => state.currency)
    const amount = useFundWithdrawConfirmModalState(state => state.amount)
    const moreBtnTxt = useFundWithdrawConfirmModalState(state => state.moreBtnTxt)
    const setState = useFundWithdrawConfirmModalState(state => state.setState) 
    const navigate = useNavigate()

    /**
     * The function sets the state of showModal to false when onCancel is called.
     */
    const onCancel = () => {
        setState({
            showModal: false
        })
    }
    /**
     * The function `handleConfirm` calls `onConfirm` functions.
     */
    const handleConfirm = () => {
        onCancel()
        onConfirm()
    }
    /**
     * The function navigates to the dashboard page.
     */
    const gotoDashboard = () => {
        onCancel()
        navigate('/cards/dashboard')
    }
  return (
    <>
        <Modal
            open={showModal}
            closable={false}
            footer={null}
            bodyStyle={{ padding: '0px' }}
            getContainer={false}
            destroyOnClose>
                <Card className="full-percent-width rounded">
                    <Row>
                        <Col span={24}>
                            <Typography.Text type={type === 'success' ? "success": ""} className="extra-bold dark-grey medium fs-20px center-align-text">{title}</Typography.Text>
                        </Col>
                    </Row>
                    <Row className="m-t-30">
                        <Col span={24}>
                            <Typography.Paragraph className="regular fs-15px">{message}</Typography.Paragraph>
                        </Col>
                    </Row>
                    <Row className="m-t-30">
                        <Col span={24}>
                        <Typography.Text className="medium fs-20px bold">Amount and Description</Typography.Text>
                        </Col>
                        <Col span={11} className="m-t-5 p-l-10 p-t-5 p-b-10 b-g small-rounded line-height-18px">
                                 <span>
                                    <Image className="m-b-5" src={FLAGS[currency]} preview={false} width={30} />
                                    <Typography.Text className="muli light fs-15px bold dark-green m-l-5"> {accountingFormat(amount)} </Typography.Text>
                                 </span>
                        </Col>
                        <Col span={11} offset={2} className="m-t-5 p-l-10 p-t-10 p-b-10 b-g small-rounded line-height-18px" >
                                     <Typography.Text className="muli light fs-15px bold dark-green m-l-5 p-t-5"> {description} </Typography.Text>
                        </Col>
                    </Row>
                    <Row className="m-t-35">
                        <Col span={24} className='center-align-text'>
                            <Space size={20}>
                                {type !== 'success' ? (
                                <>
                                    <Button type="primary" onClick={onCancel}>Cancel</Button>
                                    <Button type="primary" onClick={handleConfirm}>Confirm</Button>
                                </>) :
                                (<>
                                    <Button type="primary" onClick={onCancel}>{moreBtnTxt}</Button>
                                    <Button type="primary" onClick={gotoDashboard}>Dashboard</Button>
                                </>)
                                }
                            </Space>
                        </Col>
                    </Row>
                </Card>
            </Modal>
    </>
  )
}

export default FundWithdrawConfirmModal