import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useCardsState } from "App/Pages/Cards/Cards/store";

const useActivePrepaidCardsQuery = (enabled = true) => {
    const apiRequest = useApiRequest();
    const currentPage = useCardsState(state => state.currentPage);
	const limit = useCardsState(state => state.limit);
	const cardSearchFilter = useCardsState(state => state.cardSearchFilter);
    const setCardsState = useCardsState(state => state.setState);

    const fetchQuery = async () => {
        if(cardSearchFilter) {
            const data = await apiRequest(`${APIROUTES.get.prepaidcard_active}?$with=All&$skip=${(currentPage - 1) * limit}&$limit=${limit}&searchCustom=${cardSearchFilter}`);
            return data;
        }else {
            const data = await apiRequest(`${APIROUTES.get.prepaidcard_active}?$with=All&$skip=${(currentPage - 1) * limit}&$limit=${limit}`);
            return data;
        }
    }

    const query = useQuery({
        queryKey: ['_prepaid_active_cards', currentPage, limit, cardSearchFilter],
        queryFn: fetchQuery,
        enabled: enabled,
        refetchOnMount: true,
        cacheTime: 0,
        staleTime: 0,
        onSuccess: (resData) => {
            setCardsState({
                prepaidCardsQueryData: resData
            });
        },
    });

    return query;
}

export { useActivePrepaidCardsQuery };
