import create from "zustand";

const useRefundDetailsState = create(set => ({
    modalOpen: false,
    currentPage: 1,
    limit: 10,
    isRemoving: false,
    setState: obj => set(obj)
}));

export { useRefundDetailsState };