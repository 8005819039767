import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useWalletDashboardState } from "App/Pages/Wallet/WalletDashboard/store";

const useCompanyWalletBalancesQuery = () => {
    const apiRequest = useApiRequest();
    const setState = useWalletDashboardState(state => state.setState);

    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
        const { data } = await apiRequest(APIROUTES.get.company_wallet_balances);
        return data;
    }

    // eslint-disable-next-line no-unused-vars
    const _fetchQuery = async () => {
        return new Promise(resolve => {
            setTimeout(resolve([]), 1200);
        });
    }

    const query = useQuery({
        queryKey: ['_company_wallet_balances'],
        queryFn: fetchQuery,
        refetchInterval: 1000 * 60 * 10,
        refetchOnWindowFocus: true,
        onSuccess: (resData) => {
            setState({
                cwSummaryCurrency: resData.currency,
                cwSummaryTotalCompanyBalance: resData.total_company_balance,
                cwSummaryWalletBalance: resData.wallet_balance,
                cwSummaryCardsBalance: resData.cards_balance
            });
        },
    });

    return query;
}

export { useCompanyWalletBalancesQuery };