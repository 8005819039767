import create from "zustand";

const usePaymentHistoryState = create(set => ({
    paymentData: undefined,
    paymentsLoading: false,
    limit: 20,
    page: 1,
    totalPages: 1,
    setState: obj => set(obj)
}));

export { usePaymentHistoryState };