import { useMutation } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useCardWithdrawRequestMutation = ({prepaidCardId, toPrepaidCardId, transferCurrency, transferAmount, description}) => {
    const apiRequest = useApiRequest();

    const mutation = useMutation({
        mutationFn: ({prepaidCardId, transferCurrency, toPrepaidCardId, transferAmount, description}) => {
            return apiRequest(`${APIROUTES.post.card_transfer_request}`, 'POST', {
                prepaidCardId,
                toPrepaidCardId,
                transferCurrency,
                transferAmount: Number(transferAmount), // unclear of data type so type safe it to number (int or float)
                description,
            });
        },
        mutationKey: ['_card_transfer_request', prepaidCardId, transferCurrency, transferAmount, toPrepaidCardId],
        onSuccess: (data) => {},
        onError: (error) => {},
    });

    return mutation;
}

export { useCardWithdrawRequestMutation };