import create from "zustand";

const useTransactionsHistoryState = create((set, get) => ({
    currentPage: 1,
    limit: 10,
    totalPages: 0,
    searchQuery: '',
    currencyId: null,
    withAttachments: null,
    transactionCategoryId: null,
    transactionTypeId: null,
    prepaidCardId: null,
    searchFilter: null,

    //
    setState: (data) => set(data),
}));

export { useTransactionsHistoryState }
