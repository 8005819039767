import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import {  notification } from "antd";
import { useTrackingCodesState } from "../store";

const useDeleteTrackingCodeMutation = () => {
    const apiRequest = useApiRequest();
    const queryClient = useQueryClient();
    const setState = useTrackingCodesState(state => state.setState);

    const mutation = useMutation({
        mutationFn: (payload) => {
            return apiRequest(`${APIROUTES.del.tracking_code_delete}/${payload.uuid}`, 'DELETE', { $password: payload.password});
        },
        mutationKey: ['_delete_tracking_code_mutation'],
        onMutate: () => {},
        onSuccess: (data) => {
            if(data?.data?.deleted) {
                notification.success({ message: 'Tracking Code Deleted Successfully!', placement:'top'})
                queryClient.invalidateQueries({ queryKey: ['_get_tracking_code_expense_tag'] });
                setState({ deleteConfirmation : false, selectedRow: {}})
            } else {
                notification.error({ message: data?.response?.data?.error?.message, placement:'top'})
            }
        },
    });

    return mutation;
}

export { useDeleteTrackingCodeMutation };