import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useManageSpecificUserState } from "App/Pages/Settings/CompanyAccount/ManageUsers/ManageSpecificUser/store";

const useSpecificUserQuery = (userId) => {
  const apiRequest = useApiRequest();
  const setState = useManageSpecificUserState(state => state.setState);

  const fetchQuery = async () => {
    const { data } = await apiRequest(`${APIROUTES.get.edit_users_manage}${userId}&$with=user.role,country,user.userStatus`);
    return data;
  }

  const query = useQuery({
    queryKey: ['_edit_users_manage', userId],
    queryFn: fetchQuery,
    onSuccess: (res) => {
      setState({ role: res[0]?.user?.role?.id, email: res[0]?.user?.email, telephoneMobile: res[0]?.telephone_mobile })
      return res[0]
    },
    refetchOnMount: 'always',
    enabled: !!userId
  });

  return query;
}

export { useSpecificUserQuery };