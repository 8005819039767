/* eslint-disable react-hooks/exhaustive-deps */
import {
  Row,
  Col,
  Typography,
  Card,
  Form,
  Select,
  Space,
  Progress,
  Button,
  Spin,
  InputNumber,
  Image,
} from "antd";
import { useRateCheckerState } from "App/Components/RateChecker/store";
import { useApiRequest } from "Hooks/API";
import { useSelectSearch } from "Hooks/Search";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { accountingFormat, numberFormat } from "Utils";

function RateChecker(props) {
  const clear = props.clear;
  const currencies = props.currencies;
  const redirectUrl = props.url;
  const onFinish = props.onFinish;
  const fromCurrencies = useRateCheckerState((state) => state.fromCurrencies);
  const toCurrencies = useRateCheckerState((state) => state.toCurrencies);
  const navigate = useNavigate();
  const commonFromCurrencies = useRateCheckerState(
    (state) => state.commonFromCurrencies
  );
  const commonToCurrencies = useRateCheckerState(
    (state) => state.commonToCurrencies
  );
  const rate = useRateCheckerState((state) => state.rate);
  const loadingRate = useRateCheckerState((state) => state.loadingRate);
  const loadingConvert = useRateCheckerState((state) => state.loadingConvert);
  const seconds = useRateCheckerState((state) => state.seconds);
  const selectedFromCurrency = useRateCheckerState(
    (state) => state.selectedFromCurrency
  );
  const selectedToCurrency = useRateCheckerState(
    (state) => state.selectedToCurrency
  );
  const toValue = useRateCheckerState((state) => state.toValue);
  const fromValue = useRateCheckerState((state) => state.fromValue);
  const setState = useRateCheckerState((state) => state.setState);
  const side = useRateCheckerState((state) => state.side);
  const apiRequest = useApiRequest();
  const filterSelect = useSelectSearch();

  const [form] = Form.useForm();
  let timeout = useRef();

  useEffect(() => {
    if (props.loading) {
      form.setFieldValue('convertToValue', accountingFormat(form.getFieldValue('convertToValue')))
      form.setFieldValue('convertFromValue', accountingFormat(form.getFieldValue('convertFromValue')))
    }
  }, [props.loading]);

  useEffect(() => {
    if (clear) {
      setState({
        selectedFromCurrency: undefined,
        selectedToCurrency: undefined,
        toValue: undefined,
        fromValue: undefined
      });
      form.resetFields();
    } else {
      form.setFieldsValue({ convertToValue: toValue });
      form.setFieldsValue({ convertFromValue: fromValue });
      form.setFieldsValue({ convertToCurrency: selectedToCurrency });
      form.setFieldsValue({ convertFromCurrency: selectedFromCurrency });
      if (
        selectedToCurrency === undefined &&
        selectedFromCurrency === undefined
      ) {
        setState({ rate: undefined });
        form.resetFields();
      }
    }
    return () => {
      clearTimeout(timeout?.current);
    }
  }, []);

  useEffect(() => {
    if (fromCurrencies === undefined && toCurrencies === undefined) {
      let commonFrom = undefined;
      let commonTo = undefined;
      let otherFrom = undefined;
      let otherTo = undefined;
      if (currencies) {
        commonFrom = currencies
          .filter((data) => data.sort_order_sell !== 999)
          .sort((a, b) => a.sort_order_sell - b.sort_order_sell);
        otherFrom = currencies.filter((data) => data.sort_order_sell === 999);
        commonTo = currencies
          .filter((data) => data.sort_order_buy !== 999)
          .sort((a, b) => a.sort_order_buy - b.sort_order_buy);
        otherTo = currencies.filter((data) => data.sort_order_buy === 999);
        setState({
          fromCurrencies: otherFrom,
          toCurrencies: otherTo,
          commonFromCurrencies: commonFrom,
          commonToCurrencies: commonTo,
        });
      }
    }
  }, [currencies]);

  useEffect(() => {
    if (seconds > 0) {
      clearTimeout(timeout?.current);
      timeout.current = setTimeout(() => setState({ seconds: seconds - 1 }), 1000);
    }
    if (!props.url && seconds === 0) {
      getExchangeRate();
    }
  }, [seconds]);

  useEffect(() => {
    if (side) {
      let fromTo = "from";
      fromTo = side === "buy" ? "to" : "from";
      calculateRate(fromTo);
    } else {
      calculateRate("from");
    }
  }, [rate]);

  const onToCurrencyChange = (value) => {
    let cur = currencies
      .filter((data) => data.sort_order_sell === 999)
      .filter((val) => val.currency !== value);
    let commonCur = currencies
      .filter((data) => data.sort_order_sell !== 999)
      .sort((a, b) => a.sort_order_sell - b.sort_order_sell)
      .filter((val) => val.currency !== value);
    setState({
      rate: undefined,
      fromCurrencies: cur,
      commonFromCurrencies: commonCur,
      selectedToCurrency: form.getFieldValue("convertToCurrency"),
    });
    if (
      form.getFieldValue("convertFromCurrency") &&
      form.getFieldValue("convertToCurrency")
    ) {
      getExchangeRate();
    }
  };

  const onFromCurrencyChange = (value) => {
    let cur = currencies
      .filter((data) => data.sort_order_buy === 999)
      .filter((val) => val.currency !== value);
    let commonCur = currencies
      .filter((data) => data.sort_order_buy !== 999)
      .sort((a, b) => a.sort_order_buy - b.sort_order_buy)
      .filter((val) => val.currency !== value);
    setState({
      rate: undefined,
      toCurrencies: cur,
      commonToCurrencies: commonCur,
      selectedFromCurrency: form.getFieldValue("convertFromCurrency"),
    });
    if (
      form.getFieldValue("convertToCurrency") &&
      form.getFieldValue("convertFromCurrency")
    ) {
      getExchangeRate();
    }
  };

  const getExchangeRate = async () => {
    const from = form.getFieldValue("convertFromCurrency");
    const to = form.getFieldValue("convertToCurrency");
    clearTimeout(timeout?.current);
    setState({
      loadingRate: true,
      rate: undefined,
      seconds: 0,
    });
    const res = await apiRequest("fxrate/conversion/current?currencyPair=" + from + to);
    if (res && res.data && res.data.length > 0) {
      setState({
        rate: res.data[0].rate,
        rateUid: res.data[0].uid,
        seconds: props.timeOut ? props.timeOut : 30,
        loadingRate: false,
      });
    }
  };

  const calculateRate = (type) => {
    if (
      form.getFieldValue("convertToCurrency") &&
      form.getFieldValue("convertFromCurrency") &&
      !loadingRate &&
      seconds > 0 &&
      rate !== undefined
    ) {
      const from = form.getFieldValue("convertFromValue");
      const to = form.getFieldValue("convertToValue");
      if (type === "from") {
        form.setFieldsValue({
          convertToValue: from !== "" ? (from * rate) && accountingFormat(from * rate) : 0,
        });
        setState({
          side: "sell",
        });
      } else {
        form.setFieldsValue({
          convertFromValue: to !== "" ? (to / rate) && accountingFormat(to / rate) : 0,
        });
        setState({
          side: "buy",
        });
      }
      setState({
        fromValue: form.getFieldValue("convertFromValue"),
        toValue: form.getFieldValue("convertToValue"),
      });
    }
  };

  const handleOnFinish = () => {
    clearTimeout(timeout?.current);
    redirectUrl ? navigate(redirectUrl) : onFinish(form.resetFields);
  };

  return (
    <Spin size="large" spinning={props.loading ? true : false}>
      <Row>
        <Col span={24}>
          <Card>
            <Card.Grid className="full-percent-width rounded b-g hover-no-border">
              <Form layout="vertical" form={form} onFinish={handleOnFinish}>
                <Typography.Text className="muli semi-bold fs-18px dark-green">
                  Convert To
                </Typography.Text>
                <Row gutter={8} className="m-t-5">
                  <Col flex='90px'>
                    <Form.Item name="convertToCurrency">
                      <Select
                        className="dark-green"
                        onChange={onToCurrencyChange}
                        showSearch
                        allowClear
                        filterOption={filterSelect}
                      >
                        {commonToCurrencies && commonToCurrencies.length > 0 && (
                          <Select.OptGroup label="Common">
                            {commonToCurrencies.map((val, key) => (
                              <Select.Option value={val.currency} key={key} className='p-l-10'>
                                <Space size={4} align="center">
                                  {val.flag && (
                                    <Image
                                      src={val.flag}
                                      preview={false}
                                      width={20}
                                      alt={val.currency}
                                    />
                                  )}
                                  {val.currency}
                                </Space>
                              </Select.Option>
                            ))}
                          </Select.OptGroup>
                        )}
                        {toCurrencies && toCurrencies.length > 0 && (
                          <Select.OptGroup label="Other">
                            {toCurrencies.map((val, key) => (
                              <Select.Option
                                key={
                                  key +
                                  (commonToCurrencies
                                    ? commonToCurrencies.length
                                    : 0)
                                }
                                value={val.currency}
                                className='p-l-10'
                              >
                                <Space size={4} align="center">
                                  {val.flag && (
                                    <Image
                                      src={val.flag}
                                      preview={false}
                                      width={20}
                                      alt={val.currency}
                                    />
                                  )}
                                  {val.currency}
                                </Space>
                              </Select.Option>
                            ))}
                          </Select.OptGroup>
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col flex='auto'>
                    <Form.Item name="convertToValue">
                      <InputNumber
                        className="full-percent-width"
                        placeholder="Enter Amount"
                        onChange={() => calculateRate("to")}
                        controls={false}
                        formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
                        parser={(val) => val ? numberFormat(val) : 0.00}
                        disabled={!form.getFieldValue('convertToCurrency') || !form.getFieldValue('convertFromCurrency')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Typography.Text className="muli semi-bold fs-18px dark-green">
                  Convert From
                </Typography.Text>
                <Row gutter={8} className="m-t-5">
                  <Col flex='90px'>
                    <Form.Item name="convertFromCurrency">
                      <Select
                        className="dark-green"
                        onChange={onFromCurrencyChange}
                        showSearch
                        allowClear
                        filterOption={filterSelect}
                      >
                        {commonFromCurrencies &&
                          commonFromCurrencies.length > 0 && (
                            <Select.OptGroup label="Common">
                              {commonFromCurrencies.map((val, key) => (
                                <Select.Option value={val.currency} key={key} className='p-l-10'>
                                  <Space size={4} align="center">
                                    {val.flag && (
                                      <Image
                                        src={val.flag}
                                        preview={false}
                                        width={20}
                                        alt={val.currency}
                                      />
                                    )}
                                    {val.currency}
                                  </Space>
                                </Select.Option>
                              ))}
                            </Select.OptGroup>
                          )}
                        {fromCurrencies && fromCurrencies.length > 0 && (
                          <Select.OptGroup label="Other">
                            {fromCurrencies.map((val, key) => (
                              <Select.Option
                                key={
                                  key +
                                  (commonFromCurrencies
                                    ? commonFromCurrencies.length
                                    : 0)
                                }
                                value={val.currency}
                                className='p-l-10'
                              >
                                <Space size={4} align="center">
                                  {val.flag && (
                                    <Image
                                      src={val.flag}
                                      preview={false}
                                      width={20}
                                      alt={val.currency}
                                    />
                                  )}
                                  {val.currency}
                                </Space>
                              </Select.Option>
                            ))}
                          </Select.OptGroup>
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col flex='auto'>
                    <Form.Item name="convertFromValue">
                      <InputNumber
                        className="full-percent-width"
                        placeholder="Enter Amount"
                        onChange={() => calculateRate("from")}
                        controls={false}
                        formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
                        parser={(val) => val ? numberFormat(val) : 0.00}
                        disabled={!form.getFieldValue('convertToCurrency') || !form.getFieldValue('convertFromCurrency')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="bottom">
                  <Col xs={24} sm={24} lg={12} span={12}>
                    <Spin spinning={loadingRate} />
                    {rate !== undefined && (
                      <Space>
                        <Progress
                          type="circle"
                          percent={(seconds / (+props?.timeOut + 0.01)) * 100}
                          width={40}
                          format={() => `${seconds}s`}
                        />
                        <Space direction="vertical" size={0}>
                          {seconds > 0 ? (
                            <>
                              <Typography.Text className="muli semi-bold light-green">
                                FX Rate
                              </Typography.Text>
                              <Typography.Text className="muli semi-bold light-green">
                                1 {form.getFieldValue("convertFromCurrency")} ={" "}
                                {rate} {form.getFieldValue("convertToCurrency")}
                              </Typography.Text>
                            </>
                          ) : (
                            <>
                              <Typography.Text
                                className="muli semi-bold light-green pointer"
                                onClick={getExchangeRate}
                              >
                                Refresh FX Rate
                              </Typography.Text>
                            </>
                          )}
                        </Space>
                      </Space>
                    )}
                  </Col>
                  <Col xs={24} sm={24} lg={12} span={12} className="right-align-text">
                    <Button type="primary" htmlType="submit" loading={loadingConvert} disabled={form.getFieldValue('convertFromValue') === undefined || form.getFieldValue('convertToValue') === undefined || form.getFieldValue('convertFromValue') === 0 || form.getFieldValue('convertToValue') === 0}>
                      Convert
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Grid>
          </Card>
        </Col>
      </Row>
    </Spin>
  );
}

export default RateChecker;
