import { useMutation } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

// txId is specific transaction_id
const useCardsDailyLimitsBulkUpsertMutation = () => {
    const apiRequest = useApiRequest();

    const mutation = useMutation({
        mutationFn: (payload) => {
            return apiRequest(`${APIROUTES.post.shared_balance_card_limits_bulk}`, 'POST', payload);
        },
        mutationKey: ['_shared_balance_card_limits_bulk'],
        onMutate: () => {},
        onSuccess: (data) => {},
        onError: (error) => {},
    });

    return mutation;
}

export { useCardsDailyLimitsBulkUpsertMutation };
