import { useMutation } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useTxVatMapMutation = ({txVat}) => {
    const apiRequest = useApiRequest();

    //
    const mutation = useMutation({
        mutationFn: ({txId, id, txVat}) => {
            const payload = {
                transaction_vat_id: txVat?.transaction_vat_id || null,
                other: txVat?.other || '', //txVat?.is_other ? (txVat?.other ?? '') : '',
                is_other: true //txVat?.is_other ?? false,
            }

            if(id) {
                payload.id = id;
            } else {
				payload.transaction_id = Number(txId);
			}

            return apiRequest(`${APIROUTES.post.transaction_vat_map_create_update}`, 'POST', payload);
        },
        mutationKey: ['_transaction_vat_map_create_update', txVat?.id],
        onMutate: () => {},
        onSuccess: (data) => {},
        onError: (error) => {},
    });

    return mutation;
}

export { useTxVatMapMutation };
