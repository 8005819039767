import { Card, Col, Divider, Image, Row, Space, Typography } from "antd";
import AlertsCarousel from "App/Components/AlertsCarousel";
import DashboardCarousel from "App/Components/DashboardCarousel";
import CustomTable from "App/Components/CustomTable";
import { FLAGS } from "Constants/Images";
import convertBalances from 'Assets/Images/carousel1.png';
import cards from 'Assets/Images/carousel2.png';
import withdraw from 'Assets/Images/carousel3.png';
import { useEffect } from 'react';
import URLS from "Routes/constants";
import { Link, useNavigate } from 'react-router-dom';
import { useTransactionsHistoryQuery } from 'App/Pages/Cards/TransactionHistory/query';
import moment from 'moment';
import CardsDashboardModal from 'App/Components/CardsDashboardModal';
import { accountingFormat } from "Utils";
import { useCardsDashboardState } from 'App/Pages/Cards/CardsDashboard/store';
import FrequentCardsCarousel from "App/Components/FrequentCardsCarousel";
import { FORMATS } from "Constants/Formats";

function CardsDashboard() {

  const modalVisible = useCardsDashboardState(state => state.modalVisible);
  const setState = useCardsDashboardState(state => state.setState);
  const modalTitle = useCardsDashboardState(state => state.modalTitle);
  const modalType = useCardsDashboardState(state => state.modalType);
  const { data: txHistory, isLoading: txHistoryLoading } = useTransactionsHistoryQuery({txnTypeIds: '5,6,12'});
//   const setTxnHistoryState = useTransactionsHistoryState(state => state.setState);
  const navigate = useNavigate()

  useEffect(() => {
    return () => {
		setState({ modalVisible: false, modalTitle: '' });
	};
    // eslint-disable-next-line
  }, []);

  //
  const txColumns = [
    {
      title: 'Date',
      dataIndex: 'transaction_time',
      key: 'transaction_time',
      align: 'center',
      render: (value) => moment(value).format(FORMATS.date)
    },
    {
      title: 'User',
      dataIndex: ['extended_prepaid_card', 'embossed_name'],
      key: 'extended_prepaid_card_embossed_name',
      align: 'center',
    },
    {
      title: 'Currency',
      dataIndex: ['currency', 'currency'],
      key: 'currency',
      align: 'center',
      render: (value) => <Space size={4}>
        {FLAGS[value] && <Image src={FLAGS[value]} alt={value} width={24} preview={false} />}
        {value}
      </Space>
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
      sorter: (a, b) => (a.amount && b.amount) && a.amount - b.amount,
      render: (value, record) => <Typography.Text strong type={record.debit_credit === 1 ? 'success' : 'danger'}>
        {record.debit_credit === 1 ? '+' : '-'}{accountingFormat(value)}
      </Typography.Text>
    },
    {
      title: 'Type',
      dataIndex: ['opt_transaction_type', 'option'],
      key: 'opt_transaction_type_option',
      align: 'center',
    },
  ];

  //
  const txData = txHistory?.data?.slice(0, 5) ?? [];

  //
  const toggleModal = (type) => {
    let modalTitle = '';

    if (type === 'convert') {
      modalTitle = {
        title: 'Convert Balance Card Selection',
        text: '- Choose a Card you wish to Convert Balances with'
      }
    } else if (type === 'fund') {
      modalTitle = {
        title: 'Fund Card Selection',
        text: '- Choose a Card you wish to Fund'
      }
    } else if (type === 'withdraw') {
      modalTitle = {
        title: 'Withdraw Card Selection',
        text: '- Choose a Card you wish to Withdraw from'
      }
    }
    setState({ modalVisible: !modalVisible, modalTitle, modalType: type });
  }

  //
  const dashboardCarouselCards = [
    {
      image: <Image src={convertBalances} preview={false} height={170} />,
      text: 'Convert Balances',
      cardProps: {
        onClick: () => toggleModal('convert')
      }
    },
    {
      image: <Image src={cards} preview={false} height={170} />,
      text: 'Fund Card',
      cardProps: {
        onClick: () => toggleModal('fund')
      }
    },
    {
      image: <Image src={withdraw} preview={false} height={170} />,
      text: 'Withdraw',
      cardProps: {
        onClick: () => toggleModal('withdraw')
      }
    }
  ];

  //
  return (
    <>
      {/* ============ Modal */}
      <CardsDashboardModal show={modalVisible} title={modalTitle} onToggle={toggleModal} type={modalType} />

      {/* ============ Card Action Buttons */}
      <Row>
        <Col xxl={16} xl={24} lg={24} md={24} sm={24} xs={24}>
          <DashboardCarousel cards={dashboardCarouselCards} />
        </Col>
        <Col xxl={1} xl={0} lg={0} ms={0} sm={0} xs={0}>
          <Divider type='vertical' style={{ height: '100%', margin: '0 50%' }} />
        </Col>
        <Col xxl={7} xl={9} lg={11} md={14} sm={18} xs={24}>
          <AlertsCarousel />
        </Col>
      </Row>

      {/* ============ Frequently Used Cards */}
      <Row className="m-t-30" gutter={58}>
        <Col xxl={16} xl={20} lg={24}>
          <Row>
            <Col span={24}>
              <Card className="bg-gradient big-rounded">
                <Row align="bottom" justify='space-between' gutter={10}>
                  <Col>
                    <Typography.Text className="fs-25px medium dark-green">Frequently Used Cards</Typography.Text>
                  </Col>
                  <Col>
                    <Link to={URLS.Cards} className="fs-18px medium">View All</Link>
                  </Col>
                </Row>
                <Row className="m-t-5">
                  <Col span={24}>
                    <FrequentCardsCarousel />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>

        <Col xxl={8} xl={12} lg={16} md={20} sm={24} xs={24}>
          <Row align="bottom" justify='space-between' className="m-t-30">
            <Col>
              <Typography.Text className="fs-25px medium dark-green">Card Transaction History</Typography.Text>
            </Col>
            <Col>
              <Link to={URLS.TransactionHistory} className="fs-18px medium">View All</Link>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <CustomTable
                loading={txHistoryLoading}
                columns={txColumns}
                dataSource={txData}
                showHeader={false}
                rowClassName="medium fs-18px pointer"
                rowKey="id"
                onRow={(record) => ({
                  onClick: () => navigate(`${URLS.SpecificCardTransaction}/${record.id}`),
              })}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default CardsDashboard;
