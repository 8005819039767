import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import Spinner from "App/Components/Spinner";
import { useLoginState, useLoginStore } from "App/Pages/Login/store";
import { useLocation, useNavigate } from "react-router-dom";
import URLS from "Routes/constants";

const AuthGuard = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const accessToken = useLoginState(state => state.accessToken);
	const getAuthHeader = useLoginState(state => state.getAuthHeader);
	const fetchConfig = useLoginState(state => state.fetchConfig);
	const config = useLoginStore(state => state.config);

	//
	useEffect(() => {
		(async () => {
			try {
				const auth = await getAuthHeader();
				fetchConfig(auth);
			} catch (err) {
				navigate(URLS.Login);
			}
		})();

		// eslint-disable-next-line
	}, [location.pathname]);

	//@Craig, what should happen when no config? Do we keep the user loggedin ?
	return (
		!!accessToken && accessToken.expires.getTime() > Date.now() && !!config
			? <Outlet />
			: <Spinner full />
	)

}

export default AuthGuard;

