import {
	Button,
	Col,
	Row,
	Typography,
} from "antd";

function ReportCard({cardId, onCancel = () => {}, onConfirm = () => {}}) {

	return (
		<>
			<Row>
				<Col>
					<Typography.Text className="muli semi-bold fs-18px dark-green">
						CARD LOST/STOLEN
					</Typography.Text>
				</Col>
			</Row>
			<Row className="m-t-20">
				<Col>
					<Typography.Paragraph className="muli semi-bold fs-16px dark-green">
						Please ensure that all balances are transferred onto the company wallet before reporting the card as lost/stolen. Reporting it as such will automatically cancel the card; this action CANNOT be reversed.
					</Typography.Paragraph>
					<Typography.Paragraph className="muli semi-bold fs-16px dark-green">
						Do you want to mark the prepaid card as lost / stolen?
					</Typography.Paragraph>
				</Col>
			</Row>
			<Row className="m-t-20" justify="space-between">
				<Col>
					<Button type="primary" onClick={onCancel}>
						Close
					</Button>
				</Col>
				<Col>
					<Button onClick={onConfirm} type="danger">Report</Button>
				</Col>
			</Row>
	  </>
	)
}

export default ReportCard;
