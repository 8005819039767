import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { notification } from "antd";

const useBulkCardAutoFundMutation = ({payload}) => {
    const apiRequest              = useApiRequest();
    const queryClient             = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload) => {
            return apiRequest(APIROUTES.post.prepaidcard_bulk_autoload, 'POST', payload);
        },
        mutationKey: ['_prepaidcard_bulk_autoload'],
        onMutate: () => {},
        onSuccess: (data, context) => {
			if(!data?.success) {
				notification.error({ message: 'Something went wrong.',  placement: 'top' });
				return;
			}

            queryClient.invalidateQueries({ queryKey: ['_prepaidcard_autoload']});
            queryClient.invalidateQueries({ queryKey: ['_prepaid_cards'], exact: true});
            notification.success({ message: 'Bulk autofund amounts saved!',  placement: 'top' });
        },
    });

    return mutation;
}

export { useBulkCardAutoFundMutation };
