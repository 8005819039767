import { Button, Col, Row } from "antd";
import PageDescription from "App/Components/PageDescription";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useConfigurationState } from "App/Pages/Accounting/Configuration/store";


function AccountingConnect() {

    const [loading, setLoading] = useState(false);
    const apiRequest = useApiRequest();
    const setState = useConfigurationState(state => state.setState);

    // eslint-disable-next-line no-unused-vars
    const queryClient = useQueryClient();

    useEffect(() => {
        setState({pollForStatusInterval: false});

    // eslint-disable-next-line
    }, []);

    const handleConnect = async () => {
        setLoading(() => true);
        const { data } = await apiRequest(`${APIROUTES.set_client_company}`, 'POST');
        setLoading(() => false);

        // Redirect to codat, maybe use useEffect
        if(data.success && data?.response?.redirect) {
            // Maybe invalidate map query based on repsonse from "exiting" codat connection
            // queryClient.invalidateQueries({ queryKey: ['_accounting_company_map'], exact: true});
            window.location.href = data.response.redirect;
        }
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <PageDescription title='Connect Accounting' />
                </Col>
            </Row>
            <Row className="m-t-20">
                <Col>
                    <Button
                        type="primary"
                        onClick={handleConnect}
                        loading={loading}
                    >
                        Connect to Accounting Software
                    </Button>
                </Col>
            </Row>
        </>
    )
}

export default AccountingConnect;
