const APIROUTES = {
    terms_acceptance: `accounting/client/company/accept`,
    accounting_company_map: `accounting/client/company/map`,
    set_accept_terms: `accounting/setClientAccept`,
    set_client_company: `accounting/setClientCompany`,
    delete_accounting_config: `accounting/delConfig`,
    get_accounting_config_all: `accounting/getAllConfiguration`,
    get_codat_categories: `accounting/getAccountCategories`,
    get_codat_tracking_categories: `accounting/getAccountTrackingCategories`,
    get_bank_accounts: `accounting/getBankAccounts`,
    get_bill_payment_bank_accounts: `accounting/getBillPaymentBankAccounts`,
    get_transaction_categories: `transaction/category`,
    get_tax_rates: `accounting/getTaxRates`,
    get_tracking_category: `accounting/getAccountTrackingCategories`,
    get_accounting_transactions_bank_feeds: `accounting/connection/feed?$with=transaction,transaction.prepaidCard,transaction.currency,transactionCategory.category,transaction.prepaidCard.cardHolder&$pageCount`,
    get_accounting_transactions_expenses: `accounting/connection/expenses?$with=transaction,transaction.prepaidCard,transaction.expense,transaction.expense.fileStore,transaction.currency,transactionCategory.category,transaction.prepaidCard.cardHolder&$pageCount`,
    post_accounting_synch_expense: `accounting/synchExpense`,
    prepaidcard_disabledtxtype: `prepaidcard/disabledtxtype`,
    prepaidcard_maxtransaction: `prepaidcard/maxtransaction`,

    get: {
        users_prepaidcards: `users/prepaidcards`,
        card_image_url: `getImageUrl`,
        prepaidcard: `prepaidcard/-`,
        prepaidcard_list: `prepaidcard/list`,
        prepaidcard_active: `prepaidcard/active`,
        transaction_settled: `transaction/settled`,
        transaction: `transaction/-`,
        file: `file/file?uuid=`,
        thumbnail: `file/file/thumbnail?uuid=`,
        users_manage: `users-manage?$with=All`,
        edit_users_manage: `users-manage/user?userId=`,
        account_cw_balance: `account/cw/balance`,
        prepaidcard_frequent: `prepaidcard/frequent`,
        company_wallet_balances: `company/wallet/balances`,
        currency: `currency`,
        company_wallet_breakdown: `company/wallet/breakdown`,
        users_card_balance: `users/card-balance`,
        prepaidcard_autoload: `prepaidcard/autoload`,
        company_Wallet_service: `services/company-wallet`,
        company_payments_service: `services/payments`,
        cards_service: `services/cards`,
        accounting_service: `services/accounting`,
        analytics_services: `services/analytics`,
        wallet_balance_services: `services/shared-wallet-balance`,
        limit_prepaid_tier_status: `limit/prepaid/tier/status`,
        company_wallet_card: `company/wallet/card`,
        shared_balance_limits: `shared/balance/limits`,
        platform_alert: `platform/alert`,
        gps_get_card_pin: `gps/getCardPin`,
        user_access: `users-manage/user-access?target_user_id=`,
        user_level_access: `users-manage/user-level-access/-`,
        registered_cards: `users/registered-cards`,
        notifications: `platform/alert`,
        notification_settings: `platform/settings`,
        qr_2fa: `generateQRCode`,
        get_funding_details: 'client/cw/fund/details',
        personal_profile: `users-manage/self`,
        refund_details: `tcc/refund/beneficiary`,
        recipient_details: `tcc/beneficiary/-`,
        recipients: `tcc/beneficiary`,
        refund_recipient: `tcc/refund/beneficiary/-`,
        payment_history: `tcc/payment/history`,
        manual_trade_history: 'tcc/manualpayment/history',
        exchange_out_of_hours: 'exchange/b2b/self/out/of/hours',
        approvers: 'approval/approvers',
        approval_rules: `approval/rule`,
        approval_users: `users/payment/users`,
        is_approver: `approval/is/approver`,
        draft_payments: `exchange/b2b/self/recall/quote`,
        derive_quote: 'exchange/b2b/self/recall/quote/derived',
        file_file: '/file/file',
        quote_recipients: `exchange/b2b/self/recall/quote/recipients`,
        accounting_getBooked_payment: 'accounting/getBookedPayment/',
        easy_transfer_getBooked_payment: 'easyTransfer/getBookedPayment/',
        monthly_payment_reports: 'monthly-payment-reports',
        download_payment_reports: 'download/payment-reports',
        tracking_codes: `tracking/code/getAll`,
        get_all_currencies: 'currency',
        get_transaction_types: 'opt/TransactionType',
        users_transaction_cards: 'users/transaction-cards',
        accounting_transaction_cards: 'users/accounting-transaction-cards',
        get_currency_rules: 'currency/rules',
        service_notifications: `service/notification/map`,
        get_payment_purpose_codes: 'tcc-payment-purpose-codes',
        fx_files: `fx-proof/files`,
        fx_file: `fx-proof/file`,
        get_All_volopa_tracking_codes: 'tracking/code/getAll',
        tracking_code: 'tracking/code/getDetail/'
    },

    post: {
        set_all_accounting_config: `accounting/setAllConfiguration`,
        expense: `expense`,
        prepaidcard_autoload: `prepaidcard/autoload`,
        prepaidcard_bulk_autoload: `prepaidcard/bulk/autoload`,
        gps_unblockcard: `gps/unblockCard`,
        gps_blockcard: `gps/blockCard`,
        gps_stolencard: `gps/stolenCard`,
        gps_destroycard: `gps/destroyCard`,
        file_files_store: `file/files/store`,
        transaction_category_map_create_update: `transaction/category/map/create/update`,
        transaction_vat_map_create_update: `transaction/vat/map/create/update`,
        enable_service: `services/enable-service`,
        disable_service: `services/disable-service`,
        card_transfer_request: `transfer/request`,
        card_transfer_confirm: `transfer/confirm`,
        contact: 'contact',
        shared_balance_card_limits_bulk: `shared/balance/bulk/limits`,
        accounting_create_bank_account: `accounting/createBankAccount`,
        user_access: `users-manage/user-access`,
        prepaidcard_user_pan_features: 'prepaidcard/user/pan/features',
        business_user_create: 'businessUser/create',
        business_user_resend_activation_code: 'businessUser/resendActivationCode',
        notification_settings: `platform/settings`,
        verify_2fa: 'verify2faCode',
        disable_2fa: 'disable2fa',
        user_update: 'users-manage/update-user',
        cancel_funding_history: 'client/cw/cancel',
        user_level_access: `users-manage/user-level-access`,
        email_change_resend: 'users/email-change/resend',
        accounting_synch_bank_feeds: `accounting/synchFeed`,
        users_password: `users/password`,
        postCardPin: 'gps/postCardPin',
        refresh_transactions_data: 'accounting/refreshTransactionData',
        approvers: 'approval/approvers',
        bulk_approvers: 'approval/bulk/rules/approvers',
        accept_quote: 'exchange/b2b/self/acceptquote',
        accept_quote_uuid: `exchange/b2b/self/acceptquote/uuid`,
        payment_draft: 'exchange/b2b/self/save/approval/payment',
        cancel_draft: 'exchange/b2b/self/cancel/approval/schedule',
        approve_scheduled_payment: 'exchange/b2b/self/approve/scheduled/payment',
        schedule_draft: 'exchange/b2b/self/save/scheduled/payment',
        accounting_create_payment: 'easyTransfer/createPayment',
        get_payment_detail: 'easyTransfer/getPaymentDetail',
        tracking_code_create: 'tracking/code/create',
        tracking_code_transaction_map: `tracking/code/map/transaction`,
        refresh_codat_company_data: 'accounting/refreshCodatCompanyData'
    },

    put: {
        expenses_override_codat_category_id: `accounting/expenses/-`,
        expenses_override_codat_tax_id: `accounting/expenses/-`,
        expense: `expense/-`,
        prepaidcard_autoload: `prepaidcard/autoload`,
        activate_card: `gps/phoneActivateCard`,
        notifications: `platform/alert/-`,
        sync_feed_reset: 'accounting/synchFeed/reset',
        change_email: 'users/email-change',
        change_email_confirm: 'users/email-change/confirm',
        users_forgot_password: `users/forgot-password`,
    },

    del: {
        prepaidcard_autoload: `prepaidcard/autoload`,
        prepaidcard_bulk_autoload: `prepaidcard/bulk/autoload`,
        shared_balance_card_limits: `shared/balance/limits`,
        file: `file/file`,
        user_access: `users-manage/user-access`,
        user: `users/user`,
        notifications: `platform/delete-alerts`,
        prepaidcard_user_pan_features: 'prepaidcard/user/pan/features',
        user_level_access: `users-manage/user-level-access/-`,
        refund_beneficiary: `tcc/refund/beneficiary/delete/-`,
        tracking_code_delete: 'tracking/code/delete',
    }
}

const REPORTS_ROUTES = {
    get: {
        get_all_reports: `getAllReports`,
        get_client_reports: `statements-and-reports`,
        get_all_reports_paged: `getAllReportsWithPagination`,
        get_reports_download: `download/client-reports`,
    },

    post: {

    }
}

export { APIROUTES, REPORTS_ROUTES };
