import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useManualTradeHistory = (currentPage, limit, search) => {
    const apiRequest = useApiRequest();

    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
        const data = await apiRequest(`${APIROUTES.get.manual_trade_history}?$with=fxDealBeneficiary.beneficiaryAccount.account,currencySell,currencyBuy,optPaymentStatus&automatic=0&$pageCount&$skip=${(currentPage - 1) * limit}&$limit=${limit}${search ? '&search=' + search: ''}`);
        return data;
    }

    const query = useQuery({
        queryKey: ['_manuak_trade_history', currentPage, limit, search],
        queryFn: fetchQuery,
        onSuccess: () => {
        },
    });

    return query;
}

export { useManualTradeHistory };