import { Modal } from "antd";

function FullScreenModal(props) {
    const open = props.open;
    const onCancel = props.onCancel;
    const closable = props.closable === false ? false : true;

    return (
        <Modal
            open={open}
            destroyOnClose
            footer={null}
            width='100vw'
            style={{ top: 20 }}
            onCancel={onCancel}
            data-testid='container'
            closable={closable}
        >
            <style>
                {"\
                    .ant-modal-mask{\
                        background-color:rgba(233,238,240,0.8);\
                        backdrop-filter: blur(5px);\
                    }\
                    .ant-modal-content{\
                        background-color: transparent;\
                        box-shadow: none;\
                        min-height: calc(100vh - 45px)\
                    }\
                    .ant-modal-body{\
                        padding-left: 2.08%;\
                        padding-right: 2.08%;\
                    }\
                "}
            </style>
            {props.children}
        </Modal>
    )
}

export default FullScreenModal;
