import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useRefundDetailsState } from "App/Pages/Settings/CompanyAccount/RefundDetails/store";

const useRefundDetailsQuery = () => {
  const apiRequest = useApiRequest();
  const currentPage = useRefundDetailsState(state => state.currentPage);
  const limit = useRefundDetailsState(state => state.limit);

  // eslint-disable-next-line no-unused-vars
  const fetchQuery = async () => {
    const res = await apiRequest(`${APIROUTES.get.refund_details}?$pageCount&$skip=${(currentPage - 1) * limit}&$limit=${limit}`);
    return res;
  }

  const query = useQuery({
    queryKey: ['_refund_details', currentPage, limit],
    queryFn: fetchQuery,
    refetchOnMount: 'always'
  });

  return query;
}

export { useRefundDetailsQuery };