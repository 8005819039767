import { Button, Card, Col, Divider, Row, Space, Table, Typography } from 'antd';
import PageDescription from 'App/Components/PageDescription';
import { useNavigate } from 'react-router-dom';
import URLS from 'Routes/constants';
import { dataSource, pageContent, pageEndContent } from './store/data';

function UserPermissions() {
    const navigate = useNavigate();
    const columns = [
        {
            title: '',
            dataIndex: 'type',
            key: 'type',
            render: (text, _, index) => <>{text}<Divider type='vertical' className={[0, 1, 3, 4, 7].includes(index) ? 'light-divider' : 'dark-divider'} /></>,
            onCell: (_, index) => {
                if ([0, 3, 5].includes(index))
                    return { rowSpan: 2 };
                if ([1, 4, 6].includes(index))
                    return { rowSpan: 0 };
            }
        },
        {
            title: <Typography.Text className='muli semi-bold dark-green fs-18px'>User Role</Typography.Text>,
            dataIndex: 'userRole',
            key: 'userRole',
            render: data => <Space size={0} direction='vertical'>{data && data.length > 0 && data.map((text) => text)}</Space>
        },
        {
            title: <Typography.Text className='muli semi-bold purple fs-18px'>Primary Administrator</Typography.Text>,
            dataIndex: 'primaryAdministrator',
            key: 'primaryAdministrator'
        },
        {
            title: <Typography.Text className='muli semi-bold error-hover fs-18px'>Administrator</Typography.Text>,
            dataIndex: 'administrator',
            key: 'administrator',
        },
        {
            title: <Typography.Text className='muli semi-bold light-green fs-18px'>Business User</Typography.Text>,
            dataIndex: 'businessUser',
            key: 'businessUser'
        },
        {
            title: <Typography.Text className='muli semi-bold dark-green fs-18px'>Card User</Typography.Text>,
            dataIndex: 'cardUser',
            key: 'cardUser'
        }
    ];
    return (
        <>
            <Row>
                <Col span={24}>
                    <PageDescription title='User Permissions' />
                </Col>
            </Row>
            <Row className='m-t-10' justify='end'>
                <Col>
                    <Button type='primary' onClick={() => navigate(URLS.UserGuides)}>Return to User Guides</Button>
                </Col>
            </Row>
            <Row className='m-t-20' gutter={[12, 12]}>
                {pageContent && pageContent.length > 0 && pageContent.map((item, key) => (
                    <Col span={24} key={key}>
                        <PageDescription
                            title={item.title}
                            text={item.text}
                            defaultStyle={false}
                        />
                    </Col>
                ))}
            </Row>
            <Row className='m-t-10'>
                <Col span={24}>
                    <Card hoverable className='b-g' bodyStyle={{ overflow: 'hidden' }}>
                        <Table
                            columns={columns}
                            dataSource={dataSource}
                            pagination={false}
                            className='no-row-spacing'
                            rowClassName={(_, index) => {
                                if ([0, 3].includes(index)) {
                                    return 'row-filled-start fs-18px medium'
                                } else if ([1, 4].includes(index)) {
                                    return 'row-filled-end fs-18px medium'
                                } else if ([7].includes(index)) {
                                    return 'row-filled fs-18px medium'
                                } else {
                                    return 'fs-18px medium'
                                }
                            }}
                        />
                    </Card>
                </Col>
            </Row>
            <Row className='m-t-10' gutter={[12, 12]}>
                {pageEndContent && pageEndContent.length > 0 && pageEndContent.map((item, key) => (
                    <Col span={24} key={key}>
                        <PageDescription
                            title={item.title}
                            text={item.text}
                            defaultStyle={false}
                        />
                    </Col>
                ))}
            </Row>
        </>
    )
}

export default UserPermissions;