import { Button, Card, Col, Form, Image, Input, Row, Typography, notification } from "antd";
import logo from 'Assets/Images/logo.png';
import one from 'Assets/Images/loginOne.png';
import two from 'Assets/Images/loginTwo.png';
import three from 'Assets/Images/loginThree.png';
import { apiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import URLS from "Routes/constants";
import { useResetPasswordState } from "App/Pages/Login/ResetPassword/store";

function ResetPassword() {
    const loading = useResetPasswordState(state => state.loading);
    const setState = useResetPasswordState(state => state.setState);
    const { search } = useLocation();
    const navigate = useNavigate();

    const submitForm = async (data) => {
        setState({ loading: true });
        const payload = {
            new_password: data.password,
            key: search.replace("?key=", "")
        }

        const res = await apiRequest(
            APIROUTES.post.users_password,
            'POST',
            payload,
        );

        if (res.success) {
            notification.success({ message: 'Password updated successfully.', placement: 'top' });
            navigate(URLS.Login);
        } else {
            notification.error({ message: 'Password failed to update.', placement: 'top' });
        }
        setState({ loading: false });
    }

    useEffect(() => {
        if (search === "" || !search || search.replace("?key=", "") === "") {
            navigate(URLS.Login);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row className="full-height" align="middle" justify="center" style={{ overflow: 'hidden' }}>
            <Col xxl={6} xl={9} lg={12} md={12} sm={18} xs={22}>
                <img src={one} className='one' alt="1" />
                <img src={two} className='two' alt="2" />
                <img src={three} className='three' alt="3" />
                <Card hoverable >
                    <Row justify="center" className="m-t-100">
                        <Col>
                            <Image src={logo} alt='logo' preview={false} width={214} />
                        </Col>
                    </Row>
                    <Row justify="center" className="m-t-10">
                        <Col>
                            <Typography.Text className="fs-18px medium">Reset Password</Typography.Text>
                        </Col>
                    </Row>
                    <Row className="m-t-30" justify="center">
                        <Col span={18}>
                            <Form
                                layout="vertical"
                                requiredMark={false}
                                onFinish={submitForm}>
                                <Form.Item
                                    name='password'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your new password'
                                        },
                                        {
                                            pattern: /\d/,
                                            message: 'Password must include at least one number (0-9)'
                                        },
                                        {
                                            pattern: /[A-Z]/,
                                            message: 'Password must include at least one Uppercase character (A-Z)'
                                        },
                                        {
                                            pattern: /[a-z]/,
                                            message: 'Password must include at least one lowercase character (a-z)'
                                        },
                                        {
                                            min: 8,
                                            message: 'Password must contain at least 8 characters'
                                        },
                                    ]}
                                >
                                    <Input.Password size="large" placeholder="New Password" className="b-g placeholder-green" />
                                </Form.Item>
                                <Form.Item
                                    name='confirmPassword'
                                    dependencies={['password']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Confirm Your Password'
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || !getFieldValue('password') || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                            },
                                        }),
                                    ]}>
                                    <Input.Password size="large" placeholder="Confirm Password" className="b-g placeholder-green" />
                                </Form.Item>
                                <Row justify="center" className="m-b-40">
                                    <Col>
                                        <Button type="primary" htmlType="submit" className="right-align-text" disabled={loading} loading={loading}>Reset</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    )
}

export default ResetPassword;