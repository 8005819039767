import { useLoginStore } from "App/Pages/Login/store";
import { Navigate, Outlet } from "react-router-dom";
import URLS from "Routes/constants";

function ActiveClientGuard({
  redirectTo = URLS.AdditionalServices,
}) {
  const config = useLoginStore(s => s.config);

  return (config?.client_status?.client_verification_status_id === 1)
    ? <Outlet />
    : <Navigate to={redirectTo} replace />

}

export default ActiveClientGuard;
