import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { cloneDeep } from "lodash";

function useRecipientsQuery(enabled = true) {
  const apiRequest = useApiRequest();

  // eslint-disable-next-line no-unused-vars
  const fetchQuery = async () => {
    const { data } = await apiRequest(`${APIROUTES.get.recipients}?$with=tccContact.tccAccount,getLastPaymentInfo&$limit=-1`);
    return data;
  }

  const query = useQuery({
    queryKey: ['_recpipients'],
    refetchOnWindowFocus: false,
    enabled: enabled,
    queryFn: fetchQuery,
    select: (data) => {
      if (data) {
        const responseData = cloneDeep(data);

        const frequentRecipients = [].concat(data)
          .sort((a, b) => a.payment_count < b.payment_count ? 1 : -1)
          .slice(0, 4)
          .map((item, i) => ({
            key: i,
            id: item?.id,
            name: item.name !== "undefined undefined" ? item.name : item.beneficiary_company_name
          })
          );

        const obj = data.reduce((acc, c) => {
          let recName = c.name !== "undefined undefined" ? c.name[0] : c.beneficiary_company_name[0];
          const letter = recName.toUpperCase();
          acc[letter] = (acc[letter] || []).concat(c);
          return acc;
        }, {});

        const recipientsSorted = Object.entries(obj).map(([letter, recipients]) => {
          return { letter, recipients }
        }).sort((a, b) => a.letter > b.letter ? 1 : -1);

        const recipientsGrouped = recipientsSorted.map((item, i) => ({
          letter: item?.letter,
          recipients: item.recipients.map((row, j) => ({
            key: row.id,
            value: row.id,
            name: row.name !== "undefined undefined" ? row.name : row.beneficiary_company_name
          }))
        }));

        return { recipientsData: responseData, frequentRecipients, recipientsGrouped };
      } else {
        return false
      }
    }
  });

  return query;
}

export { useRecipientsQuery };