import create from "zustand";

const useEmailConfirmedState = create((set, get) => ({
	loading: false,
    success: true,
	setState: (data) => set({ ...data })
}));

export {
    useEmailConfirmedState
}
