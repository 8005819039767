import { Col, Row, Typography, Grid, Spin } from "antd";
import { useCompanyWalletBalancesQuery } from 'Hooks/Queries';
import { accountingFormat } from "Utils";
import { useWalletDashboardState } from "App/Pages/Wallet/WalletDashboard/store";
import getSymbolFromCurrency from "currency-symbol-map";
import { useNavigate } from "react-router-dom";
import URLS from "Routes/constants";

const { useBreakpoint } = Grid;

function TotalCompanyBalance({
    style = { marginRight: '4.17vw', marginLeft: '4.17vw' },
    gutter = [0, 24]
}) {
    const breakpoint = useBreakpoint();
    const { isLoading, isRefetching } = useCompanyWalletBalancesQuery();
    const cwSummaryCurrency = useWalletDashboardState(state => state.cwSummaryCurrency);
    const cwSummaryTotalCompanyBalance = useWalletDashboardState(state => state.cwSummaryTotalCompanyBalance);
    const cwSummaryWalletBalance = useWalletDashboardState(state => state.cwSummaryWalletBalance);
    const cwSummaryCardsBalance = useWalletDashboardState(state => state.cwSummaryCardsBalance);
    const navigate = useNavigate();

    return (
        <Spin spinning={isLoading || isRefetching}>
            <Row style={style} gutter={gutter}>
                <Col xxl={9} xl={10} lg={10} md={24} sm={24} xs={24} >
                    <Row justify={breakpoint.lg === false ? 'start' : 'end'}>
                        <Col>
                            <Typography.Text className='light-green medium fs-28px'>Total Company Balance</Typography.Text>
                        </Col>
                    </Row>
                    <Row justify={breakpoint.lg === false ? 'start' : 'end'}>
                        <Col>
                            <Typography.Text className=' bold fs-28px'>{getSymbolFromCurrency(cwSummaryCurrency)} {accountingFormat(cwSummaryTotalCompanyBalance) ?? '-'}</Typography.Text>
                        </Col>
                    </Row>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={24} sm={24} xs={24}>
                    <Row justify={breakpoint.lg === false ? 'start' : 'end'}>
                        <Col>
                            <Typography.Text className='dark-green medium fs-28px'>Wallet Balance</Typography.Text>
                        </Col>
                    </Row>
                    <Row justify={breakpoint.lg === false ? 'start' : 'end'}>
                        <Col>
                            <Typography.Text className=' bold fs-28px'>{getSymbolFromCurrency(cwSummaryCurrency)} {accountingFormat(cwSummaryWalletBalance) ?? '-'}</Typography.Text>
                        </Col>
                    </Row>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={24} sm={24} xs={24}>
                    <Row justify={breakpoint.lg === false ? 'start' : 'end'}>
                        <Col>
                            <Typography.Text className='dark-green medium fs-28px pointer' onClick={() => navigate(URLS.CardsDashboard)}>Cards Balance</Typography.Text>
                        </Col>
                    </Row>
                    <Row justify={breakpoint.lg === false ? 'start' : 'end'}>
                        <Col>
                            <Typography.Text className=' bold fs-28px pointer' onClick={() => navigate(URLS.CardsDashboard)}>{getSymbolFromCurrency(cwSummaryCurrency)} {accountingFormat(cwSummaryCardsBalance) ?? '-'}</Typography.Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Spin>
    )
}

export default TotalCompanyBalance;