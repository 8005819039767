import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const use2faQrQuery = () => {
  const apiRequest = useApiRequest();

  // eslint-disable-next-line no-unused-vars
  const fetchQuery = async () => {
    const data = await apiRequest(`${APIROUTES.get.qr_2fa}`, 'GET', undefined, undefined, undefined, true);
    return data;
  }

  const query = useQuery({
    queryKey: ['_mfa_qr'],
    queryFn: fetchQuery,
    refetchOnWindowFocus: false
  });

  return query;
}

export { use2faQrQuery };