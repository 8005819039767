import { Button, Col, Row } from 'antd';
import PageDescription from 'App/Components/PageDescription';
import { useNavigate } from 'react-router-dom';
import URLS from 'Routes/constants';
import { pageContent } from './store/data';

function VolopaCardFAQs() {
    const navigate = useNavigate();
    return (
        <>
            <Row>
                <Col span={24}>
                    <PageDescription
                        title='Volopa Card FAQs'
                        text='Below are some of the common questions asked by our customers. For a full list please visit the FAQ section at www.volopa.com'
                    />
                </Col>
            </Row>
            <Row className='m-t-10' justify='end'>
                <Col>
                    <Button type='primary' onClick={() => navigate(URLS.FAQs)}>
                        Return to FAQs
                    </Button>
                </Col>
            </Row>
            <Row className='m-t-20' gutter={[12, 12]}>
                {pageContent && pageContent.length > 0 && pageContent.map((item, key) => (
                    <Col span={24} key={key}>
                        <PageDescription
                            title={item.title}
                            text={item.text}
                            defaultStyle={false}
                        />
                    </Col>
                ))}
            </Row>
        </>
    );
}

export default VolopaCardFAQs;