import create from "zustand";

const useRecipientListState = create(set => ({
    recipientsData: undefined,
    recipientsLoading: false,
    currentPage: 1,
	limit: 10,
    total: 1,
    setState: obj => set(obj)
}));

export { useRecipientListState };