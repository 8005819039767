/* eslint-disable react-hooks/exhaustive-deps */
import CustomTable from "App/Components/CustomTable";
import PageDescription from "App/Components/PageDescription";
import { FORMATS } from "Constants/Formats";
import { colors } from "Constants/Style";
import { useFxFilesQuery } from "Hooks/Queries";
import { Button, Col, Radio, Row, Space, notification } from "antd";
import moment from "moment";
import { AiOutlineFilePdf } from "react-icons/ai";
import { FaFileCsv } from "react-icons/fa";
import { HiOutlineDocumentText } from "react-icons/hi";
import { IoDocumentTextOutline } from "react-icons/io5";
import { SiMicrosoftexcel } from "react-icons/si";
import { useEffect } from "react";
import { cloneDeep } from "lodash";
import { useFxForwardsState } from "App/Pages/FxForwards/FxForwardsDashboard/store"
import { useExtendedApiRequest } from "Hooks/ExtendedAPI";
import { APIROUTES } from "Constants/ApiRoutes";
import { urlObjectCreator } from "Utils";
import CustomPagination from "App/Components/CustomPagination";

export default function FxForwardsDashboard() {
  const { data: fxFiles, isLoading: fxFilesLoading } = useFxFilesQuery();
  const selectedFiles = useFxForwardsState(s => s.selectedFiles);
  const downloading = useFxForwardsState(s => s.downloading);
  const limit = useFxForwardsState(s => s.limit);
  const currentPage = useFxForwardsState(s => s.currentPage);
  const setState = useFxForwardsState(s => s.setState);
  const resetState = useFxForwardsState(s => s.resetState);
  const extendedApiRequest = useExtendedApiRequest();

  const columns = [
    {
      title: 'Date',
      dataIndex: 'update_time',
      key: 'date',
      align: 'left',
      sorter: (a, b) => moment(a?.month).unix() - moment(b?.month).unix(),
      render: (value) => {
        const docDate = moment(value);
        return <Space size="small" align="center">
          <HiOutlineDocumentText style={{ fontSize: '40px', color: colors.darkGreen, marginBottom: '-4px' }} />
          <Space direction="vertical" size={0}>
            {docDate.format(FORMATS.date)}
            {docDate.format(FORMATS.time)}
          </Space>
        </Space>
      }
    },
    {
      title: 'Document name',
      dataIndex: 'file_name',
      key: 'doc_name',
      align: 'left',
    },
    {
      title: 'Format',
      dataIndex: 'file_extension',
      key: 'format',
      align: 'left',
      render: (value) => <Space size='small' align="center">{getDocumentFormatIcon(value)} {value?.toUpperCase()}</Space>
    },
    {
      title: 'Download',
      dataIndex: 'uuid',
      key: 'select_all',
      render: (uuid) => <Radio
        checked={selectedFiles?.find(selectedUuid => selectedUuid === uuid)} onClick={() => handleRadioClick(uuid)}
        className='b-2-grey big-rounded p-r-2 p-l-2'
      />
    },
  ];

  useEffect(() => {
    return resetState();
  }, [])

  const getDocumentFormatIcon = (type) => {
    if (type === 'pdf') {
      return <AiOutlineFilePdf style={{ fontSize: '40px', color: colors.darkGreen, marginBottom: '-4px' }} />;
    } else if (type === 'csv') {
      return <FaFileCsv style={{ fontSize: '40px', color: colors.darkGreen, marginBottom: '-4px' }} />;
    } else if (type === 'xls' || type === 'xlsx') {
      return <SiMicrosoftexcel style={{ fontSize: '40px', color: colors.darkGreen, marginBottom: '-4px' }} />;
    } else {
      return <IoDocumentTextOutline style={{ fontSize: '40px', color: colors.darkGreen, marginBottom: '-4px' }} />;
    }
  }

  const handleRadioClick = (uuid) => {
    const index = selectedFiles.indexOf(uuid)
    let files = cloneDeep(selectedFiles);
    if (index === -1) {
      files.push(uuid);
    } else {
      files?.splice(index, 1);
    }
    setState({ selectedFiles: files })
  }

  const handleDownloadClick = async () => {
    setState({ downloading: true })
    const downloads = selectedFiles?.map(uuid =>
      extendedApiRequest({
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        path: `${APIROUTES.get.fx_file}?uuid=${uuid}`,
        method: 'GET',
        config: {
          responseType: "blob",
        },
        fullResponse: true,
      })
    )

    const res = await Promise.all(downloads)

    res?.forEach((resp, key) => {
      if (resp.status === 200) {
        const href = urlObjectCreator(resp.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fxFiles?.data?.find(file => file?.uuid === selectedFiles?.[key])?.file_name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      } else {
        notification.destroy()
        notification.error({ placement: 'top', message: 'Error', description: "We couldn't fetch some of your files" })
      }
    })
    setState({ downloading: false })
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <PageDescription title="FX Forwards Dashboard" />
        </Col>
      </Row>
      <Row className="m-t-20" justify="end">
        <Col>
          <Button
            type="primary"
            disabled={selectedFiles?.length === 0}
            onClick={() => handleDownloadClick()}
            loading={downloading}
          >
            Download Selected
          </Button>
        </Col>
      </Row>
      <Row className="m-t-10">
        <Col span={24}>
          <CustomTable
            loading={fxFilesLoading}
            columns={columns}
            dataSource={fxFiles?.data || []}
            className='spaced-rows'
            headerColor='green'
            rowClassName='fs-18px regular'
            styleAllRows
            rowKey="uuid"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CustomPagination
            loading={fxFilesLoading}
            onPageChange={(page) => setState({ currentPage: page })}
            total={fxFiles?.pager?.count ?? 0}
            pageSize={limit}
            current={currentPage}
            onPageSizeChange={(_, size) => setState({ limit: size, currentPage: 1 })}
          />
        </Col>
      </Row>
    </>
  )
}