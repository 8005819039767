import {
	Button,
	Col,
	Row,
	Typography,
} from "antd";
import _ from 'lodash'

function ManualPayment({ cardNumber, onCancel = () => { }, onConfirm = () => { }, type = 'enable' }) {

	return (
		<>
			<Row>
				<Col>
					<Typography.Text className="muli semi-bold fs-18px dark-green">
						{type.toLocaleUpperCase()} PAYMENT
					</Typography.Text>
				</Col>
			</Row>
			<Row className="m-t-20">
				<Col>
					<Typography.Text className="muli semi-bold fs-16px dark-green">
						Are you sure you want to {type} Manual Payments on Card *{cardNumber}?
					</Typography.Text>
				</Col>
			</Row>
			<Row className="m-t-20" justify="space-between">
				<Col>
					<Button type="primary" onClick={onCancel}>
						Close
					</Button>
				</Col>
				<Col>
					<Button onClick={onConfirm} type="primary">{_.capitalize(type)}</Button>
				</Col>
			</Row>
		</>
	)
}

export default ManualPayment;
