import create from 'zustand'

const useNewPaymentState = create(set => ({
    presetPayment: undefined,
    resetRecipient: true,
    frequentRecipients: undefined,
    recipient: undefined,
    showPaymentDetails: false,
    modalOpen: false,
    modalType: undefined,
    currencies: undefined,
    commonCurrencies: undefined,
    fundingMethod: 'Push Funds',
    settlementMethod: 'regular',
    setAmount: undefined,
    fee: undefined,
    firstDate: undefined,
    displayRate: "-",
    displayRateInversed: "-",
    seconds: 0,
    fullPageSpinner: false,
    paymentCrossBorderCode: undefined,
    handleOnFinishLoading: false,
    acceptQuoteLoading: undefined,
    quoteAccepted: false,
    disabledMethod: undefined,
    volopaAccountHolderName: undefined,
    volopaAccountSortCode: undefined,
    volopaAccountNumber: undefined,
    volopaAccountCountry: undefined,
    volopaAccountAddress: undefined,
    volopaAccountName: undefined,
    volopaAccountPaymentRef: undefined,
    volopaAccountIban: undefined,
    volopaAccountSwift: undefined,
    volopaAccountNumberType: undefined,
    volopaAccountSortCodeLabel: 'Sort Code',
    volopaAccountDetails: undefined,
    fundingLimitReached: false,
    showPaymentsUnder: false,
    showPaymentsOver: false,
    approvalRequired: false,
    draftLoading: false,
    deletingDraft: false,
    dates: undefined,
    scheduled: undefined,
    quoteUuid: undefined,
    weConvert: undefined,
    disabledFundingDropdown: true,
    TransactionComplete: false,
    PaymentPurposeCodes: {},
    currencyLimitUKOrEEA: false,
    totalDailyLimitReached: false,
    showInvoiceAndDateFields: false,
    setState: obj => set(obj),
    resetState: () => set({
        TransactionComplete: false,
        disabledFundingDropdown: true,
        weConvert: undefined,
        quoteUuid: undefined,
        scheduled: undefined,
        dates: undefined,
        presetPayment: undefined,
        resetRecipient: true,
        frequentRecipients: undefined,
        recipient: undefined,
        showPaymentDetails: false,
        modalOpen: false,
        modalType: undefined,
        currencies: undefined,
        commonCurrencies: undefined,
        currency: undefined,
        fundingMethod: 'Push Funds',
        settlementMethod: 'regular',
        setAmount: undefined,
        fee: undefined,
        firstDate: undefined,
        displayRate: "-",
        displayRateInversed: "-",
        seconds: 0,
        fullPageSpinner: false,
        handleOnFinishLoading: false,
        acceptQuoteLoading: undefined,
        quoteAccepted: false,
        disabledMethod: undefined,
        volopaAccountHolderName: undefined,
        volopaAccountSortCode: undefined,
        volopaAccountNumber: undefined,
        volopaAccountCountry: undefined,
        volopaAccountAddress: undefined,
        volopaAccountName: undefined,
        volopaAccountPaymentRef: undefined,
        volopaAccountIban: undefined,
        volopaAccountSwift: undefined,
        volopaAccountNumberType: undefined,
        volopaAccountSortCodeLabel: 'Sort Code',
        volopaAccountDetails: undefined,
        fundingLimitReached: false,
        showPaymentsUnder: false,
        showPaymentsOver: false,
        approvalRequired: false,
        draftLoading: false,
        deletingDraft: false,
        showInvoiceAndDateFields: false,
    }),
    viewDetailsModalOpen: false,
}));

export { useNewPaymentState };