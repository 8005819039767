import create from 'zustand'

const useChangeEmailState = create(set => ({
  emailSent: false,
  submitting: false,
  email: undefined,
  passwordStatus: '',
  passwordHelp: '',
  showPendingStatus: false,
  setState: obj => set(obj)
}));

export { useChangeEmailState };