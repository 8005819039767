const pageContent = [
    {
        title: 'User Guide Videos',
        text: <a href="https://volopa.com/resources/user-guides/" target="_blank" rel="noreferrer">Volopa User Guide Videos & Tutorials</a>,
    },
    {
        title: 'Before making an International Payment',
        text: 'You can use our International Payments solution to make bank-to-bank payments to recipients, typically in currencies that you do not hold. You do not need to open a new bank account for this service.'
    },
    {
        title: 'Making an International Payment',
        text: <ul className="dashed">
            <li>
                Your recipient(s) account details (see the Verifying Your Recipient Bank Details page for more information)
            </li>
            <li>
                The payment amount
                <ul className="dashed">
                    <li>
                        You can specify the exact currency amount you wish the recipient to receive, or
                    </li>
                    <li>
                        You can specify the exact currency amount you wish to send to us for conversion
                    </li>
                </ul>
            </li>
        </ul>
    },
    {
        title: 'Getting a Quote',
        text: [
            'Once you enter the details in the new payment page, you will get a quote. This is a real-time quote and we will hold the exchange rate displayed for 30 seconds, showing the amounts you will send to us and the amount the recipient will receive, including any payment processing fees*',
            '*This may not include intermediary or correspondent bank charges'
        ]
    },
    {
        title: 'Confirming your International Payment',
        text: 'If you are happy with the quote, simply follow the next steps to confirm the International Payment. We will let you know the funding account details. This is where you will send the funding payment so that your International Payment can be processed.'
    }
];

export { pageContent }