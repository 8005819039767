/* eslint-disable react-hooks/exhaustive-deps */
import PageDescription from "App/Components/PageDescription";
import { useApprovalRulesQuery, useApprovalUsersQuery, useApproversQuery } from "App/Pages/Settings/CompanyAccount/NotificationSettings/WorkflowApproval/query";
import { useWorkflowApprovalState } from "App/Pages/Settings/CompanyAccount/NotificationSettings/WorkflowApproval/store";
import { APIROUTES } from "Constants/ApiRoutes";
import { useApiRequest } from "Hooks/API";
import { useCurrenciesQuery } from "Hooks/Queries";
import { useSelectSearch } from "Hooks/Search";
import URLS from "Routes/constants";
import { accountingFormat, numberFormat } from "Utils";
import { Button, Col, Form, Image, InputNumber, Row, Select, Space, Spin, Tooltip, Typography, notification } from "antd";
import { cloneDeep } from "lodash";
import { useEffect } from "react";
import { FaInfoCircle, FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function WorkflowApproval() {
  const { data: currencies, isFetching: fetchingCurrencies } = useCurrenciesQuery();
  const { data: approvalUsers, isFetching: fetchingApprovalUsers } = useApprovalUsersQuery();
  const { data: approvers, isFetching: fetchingApprovers } = useApproversQuery();
  const { data: rules, isFetching: fetchingRules } = useApprovalRulesQuery();
  const commonCurrencies = useWorkflowApprovalState(s => s.commonCurrencies);
  const otherCurrencies = useWorkflowApprovalState(s => s.otherCurrencies);
  const isSubmitting = useWorkflowApprovalState(s => s.isSubmitting);
  const saveRes = useWorkflowApprovalState(s => s.saveRes);
  const setState = useWorkflowApprovalState(s => s.setState);
  const resetState = useWorkflowApprovalState(s => s.resetState);
  const filterSelect = useSelectSearch();
  const [form] = Form.useForm();
  const selectedApprovers = Form.useWatch('approvers', form);
  const selectedCurrencies = Form.useWatch('rules', form);
  const navigate = useNavigate();
  const apiRequest = useApiRequest();

  useEffect(() => {
    return resetState();
  }, []);

  useEffect(() => {
    let common = undefined;
    let other = undefined;
    if (currencies) {
      common = currencies
        .filter((data) => data.sort_order_sell !== 999)
        .sort((a, b) => a.sort_order_sell - b.sort_order_sell);
      other = currencies.filter((data) => data.sort_order_sell === 999);
      setState({
        commonCurrencies: common,
        otherCurrencies: other,
      });
    }
  }, [currencies]);

  useEffect(() => {
    form.setFieldValue('rules', rules)
    setState({ saveRes: undefined })
  }, [rules])

  useEffect(() => {
    form.setFieldValue('approvers', approvers)
    setState({ saveRes: undefined })
  }, [approvers])

  const handleSubmit = async (values) => {
    setState({ isSubmitting: true });
    const request = {
      rules: values?.rules?.map(val => ({
        approval_rule_type_id: "1",
        check_type: val.check_type,
        check_value: String(val.check_value)
      })),
      approvers: values?.approvers?.map(val => val.user_id)
    }
    const res = await apiRequest(APIROUTES.post.bulk_approvers, 'POST', request);
    const approversCheck = res?.data?.approvers?.some(val => val.success === false);
    const rulesCheck = res?.data?.rules?.some(val => val.success === false);
    if (approversCheck || rulesCheck) {
      notification.error({
        placement: 'top',
        message: 'Something went wrong',
        description: 'All changes were not saved'
      })
    } else {
      notification.success({
        placement: 'top',
        message: 'Changes saved successfully'
      })
    }
    setState({
      isSubmitting: false,
      saveRes: res
    });
  }

  const clearValidation = (type, key) => {
    const validation = cloneDeep(saveRes);
    if (validation?.data)
      validation.data[type][key] = undefined;
    setState({ saveRes: validation });
  }

  return (
    <Spin size="large" spinning={fetchingApprovers || fetchingRules || fetchingCurrencies || fetchingApprovalUsers}>
      <Row>
        <Col span={24}>
          <PageDescription title="Workflow Approval Rules" />
        </Col>
      </Row>
      <Form layout="vertical" requiredMark={false} form={form} onFinish={handleSubmit}>
        <Row className="m-t-20" gutter={[12, 24]}>
          <Col xxl={12} xl={12} lg={12} span={24}>
            <Row>
              <Col>
                <Typography.Text className="fs-28px medium dark-green">Workflow Approval Rules</Typography.Text>
              </Col>
            </Row>
            <Form.List
              name='rules'
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue('approvers')?.length > 0 && !value?.length) {
                      return Promise.reject(new Error("Please add at least one approval rule"));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields?.map(({ key, name, ...restField }) => (
                    <Row gutter={12} align="middle" key={key}>
                      <Col flex='105px'>
                        <Form.Item
                          {...restField}
                          name={[name, 'check_type']}
                          label={name === 0 && <Typography.Text className="fs-18px medium dark-green">Currency</Typography.Text>}
                          rules={[
                            {
                              required: true,
                              message: 'Please select'
                            }
                          ]}
                        >
                          <Select
                            className="dark-green"
                            showSearch
                            allowClear
                            filterOption={filterSelect}
                          >
                            {commonCurrencies && commonCurrencies.length > 0 &&
                              (
                                !commonCurrencies?.every(val => selectedCurrencies?.some(item => item?.check_type === val?.currency)) ||
                                commonCurrencies?.some(val => selectedCurrencies?.[name]?.check_type === val.currency)
                              ) && (
                                <Select.OptGroup label="Common">
                                  {commonCurrencies.map((val) => (
                                    (!selectedCurrencies?.some(item => item?.check_type === val?.currency) || selectedCurrencies?.[name]?.check_type === val?.currency) &&
                                    <Select.Option
                                      value={val.currency}
                                      key={val.id}
                                      className='p-l-10'
                                    >
                                      <Space size={4} align="center">
                                        {val.flag && (
                                          <Image
                                            src={val.flag}
                                            preview={false}
                                            width={20}
                                            alt={val.currency}
                                            disabled={true}
                                          />
                                        )}
                                        {val.currency}
                                      </Space>
                                    </Select.Option>
                                  ))}
                                </Select.OptGroup>
                              )}
                            {otherCurrencies && otherCurrencies.length > 0 &&
                              (
                                !otherCurrencies?.every(val => selectedCurrencies?.some(item => item?.check_type === val?.currency)) ||
                                otherCurrencies?.some(val => selectedCurrencies?.[name]?.check_type === val.currency)
                              ) && (
                                <Select.OptGroup label="Other">
                                  {otherCurrencies.map((val) => (
                                    (!selectedCurrencies?.some(item => item?.check_type === val.currency) || selectedCurrencies?.[name]?.check_type === val.currency) &&
                                    <Select.Option
                                      key={val.id}
                                      value={val.currency}
                                      className='p-l-10'
                                      disabled={selectedCurrencies?.some(item => item?.check_type === val.currency)}
                                    >
                                      <Space size={4} align="center">
                                        {val.flag && (
                                          <Image
                                            src={val.flag}
                                            preview={false}
                                            width={20}
                                            alt={val.currency}
                                          />
                                        )}
                                        {val.currency}
                                      </Space>
                                    </Select.Option>
                                  ))}
                                </Select.OptGroup>
                              )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col flex='200px'>
                        <Form.Item
                          {...restField}
                          name={[name, 'check_value']}
                          label={
                            name === 0 &&
                            <Space>
                              <Typography.Text className="fs-18px medium dark-green">Amount</Typography.Text>
                              <Tooltip title='International Payments larger than this amount will be submitted for a second user approval' >
                                <FaInfoCircle className="dark-green" style={{ marginBottom: '-3px' }} />
                              </Tooltip>
                            </Space>
                          }
                          rules={[{
                            required: true,
                            message: 'Enter amount'
                          }]}
                        >
                          <InputNumber
                            className="full-percent-width"
                            placeholder="Enter Amount"
                            controls={false}
                            formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
                            parser={(val) => numberFormat(val)}
                            min={0}
                          />
                        </Form.Item>
                      </Col>
                      <Col className={name === 0 && "m-t-40"}>
                        <Form.Item>
                          <Button type="primary" size="small" danger onClick={() => remove(name)}>
                            <FaTrashAlt style={{ marginBottom: '-2px' }} />
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Row>
                    <Col>
                      <Button type="primary" onClick={() => add()}>+ Add</Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.ErrorList errors={errors} />
                    </Col>
                  </Row>
                </>
              )}
            </Form.List>
          </Col>
          <Col xxl={12} xl={12} lg={12} span={24}>
            <Row>
              <Col>
                <Typography.Text className="fs-28px medium dark-green">Workflow Approvers</Typography.Text>
              </Col>
            </Row>
            <Form.List
              name='approvers'
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue('rules')?.length > 0 && !value?.length) {
                      return Promise.reject(new Error("Please add at least one approver"));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields?.map(({ key, name, ...restField }) => (
                    <Row gutter={12} align="middle" key={key}>
                      <Col flex='350px'>
                        <Form.Item
                          {...restField}
                          name={[name, 'user_id']}
                          label={name === 0 && <Typography.Text className="fs-18px medium dark-green">Approver</Typography.Text>}
                          rules={[
                            {
                              required: true,
                              message: 'Please select'
                            }
                          ]}
                          {...saveRes?.data?.approvers?.[name]?.success === false && { validateStatus: 'error' }}
                          {...saveRes?.data?.approvers?.[name]?.errors && { help: saveRes?.data?.approvers?.[name]?.errors }}
                        >
                          <Select
                            className="dark-green"
                            showSearch
                            allowClear
                            filterOption={filterSelect}
                            loading={fetchingApprovers}
                            placeholder='Select Approver'
                            onChange={() => clearValidation('approvers', name)}
                          >
                            {
                              approvalUsers?.map(val =>
                                (!selectedApprovers?.some(item => item?.user_id === val.id) || selectedApprovers?.[name]?.user_id === val.id) &&
                                <Select.Option value={val?.id} key={val.id}>
                                  {`${val.first_name || ''} ${val.middle_name || ''} ${val.last_name || ''}`}
                                </Select.Option>
                              )
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col className={name === 0 && "m-t-40"}>
                        <Form.Item>
                          <Button type="primary" size="small" danger onClick={() => { clearValidation('approvers', name); remove(name); }}>
                            <FaTrashAlt style={{ marginBottom: '-2px' }} />
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Row>
                    <Col>
                      <Button type="primary" onClick={() => add()}>+ Add</Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.ErrorList errors={errors} />
                    </Col>
                  </Row>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        <Row justify="end" gutter={12}>
          <Col>
            <Button type="primary" danger onClick={() => navigate(URLS.NotificationSettings)} disabled={isSubmitting}>Return</Button>
          </Col>
          <Col>
            <Button type='primary' ghost htmlType="submit" loading={isSubmitting}>Save</Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}

export { WorkflowApproval };