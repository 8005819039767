import create from "zustand";

const useTrackingCodesState = create((set, get) => ({
    mode: 'view',
    rows: [],
    newTrackingCode: '',
    currentPage: 1,
    limit: 10,
    deleteConfirmation: false,
    selectedRow: {},
    //
    setState: (data) => set(data),
}));

export { useTrackingCodesState }
