import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useIsApproverQuery = () => {
  const apiRequest = useApiRequest();

  const fetchQuery = async () => {
    const { data } = await apiRequest(APIROUTES.get.is_approver);
    return data;
  }

  const query = useQuery({
    queryKey: ['_is_approver'],
    queryFn: fetchQuery
  });

  return query;
}

export { useIsApproverQuery };