import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useSyncBankFeedsMutation = () => {
    const apiRequest = useApiRequest();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (feedId) => {
            return apiRequest(APIROUTES.post.accounting_synch_bank_feeds, 'POST', {feedId});
        },
        mutationKey: '_post_accounting_synch_bank_feeds',
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['_get_accounting_transactions_bank_feeds'], exact: false});
        }
    });

    return mutation;
}

export { useSyncBankFeedsMutation };