import create from "zustand";

const useTransactionState = create((set, get) => ({
    transactionId: null,
    amount: null,
    description: null,
    transaction_category: null,
    transaction_vat: null,
    expenseNotes: '',
    expenseTypeDesc: '',
    expenseId: null,
    fileStore: [],
    attachments: [],
    loading: false,
    isSaving: false,
    fileLoading: true,
    projectId: undefined,
    setState: (data) => set(data),
}));

export { useTransactionState };