import { Col, Input, Row, Space, Typography } from "antd";
import CustomTable from "App/Components/CustomTable";
import { colors } from "Constants/Style";
import { FaFileUpload, FaRegCheckCircle } from "react-icons/fa";
import { dataSource } from "./store/data";

function YourApplication() {
    const columns = [
        {
            title: 'Nature of Request',
            dataIndex: 'natureOfRequest',
            key: 'natureOfRequest'
        },
        {
            title: 'Request Description',
            dataIndex: 'requestDescription',
            key: 'requestDescription'
        },
        {
            title: 'Document Upload',
            dataIndex: 'documentUpload',
            key: 'documentUpload',
            render: (val) => val === false ?
                <Space><FaFileUpload className="dark-green" /> Upload Document</Space> :
                <Space><FaRegCheckCircle color={colors.success} /> Document Uploaded</Space>
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
            render: (text, obj) => obj.status === 'Submitted' ? { text } : <Input.TextArea rows={2} value={text} />
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: text => <Typography.Text type={text === 'Incomplete' ? 'danger' : (text === 'Submitted' && 'success')}>{text}</Typography.Text>
        }
    ];

    return (
        <>
            <Row className="m-t-40">
                <Col>
                    <Typography.Text className="fs-30px bold">
                        Please use the space below to upload a document, uploading the hierarchy of the businesses that are shareholders of your company.
                    </Typography.Text>
                </Col>
            </Row>
            <Row className="m-t-20">
                <Col span={24}>
                    <CustomTable columns={columns} dataSource={dataSource} />
                </Col>
            </Row>
        </>
    )
}

export default YourApplication;