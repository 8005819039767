/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Col, Form, Input, Row, Select, Space, Typography, notification, Image, Spin, Modal, Tooltip } from "antd";
import { useApiRequest } from "Hooks/API";
import { FLAGS } from "Constants/Images";
import { useEffect } from "react";
import { FaInfoCircle, FaRegBuilding, FaRegCheckCircle, FaUserAlt } from "react-icons/fa";
// import { useSelectSearch } from "../../../../Hooks/Search";
import PageDescription from "App/Components/PageDescription";
import { useAddRecipientState } from "./store";
import debounce from 'lodash/debounce'
import URLS from "Routes/constants";
import { useNavigate } from "react-router-dom";
import { usePayMultipleRecipientsState } from "App/Pages/Payments/BatchPayments/PayMultipleRecipients/store";
import { colors } from "Constants/Style";
import { useNewPaymentState } from "App/Pages/Payments/NewPayment/store";
import { useSelectSearch } from "Hooks/Search";
import { useLoginStore } from 'App/Pages/Login/store';
import {
    EEA_EXCLUDED_CCY,
    NON_EEA_EXCLUDED_CCY,
    PAYMENT_REASONS,
    REFUND_EEA_EXCLUDED_CCY,
    REFUND_NON_EEA_EXCLUDED_CCY,
    // eslint-disable-next-line no-unused-vars
    PAYMENT_RECIPIENT_TYPES,
    ALLOWED_COUNTRIES_ALL_CURRENCIES_PAYMENT_PURPOSE_CODE,
    ALLOWED_COUNTRIES_LOCAL_CURRENCY_PAYMENT_PURPOSE_CODE,
    ALLOWED_COUNTRIES_LOCAL_CURRENCY_PAYMENT_PURPOSE_CODE_WITH_B2B_B2C,
} from "Constants/Common";
import { useGetCurrencyRulesQuery } from "Hooks/Queries/useGetCurrencyRulesQuery";
import { useGetPaymentPurposeCode } from "Hooks/Queries/useGetPaymentPurposeCode";

function AddRecipient({ handleNewRecipient = false, refundRecipient = false, addedFromBatchPayment = false }) {
    // const selectSearch = useSelectSearch();
    const config = useLoginStore(state => state.config);
    const recipientType = useAddRecipientState(state => state.recipientType);
    const setState = useAddRecipientState(state => state.setState);
    const countries = useAddRecipientState(state => state.countries);
    const commonCountries = useAddRecipientState(state => state.commonCountries);
    const currencies = useAddRecipientState(state => state.currencies);
    const commonCurrencies = useAddRecipientState(state => state.commonCurrencies);
    const paymentPurpose = useAddRecipientState(state => state.paymentPurpose);
    const paymentPurposeOther = useAddRecipientState(state => state.paymentPurposeOther);
    const recipientCurrency = useAddRecipientState(state => state.recipientCurrency);
    const bankCountry = useAddRecipientState(state => state.bankCountry);
    // const hasIban = useAddRecipientState(state => state.hasIban);
    const bankDetailsLoading = useAddRecipientState(state => state.bankDetailsLoading);
    const disableSubmit = useAddRecipientState(state => state.disableSubmit);
    const disableFormSubmitBtn = useAddRecipientState(state => state.disableFormSubmitBtn);
    const bankDetailsName = useAddRecipientState(state => state.bankDetailsName);
    const bankDetailsError = useAddRecipientState(state => state.bankDetailsError);
    const bankDetailsAddress1 = useAddRecipientState(state => state.bankDetailsAddress1);
    const bankDetailsAddress2 = useAddRecipientState(state => state.bankDetailsAddress2);
    const bankDetailsPostcode = useAddRecipientState(state => state.bankDetailsPostcode);
    const bankDetailsCountry = useAddRecipientState(state => state.bankDetailsCountry);
    const bankDetailsIdentifierValue = useAddRecipientState(state => state.bankDetailsIdentifierValue);
    const bankDetailsIdentifierType = useAddRecipientState(state => state.bankDetailsIdentifierType);
    const bankDetailsSwift = useAddRecipientState(state => state.bankDetailsSwift);
    const bankDetailsSortCode = useAddRecipientState(state => state.bankDetailsSortCode);
    // const bankDetailsAccountNumber = useAddRecipientState(state => state.bankDetailsAccountNumber);
    const bankDetailsAba = useAddRecipientState(state => state.bankDetailsAba);
    const submitLoading = useAddRecipientState(state => state.submitLoading);
    const showBankDetails = useAddRecipientState(state => state.showBankDetails);
    const bankLabel = useAddRecipientState(state => state.bankLabel);
    const bankInput = useAddRecipientState(state => state.bankInput);
    const loading = useAddRecipientState(state => state.loading);
    const hasAba = useAddRecipientState(state => state.hasAba);
    const hasBsb = useAddRecipientState(state => state.hasBsb);
    const postCodeRequired = useAddRecipientState(state => state.postCodeRequired);
    const beneficiaryDetails = useAddRecipientState(state => state.beneficiaryDetails);
    // paymentTypes is incorrectly used by legacy
    // @see line 338 getPaymentTypes setting a local var with same name
    // eslint-disable-next-line no-unused-vars
    const paymentTypes = useAddRecipientState(state => state.paymentTypes);
    const selectedRecipients = usePayMultipleRecipientsState(state => state.selectedRecipients);
    const modalOpen = useAddRecipientState(state => state.modalOpen);
    const last_inserted_id = useAddRecipientState(state => state.last_inserted_id);
    const setnewPaymentState = useNewPaymentState((state) => state.setState);
    const PaymentPurposeCodes = useNewPaymentState((state) => state.PaymentPurposeCodes);
    const { isLoading: fetchingPaymentCodes } = useGetPaymentPurposeCode();
    const filterSelect = useSelectSearch();
    const { data: currencyRules } = useGetCurrencyRulesQuery();

    const getCrossBorderPurposeCodes = () => {
        if (ALLOWED_COUNTRIES_ALL_CURRENCIES_PAYMENT_PURPOSE_CODE.includes(bankCountry)) {
            for (const [key, value] of Object.entries(PaymentPurposeCodes)) {
                if (key.includes(bankCountry)) {
                    if (ALLOWED_COUNTRIES_LOCAL_CURRENCY_PAYMENT_PURPOSE_CODE_WITH_B2B_B2C.includes(bankCountry + '_' + recipientCurrency)) {
                        const codes = value?.filter(item => {
                            if (recipientType !== 'individual') {
                                return item?.use_b2b === 1;
                            } else {
                                return item?.use_b2c === 1;
                            }
                        });
                        return codes?.sort((a, b) => a?.label?.localeCompare(b?.label)) || [];
                    }
                    return value?.sort((a, b) => a?.label?.localeCompare(b?.label)) || [];
                }
            }
        }
        const paymentPurposecodes = PaymentPurposeCodes?.[bankCountry + '_' + recipientCurrency]?.sort((a, b) => a.label.localeCompare(b.label)) || []
        return paymentPurposecodes;
    }

    const crossBorderPurposeCodes = getCrossBorderPurposeCodes();

    const [form] = Form.useForm();
    const apiRequest = useApiRequest();
    const navigate = useNavigate();
    const setRecipientType = (recipientType) => {
        setState({ recipientType });
    }

    useEffect(() => {
        if (currencyRules && currencyRules?.length) {
            getCurrencies();
        }
    }, [currencyRules])

    useEffect(() => {
        getCountries();
        getCurrencies();
        resetData();
        setState({
            recipientCurrency: undefined,
            bankCountry: undefined,
        });

        return () => {
            resetData();
            form.resetFields();
            setState({
                recipientCurrency: undefined,
                bankCountry: undefined,
            });
        }
    }, []);

    useEffect(() => {
        if (recipientCurrency && bankCountry) {
            getTccBeneficiariesRequiredDetails();
        }

        // Client is not eea 
        // and recipient ccy is CNY
        if (config.is_eea !== 1 && recipientCurrency === 'CNY' && bankCountry === 'CN') {
            // setRecipientType(PAYMENT_RECIPIENT_TYPES.company);
        }
        getCurrencies();
    }, [recipientCurrency, bankCountry]);

    useEffect(() => {
        validateRecipientForm();
    }, [recipientType])

    const getCountries = async () => {
        let requestUrl = 'country?$limit=-1';
        let response = await apiRequest(requestUrl);
        if (response && response.data && response.data.length > 0) {
            response = response.data.map((val, key) => {
                val.key = key;
                val.label = val.name;
                return val;
            })
            let countryData = response
                .filter((data) => data.id !== 1 && data.allowed === 1)
                .sort((a, b) => a.name > b.name ? 1 : -1);
            let commonCountriesData = response
                .filter((data) => data.id === 1 && data.allowed === 1)
                .sort((a, b) => a.name - b.name);
            setState({ countries: countryData, commonCountries: commonCountriesData });
        } else {
            notification.error({ message: 'Something Went Wrong', description: 'There was an issue with loading bank countries', placement: 'top' });
        }
    }

    // @ This function will be used when we move Exclusion logic to backend
    // const getUKOrEEACurrencies = (UKonly) => {
    //     let data = []
    //     if(currencyRules) {
    //         data = currencyRules?.filter((item) => {
    //             if(UKonly) {
    //                 return item?.uk_client === 1;
    //             } else {
    //                 return item?.eea_client === 1;
    //             }
    //         }).map(item => item?.upper_limit_ccy)
    //     }
    //     return data
    // }

    // This function is to apply Currency Rule on INR if country is IN
    const CurrencyRuleForLocalINR = (currArray) => {
        if (bankCountry === 'IN') {
            // If recipient is local IN then removed INR from Exclusion array
            currArray = currArray.filter(item => item !== 'INR')
        } else if (bankCountry !== 'IN' && recipientCurrency === 'INR') {
            setState({ recipientCurrency: '' });
            form.resetFields(['recipientCurrency'])
            if (!currArray.includes('INR')) {
                currArray.push('INR')
            }
        }
        return currArray;
    }

    const getCurrencies = async () => {
        const response = await apiRequest("currency?$limit=500&active=1");

        if (response && response.data) {
            let currArray = config.is_eea === 1
                ? (!!refundRecipient ? REFUND_EEA_EXCLUDED_CCY : EEA_EXCLUDED_CCY)
                : (!!refundRecipient ? REFUND_NON_EEA_EXCLUDED_CCY : NON_EEA_EXCLUDED_CCY);
            // @ This logic will be used when we move Exclusion logic to backend
            // let eeaRestrictedCurrencies = config.is_eea === 1 ? getUKOrEEACurrencies(false) : getUKOrEEACurrencies(true);
            // currArray = currArray.filter(currency => !eeaRestrictedCurrencies.includes(currency));
            currArray = CurrencyRuleForLocalINR(currArray);
            let cur = response.data
                .filter((data) => (!currArray.includes(data.currency)))
                .sort((a, b) => a.currency.localeCompare(b.currency));

            if (handleNewRecipient && selectedRecipients && selectedRecipients.length > 0) {
                cur = cur.filter((data) => data.currency === selectedRecipients[0].currency);
            }
            cur = cur.map((val, key) => {
                val.key = key;
                val.flag = FLAGS[val.currency];
                val.label = val.title + ' (' + val.currency + ')';
                val.name = val.currency;
                val.value = val.currency;
                return val;
            });
            let commonCur = cur
                .filter((data) => data.sort_order_buy !== 999)
                .sort((a, b) => a.sort_order_buy - b.sort_order_buy);

            if (handleNewRecipient && selectedRecipients && selectedRecipients.length > 0) {
                commonCur = [];
            }
            setState({ currencies: cur, commonCurrencies: commonCur });
        }
    };

    const getTccBeneficiariesRequiredDetails = async () => {
        let requestUrl = 'tcc/beneficiary/required/details?currency=';
        if (recipientCurrency) {
            requestUrl += recipientCurrency;
        }
        if (bankCountry) {
            requestUrl += '&bank_account_country=' + bankCountry;
        }
        setState({ loading: true });
        const beneficiaryDetails = await apiRequest(requestUrl);
        let bankInput = [];
        let ibanTemp = beneficiaryDetails.data.details.filter(function (obj) {
            return obj.hasOwnProperty('iban');
        });
        let hasIbanTemp = ibanTemp.length > 0;
        if (hasIbanTemp) {
            bankInput = [...bankInput, {
                label: 'IBAN',
                name: 'iban',
                placeholder: 'Enter IBAN Number',
                regex: ibanTemp[0]['iban']
            }];
        }
        let swiftTemp = beneficiaryDetails.data.details.filter(function (obj) {
            return obj.hasOwnProperty('bic_swift');
        });
        let hasSwiftTemp = swiftTemp.length > 0;
        if (hasSwiftTemp) {
            bankInput = [...bankInput, {
                label: 'SWIFT Code/BIC',
                name: 'swift',
                placeholder: 'Enter SWIFT Code/BIC',
                regex: swiftTemp[0]['bic_swift']
            }];
        }
        let accNoTemp = beneficiaryDetails.data.details.filter(function (obj) {
            return obj.hasOwnProperty('acct_number');
        });
        let hasAccNoTemp = accNoTemp.length > 0;
        if (hasAccNoTemp) {
            bankInput = [...bankInput, {
                label: 'Account Number',
                name: 'accNumber',
                placeholder: 'Enter Account Number',
                regex: accNoTemp[0]['acct_number']
            }];
        }
        let sortCodeTemp = beneficiaryDetails.data.details.filter(function (obj) {
            return obj.hasOwnProperty('sort_code');
        });
        let hasSortCodeTemp = sortCodeTemp.length > 0;
        if (hasSortCodeTemp) {
            bankInput = [...bankInput, {
                label: 'Sort Code',
                name: 'sortCode',
                placeholder: 'Enter Sort Code',
                regex: sortCodeTemp[0]['sort_code']
            }];
        }
        let abaTemp = beneficiaryDetails.data.details.filter(function (obj) {
            return obj.hasOwnProperty('aba');
        });
        let hasAbaTemp = abaTemp.length > 0;
        if (hasAbaTemp) {
            bankInput = [...bankInput, {
                label: 'ABA',
                name: 'aba',
                placeholder: 'Enter ABA',
                regex: abaTemp[0]['aba']
            }];
            setState({ hasAba: hasAbaTemp });
        } else {
            setState({ hasAba: false });
        }
        let cnapsTemp = beneficiaryDetails.data.details.filter(function (obj) {
            return obj.hasOwnProperty('cnaps');
        });
        if (cnapsTemp?.length > 0) {
            bankInput = [...bankInput, {
                label: 'CNAPS Code',
                name: 'cnaps',
                placeholder: 'Enter CNAPS Number',
                regex: cnapsTemp[0]['cnaps']
            }];
            setState({ hasCnaps: true });
        } else {
            setState({ hasCnaps: false });
        }
        let bsbTemp = beneficiaryDetails.data.details.filter(function (obj) {
            return obj.hasOwnProperty('bsb_code');
        });
        let hasBsbTemp = bsbTemp.length > 0;
        if (hasBsbTemp) {
            bankInput = [...bankInput, {
                label: 'Bsb',
                name: 'bsb',
                placeholder: 'Enter Bsb',
                regex: bsbTemp[0]['bsb_code']
            }];
            setState({ hasBsb: hasBsbTemp });
        } else {
            setState({ hasBsb: false });
        }
        let clabeTemp = beneficiaryDetails.data.details.filter(function (obj) {
            return obj.hasOwnProperty('clabe');
        });
        let hasClabeTemp = clabeTemp.length > 0;
        if (hasClabeTemp) {
            bankInput = [...bankInput, {
                label: 'CLABE',
                name: 'clabe',
                placeholder: 'Enter CLABE',
                regex: clabeTemp[0]['clabe']
            }];
            bankInput = bankInput.filter((item) => item.name !== "accNumber")
            setState({ hasClabe: hasClabeTemp });
        } else {
            setState({ hasClabe: false });
        }

        let ifscTemp = beneficiaryDetails.data.details.filter(function (obj) {
            return obj.hasOwnProperty('ifsc');
        });
        let hasIfscTemp = ifscTemp.length > 0;
        if (hasIfscTemp) {
            bankInput = [...bankInput, {
                label: 'IFSC',
                name: 'ifsc',
                placeholder: 'Enter IFSC',
                regex: ifscTemp[0]['ifsc']
            }];
            setState({ hasIFSC: hasIfscTemp });
        } else {
            setState({ hasIFSC: false });
        }

        let bankCodeTemp = beneficiaryDetails.data.details.filter(function (obj) {
            return obj.hasOwnProperty('bank_code');
        });
        let hasBankCodeTemp = bankCodeTemp.length > 0;
        if (hasBankCodeTemp) {
            bankInput = [...bankInput, {
                label: 'Bank Code',
                name: 'bank_code',
                placeholder: 'Enter Bank Code',
                regex: bankCodeTemp[0]['bank_code']
            }];
            setState({ hasBankCode: hasBankCodeTemp });
        } else {
            setState({ hasBankCode: false });
        }
        let branchCodeTemp = beneficiaryDetails.data.details.filter(function (obj) {
            return obj.hasOwnProperty('branch_code');
        });
        let hasBranchCodeTemp = branchCodeTemp.length > 0;
        if (hasBranchCodeTemp) {
            bankInput = [...bankInput, {
                label: 'Branch Code',
                name: 'branch_code',
                placeholder: 'Enter Branch Code',
                regex: branchCodeTemp[0]['branch_code']
            }];
            setState({ hasBranchCode: hasBranchCodeTemp });
        } else {
            setState({ hasBranchCode: false });
        }

        if ((bankCountry === 'DK' && recipientCurrency === 'DKK') || (bankCountry === 'NO' && recipientCurrency === 'NOK') || (bankCountry === 'SE' && recipientCurrency === 'SEK')) {
            setState({ postCodeRequired: true })
        }

        setState({
            bankLabel: 'International Account Details',
            hasIban: hasIbanTemp,
            loading: false,
            bankInput,
            beneficiaryDetails,
        });
    }

    const validateBank = debounce(async (value, type = "iban") => {
        setState({ showBankDetails: true, bankDetailsLoading: true });
        let data = {};
        if (value !== "") {
            data.identifier_value = value.replace(/-|\s/g, "");
            data.identifier_type = type;
        }
        const validateResponse = await apiRequest("tcc/beneficiary/validate-iban", "POST", data);
        if (validateResponse.data && validateResponse.data.response) {
            setState({
                bankDetailsName: validateResponse.data.response.response.bank_name,
                bankDetailsAddress1: validateResponse.data.response.response.bank_address,
                bankDetailsAddress2: validateResponse.data.response.response.bank_city,
                bankDetailsPostcode: validateResponse.data.response.response.bank_post_code,
                bankDetailsCountry: validateResponse.data.response.response.bank_country,
                bankDetailsIdentifierValue: validateResponse.data.response.response.identifier_value,
                bankDetailsIdentifierType: validateResponse.data.response.response.identifier_type,
                bankDetailsSwift: validateResponse.data.response.response.bic_swift,
                bankDetailsAccountNumber: validateResponse.data.response.response.account_number,
                bankDetailsLoading: false,
                disableSubmit: false,
                bankDetailsError: false
            });
        } else {
            const Error = convertToJavaScriptObject(validateResponse.response.data.error.details);

            [1301].indexOf(validateResponse.response?.data?.error?.error_number) === -1 &&
                notification.error({
                    message: 'Error Code: ' + validateResponse.response?.data?.error?.error_number,
                    description: Error?.message?.length > 0 ?
                        <Space direction="vertical" size={0}>
                            {Error?.message?.map(message => <>{message}</>)}
                        </Space> :
                        "Something Went Wrong While Fetching Bank Details, Please Check The Details Provided",
                    placement: 'top'
                });

            setState({
                disableSubmit: false,
                bankDetailsLoading: false,
                bankDetailsError: true
            });
        }
    }, 1000);

    function getPaymentTypes() {
        let hasRegularTemp = false;
        let hasPriotiryTemp = false;

        let isNativeCorridor = false;
        //Check for Native corridors
        if (recipientCurrency === "USD" && bankCountry === "US") {
            if (form.getFieldValue("accNumber") && form.getFieldValue("accNumber") !== "" && ((form.getFieldValue("swift") && form.getFieldValue("swift") !== "") || (form.getFieldValue("aba") && form.getFieldValue("aba") !== ""))) {
                hasPriotiryTemp = true;
            }
            if (form.getFieldValue("accNumber") && form.getFieldValue("accNumber") !== "" && form.getFieldValue("aba") && form.getFieldValue("aba") !== "") {
                hasRegularTemp = true;
            }
            isNativeCorridor = true;
        }
        if (recipientCurrency === "GBP" && bankCountry === "GB") {
            if (form.getFieldValue("iban") && form.getFieldValue("iban") !== "" && form.getFieldValue("swift") && form.getFieldValue("swift") !== "") {
                hasPriotiryTemp = true;
            }
            if (form.getFieldValue("accNumber") && form.getFieldValue("accNumber") !== "" && form.getFieldValue("sortCode") && form.getFieldValue("sortCode") !== "") {
                hasRegularTemp = true;
            }
            isNativeCorridor = true;
        }
        if (recipientCurrency === "AUD" && bankCountry === "AU") {
            if (form.getFieldValue("accNumber") && form.getFieldValue("accNumber") !== "" && form.getFieldValue("swift") && form.getFieldValue("swift") !== "") {
                hasPriotiryTemp = true;
            }
            if (form.getFieldValue("accNumber") && form.getFieldValue("accNumber") !== "" && form.getFieldValue("bsb") && form.getFieldValue("bsb") !== "") {
                hasRegularTemp = true;
            }
            isNativeCorridor = true;
        }
        if ((recipientCurrency === "SEK" && bankCountry === "SE") || (recipientCurrency === "NOK" && bankCountry === "NO") || (recipientCurrency === "DKK" && bankCountry === "DK")) {
            if (form.getFieldValue("iban") && form.getFieldValue("iban") !== "" && form.getFieldValue("swift") && form.getFieldValue("swift") !== "") {
                hasPriotiryTemp = true;
            }
            if (form.getFieldValue("accNumber") && form.getFieldValue("accNumber") !== "" && form.getFieldValue("bank_code") && form.getFieldValue("bank_code") !== "") {
                hasRegularTemp = true;
            }
            isNativeCorridor = true;
        }

        if (recipientCurrency === "CAD" && bankCountry === "CA") {
            if (form.getFieldValue("accNumber") && form.getFieldValue("accNumber") !== "" && form.getFieldValue("swift") && form.getFieldValue("swift") !== "") {
                hasPriotiryTemp = true;
            }
            isNativeCorridor = true;
        }

        if (!isNativeCorridor) {
            hasRegularTemp = beneficiaryDetails.data.details.filter(function (obj) {
                return obj.payment_type === "regular";
            }).length > 0;
            hasPriotiryTemp = beneficiaryDetails.data.details.filter(function (obj) {
                return obj.payment_type === "priority";
            }).length > 0;
        }

        currencyRules.forEach(item => {
            if (item?.upper_limit_ccy === recipientCurrency) {
                hasRegularTemp = JSON.parse(item?.type_of_payment || '[]').includes("regular")
                hasPriotiryTemp = JSON.parse(item?.type_of_payment || '[]').includes("priority")
            }
        });

        if ((recipientCurrency === "SGD" && bankCountry !== "SG") || (recipientCurrency === "TRY" && bankCountry !== "TR")) {
            hasRegularTemp = true;
            hasPriotiryTemp = true;
        }

        let paymentTypes = [];
        if (hasRegularTemp) {
            paymentTypes = ["regular"];
        }
        if (hasPriotiryTemp) {
            paymentTypes = ["priority"];
        }
        if (hasRegularTemp && hasPriotiryTemp) {
            paymentTypes = ["regular", "priority"];
        }

        // big confusion here, with state vs local var
        setState({ paymentTypes });
        return paymentTypes;
    }

    function convertToJavaScriptObject(inputString) {
        const lines = inputString?.split('\n');
        const resultObject = {};

        for (const line of lines) {
            if (line.includes("=>")) {
                const [key, value] = line.split("=>").map(part => part.trim().replace(/\[|\]/g, ''));
                if (resultObject[key]) {
                    resultObject[key].push(value)
                } else {
                    resultObject[key] = [value];
                }

            }
        }

        return resultObject;
    }

    const resetData = async () => {
        setState({
            recipientType: undefined,
            recipientEmail: undefined,
            recipientIban: undefined,
            recipientSwift: undefined,
            recipientSortCode: undefined,
            recipientAcc: undefined,
            accountLocal: false,
            paymentPurpose: undefined,
            recipientCountry: undefined,
            hasIban: false,
            bankDetailsLoading: false,
            bankDetailsName: undefined,
            bankDetailsAddress1: undefined,
            bankDetailsAddress2: undefined,
            bankDetailsPostcode: undefined,
            bankDetailsCountry: undefined,
            bankDetailsIdentifierValue: undefined,
            bankDetailsIdentifierType: undefined,
            bankDetailsSwift: undefined,
            bankDetailsSortCode: undefined,
            bankDetailsAccountNumber: undefined,
            bankDetailsAba: undefined,
            disableSubmit: true,
            submitLoading: false,
            showBankDetails: false,
            loading: false,
            bankLabel: undefined,
            bankInput: [],
            paymentTypes: [],
            hasAba: false,
            hasBsb: false,
            hasClabe: false,
            hasCnaps: false,
            modalOpen: false,
            hasBankCode: false,
            hasBranchCode: false,
            last_inserted_id: undefined
        });

        form.setFieldValue('address', "");
        form.setFieldValue('beneficiaryCity', "");
        form.setFieldValue('cnaps', undefined);
        form.setFieldValue('crossBorderPurposeCode', undefined);
    }

    const hasCrossBorderPaymentPurposeCode = () => {
        if (ALLOWED_COUNTRIES_ALL_CURRENCIES_PAYMENT_PURPOSE_CODE.includes(bankCountry)) {
            return true;
        } else if (ALLOWED_COUNTRIES_LOCAL_CURRENCY_PAYMENT_PURPOSE_CODE.includes(`${bankCountry}_${recipientCurrency}`)) {
            return true;
        }
        return false;
    }

    // Update payment reason
    // based on cross border purpose codes
    // if any or selected payment reason
    const getPaymentReason = () => {
        if (hasCrossBorderPaymentPurposeCode()) {
            const pcode = form.getFieldValue("crossBorderPurposeCode").replace(/-|\s/g, "");
            return crossBorderPurposeCodes?.filter((c) => c.code === pcode)?.[0]?.label || 'Other transfers';
        } else {
            return paymentPurpose !== 'Other' ? paymentPurpose : paymentPurposeOther;
        }
    }

    const submitRecipient = async (recipient) => {
        setState({ submitLoading: true });
        let data = {};
        data.bank_country = form.getFieldValue("recipientBankCountry");
        data.currency = form.getFieldValue("recipientCurrency");
        data.beneficiary_address = form.getFieldValue("address");
        data.beneficiary_entity_type = recipientType;
        data.beneficiary_city = form.getFieldValue("beneficiaryCity");
        data.payment_types = getPaymentTypes();
        data.payment_reason = getPaymentReason();

        if (recipientType === 'company') {
            data.business_nature = recipient?.businessNature;
            data.company_website = recipient?.businessWebsite;
        }
        if (recipientType === 'individual') {
            data.beneficiary_first_name = form.getFieldValue("firstName");
            data.beneficiary_last_name = form.getFieldValue("lastName");
        }
        if (recipientType === 'company' && form.getFieldValue("businessName") && form.getFieldValue("businessName") !== "") {
            data.beneficiary_company_name = form.getFieldValue("businessName");
        }

        if (form.getFieldValue("iban") && form.getFieldValue("iban") !== "") {
            data.iban = form.getFieldValue("iban").replace(/-|\s/g, "");
        }
        if (form.getFieldValue("swift") && form.getFieldValue("swift") !== "") {
            data.bic_swift = form.getFieldValue("swift").replace(/-|\s/g, "");
        }
        if (form.getFieldValue("sortCode") && form.getFieldValue("sortCode") !== "") {
            data.routing_code_type_1 = "sort_code";
            data.routing_code_value_1 = form.getFieldValue("sortCode").replace(/-|\s/g, "");
        }
        if (form.getFieldValue("cnaps") && form.getFieldValue("cnaps") !== "") {
            data.routing_code_type_1 = "cnaps";
            data.routing_code_value_1 = form.getFieldValue("cnaps")?.replace(/-|\s/g, "");
        }
        if (form.getFieldValue("aba") && form.getFieldValue("aba") !== "") {
            data.routing_code_value_2 = form.getFieldValue("aba").replace(/-|\s/g, "");
            data.routing_code_type_2 = "aba";
        }
        if (form.getFieldValue("accNumber") && form.getFieldValue("accNumber") !== "") {
            data.account_number = form.getFieldValue("accNumber").replace(/-|\s/g, "");
        }
        if (form.getFieldValue("postcode") && form.getFieldValue("postcode") !== "") {
            data.beneficiary_postcode = form.getFieldValue("postcode");
        }
        if (form.getFieldValue("state") && form.getFieldValue("state") !== "") {
            data.beneficiary_state_or_province = form.getFieldValue("state");
        }
        if (form.getFieldValue("bsb") && form.getFieldValue("bsb") !== "") {
            data.routing_code_type_2 = "bsb_code";
            data.routing_code_value_2 = form.getFieldValue("bsb").replace(/-|\s/g, "");
        }
        if (form.getFieldValue("bank_code") && form.getFieldValue("bank_code") !== "") {
            data.routing_code_type_2 = "bank_code";
            data.routing_code_value_2 = form.getFieldValue("bank_code").replace(/-|\s/g, "");
        }
        if (form.getFieldValue("branch_code") && form.getFieldValue("branch_code") !== "") {
            data.routing_code_type_1 = "branch_code";
            data.routing_code_value_1 = form.getFieldValue("branch_code").replace(/-|\s/g, "");
        }
        if (form.getFieldValue("clabe") && form.getFieldValue("clabe") !== "") {
            data.routing_code_type_1 = "clabe";
            data.routing_code_value_1 = form.getFieldValue("clabe").replace(/-|\s/g, "");
        }
        if (form.getFieldValue("ifsc") && form.getFieldValue("ifsc") !== "") {
            data.routing_code_type_1 = "ifsc";
            data.routing_code_value_1 = form.getFieldValue("ifsc").replace(/-|\s/g, "");
        }
        data.name = (data.beneficiary_first_name && data.beneficiary_last_name) ? data.beneficiary_first_name + " " + data.beneficiary_last_name : data.beneficiary_company_name;
        data.bank_account_holder_name = (data.beneficiary_first_name && data.beneficiary_last_name) ? data.beneficiary_first_name + " " + data.beneficiary_last_name : data.beneficiary_company_name;

        data.beneficiary_country = form.getFieldValue("beneficiaryCountry");
        data.email = form.getFieldValue("recipientEmail");

        if (hasCrossBorderPaymentPurposeCode()) {
            data.tcc_payment_purpose_code = form.getFieldValue("crossBorderPurposeCode")?.replace(/-|\s/g, "") || 'OTF';
        }

        let ibanMissing = false;
        const ibanRequired = (bankCountry === 'DK' && recipientCurrency === 'DKK') || (bankCountry === 'NO' && recipientCurrency === 'NOK') || (bankCountry === 'SE' && recipientCurrency === 'SEK')
        const iban = form.getFieldValue("iban") ? form.getFieldValue("iban") : '';
        ibanMissing = iban.trim() === "";
        if (ibanRequired && ibanMissing) {
            setState({ submitLoading: false });
            notification.error({ message: 'IBAN is required! Please fill in the IBAN.', placement: 'top' });
            return;
        }

        const requestPath = refundRecipient ? "tcc/refund/beneficiary/create" : "tcc/beneficiary/create";
        if (!!data?.payment_types?.length) {
            ;
            const submitResponse = await apiRequest(requestPath, "POST", data);

            if (submitResponse.response && submitResponse.response.status === 400) {
                const Error = convertToJavaScriptObject(submitResponse.response?.data?.error?.details)
                notification.error({
                    message: 'Error Code: ' + submitResponse.response?.data?.error?.error_number,
                    description: Error?.message?.length > 0 ?
                        <Space direction="vertical" size={0}>
                            {Error?.message?.map(message => <>{message}</>)}
                        </Space> :
                        "Something Went Wrong While Submitting Recipient, Please Check The Details Provided",
                    placement: 'top'
                });
            }

            setState({ submitLoading: false });
            if (submitResponse.data && submitResponse.data.last_inserted_id) {
                if(addedFromBatchPayment) {
                    notification.success({ message: 'Recipient Added Successfully!', placement: 'top' });
                }
                if (handleNewRecipient) {
                    let newRecipient = submitResponse.data.response.response;
                    newRecipient.email = submitResponse?.data?.response?.result?.email;
                    newRecipient.tcc_beneficiary_uid = newRecipient.id;
                    newRecipient.id = submitResponse.data.last_inserted_id;
                    handleNewRecipient(false, newRecipient);
                } else {
                    setState({ modalOpen: true, last_inserted_id: submitResponse.data.last_inserted_id });
                }
            }
        } else {
            notification.error({ message: 'Please make sure you fill in the necessary fields', placement: 'top' });
        }

    }

    const onBankFieldsChange = (value, key) => {
        let tempBankInput = [...bankInput];
        let temp = { ...tempBankInput[key] }
        temp.value = value
        tempBankInput[key] = temp;
        switch (tempBankInput[key].name) {
            case "iban":
                if (tempBankInput[key].value.length > 15) {
                    validateBank(tempBankInput[key].value, "iban")
                }
                break;
            case "aba":
                if (tempBankInput[key].value.length > 8) {
                    validateBank(tempBankInput[key].value, "aba")
                }
                break;
            case "bsb":
                if (tempBankInput[key].value.length > 5) {
                    validateBank(tempBankInput[key].value, "bsb_code")
                }
                break;
            case "swift":
                if (tempBankInput[key].value.length > 7) {
                    validateBank(tempBankInput[key].value, "bic_swift")
                }
                break;
            case "sortCode":
                if (tempBankInput[key].value.length > 5) {
                    validateBank(tempBankInput[key].value, "sort_code")
                }
                break;
            case "ifsc":
                if (tempBankInput[key].value.length > 5) {
                    validateBank(tempBankInput[key].value, "ifsc")
                }
                break;
            // @todo Ask Ali about his
            // case "cnaps":
            //     if (tempBankInput[key].value.length > 11) {
            //         validateBank(tempBankInput[key].value, "cnaps")
            //     }
            //     break;
            case "bank_code":
                setState({ disableSubmit: false });
                break;
            default:
                break;
        }

        const ibanRequired = (bankCountry === 'DK' && recipientCurrency === 'DKK') || (bankCountry === 'NO' && recipientCurrency === 'NOK') || (bankCountry === 'SE' && recipientCurrency === 'SEK')
        if (ibanRequired) {
            const iban = form.getFieldValue("iban") ? form.getFieldValue("iban") : '';
            const status = iban.trim() === "";
            setState({ disableSubmit: status });
        }

        setState({ bankInput: tempBankInput })
    }

    const payRecipient = async () => {
        if (last_inserted_id) {
            const fullDetails = await apiRequest("tcc/beneficiary/-" + last_inserted_id + "?$with=All");
            setnewPaymentState({ recipient: fullDetails.data, resetRecipient: false });
            navigate(URLS.NewPayment);
        }
    }

    const validateRecipientForm = () => {
        let status = false;
        if (recipientType === 'company') {
            const companyName = form.getFieldValue("businessName") ? form.getFieldValue("businessName") : '';
            const pattern = /.*[^\d].*/;
            status = companyName.trim() === '' || !pattern.test(companyName);
        } else {
            const firstName = form.getFieldValue("firstName") ? form.getFieldValue("firstName") : '';
            const lastName = form.getFieldValue("lastName") ? form.getFieldValue("lastName") : '';
            const namePattern = /^([^0-9]*)$/;
            status = firstName.trim() === "" || !namePattern.test(firstName) || lastName.trim() === "" || !namePattern.test(lastName);
        }
        if ((bankCountry === 'DK' && recipientCurrency === 'DKK') || (bankCountry === 'NO' && recipientCurrency === 'NOK') || (bankCountry === 'SE' && recipientCurrency === 'SEK')) {
            const postCode = form.getFieldValue("postcode") ? form.getFieldValue("postcode") : '';
            status = postCode.trim() === "";
        }
        setState({
            disableFormSubmitBtn: status
        });
    }

    const updateRecipientEmail = (value) => {
        setState({ recipientEmail: value });
    }

    const updatePaymentPurpose = (value) => {
        setState({ paymentPurpose: value });
    }
    const updatePaymentPurposeOther = (e) => {
        setState({ paymentPurposeOther: e.target.value });
    }
    const updateRecipientCurrency = (value) => {
        resetData();
        setState({ recipientCurrency: value });
    }
    const updateBankCountry = (value) => {
        resetData();
        setState({ bankCountry: value });
    }
    // const updateAddress = (value) => {
    //     if (form.getFieldValue("recipientBankCountry") && form.getFieldValue("recipientCurrency") && form.getFieldValue("address") && form.getFieldValue("beneficiaryCity")) {
    //         validateRecipient();
    //     }
    // }
    // const updateRecipientCity = (value) => {
    //     if (form.getFieldValue("recipientBankCountry") && form.getFieldValue("recipientCurrency") && form.getFieldValue("address") && form.getFieldValue("beneficiaryCity")) {
    //         validateRecipient();
    //     }
    // }

    const updateCrossBorderPaymentPurposeCode = (val) => {
        const pcode = PaymentPurposeCodes?.[`${bankCountry}_${recipientCurrency}`];
        setState({
            paymentPurpose: pcode?.label,
            paymentPurposeCode: val,
        });
    }

    return (
        <>
            <Modal
                open={modalOpen}
                footer={false}
                closable={false}
                width={1050}
                destroyOnClose
                bodyStyle={{ minHeight: '600px' }}
            >
                <Row justify="center" className="m-t-100">
                    <Col span={24} align="center">
                        <Space size={30} align="center" direction="vertical">
                            <FaRegCheckCircle className="m-t-90" size={120} color={colors.success} />
                            <Typography.Text style={{ color: colors.success }} className="fs-20px bold">Recipient Added</Typography.Text>
                        </Space>
                    </Col>
                </Row>
                <Row justify="center" className="m-t-100">
                    <Col span={6} align="center">
                        <Button type="primary" onClick={() => payRecipient()} >Pay This Recipient</Button>
                    </Col>
                    <Col span={6} align="center">
                        <Button type="primary" onClick={() => navigate(URLS.PaymentsDashboard)} >Return To Dashboard</Button>
                    </Col>
                    <Col span={6} align="center">
                        <Button type="primary" onClick={() => {
                            resetData();
                            setState({
                                recipientCurrency: undefined,
                                bankCountry: undefined,
                                modalOpen: false,
                            });
                            form.resetFields();
                        }} >Add New Recipient</Button>
                    </Col>
                    <Col span={6} align="center">
                        <Button type="primary" onClick={() => navigate(URLS.RecipientList)} >View My Recipients</Button>
                    </Col>
                </Row>
            </Modal>
            <Row>
                <Col span={24}>
                    <PageDescription title={(refundRecipient ? 'Refund ' : '') + 'Recipient Details'} text='- Add your recipient information' />
                </Col>
            </Row>
            <Spin size='large' spinning={loading || fetchingPaymentCodes} tip='Loading...'>
                <Row className="m-t-10" gutter={10}>
                    <Col xxl={13} xl={16} lg={21} md={24} sm={24} xs={24} >
                        <Form
                            layout='vertical'
                            form={form}
                            onFinish={submitRecipient}
                            requiredMark={false}
                        >
                            <Row gutter={24}>
                                <Col xxl={11} xl={11} lg={11} md={14} sm={18} xs={24}>

                                    <Form.Item
                                        label={<Typography.Text className="muli semi-bold fs-24px dark-green">Recipient Bank Country</Typography.Text>}
                                        name='recipientBankCountry'
                                    >
                                        <Select
                                            placeholder='Search'
                                            showSearch
                                            filterOption={(input, option) => {
                                                if (option?.children?.props?.children) {
                                                    return option.children.props.children.toLowerCase().includes(
                                                        input.toLowerCase()
                                                    );
                                                } else {
                                                    return false;
                                                }
                                            }}
                                            onChange={(val) => updateBankCountry(val)}
                                        >
                                            {commonCountries && commonCountries.length > 0 && (
                                                <Select.OptGroup label="Common">
                                                    {commonCountries.map((val, key) => (
                                                        <Select.Option value={val.code} key={key} className='p-l-10'>
                                                            <Space size={4} align="center">
                                                                {val.name}
                                                            </Space>
                                                        </Select.Option>
                                                    ))}
                                                </Select.OptGroup>
                                            )}
                                            {countries && countries.length > 0 && (
                                                <Select.OptGroup label="Other">
                                                    {countries.map((val, key) => (
                                                        <Select.Option
                                                            key={
                                                                key +
                                                                (countries
                                                                    ? countries.length
                                                                    : 0)
                                                            }
                                                            value={val.code}
                                                            className='p-l-10'
                                                        >
                                                            <Space size={4} align="center">
                                                                {val.name}
                                                            </Space>
                                                        </Select.Option>
                                                    ))}
                                                </Select.OptGroup>
                                            )}
                                        </Select>
                                    </Form.Item>
                                    {!!bankCountry &&
                                        <Form.Item
                                            label={<Typography.Text className="muli semi-bold fs-24px dark-green">{!!bankCountry && "Recipient Currency"}</Typography.Text>}
                                            name='recipientCurrency'
                                        >
                                            <Select
                                                placeholder='Search'
                                                showSearch
                                                onChange={(val) => updateRecipientCurrency(val)}
                                                filterOption={filterSelect}
                                            >
                                                {commonCurrencies && commonCurrencies.length > 0 && (
                                                    <Select.OptGroup label="Common">
                                                        {commonCurrencies.map((val, key) => (
                                                            <Select.Option value={val.currency} key={key} className='p-l-10'>
                                                                <Space size={4} align="center">
                                                                    {val.flag && (
                                                                        <Image
                                                                            src={val.flag}
                                                                            preview={false}
                                                                            width={20}
                                                                            alt={val.currency}
                                                                        />
                                                                    )}
                                                                    {val.currency}
                                                                </Space>
                                                            </Select.Option>
                                                        ))}
                                                    </Select.OptGroup>
                                                )}
                                                {currencies && currencies.length > 0 && (
                                                    <Select.OptGroup
                                                        {...((!handleNewRecipient && !selectedRecipients) &&
                                                            { label: "Other" })}>
                                                        {currencies.map((val, key) => (
                                                            <Select.Option
                                                                key={
                                                                    key +
                                                                    (commonCurrencies
                                                                        ? commonCurrencies.length
                                                                        : 0)
                                                                }
                                                                value={val.currency}
                                                                className='p-l-10'
                                                            >
                                                                <Space size={4} align="center">
                                                                    {val.flag && (
                                                                        <Image
                                                                            src={val.flag}
                                                                            preview={false}
                                                                            width={20}
                                                                            alt={val.currency}
                                                                        />
                                                                    )}
                                                                    {val.currency}
                                                                </Space>
                                                            </Select.Option>
                                                        ))}
                                                    </Select.OptGroup>
                                                )}
                                            </Select>
                                        </Form.Item>
                                    }
                                    {(bankCountry && recipientCurrency) &&
                                        <Form.Item
                                            label={<Typography.Text className="muli semi-bold fs-24px dark-green">{(bankCountry && recipientCurrency) && "Recipient Email Address"}</Typography.Text>}
                                            name='recipientEmail'
                                        >
                                            <Input placeholder="Enter Recipient Email" autoComplete="new-password" onChange={() => updateRecipientEmail} />
                                        </Form.Item>
                                    }
                                </Col>
                            </Row>
                            {(bankCountry && recipientCurrency && bankLabel) && (
                                <>
                                    <Row>
                                        <Col>
                                            <Typography.Text className="fs-28px medium dark-green">Recipient Bank Account</Typography.Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Typography.Text className="fs-24px bold dark-green">{bankLabel}</Typography.Text>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        {bankInput && bankInput.length > 0 && bankInput.map((item, key) => (
                                            <Col xxl={11} xl={11} lg={11} md={14} sm={18} xs={24} key={key}>
                                                <Form.Item
                                                    label={<Typography.Text className="muli semi-bold fs-24px dark-green">{item.label}</Typography.Text>}
                                                    name={item.name}
                                                    rules={[
                                                        {
                                                            pattern: item.regex,
                                                            message: 'Incorrect Value Format.',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder={item.placeholder} autoComplete="new-password" onBlur={(e) => onBankFieldsChange(e.target.value, key)} />
                                                </Form.Item>
                                            </Col>
                                        ))}
                                    </Row>
                                    <Row gutter={24}>
                                        <Col xxl={11} xl={11} lg={11} md={14} sm={18} xs={24}>
                                            <Space direction="vertical" size={8}>
                                                <Typography.Text className="muli semi-bold fs-24px dark-green">Recipient Type</Typography.Text>
                                                <Space size={24}>
                                                    {(config.is_eea !== 1 && recipientCurrency === 'CNY' && bankCountry === 'CN') ? null : (

                                                        <Card className="b-g" hoverable onClick={() => setRecipientType('individual')}>
                                                            <Row justify="center">
                                                                <Col>
                                                                    <FaUserAlt className={recipientType === 'individual' ? 'light-green' : ''} size={36} />
                                                                </Col>
                                                            </Row>
                                                            <Row justify="center">
                                                                <Col>
                                                                    <Typography.Text className={"muli fs-18px semi-bold " + (recipientType === 'individual' ? 'dark-green' : '')}>Individual</Typography.Text>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    )}
                                                    <Card className="b-g" hoverable onClick={() => setRecipientType('company')}>
                                                        <Row justify="center">
                                                            <Col>
                                                                <FaRegBuilding className={recipientType === 'company' ? 'light-green' : ''} size={36} />
                                                            </Col>
                                                        </Row>
                                                        <Row justify="center">
                                                            <Col>
                                                                <Typography.Text className={"muli fs-18px semi-bold " + (recipientType === 'company' ? 'dark-green' : '')}>Business</Typography.Text>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Space>
                                            </Space>
                                        </Col>
                                    </Row>
                                </>
                            )}
                            {recipientType === 'company' && (
                                <Row className="m-t-20">
                                    <Col xxl={11} xl={11} lg={11} md={14} sm={18} xs={24}>
                                        <Form.Item
                                            name='businessName'
                                            onChange={() => { validateRecipientForm() }}
                                            label={<Typography.Text className="muli semi-bold fs-24px dark-green">Business Name / Description</Typography.Text>}
                                            rules={[
                                                {
                                                    pattern: /.*[^\d].*/,
                                                    message: 'Business name must contain at least one non-numeric character.',
                                                }
                                            ]}
                                        >
                                            <Input placeholder='Enter here' />
                                        </Form.Item>
                                        <Form.Item
                                            name='businessDescription'
                                            label={<Typography.Text className="muli semi-bold fs-24px dark-green">Business Description</Typography.Text>}
                                        >
                                            <Input placeholder='Enter here' />
                                        </Form.Item>
                                        <Form.Item
                                            name='businessNature'
                                            label={
                                                <Space>
                                                    <Typography.Text className="muli semi-bold fs-24px dark-green">Business Nature</Typography.Text>
                                                    <Tooltip title="Please describe the business type, industry and products of your recipient. Providing this information upfront will help us process your payment quicker">
                                                        <FaInfoCircle style={{ marginBottom: '-5px' }} className='dark-green' />
                                                    </Tooltip>
                                                </Space>
                                            }
                                        >
                                            <Input placeholder='Enter here' />
                                        </Form.Item>
                                        <Form.Item
                                            name='businessWebsite'
                                            label={
                                                <Space>
                                                    <Typography.Text className="muli semi-bold fs-24px dark-green">Business Website</Typography.Text>
                                                    <Tooltip title="Please provide the company website of your recipient here. Providing this information upfront will help us process your payment quicker">
                                                        <FaInfoCircle style={{ marginBottom: '-5px' }} className='dark-green' />
                                                    </Tooltip>
                                                </Space>
                                            }
                                        >
                                            <Input placeholder='Enter here' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                            {recipientType === 'individual' && (
                                <Row className="m-t-20">
                                    <Col xxl={11} xl={11} lg={11} md={14} sm={18} xs={24}>
                                        <Form.Item
                                            name='firstName'
                                            onChange={() => { validateRecipientForm() }}
                                            label={<Typography.Text className="muli semi-bold fs-24px dark-green">First Name</Typography.Text>}
                                            rules={[
                                                {
                                                    pattern: /^[^0-9]+$/,
                                                    message: 'First name cannot contain numbers.',
                                                },
                                                {
                                                    min: 2,
                                                    message: 'First name should have more than one character.'
                                                },
                                                {
                                                    required: true,
                                                    message: 'Please enter first name.'
                                                },
                                                {
                                                    pattern: /^[^.]+$/,
                                                    message: 'First name cannot contain initials.'
                                                },
                                                {
                                                    pattern: /^(?!.*\b(?:Ltd|Limited|PLC|LLC)\b).*$/i,
                                                    message: 'First name cannot contain signifiers.'
                                                }
                                            ]}
                                        >
                                            <Input placeholder='Enter here' />
                                        </Form.Item>
                                        <Form.Item
                                            name='lastName'
                                            onChange={() => { validateRecipientForm() }}
                                            label={<Typography.Text className="muli semi-bold fs-24px dark-green">Last Name</Typography.Text>}
                                            rules={[
                                                {
                                                    pattern: /^[^0-9]+$/,
                                                    message: 'Last name cannot contain numbers.',
                                                },
                                                {
                                                    min: 2,
                                                    message: 'Last name should have more than one character.'
                                                },
                                                {
                                                    required: true,
                                                    message: 'Please enter last name.'
                                                },
                                                {
                                                    pattern: /^[^.]+$/,
                                                    message: 'Last name cannot contain initials.'
                                                },
                                                {
                                                    pattern: /^(?!.*\b(?:Ltd|Limited|PLC|LLC)\b).*$/i,
                                                    message: 'Last name cannot contain signifiers.'
                                                }
                                            ]}
                                        >
                                            <Input placeholder='Enter here' />
                                        </Form.Item>
                                        <Form.Item
                                            name='descriprion'
                                            label={<Typography.Text className="muli semi-bold fs-24px dark-green">Recipient Nickname / Description</Typography.Text>}
                                        >
                                            <Input placeholder='Enter here' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                            {(recipientType === 'company' || recipientType === 'individual') && (
                                <>
                                    {(hasAba || hasBsb || postCodeRequired || ((bankCountry === "MX") || (bankCountry === "CA") || (bankCountry === 'US'))) && <Row align="bottom" gutter={16}>
                                        <Col xxl={11} xl={11} lg={11} md={14} sm={18} xs={24}>
                                            <Form.Item
                                                name='postcode'
                                                onChange={() => { validateRecipientForm() }}
                                                label={<Typography.Text className="muli semi-bold fs-24px dark-green">Postcode</Typography.Text>}
                                            >
                                                <Input placeholder='Enter here' />
                                            </Form.Item>
                                            {(hasAba || (bankCountry === "MX") || (bankCountry === "CA") || (bankCountry === 'US')) &&
                                                <Form.Item
                                                    name='state'
                                                    label={<Typography.Text className="muli semi-bold fs-24px dark-green">State</Typography.Text>}
                                                    rules={[
                                                        {
                                                            pattern: /^(?=.*[a-zA-Z])[a-zA-Z0-9 ]+$/,
                                                            message: 'Invalid city'
                                                        }
                                                    ]}
                                                >
                                                    <Input placeholder='Enter here' />
                                                </Form.Item>
                                            }
                                        </Col>
                                    </Row>}
                                    <Row align="bottom" gutter={16}>
                                        <Col xxl={11} xl={11} lg={11} md={14} sm={18} xs={24}>
                                            <Form.Item
                                                name='address'
                                                label={<Typography.Text className="muli semi-bold fs-24px dark-green">Address</Typography.Text>}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter address'
                                                    },
                                                    {
                                                        pattern: /^(?=.*[a-zA-Z/\-?:().,'+])[a-zA-Z0-9 /\-?:().,'+]+$/,
                                                        message: 'Invalid Address'
                                                    }
                                                ]}
                                            >
                                                <Input placeholder='Enter here' />
                                            </Form.Item>
                                            <Form.Item
                                                name='beneficiaryCity'
                                                label={<Typography.Text className="muli semi-bold fs-24px dark-green">City</Typography.Text>}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter city'
                                                    },
                                                    {
                                                        pattern: /^(?=.*[a-zA-Z])[a-zA-Z0-9 ]+$/,
                                                        message: 'Invalid city'
                                                    }
                                                ]}
                                            >
                                                <Input placeholder='Enter here' />
                                            </Form.Item>
                                            <Form.Item
                                                label={<Typography.Text className="muli semi-bold fs-24px dark-green">Recipient Country</Typography.Text>}
                                                name='beneficiaryCountry'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select recipient country'
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    placeholder='Search'
                                                    showSearch
                                                    filterOption={(input, option) => {
                                                        if (option?.children?.props?.children) {
                                                            return option.children.props.children.toLowerCase().includes(
                                                                input.toLowerCase()
                                                            );
                                                        } else {
                                                            return false;
                                                        }
                                                    }}
                                                >
                                                    {commonCountries && commonCountries.length > 0 && (
                                                        <Select.OptGroup label="Common">
                                                            {commonCountries.map((val, key) => (
                                                                <Select.Option value={val.code} key={key} className='p-l-10'>
                                                                    <Space size={4} align="center">
                                                                        {val.name}
                                                                    </Space>
                                                                </Select.Option>
                                                            ))}
                                                        </Select.OptGroup>
                                                    )}
                                                    {countries && countries.length > 0 && (
                                                        <Select.OptGroup label="Other">
                                                            {countries.map((val, key) => (
                                                                <Select.Option
                                                                    key={
                                                                        key +
                                                                        (countries
                                                                            ? countries.length
                                                                            : 0)
                                                                    }
                                                                    value={val.code}
                                                                    className='p-l-10'
                                                                >
                                                                    <Space size={4} align="center">
                                                                        {val.name}
                                                                    </Space>
                                                                </Select.Option>
                                                            ))}
                                                        </Select.OptGroup>
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            {/* <Button type="link" className="m-b-25 p-0"><span className="underline">Enter address manually</span></Button> */}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xxl={11} xl={11} lg={11} md={14} sm={18} xs={24}>
                                            {hasCrossBorderPaymentPurposeCode() ? (
                                                <Form.Item
                                                    {...((bankCountry === 'CN' && recipientCurrency === 'CNY') ? { initialValue: crossBorderPurposeCodes?.[0]?.code } : {})}
                                                    name='crossBorderPurposeCode'
                                                    label={<Typography.Text className="muli semi-bold fs-24px dark-green">Payment Purpose</Typography.Text>}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please select Payment Purpose',
                                                        }]}
                                                >
                                                    <Select placeholder='Search' onChange={(val) => updateCrossBorderPaymentPurposeCode(val)}>
                                                        {crossBorderPurposeCodes?.map((pcode, idx) => (
                                                            <Select.Option key={pcode.code} value={pcode.code}>
                                                                {pcode.label}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            ) : (
                                                <>
                                                    <Form.Item
                                                        name='paymentPurpose'
                                                        label={<Typography.Text className="muli semi-bold fs-24px dark-green">Payment Purpose</Typography.Text>}
                                                    >
                                                        <Select placeholder='Search' onChange={(val) => updatePaymentPurpose(val)}>
                                                            {PAYMENT_REASONS.map((reas, idx) => (
                                                                <Select.Option key={idx} value={reas.value}>
                                                                    {reas.label}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                    {paymentPurpose === "Other" &&
                                                        <Form.Item
                                                            name='paymentPurposeOther'
                                                        >
                                                            <Input placeholder='Other Reason' onKeyUp={debounce(updatePaymentPurposeOther, 1000)} />
                                                        </Form.Item>
                                                    }
                                                </>
                                            )}
                                            <Form.Item
                                                name='submit'
                                            >
                                                <Button type="primary" htmlType="submit" disabled={disableSubmit || disableFormSubmitBtn} loading={submitLoading}>Add Recipient</Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Form>
                    </Col >

                    {showBankDetails &&
                        <Col xxl={{ span: 6, offset: 5 }} xl={8} lg={10} md={13} sm={17} xs={24} >
                            <Card loading={bankDetailsLoading}>
                                <Space direction="vertical">
                                    <Space>
                                        <Typography.Text className="fs-28px medium dark-green">Bank Detail Summary</Typography.Text>
                                        <Tooltip title="This is a summary of your recipient's bank account details. It's important that you ensure these details are correct for payments to be processed correctly."><FaInfoCircle className='dark-green' /></Tooltip>
                                    </Space>
                                    {bankDetailsError ?
                                        <Typography.Text className="muli fs-24px light">
                                            We have not been able to identify a match for the Recipient Bank Details you have entered. You can proceed to add this Recipient, however please ensure the details are correct to minimise the risk of payments to this Recipient being returned.
                                        </Typography.Text>
                                        :
                                        <>
                                            <Space direction="vertical" size={0}>
                                                <Typography.Text className="muli fs-24px light">Name</Typography.Text>
                                                <Typography.Text className="muli fs-24px semi-bold dark-green">{bankDetailsName}</Typography.Text>
                                            </Space>
                                            <Space direction="vertical" size={0}>
                                                <Typography.Text className="muli fs-24px light">Address</Typography.Text>
                                                <Typography.Text className="muli fs-24px semi-bold dark-green">{bankDetailsAddress1 ?? "-"}</Typography.Text>
                                                <Typography.Text className="muli fs-24px semi-bold dark-green">{bankDetailsAddress2 ?? "-"}</Typography.Text>
                                                <Typography.Text className="muli fs-24px semi-bold dark-green">{bankDetailsPostcode ?? "-"}</Typography.Text>
                                                <Typography.Text className="muli fs-24px semi-bold dark-green">{bankDetailsCountry ?? "-"}</Typography.Text>
                                            </Space>
                                            {bankDetailsIdentifierValue && <Space direction="vertical" size={0}>
                                                <Typography.Text className="muli fs-24px semi-bold">Bank Account</Typography.Text>
                                                <Typography.Text className="muli fs-24px light">{bankDetailsIdentifierType ? bankDetailsIdentifierType.toUpperCase() : "-"}</Typography.Text>
                                                <Typography.Text className="muli fs-24px semi-bold dark-green">{bankDetailsIdentifierValue ?? "-"}</Typography.Text>
                                            </Space>}
                                            {(bankDetailsSwift && bankDetailsIdentifierType.toUpperCase() !== "BIC_SWIFT") && <Space direction="vertical" size={0}>
                                                <Typography.Text className="muli fs-24px light">SWIFT Code/BIC</Typography.Text>
                                                <Typography.Text className="muli fs-24px semi-bold dark-green">{bankDetailsSwift ?? "-"}</Typography.Text>
                                            </Space>}
                                            {bankDetailsSortCode && <Space direction="vertical" size={0}>
                                                <Typography.Text className="muli fs-24px light">Sort Code</Typography.Text>
                                                <Typography.Text className="muli fs-24px semi-bold dark-green">{bankDetailsSortCode ?? "-"}</Typography.Text>
                                            </Space>}
                                            {/* {bankDetailsAccountNumber && <Space direction="vertical" size={0}>
                                        <Typography.Text className="muli fs-24px light">Account Number</Typography.Text>
                                        <Typography.Text className="muli fs-24px semi-bold dark-green">{bankDetailsAccountNumber ?? "-"}</Typography.Text>
                                    </Space>} */}

                                            {bankDetailsAba && <Space direction="vertical" size={0}>
                                                <Typography.Text className="muli fs-24px light">ABA</Typography.Text>
                                                <Typography.Text className="muli fs-24px semi-bold dark-green">{bankDetailsAba ?? "-"}</Typography.Text>
                                            </Space>}
                                        </>
                                    }


                                    <Button type="primary" ghost onClick={() => {
                                        resetData();
                                        setState({
                                            recipientCurrency: undefined,
                                            bankCountry: undefined,
                                        });
                                        form.resetFields();
                                    }}>Reset</Button>
                                </Space>
                            </Card>
                        </Col>
                    }
                </Row >
            </Spin>
        </>
    )
}

export default AddRecipient;
