import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { notification } from "antd";

function useRefreshTransactionsQuery() {
  const apiRequest = useApiRequest();

  const fetchQuery = async () => {
    const refreshRes = await apiRequest(`${APIROUTES.post.refresh_transactions_data}`, 'POST');
    if (refreshRes) {
      return refreshRes;
    } else {
      notification.error({ message: "Error", description: 'Something went wrong while refreshing transactions', placement: 'top' });
      return false
    }
  }

  const query = useQuery({
    queryKey: [`_refresh_transactions_data`],
    queryFn: fetchQuery
  });

  return query;
}

export { useRefreshTransactionsQuery };