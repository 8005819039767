import { Row, Col, Spin } from "antd";

function Spinner({ full = false }) {
    return (
        <Row align="middle" justify="center" style={{height: `100${full ? 'vh' : '%'}`, width: '100%'}}>
            <Col>
                <Spin size="large" />
            </Col>
        </Row>

    );
}

export default Spinner;