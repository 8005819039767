import { Button, Col, Row, Typography } from "antd"
import { useNavigate } from "react-router-dom"
import URLS from "Routes/constants"

function CardActivated({ onCancel }) {
  const navigate = useNavigate()
  return (
    <>
      <Row>
        <Col>
          <Typography.Text className="muli semi-bold fs-18px dark-green">Card Activated</Typography.Text>
        </Col>
      </Row>
      <Row className="m-t-30" justify='center'>
        <Col>
          <Typography.Text className="muli fs-16px semi-bold dark-green">
            Card successfully activated
          </Typography.Text>
        </Col>
      </Row>
      <Row className="m-t-20" justify="space-around">
        <Col>
          <Button type="primary" danger onClick={() => navigate(URLS.Cards)}>Back</Button>
        </Col>
        <Col>
          <Button type="primary" onClick={onCancel}>Manage Card</Button>
        </Col>
      </Row>
    </>
  )
}

export default CardActivated