import { useQuery } from "@tanstack/react-query";
import useReportsApiRequest from "Hooks/ReportsAPI";
import { REPORTS_ROUTES } from "Constants/ApiRoutes";
import { useStatementsReportsState } from "Hooks/Store";
import moment from "moment";

const useStatementsAndReportsQuery = (enabled = true) => {
    const apiRequest = useReportsApiRequest();
	const selectedFromMonth = useStatementsReportsState(s => s.selectedFromMonth);
	const selectedToMonth = useStatementsReportsState(s => s.selectedToMonth);
	const selectedFilter = useStatementsReportsState(s => s.selectedFilter);
	const currentPage = useStatementsReportsState(s => s.currentPage);
	const limit = useStatementsReportsState(s => s.limit);

    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
		// const qString = [
		// 	`limit=${limit}`,
		// 	`offset=${currentPage-1}`
		// ];

		const qString = [];

		if(selectedFilter) {
			if(selectedFilter === 'month' && selectedFromMonth && selectedToMonth) {
				const toDate = moment(`${selectedToMonth.datestring}-01`).format('YYYY-MM-DD');
				qString.push(`from=${selectedFromMonth.datestring}-01&to=${toDate}`);
			} else {
				qString.push(`report_type=${selectedFilter}`);
			}
		}

        const data = await apiRequest({ path: `${REPORTS_ROUTES.get.get_client_reports}?${qString.join('&')}` });
        return data;
    }

    // eslint-disable-next-line no-unused-vars
    const _fetchQuery = async () => {
        return new Promise(resolve => {
            setTimeout(resolve([]), 1200);
        });
    }

    //
    const query = useQuery({
        queryKey: ['_get_all_reports+paged', currentPage, limit],
        queryFn: fetchQuery,
		enabled,
    });

    return query;
}

export { useStatementsAndReportsQuery };
