import create from "zustand";

export const useBulkDailyLimitsState = create((set, get) => ({
	selectedCards: [],
	atmAmount: '',
	posAmount: '',
	loading: false,
	setState: (data) => set(data),
	reset: () => set({
		selectedCards: [],
		atmAmount: '',
		posAmount: '',
		loading: false,
	}),
}));

// export const useBulkDailyLimitsState = () => {
// 	const cards = bulkDailyLimitsState(s => s.cards);
// 	const selectedCards = bulkDailyLimitsState(s => s.selectedCards);
// 	const currentCard = bulkDailyLimitsState(s => s.currentCard);
// 	const setState = bulkDailyLimitsState(s => s.setState);

// 	return {
// 		cards,
// 		selectedCards,
// 		currentCard,
// 		setState,
// 	}
// }
