import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useDisabledPaymentTypesQuery = (cardId) => {
    const apiRequest = useApiRequest();

    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
        // max_transaction_type_id 1 - pos, 2 - atm
        const { data } = await apiRequest(`${APIROUTES.prepaidcard_disabledtxtype}?prepaidCardId=${cardId}`);
        return data;
    }

    // eslint-disable-next-line no-unused-vars
    const _fetchQuery = async () => {
        return new Promise(resolve => {
            setTimeout(resolve([]), 1200);
        });
    }

    const query = useQuery({
        queryKey: ['_prepaidcard_disabledtxtype_by_card_id', cardId],
        queryFn: fetchQuery,
        enabled: !!cardId,
    });

    return query;
}

export { useDisabledPaymentTypesQuery };