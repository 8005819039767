import { Button, Select, Col, Row, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useSelectSearch } from "Hooks/Search";

function MappingRow({
    transactionCategories = [],
    transactionCategory = null,
    codatCategories = [],
    codatCategory = null,
    taxRates = [],
    taxRate = null,
    loading = true,
    mapId,
    onChange = () => {},
    onDelete = () => {}
}) {
    const filterSelect = useSelectSearch();
    const handleOnChange = (key, val) => {
        onChange({key, val, mapId});
    }

    return (
        <Row gutter={16} >
            <Col span={7} className="m-t-10">
                <Row gutter={11} align='middle'>
                    <Col span={23}>
                        <Select
                            status={!transactionCategory ? 'error' : null}
                            loading={loading}
                            size="large"
                            defaultValue={transactionCategory}
                            placeholder="Transaction Category"
                            name="transaction_category_id"
                            onChange={(val) => handleOnChange('transaction_category_id', val)}
                            className="dark-green select-b-g full-percent-width"
                            showSearch
                            filterOption={filterSelect}
                        >
                            {transactionCategories.length &&
								transactionCategories.map(cat =>
									<Select.Option key={cat.id} value={cat.id}>
										{cat.description}
									</Select.Option>
                            )}
                        </Select>
                    </Col>
                    <Col span={1}>
                        <Typography.Text className="bold fs-18px">=</Typography.Text>
                    </Col>
                </Row>
            </Col>
            <Col span={7} className="m-t-10">
                <Row>
                    <Select
                        loading={loading}
                        size="large"
                        status={!codatCategory ? 'error' : null}
                        defaultValue={codatCategory}
                        placeholder="Select Category"
                        name="codat_category_id"
                        onChange={(val) => handleOnChange('codat_category_id', val)}
                        className="dark-green select-b-g full-percent-width"
                    >
                        {codatCategories?.length && codatCategories.map(cat =>
                            <Select.Option key={cat.id} value={cat.id}>
                                {`${cat.name} (${cat.nominalCode})`}
                            </Select.Option>
                        )}
                    </Select>
                </Row>
            </Col>
            <Col span={7} className="m-t-10">
                <Select
                    loading={loading}
                    defaultValue={taxRate}
                    status={!taxRate ? 'error' : null}
                    placeholder="VAT Code"
                    name="codat_tax_id"
                    size="large"
                    className="dark-green select-b-g full-percent-width"
                    onChange={(val) => handleOnChange('codat_tax_id', val)}
                >
                    {taxRates?.length && taxRates.map(tax =>
                        <Select.Option key={tax.id} value={tax.id}>
                            {tax.name}
                        </Select.Option>
                    )}
                </Select>
            </Col>
            <Col span={3} className="m-t-10">
                <Button danger size="large" onClick={() => onDelete(mapId)} icon={<DeleteOutlined />} shape="circle" />
            </Col>
        </Row>
    )
}

export default MappingRow;
