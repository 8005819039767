import create from 'zustand'

const useWalletBreakdownState = create((set) => ({
    colSize: 16,
    allVisible: false,
    itemSize: 12,
    fullWidth: false,
    allZero: false,
    allToggle: true,
    balanceCount: 0,
    setState: (data) => set(({ ...data }))
}));

export { useWalletBreakdownState };