import { useMutation } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useTxLimitsMutation = ({cardId, typeId, method, tid, amount}) => {
    const apiRequest = useApiRequest();

    const mutation = useMutation({
        mutationFn: ({cardId, typeId, method, tid, amount}) => {
            return apiRequest(
                `${APIROUTES.prepaidcard_maxtransaction}${method === 'DELETE' ? ('/-' + tid) : (method === 'PUT' ? ('/-' + tid + '?amount=' + amount) : '')}`,
                method,
                method === 'POST' ? {
                    prepaid_card_id: parseInt(cardId),
                    max_transaction_type_id: typeId,
                    amount: amount,
                }: null
            );
        },
        mutationKey: ['_prepaidcard_maxtransaction', cardId, typeId, method, tid],
        onMutate: () => {},
        onError: (error) => {},
    });

    return mutation;
}

export { useTxLimitsMutation };