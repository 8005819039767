function useSelectSearch() {
    return (
        (input, option) => {
            if (option.children)
                if (option.children.props)
                    return option.children.props.children[1].toLowerCase().includes(input.toLowerCase());
                else
                    return option.children.toLowerCase().includes(input.toLowerCase());
            else if (option.label && typeof option.label === 'string') {
                return option.label.toLowerCase().includes(input.toLowerCase())
            }
        }
    );
}

export { useSelectSearch };