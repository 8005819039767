import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useAllTransactionTypes = ({txnTypeIds = null} = {}) => {
    const apiRequest = useApiRequest();

    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
        let url = `${APIROUTES.get.get_transaction_types}?show_in_filter=1`;
        const data = await apiRequest(url);
        return data;
    }


    const query = useQuery({
        queryKey: ['_transaction_types_all'],
        queryFn: fetchQuery,
        onSuccess: () => {}
    });

    return query;
}

export { useAllTransactionTypes };