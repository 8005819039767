import { useExtendedApiRequest } from "Hooks/ExtendedAPI"

const useReportsApiRequest = () => {
	const extendedApiRequest = useExtendedApiRequest();

	return async ({path, method = 'GET', data = null, config = null, fullResponse = false}) => await extendedApiRequest({
		baseUrl: process.env.REACT_APP_AUX_API_BASE_URL,
		path,
		method,
		data,
		config,
		fullResponse
	});
};

export default useReportsApiRequest;
