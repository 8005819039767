import BulkCardControlsNav from "App/Components/BulkCardControlsNav";
import { Outlet } from "react-router-dom";
import PageDescription from "App/Components/PageDescription";
import { Row, Col } from "antd";

function BulkCardControls() {

    return (
        <>
            <Row>
				<Col span={24}>
					<PageDescription
						title="Bulk Card Controls"
						text="- Manage the card controls assigned to each card, providing you ultimate control over your business spending."
					/>
				</Col>
			</Row>

			<Row className="m-t-30" justify="center">
				<Col>
					<BulkCardControlsNav />
				</Col>
			</Row>

            <Outlet />
        </>
    )
}

export default BulkCardControls;