import create from "zustand";

const useManageSpecificUserState = create(set => ({
    modalOpen: false,
    userDeleted: false,
    addAccessOpen: false,
    revokeAccessOpen: false,
    addAccessLoading: false,
    revokeAccessLoading: false,
    revokeAccessItem: undefined,
    deleted: false,
    deleteLoading: false,
    role: '',
    telelponeMobile: '',
    email: '',
    editRole: false,
    editTelephoneMobile: false,
    setState: obj => set(obj)
}));

export { useManageSpecificUserState };