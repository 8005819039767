import { useNavigate, useLocation } from "react-router-dom";
import URLS from "Routes/constants";
import { Button, Space } from "antd";
import { FaCreditCard, FaGlobe } from "react-icons/fa";
import { useAccountingConfigurationQuery } from "App/Pages/Accounting/Configuration/query";
import { useConfigurationState } from "../../Configuration/store";

function TransactionsPageNav() {
    const navigate = useNavigate();
    const location = useLocation();
    const { data: accountingConfig } = useAccountingConfigurationQuery();
    const platform_type = useConfigurationState(state => state.platform_type);

    return (
        <Space size={12} wrap>
            {accountingConfig?.configuration?.expenses === 1 && <Button
                type="primary"
                size="large"
                ghost={location.pathname !== URLS.ExpenseTransactions}
                onClick={() => navigate(URLS.ExpenseTransactions)}
            >
                <Space align='start'>
                    <FaCreditCard size={18} style={{ marginBottom: '-3px' }} />
                    Expense Information
                </Space>
            </Button>}
            {platform_type === 'Xero' && <Button
                type="primary"
                size="large"
                ghost={location.pathname !== URLS.BankFeedTransactions}
                onClick={() => navigate(URLS.BankFeedTransactions)}
            >
                <Space align='start'>
                    <FaGlobe size={18} style={{ marginBottom: '-3px' }} />
                    Bank Feeds
                </Space>
            </Button>}
        </Space>
    )
}

export default TransactionsPageNav;