import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
// eslint-disable-next-line
import { notification } from "antd";

const useCardAutofundDelMutation = ({autoloadId, cardId}) => {
    const apiRequest              = useApiRequest();
    const queryClient             = useQueryClient();

    const mutation = useMutation({
        mutationFn: ({autoloadId, cardId}) => {
            return apiRequest(`${APIROUTES.del.prepaidcard_autoload}/-${autoloadId}`, 'DELETE');
        },
        mutationKey: ['_prepaidcard_autoload_del', autoloadId],
        onMutate: () => {},
        onSuccess: (data, context) => {
            queryClient.invalidateQueries({ queryKey: ['_prepaidcard_autoload', context.cardId], exact: true});
            notification.success({ message: 'Autofund amounts deleted!.',  placement: 'top' });
        },
        onError: (error) => {
            notification.error({ message: 'Something went wrong. Please try again.',  placement: 'top' });
        },
    });

    return mutation;
}

export { useCardAutofundDelMutation };