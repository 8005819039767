import create from "zustand";

const useFundWithdrawConfirmModalState = create((set) => ({
    showModal: false,
    onConfirm: () => {},
    type: 'confirmation',
    title: () => {},
    message: () => {},
    moreBtnTxt: 'New Fund',
    setState: (data) => set({ ...data }),
}));

export { useFundWithdrawConfirmModalState };
