/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Input, InputNumber, Modal, Radio, Row, Space, Typography, notification } from "antd";
import CustomTable from "App/Components/CustomTable";
import PageDescription from "App/Components/PageDescription";
import SwitchWithText from "App/Components/SwitchWithText";
import { useNotificationSettingsState } from "App/Pages/Settings/CompanyAccount/NotificationSettings/store";
import { FaEdit } from "react-icons/fa";
import { dataSource } from "./store/data";
import { useSettingsQuery } from "App/Pages/Settings/CompanyAccount/NotificationSettings/hooks/query";
import { NOTIFICATIONS } from "Constants/Common";
import { accountingFormat, numberFormat } from "Utils";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useLoginStore } from "App/Pages/Login/store";
import { useNavigate } from "react-router-dom";
import URLS from "Routes/constants";

function NotificationSettings() {
    const { data, isFetching, refetch } = useSettingsQuery();
    const modalOpen = useNotificationSettingsState(state => state.modalOpen);
    const modalKey = useNotificationSettingsState(state => state.modalKey);
    const toggle = useNotificationSettingsState(state => state.toggle);
    const ccEmail = useNotificationSettingsState(state => state.ccEmail);
    const isSubmitting = useNotificationSettingsState(state => state.isSubmitting);
    const setState = useNotificationSettingsState(state => state.setState);
    const config = useLoginStore(state => state.config);
    const apiRequest = useApiRequest();
    const [topUpForm] = Form.useForm();
    const navigate = useNavigate();
    const [paymentReportForm] = Form.useForm();

    const columns = [
        {
            title: 'Notification',
            dataIndex: 'notification',
            key: 'notification',
            align: 'left',
            width: 400
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            align: 'left',
            width: 700
        },
        {
            title: '',
            dataIndex: 'key',
            key: 'action',
            align: 'right',
            render: (val) =>
                <FaEdit className="dark-green pointer" onClick={() => handleClick(val)} size={40} />
        }
    ];

    const handleClick = (key) => {
        if (key !== NOTIFICATIONS.workflowApprovals) {
            setState({
                modalOpen: true,
                modalKey: key
            });
        } else
            navigate(URLS.WorkflowApproval)
    }

    const handleSubmit = async (items) => {
        setState({ isSubmitting: true });
        let body = {};
        if (modalKey === NOTIFICATIONS.topUpWallet) {
            body.hideAlert = items?.amount ? 0 : 1;
            body.triggerAmount = items.amount;
            body.toEmail = items.email;
        } else if (modalKey === NOTIFICATIONS.formatPushNotification) {
            body.hideAlert = toggle === undefined ?
                data?.platform_alert_settings?.find(
                    val => val?.platform_alert_format_id === NOTIFICATIONS.formatPushNotification
                )?.hide_this_alert :
                toggle;
        } else if (modalKey === NOTIFICATIONS.paymentReports) {
            const resAlert = data?.platform_alert_settings?.find(
                val => val?.platform_alert_format_id === modalKey
            )?.hide_this_alert;

            body.hideAlert = toggle !== undefined ? (toggle ? 0 : 1) : (
                resAlert === undefined ? 0 : resAlert
            )
            body.toEmail = paymentReportForm?.getFieldValue('toEmail') || null
        } else {
            const resAlert = data?.platform_alert_settings?.find(
                val => val?.platform_alert_format_id === modalKey
            )?.hide_this_alert;
            const resEmail = data?.platform_alert_settings?.find(
                val => val?.platform_alert_format_id === modalKey
            )?.setting_json_obj?.cc_email;

            body.hideAlert = toggle !== undefined ? (toggle ? 0 : 1) : (
                resAlert === undefined ? 0 : resAlert
            )
            body.ccEmail = ccEmail || resEmail
        }
        body.alertFormatId = modalKey
        const res = await apiRequest(APIROUTES.post.notification_settings, "POST", body);
        if (!res?.success) {
            notification.error({ message: 'Something Went Wrong', description: 'Failed to update notification settings', placement: 'top' })
        }
        refetch();
        setState({
            isSubmitting: false,
            modalOpen: false,
            toggle: undefined,
            ccEmail: undefined,
        });
    }

    return (
        <>
            <Modal
                open={modalOpen}
                destroyOnClose
                closable={false}
                footer={false}
                onCancel={() => setState({
                    modalOpen: false,
                    toggle: undefined,
                    ccEmail: undefined,
                })}
            >
                {modalKey === NOTIFICATIONS.topUpWallet && (
                    <Form form={topUpForm} onFinish={handleSubmit}>
                        <Row justify="center">
                            <Col>
                                <Typography.Text className="dark-green fs-20px bold">Fund Wallet Reminders</Typography.Text>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col>
                                <Typography.Text className="dark-green fs-16px semi-bold muli">Your current minimum wallet amount is set to</Typography.Text>
                            </Col>
                        </Row>
                        <Row justify="center" gutter={12} className="m-t-10">
                            <Col span={12}>
                                <Input readOnly className="b-g" value={config?.mvc_ccy?.mvc_card_currency} />
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='amount'
                                    initialValue={
                                        data?.platform_alert_settings?.find(
                                            val => val?.platform_alert_format_id === NOTIFICATIONS.topUpWallet
                                        )?.setting_json_obj?.trigger_amount
                                    }
                                >
                                    <InputNumber
                                        className="b-g full-percent-width"
                                        controls={false}
                                        formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
                                        parser={(val) => numberFormat(val)}
                                        placeholder="Amount"
                                        min={0}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={24}>
                                <Form.Item
                                    name='email'
                                    initialValue={
                                        data?.platform_alert_settings?.find(
                                            val => val?.platform_alert_format_id === NOTIFICATIONS.topUpWallet
                                        )?.setting_json_obj?.email_to
                                    }
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'Please enter a valid email address'
                                        }
                                    ]}
                                >
                                    <Input className="b-g" placeholder="Enter email" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col>
                                <Space>
                                    <Button type="primary" htmlType="submit" loading={isSubmitting}>Confirm</Button>
                                    <Button type="primary" danger onClick={() => setState({ modalOpen: false })}>Cancel</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                )}
                {modalKey === NOTIFICATIONS.enhancedTransactions && (
                    <Form>
                        <Row justify="center">
                            <Col>
                                <Typography.Text className="dark-green fs-20px bold">Enhanced Transaction Notifications</Typography.Text>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col>
                                <Typography.Text className="dark-green fs-16px semi-bold muli">Enhanced Transaction Notifications are currently set to</Typography.Text>
                            </Col>
                        </Row>
                        <Row justify="center" className="m-t-10">
                            <Col>
                                <SwitchWithText
                                    onOff
                                    defaultChecked={
                                        toggle !== undefined ? toggle : data?.platform_alert_settings?.find(
                                            val => val?.platform_alert_format_id === NOTIFICATIONS.enhancedTransactions
                                        )?.hide_this_alert === 0
                                    }
                                    onChange={(val) => setState({ toggle: val })}
                                />
                            </Col>
                        </Row>
                        <Row justify="center" className="m-t-20">
                            <Col span={24}>
                                <Form.Item
                                    name='ccEmail'
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'Please enter a valid email address'
                                        }
                                    ]}
                                    initialValue={ccEmail}
                                >
                                    <Input placeholder="Update CC email" className="b-g" onChange={(e) => setState({ ccEmail: e.target.value })} />
                                </Form.Item>
                            </Col>
                        </Row>
                        {data?.platform_alert_settings?.find(
                            val => val?.platform_alert_format_id === NOTIFICATIONS.enhancedTransactions
                        )?.setting_json_obj?.cc_email &&
                            <Row justify="center">
                                <Col span={24}>
                                    <Input
                                        disabled
                                        value={
                                            data?.platform_alert_settings?.find(
                                                val => val?.platform_alert_format_id === NOTIFICATIONS.enhancedTransactions
                                            )?.setting_json_obj?.cc_email
                                        }
                                    />
                                </Col>
                            </Row>
                        }
                        <Row justify="center" className="m-t-20">
                            <Col>
                                <Space>
                                    <Button type="primary" onClick={handleSubmit} loading={isSubmitting}>Confirm</Button>
                                    <Button
                                        type="primary"
                                        danger
                                        onClick={() => setState({
                                            modalOpen: false,
                                            toggle: undefined,
                                            ccEmail: undefined
                                        })}
                                    >Cancel</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                )}
                {modalKey === NOTIFICATIONS.cardTransaction && (
                    <>
                        <Row justify="center">
                            <Col>
                                <Typography.Text className="dark-green fs-20px bold">Card Freeze Notifications</Typography.Text>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col>
                                <Typography.Text className="dark-green fs-16px semi-bold muli">Card Freeze Notifications are currently set to</Typography.Text>
                            </Col>
                        </Row>
                        <Row justify="center" className="m-t-10">
                            <Col>
                                <SwitchWithText
                                    onOff
                                    defaultChecked={
                                        toggle !== undefined ? toggle : data?.platform_alert_settings?.find(
                                            val => val?.platform_alert_format_id === NOTIFICATIONS.cardTransaction
                                        )?.hide_this_alert === 0
                                    }
                                    onChange={(val) => setState({ toggle: val })}
                                />
                            </Col>
                        </Row>
                        <Row justify="center" className="m-t-20">
                            <Col>
                                <Space>
                                    <Button type="primary" onClick={handleSubmit} loading={isSubmitting}>Confirm</Button>
                                    <Button
                                        type="primary"
                                        danger
                                        onClick={() => setState({
                                            modalOpen: false,
                                            toggle: undefined
                                        })}
                                    >Cancel</Button>
                                </Space>
                            </Col>
                        </Row>
                    </>
                )}
                {modalKey === NOTIFICATIONS.attachReceipt && (
                    <>
                        <Row justify="center">
                            <Col>
                                <Typography.Text className="dark-green fs-20px bold">Attach Receipt Notification</Typography.Text>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col>
                                <Typography.Text className="dark-green fs-16px semi-bold muli">Attach Receipt Notifications are currently set to</Typography.Text>
                            </Col>
                        </Row>
                        <Row justify="center" className="m-t-10">
                            <Col>
                                <SwitchWithText
                                    onOff
                                    defaultChecked={
                                        toggle !== undefined ? toggle : data?.platform_alert_settings?.find(
                                            val => val?.platform_alert_format_id === NOTIFICATIONS.attachReceipt
                                        )?.hide_this_alert === 0
                                    }
                                    onChange={(val) => setState({ toggle: val })}
                                />
                            </Col>
                        </Row>
                        <Row justify="center" className="m-t-20">
                            <Col>
                                <Space>
                                    <Button type="primary" onClick={handleSubmit} loading={isSubmitting}>Confirm</Button>
                                    <Button
                                        type="primary"
                                        danger
                                        onClick={() => setState({
                                            modalOpen: false,
                                            toggle: undefined
                                        })}
                                    >Cancel</Button>
                                </Space>
                            </Col>
                        </Row>
                    </>
                )}
                {modalKey === NOTIFICATIONS.paymentReports && (
                    <>
                        <Form form={paymentReportForm}>
                            <Row justify="center">
                                <Col>
                                    <Typography.Text className="dark-green fs-20px bold">Payment Report Notification Setting</Typography.Text>
                                </Col>
                            </Row>
                            <Row justify="center">
                                <Col>
                                    <Typography.Text className="fs-16px semi-bold muli">Payment Report Notifications are currently set to</Typography.Text>
                                </Col>
                            </Row>
                            <Row justify="center" className="m-t-10">
                                <Col>
                                    <SwitchWithText
                                        onOff
                                        defaultChecked={
                                            toggle !== undefined ? toggle : data?.platform_alert_settings?.find(
                                                val => val?.platform_alert_format_id === NOTIFICATIONS.paymentReports
                                            )?.hide_this_alert === 0
                                        }
                                        onChange={(val) => setState({ toggle: val })}
                                    />
                                </Col>
                            </Row>

                            <Row justify="center" className="m-t-20">
                                <Col span={24}>
                                    <Form.Item
                                        name='toEmail'
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'Please enter a valid email address'
                                            }
                                        ]}
                                        initialValue={data?.platform_alert_settings?.find(
                                            val => val?.platform_alert_format_id === NOTIFICATIONS.paymentReports
                                        )?.setting_json_obj?.email_to}
                                    >
                                        <Input placeholder="Update email" className="b-g" onChange={(e) => setState({ ccEmail: e.target.value })} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify="center" className="m-t-20">
                                <Col>
                                    <Space>
                                        <Button type="primary" onClick={handleSubmit} loading={isSubmitting}>Confirm</Button>
                                        <Button
                                            type="primary"
                                            danger
                                            onClick={() => setState({
                                                modalOpen: false,
                                                toggle: undefined,
                                                ccEmail: undefined
                                            })}
                                        >Cancel</Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Form>
                    </>
                )}
                {modalKey === NOTIFICATIONS.formatPushNotification && (
                    <>
                        <Row justify="center">
                            <Col>
                                <Typography.Text className="dark-green fs-20px bold">Online Transactions Verification Methods</Typography.Text>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col>
                                <Typography.Text className="fs-16px semi-bold muli">Please select your preferred method for authorising online/ecommerce card transactions</Typography.Text>
                            </Col>
                        </Row>
                        <Row justify="center" className="m-t-10" align="middle">
                            <Col span={2}>
                                <Radio
                                    checked={
                                        (
                                            toggle === undefined ?
                                                data?.platform_alert_settings?.find(
                                                    val => val?.platform_alert_format_id === NOTIFICATIONS.formatPushNotification
                                                )?.hide_this_alert :
                                                toggle
                                        )
                                    }
                                    value={1}
                                    onChange={(e) => setState({ toggle: e.target.value })}
                                    className='b-2-grey big-rounded p-r-2 p-l-2'
                                />
                            </Col>
                            <Col span={22}>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text className="dark-green fs-20px bold">SMS</Typography.Text>
                                    <Typography.Text className="fs-14px muli dark-grey semi-bold">We will send your cardholder an SMS with a one-time URL (OTU).</Typography.Text>
                                    <Typography.Text className="fs-14px muli dark-grey semi-bold">The cardholder must click on the OTU and confirm their password to verify the online card transaction</Typography.Text>
                                </Space>
                            </Col>
                        </Row>
                        <Row justify="center" className="m-t-30" align="middle">
                            <Col span={2}>
                                <Radio
                                    checked={
                                        !(
                                            toggle === undefined ?
                                                data?.platform_alert_settings?.find(
                                                    val => val?.platform_alert_format_id === NOTIFICATIONS.formatPushNotification
                                                )?.hide_this_alert :
                                                toggle
                                        )
                                    }
                                    value={0}
                                    onChange={(e) => setState({ toggle: e.target.value })}
                                    className='b-2-grey big-rounded p-r-2 p-l-2'
                                />
                            </Col>
                            <Col span={22}>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text className="dark-green fs-20px bold">Mobile App</Typography.Text>
                                    <Typography.Text className="fs-14px muli dark-grey semi-bold">We will send a push notification to your cardholder through the Volopa Business mobile app.</Typography.Text>
                                    <Typography.Text className="fs-14px muli dark-grey semi-bold">The cardholder must complete biometric verification (e.g. Fingerprint or Face ID) or provide Username & Password to verify the online card transaction</Typography.Text>
                                </Space>
                            </Col>
                        </Row>
                        <Row justify="center" className="m-t-20">
                            <Col>
                                <Space>
                                    <Button
                                        type="primary"
                                        danger
                                        onClick={() => setState({
                                            modalOpen: false,
                                            toggle: undefined
                                        })}
                                    >Cancel</Button>
                                    <Button type="primary" onClick={handleSubmit} loading={isSubmitting}>Save</Button>
                                </Space>
                            </Col>
                        </Row>
                    </>
                )}
            </Modal>

            <Row>
                <Col span={24}>
                    <PageDescription title='Notification Settings' />
                </Col>
            </Row>
            <Row className="m-t-20">
                <Col span={24}>
                    <CustomTable columns={columns} dataSource={dataSource} loading={isFetching} headerColor='green' styleAllRows className='spaced-rows' />
                </Col>
            </Row>
        </>
    );
}

export default NotificationSettings;