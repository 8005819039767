import create from "zustand";

const useRateCheckerState = create((set) => ({
  fromCurrencies: undefined,
  toCurrencies: undefined,
  commonFromCurrencies: undefined,
  commonToCurrencies: undefined,
  rate: undefined,
  rateUid: undefined,
  loadingRate: false,
  loadingConvert: false,
  seconds: undefined,
  selectedFromCurrency: undefined,
  selectedToCurrency: undefined,
  toValue: undefined,
  fromValue: undefined,
  side: undefined,
  setState: (data) => set({ ...data }),
}));

export { useRateCheckerState };
