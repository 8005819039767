import create from "zustand";

const useFundWalletState = create((set) => ({
    showModal: false,
    contentIndex: 0,
    message: undefined,
    currency: undefined,
    amount: undefined,
    description: undefined,
    loadingFund: false,
    balances: undefined,
    volopaAccountHolderName: undefined,
    volopaAccountSortCode: undefined,
    volopaAccountNumber: undefined,
    volopaAccountCountry: undefined,
    volopaAccountAddress: undefined,
    volopaAccountName: undefined,
    volopaAccountPaymentRef: undefined,
    volopaAccountIban: undefined,
    volopaAccountSwift: undefined,
    volopaAccountNumberType: undefined,
    volopaAccountSortCodeLabel: 'Sort Code',
    volopaAccountDetails: undefined,
    fundingLimitReached: false,
    loadingPayment: false,
    loadingPaymentRequest: false,
    transactionComplete: false,
    setState: (data) => set({ ...data }),
    resetState: () => set({
        transactionComplete: false,
        showModal: false,
        contentIndex: 0,
        message: undefined,
        currency: undefined,
        amount: undefined,
        description: undefined,
        loadingFund: false,
        balances: undefined,
        volopaAccountHolderName: undefined,
        volopaAccountSortCode: undefined,
        volopaAccountNumber: undefined,
        volopaAccountCountry: undefined,
        volopaAccountAddress: undefined,
        volopaAccountName: undefined,
        volopaAccountPaymentRef: undefined,
        volopaAccountIban: undefined,
        volopaAccountSwift: undefined,
        volopaAccountNumberType: undefined,
        volopaAccountSortCodeLabel: 'Sort Code',
        volopaAccountDetails: undefined,
        fundingLimitReached: false,
        loadingPayment: false,
        loadingPaymentRequest: false,
    })
}));

export default useFundWalletState;