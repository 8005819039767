import { useQuery } from "@tanstack/react-query";
import { APIROUTES } from "Constants/ApiRoutes";
import { useApiRequest } from "Hooks/API";
import create from "zustand";

const useFundingHistoryDetail = create((set, get) => ({
    modalOpen: false,
    modalOpenConfirmed: false,
    record: {},
    setState: (data) => set(data),
}));


const useGetFundingDetail = (fundingId) => {
    const apiRequest = useApiRequest();
    const setState = useFundingHistoryDetail(state => state.setState)

    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
        const { data } = await apiRequest(`${APIROUTES.get.get_funding_details}/-${fundingId}?$with=optCompanyWalletFundStatus,user`);
        return data;
    }

    const query = useQuery({
        queryKey: ['_funding_details', fundingId],
        queryFn: fetchQuery,
        onSuccess: (resData) => {
            if(!resData?.response?.data?.error) {
                setState({ record: resData.data })
            }
        },
        select: (resData) => {
            return resData
        }
    });

    return query;
}
export { useFundingHistoryDetail, useGetFundingDetail }
