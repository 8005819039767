import create from "zustand";

const useConvertBalancesState = create(set => ({
    modalOpen: undefined,
    currencies: undefined,
    message: undefined,
    rateCheckerLoading: false,
    balances: undefined,
    setState: obj => set(obj)
}));

export { useConvertBalancesState };