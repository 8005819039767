import {
	Button,
	Col,
	Row,
	Typography,
	Form,
	Input,
} from "antd";
import create from "zustand";

const useState = create(set => ({
    passwd: '',
    setState: (payload) => set(payload),
}));

function CancelCard({cardId, onClose = () => {}, onConfirm = (pass) => {}, isLoading}) {
	const [form] = Form.useForm();
    const setState = useState(state => state.setState);

	const onFinish = () => {
		setState({ passwd: form.getFieldValue('password') })
		onConfirm(form.getFieldValue('password'))
	}
	return (
		<>
			<Row>
            <Col>
                <Typography.Text className="muli semi-bold fs-18px dark-green">CANCEL CARD</Typography.Text>
            </Col>
        </Row>
		<Form
				layout="vertical"
				onFinish={onFinish}
                form={form}
				requiredMark={false}
			>
        <Row className="m-t-20">
				<Col span={24}>
					<Typography.Text className="muli semi-bold fs-16px dark-green">
						Please enter password to confirm Card Cancellation
					</Typography.Text>
				</Col>
            <Col span={24} className="m-b-0">
                    <Form.Item name='password' className="m-b-0" rules={[
							{
							required: true,
							message: "Please Enter Password",
							},
						]}>
                        <Input.Password
							placeholder="Enter Password"
						/>
                    </Form.Item>
            </Col>
			<Col span={24} className="m-b-10">
				<Typography.Text className="muli semi-bold fs-16px error">
					All funds will be transferred back into the Company Wallet. <br />
					This action cannot be undone
				</Typography.Text>
			</Col>
        </Row>
        <Row justify="space-between">
            <Col>
                <Button type="primary" onClick={onClose}>Close</Button>
            </Col>
            <Col>
                <Button type="primary" danger htmlType="submit" loading={isLoading}>Cancel Card</Button>
            </Col>
        </Row>
		</Form>
	  </>
	)
}

export default CancelCard;
