/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Col, DatePicker, Divider, Form, Image, notification, Progress, Row, Select, Space, Spin, Tooltip, Typography } from "antd";
import CustomTable from "App/Components/CustomTable";
import PageDescription from "App/Components/PageDescription";
import { useLoginStore } from "App/Pages/Login/store";
import { usePaymentSummaryState } from "App/Pages/Payments/BatchPayments/PayMultipleRecipients/PaymentSummary/store";
import { usePayMultipleRecipientsState } from "App/Pages/Payments/BatchPayments/PayMultipleRecipients/store";
import { colors } from "Constants/Style";
import { useApiRequest } from "Hooks/API";
import { FLAGS } from "Constants/Images";
import moment from "moment";
import { useEffect, useMemo, useRef } from "react";
import { FaInfoCircle, FaRegCalendarAlt } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import URLS from "Routes/constants";
import { accountingFormat } from "Utils";
import { FUNDING_METHOD, INR_ALLOWED_SELL_CURRENCIES } from "Constants/Common";
import PaymentStatus from "./components/PaymentStatus";
import { OPT_TTC_SELF_QUOTE_STATUS_ID } from "Constants/Opts";
import { APIROUTES } from "Constants/ApiRoutes";
import YapilyCallbackComponent from "App/Pages/Wallet/FundWallet/components/YapilyCallbackComponent";
import _ from "lodash";
import { useIsApproverQuery, usePaymentDetailsQuery } from "Hooks/Queries";

function PaymentSummary() {
    const fundingCurrency = usePaymentSummaryState(state => state.fundingCurrency);
    const selectedRecipients = usePayMultipleRecipientsState(state => state.selectedRecipients);
    const setSelectedRecipientsState = usePayMultipleRecipientsState(state => state.setState);
    const proceed = usePaymentSummaryState(state => state.proceed);
    const recipientDetails = usePaymentSummaryState(state => state.recipientDetails);
    const setState = usePaymentSummaryState(state => state.setState);
    const currencies = usePaymentSummaryState(state => state.currencies);
    const commonCurrencies = usePaymentSummaryState(state => state.commonCurrencies);
    const config = useLoginStore(state => state.config);
    const firstDate = usePaymentSummaryState(state => state.firstDate);
    const weConvert = usePaymentSummaryState(state => state.weConvert);
    const recipients = usePayMultipleRecipientsState(state => state.recipients);
    const displayRate = usePaymentSummaryState(state => state.displayRate);
    const displayRateInversed = usePaymentSummaryState(state => state.displayRateInversed);
    const quoteAccepted = usePaymentSummaryState(state => state.quoteAccepted);
    const totalFees = usePaymentSummaryState(state => state.totalFees);
    const quoteUuid = usePaymentSummaryState(state => state.quoteUuid);
    const seconds = usePaymentSummaryState(state => state.seconds);
    const fullPageSpinner = usePaymentSummaryState(state => state.fullPageSpinner);
    const checkRateLoading = usePaymentSummaryState(state => state.checkRateLoading);
    const volopaAccountHolderName = usePaymentSummaryState(state => state.volopaAccountHolderName);
    const volopaAccountSortCode = usePaymentSummaryState(state => state.volopaAccountSortCode);
    const volopaAccountNumber = usePaymentSummaryState(state => state.volopaAccountNumber);
    const volopaAccountCountry = usePaymentSummaryState(state => state.volopaAccountCountry);
    const volopaAccountAddress = usePaymentSummaryState(state => state.volopaAccountAddress);
    const volopaAccountName = usePaymentSummaryState(state => state.volopaAccountName);
    const volopaAccountPaymentRef = usePaymentSummaryState(state => state.volopaAccountPaymentRef);
    const volopaAccountIban = usePaymentSummaryState(state => state.volopaAccountIban);
    const volopaAccountSwift = usePaymentSummaryState(state => state.volopaAccountSwift);
    const volopaAccountNumberType = usePaymentSummaryState(state => state.volopaAccountNumberType);
    const volopaAccountSortCodeLabel = usePaymentSummaryState(state => state.volopaAccountSortCodeLabel);
    const volopaAccountDetails = usePaymentSummaryState(state => state.volopaAccountDetails);
    const fundingLimitReached = usePaymentSummaryState(state => state.fundingLimitReached);
    const totalAMount = usePaymentSummaryState(state => state.totalAMount);
    const youSendTotal = usePaymentSummaryState(state => state.youSendTotal);
    const showPaymentsUnder = usePaymentSummaryState(state => state.showPaymentsUnder);
    const showPaymentsOver = usePaymentSummaryState(state => state.showPaymentsOver);
    const approvalRequired = usePaymentSummaryState(state => state.approvalRequired);
    const recipientLoader = usePaymentSummaryState(state => state.recipientLoader);
    const resetState = usePaymentSummaryState(state => state.resetState);
    const quoteLoading = usePaymentSummaryState(state => state.quoteLoading);
    const deletingDraft = usePaymentSummaryState(state => state.deletingDraft);
    const dates = usePaymentSummaryState(state => state.dates);
    const scheduled = usePaymentSummaryState(state => state.scheduled);
    const [form] = Form.useForm();
    const scheduleDate = Form.useWatch('scheduleDate', form);
    const fundingMethod = usePaymentSummaryState(state => state.fundingMethod);
    const disabledFundingDropdown = usePaymentSummaryState(state => state.disabledFundingDropdown);
    const transactionStatus = usePaymentSummaryState(state => state.transactionStatus);
    const TransactionComplete = usePaymentSummaryState(state => state.TransactionComplete);
    const confirmationFlag = usePaymentSummaryState(state => state.confirmationFlag);
    const AmountExceed = usePaymentSummaryState(state => state.AmountExceed);
    const setStateAlert = useLoginStore(store => store.setStore);
    const timeout = useRef();
    const navigate = useNavigate();
    const apiRequest = useApiRequest();
    const { mapId } = useParams();
    const { data: reviewPaymentDetails, isFetching: quoteDataLoading, isFetched: quoteLoaded, isError: reviewPaymentDetailsError } = usePaymentDetailsQuery(mapId);
    const { data: isApprover, isFetching: isApproverLoading } = useIsApproverQuery();
    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);

    useEffect(() => {
        if (reviewPaymentDetailsError) {
            resetData();
            setState({ fullPageSpinner: true });
            notification.error({ message: 'Something Went Wrong', description: 'Draft payment was deleted.', placement: 'top' });
        }

    }, [reviewPaymentDetails, quoteDataLoading]);

    const getPaymentDetailRetry = async () => {
        try {
            setState({ fullPageSpinner: true });
            const timeout = setTimeout(() => {
                clearInterval(interval);
                setState({ fullPageSpinner: false, TransactionComplete: true, transactionStatus: 'FAILED' });
            }, 120000);
            const interval = setInterval(async () => {
                const response = await apiRequest(`${APIROUTES.post.get_payment_detail}`, 'POST', { paymentRequestId: query.get('paymentRequestId') });
                if (response?.response?.data.payments?.at(-1)?.statusDetails?.at(-1)?.status === 'COMPLETED' || response?.response?.data.payments?.at(-1)?.statusDetails?.at(-1)?.status === 'PENDING') {
                    setState({ fullPageSpinner: false, TransactionComplete: true, transactionStatus: response?.response?.data.payments?.at(-1)?.statusDetails?.at(-1)?.status });
                    clearInterval(interval);
                    clearTimeout(timeout)
                    return;
                }
            }, 5000);
        } catch (error) {

        }
    };

    const getPaymentDetailsYapily = async () => {
        try {
            setState({ fullPageSpinner: true });
            const response = await apiRequest(`${APIROUTES.post.get_payment_detail}`, 'POST', { paymentRequestId: query.get('paymentRequestId') });
            if (response && response?.success) {
                setState({ TransactionComplete: true, transactionStatus: response?.response?.data.payments?.at(-1)?.statusDetails?.at(-1)?.status, fullPageSpinner: false });
                return;
            }
            getPaymentDetailRetry()
        } catch {

        }
    }

    const handlePaymentConfirmationPage = async () => {
        try {
            setState({ fullPageSpinner: true, TransactionComplete: false, confirmationFlag: true });
            const response = await apiRequest(`${APIROUTES.get.easy_transfer_getBooked_payment}${query.get('paymentRequestId')}`, 'GET');
            if (response && response.success) {
                const recipientData = response?.data?.quote?.tcc_quote_b_b_self_beneficiary_map?.map(item => ({
                    name: item?.tcc_beneficiary?.name,
                    amount: item?.beneficiary_amt,
                    recipientGets: item?.beneficiary_amt,
                    receives: item?.beneficiary_amt,
                    recipient: item?.tcc_beneficiary?.name,
                    beneficiary_company_name: item?.tcc_beneficiary?.name,
                    currency: item?.beneficiary_ccy,
                    settlementMethod: item?.payment_type,
                    settlementMethodUpper: _.capitalize(item?.payment_type),
                    paymentFees: parseFloat(item?.fee_amt) + parseFloat(item?.fee_markup),
                    paymentReference: item?.payment_reference
                }))
                setSelectedRecipientsState({ selectedRecipients: recipientData })
                getCurrencies();
                const displayCal = (parseFloat(response?.data?.quote?.buy_amount || 0) / response?.data?.quote?.calc_client_sell)?.toFixed(6) || 1;
                let displayRateValue = isFinite(displayCal) && displayCal !== 0 ? displayCal : 1;
                let displayRateInversedValue = (1 / displayRateValue).toFixed(6);
                setState({
                    totalAMount: response?.data?.quote?.buy_amount,
                    displayRate: displayRateValue,
                    displayRateInversed: displayRateInversedValue,
                    fundingCurrency: response?.data?.quote?.sell_currency,
                    fundingMethod: response?.data?.quote?.tcc_quote_b_b_self_beneficiary_map?.fund_method_id === FUNDING_METHOD.PUSH_FUND ? 'Push Funds' : 'Easy Transfer',
                    youSendTotal: response?.data?.quote?.calc_client_send,
                    youSend: response?.data?.quote?.calc_client_sell,
                    proceed: true,
                    quoteId: response?.data?.quote?.id,
                    quoteAccepted: true,
                    modalOpen: true,
                    totalFees: response?.data?.quote?.additional_fees,
                    weConvert: response?.data?.quote?.calc_client_sell,
                    quoteUuid: response?.data?.quote?.uuid,
                    recipientDetails: recipientData,
                    seconds: (new Date(response?.data?.quote?.tcc_quote_b_b_self_beneficiary_map?.at(0)?.update_time ?? '') - new Date(response?.data?.quote?.tcc_quote_b_b_self_beneficiary_map?.at(0)?.create_time ?? '')) / 1000,
                })
            } else {
                notification.error({ message: 'Something went wrong!', placement: 'top' })
            }
        } catch (error) {
            notification.error({ message: 'Something Went Wrong!', placement: 'top' })
        } finally {
            setState({ fullPageSpinner: false });
        }
    }

    useEffect(() => {
        if (fundingCurrency && !confirmationFlag) {
            if (mapId)
                refreshQuote();
            else
                getQuote();
        } else {
            setState({ confirmationFlag: false })
        }
    }, [fundingCurrency, scheduleDate, fundingMethod])

    useEffect(() => {
        const amountLimit = youSendTotal ? youSendTotal : 0
        if (!mapId && amountLimit !== 0) {
            if (
                ['GBP', 'EUR'].includes(fundingCurrency) &&
                amountLimit < 75000 && config?.easy_transfer_allowed &&
                (
                    !scheduleDate ||
                    scheduleDate?.isSame(dates?.next_day_conversion_date, 'day') ||
                    scheduleDate?.isSame(dates?.first_conversion_date, 'day')
                )
            ) {
                setState({ fundingMethod: 'Easy Transfer', disabledFundingDropdown: false })
            } else {
                setState({ fundingMethod: 'Push Funds', disabledFundingDropdown: true });
            }
        }
    }, [fundingCurrency, youSendTotal, scheduleDate]);

    useEffect(() => {
        if (quoteLoaded && !selectedRecipients?.length > 0 && !reviewPaymentDetailsError) {
            if (reviewPaymentDetails?.opt_tcc_self_quote_status_id === OPT_TTC_SELF_QUOTE_STATUS_ID?.DRAFTED) {
                handleSelect();
                setState({
                    recipientDetails: reviewPaymentDetails?.recipient,
                    scheduled: !!reviewPaymentDetails?.scheduled,
                    fundingMethod: reviewPaymentDetails?.tcc_quote_b_b_self_beneficiary_map?.[0]?.fund_method_id === FUNDING_METHOD.PUSH_FUND ? 'Push Funds' : 'Easy Transfer'
                });
            } else {
                navigate(URLS.DraftPayments, { replace: true });
            }
        }
    }, [quoteLoaded, selectedRecipients])

    useEffect(() => {
        if (mapId && reviewPaymentDetails && !reviewPaymentDetailsError && selectedRecipients?.length > 0 && (currencies?.length > 0 || commonCurrencies?.length > 0)) {
            handleCurrencyChange(reviewPaymentDetails?.sell_currency);
        }
    }, [selectedRecipients, currencies, commonCurrencies])

    useEffect(() => {
        !!mapId && setSelectedRecipientsState({ selectedRecipients: [] });
        if (query.get('paymentRequestId')) {
            getPaymentDetailsYapily();
        }
        checkedRateAvailability();
        return () => {
            resetState();
            !!mapId && setSelectedRecipientsState({ selectedRecipients: [] });
            clearTimeout(timeout.current);
        }
    }, [mapId]);

    const handleCurrencyChange = (val) => {
        !mapId && resetData();
        setState({ fundingCurrency: val });
    }

    useEffect(() => {
        if (selectedRecipients?.length > 0) {
            getCurrencies();
            let totalAmountReceived = 0;
            // eslint-disable-next-line no-unused-vars
            for (const [key, value] of Object.entries(selectedRecipients)) {
                totalAmountReceived += parseFloat(value.amount);
            }
            setState({ totalAMount: totalAmountReceived });
        }
        clearTimeout(timeout?.current);
    }, [selectedRecipients])


    useEffect(() => {
        if (mapId && !quoteUuid)
            setState({ quoteLoading: true });
        else if (mapId && quoteUuid)
            setState({ quoteLoading: false })
    }, [quoteUuid])

    useEffect(() => {
        if (fundingCurrency) {
            getDates();
            getPaymentDetails();
        }
    }, [fundingCurrency])

    useEffect(() => {
        if (!quoteAccepted) {
            if (seconds === config.b2b_expires_in)
                clearTimeout(timeout.current);
            if (seconds > 0) {
                timeout.current = setTimeout(() => setState({ seconds: seconds - 1 }), 1000);
            }
            if (!!quoteUuid && seconds === 0) {
                refreshQuote();
            }
        }
    }, [seconds]);

    const handleSelect = () => {
        const allSelectedRecipients = reviewPaymentDetails?.tcc_quote_b_b_self_beneficiary_map?.map(recipient => {
            const temp = recipient?.tcc_beneficiary;
            temp.amount = recipient?.beneficiary_amt;
            temp.settlementMethod = recipient?.payment_type;
            temp.paymentPurpose = recipient?.payment_reason;
            temp.paymentReference = recipient?.payment_reference;
            return temp;
        });

        setSelectedRecipientsState({ selectedRecipients: allSelectedRecipients });
    }

    const resetData = () => {
        clearTimeout(timeout?.current);
        setState({
            fundingCurrency: undefined,
            proceed: false,
            recipientsFormatted: undefined,
            firstDate: undefined,
            fundingMethod: 'Push Funds',
            rate: undefined,
            weConvert: undefined,
            commissionPercentage: undefined,
            minimumCommission: undefined,
            displayRate: "-",
            displayRateInversed: "-",
            fullPageSpinner: false,
            quoteAccepted: false,
            quoteUuid: undefined,
            totalFees: 0,
            volopaAccountHolderName: undefined,
            volopaAccountSortCode: undefined,
            volopaAccountNumber: undefined,
            volopaAccountCountry: undefined,
            volopaAccountAddress: undefined,
            volopaAccountName: undefined,
            volopaAccountPaymentRef: undefined,
            volopaAccountIban: undefined,
            volopaAccountSwift: undefined,
            volopaAccountNumberType: undefined,
            volopaAccountSortCodeLabel: 'Sort Code',
            volopaAccountDetails: undefined,
            fundingLimitReached: false,
            youSendTotal: 0,
            showPaymentsUnder: false,
            showPaymentsOver: false,
        });

    }


    const getPaymentDetails = async () => {
        let response = await apiRequest("tcc/fund/account/payment?currency=" + fundingCurrency);
        if (response && response.data && (response.data.result.funding_accounts?.find(account => account?.payment_type === "regular") || response.data.result.settlement_accounts[0])) {
            setState({
                volopaAccountDetails: response.data.result
            });

            if (response.data.result.settlement_accounts[0]) {
                updateFundingDetails(null, true, response.data.result);
            }
            if (response.data.result.funding_accounts?.find(account => account?.payment_type === "regular")) {
                updateFundingDetails(null, true, response.data.result);
            }
            if (response.data.result.funding_accounts?.find(account => account?.payment_type === "regular") && response.data.result.settlement_accounts[0]) {
                setState({
                    showPaymentsUnder: true,
                    showPaymentsOver: true,
                    fundingLimitReached: true
                });
            } else {
                if (response.data.result.settlement_accounts[0]) {
                    setState({
                        showPaymentsOver: true,
                        showPaymentsUnder: false
                    });
                }
                if (response.data.result.funding_accounts?.find(account => account?.payment_type === "regular")) {
                    setState({
                        showPaymentsUnder: true,
                        showPaymentsOver: false
                    });
                }
            }
        } else {
            notification.error({ message: 'Something Went Wrong', description: 'There was an issue with loading the Volopa funding details for this currency', placement: 'top' });
        }
    }

    const updateFundingDetails = (e, over, details) => {
        let sortCodeLabel = 'Sort Code';
        let volopaAccountDetailsData = details ?? volopaAccountDetails;
        if (over === false) {
            let routingCodeType = volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code_type;
            if (routingCodeType === 'bic_swift') {
                sortCodeLabel = 'SWIFT/BIC'
            }
            if (routingCodeType === 'ach_routing_number') {
                sortCodeLabel = 'Ach Routing Number'
            }
            if (routingCodeType === 'wire_routing_number') {
                sortCodeLabel = 'Wire Routing Number'
            }
            setState({
                volopaAccountHolderName: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_holder_name,
                volopaAccountSortCode: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.routing_code,
                volopaAccountNumber: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_number,
                volopaAccountCountry: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_country,
                volopaAccountAddress: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_address,
                volopaAccountName: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.bank_name,
                volopaAccountPaymentRef: volopaAccountDetailsData?.short_reference,
                volopaAccountIban: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.iban,
                volopaAccountSwift: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.bic_swift,
                volopaAccountNumberType: volopaAccountDetailsData?.funding_accounts?.find(account => account?.payment_type === "regular")?.account_number_type,
                volopaAccountSortCodeLabel: sortCodeLabel,
                fundingLimitReached: false
            });
        } else {
            let routingCodeType = volopaAccountDetailsData?.settlement_accounts[0]?.routing_code_type_1;
            if (routingCodeType === 'bic_swift') {
                sortCodeLabel = 'SWIFT/BIC'
            }
            if (routingCodeType === 'ach_routing_number') {
                sortCodeLabel = 'Ach Routing Number'
            }
            if (routingCodeType === 'wire_routing_number') {
                sortCodeLabel = 'Wire Routing Number'
            }
            setState({
                volopaAccountHolderName: volopaAccountDetailsData?.settlement_accounts[0]?.bank_account_holder_name,
                volopaAccountSortCode: volopaAccountDetailsData?.settlement_accounts[0]?.routing_code_value_1,
                volopaAccountNumber: volopaAccountDetailsData?.settlement_accounts[0]?.account_number,
                volopaAccountCountry: volopaAccountDetailsData?.settlement_accounts[0]?.bank_country,
                volopaAccountAddress: volopaAccountDetailsData?.settlement_accounts[0]?.bank_address,
                volopaAccountName: volopaAccountDetailsData?.settlement_accounts[0]?.bank_name,
                volopaAccountPaymentRef: volopaAccountDetailsData?.short_reference,
                volopaAccountIban: volopaAccountDetailsData?.settlement_accounts[0]?.iban,
                volopaAccountSwift: volopaAccountDetailsData?.settlement_accounts[0]?.bic_swift,
                volopaAccountSortCodeLabel: sortCodeLabel,
                fundingLimitReached: true
            });
        }
    }

    const getCurrencies = async () => {
        const response = await apiRequest("currency?$limit=500&active=1");
        if (response && response.data) {
            let currArray = config.is_eea === 1 ? ['BHD', 'BGN', 'CNY', 'ILS', 'KWD', 'OMR', 'QAR', 'RUB', 'THB', 'UGX', 'AED', 'INR', 'KES', 'RON', 'SAR', 'TRY', 'MXN', 'CZK'] : ['BHD', 'BGN', 'CNY', 'ILS', 'KWD', 'OMR', 'QAR', 'RUB', 'UGX', 'AED', 'INR', 'KES', 'RON', 'SAR', 'TRY', 'MXN', 'CZK'];
            let cur = response.data
                .filter((data) => data.currency !== selectedRecipients?.at(0)?.currency && (!currArray.includes(data.currency)))
                .sort((a, b) => a.currency.localeCompare(b.currency));
            cur = cur.map((val, key) => {
                val.key = key;
                val.flag = FLAGS[val.currency];
                val.label = val.title + ' (' + val.currency + ')';
                val.name = val.currency;
                val.value = val.currency;
                return val;
            });
            // Add condition for allowed sell currencies for INR only
            selectedRecipients?.forEach((item) => {
                if (item?.currency === 'INR') {
                    cur = cur.filter((data) => INR_ALLOWED_SELL_CURRENCIES.includes(data.currency));
                }
            })
            let commonCur = cur
                .filter((data) => data.sort_order_buy !== 999 && data.currency !== selectedRecipients?.at(0)?.currency)
                .sort((a, b) => a.sort_order_buy - b.sort_order_buy);
            setState({ currencies: cur, commonCurrencies: commonCur });
        } else {
            notification.error({ message: 'Something Went Wrong', description: 'There was an issue with loading currencies', placement: 'top' });
        }
    };

    const checkedRateAvailability = async () => {
        try {
            const response = await apiRequest(APIROUTES.get.exchange_out_of_hours);
            if (response && response?.data?.success) {
                setStateAlert({ outageAlert: undefined });
            } else {
                setStateAlert({ outageAlert: 'Please note: New Payments cannot be submitted at the moment' });
            }
        } catch (e) {
            setStateAlert({ outageAlert: 'Please note: New Payments cannot be submitted at the moment' });
        } finally {
            setState({ checkRateLoading: false })
        }
    }

    const getQuote = async () => {
        if (selectedRecipients && selectedRecipients[0].currency && fundingCurrency) {
            setState({
                fullPageSpinner: true,
            });
            clearTimeout(timeout?.current);
            let recipientListArray = [];
            // eslint-disable-next-line no-unused-vars
            for (const [key, value] of Object.entries(selectedRecipients)) {
                let recipientObject = {};
                recipientObject.beneficiary_id = value.id;
                recipientObject.amount = value.amount;
                recipientObject.payment_type = value.settlementMethod;
                recipientObject.payment_reason = value.paymentPurpose === "Other" ? value.paymentPurposeOther : value.paymentPurpose;
                recipientObject.payment_reference = value.paymentReference;
                recipientObject.payment_purpose_code = value.payment_purpose_code;
                if (value?.hasOwnProperty('invoiceNumber')) {
                    recipientObject.invoice_number = value.invoiceNumber;
                }
                if (value?.hasOwnProperty('invoiceDate')) {
                    recipientObject.invoice_date = value.invoiceDate;
                }
                recipientListArray = [...recipientListArray, recipientObject]
            }

            let data = {
                ccySell: fundingCurrency,
                ccyBuy: selectedRecipients[0].currency,
                amount: totalAMount,
                reason: selectedRecipients[0].paymentPurpose === "Other" ? selectedRecipients[0].paymentPurposeOther : selectedRecipients[0].paymentPurpose,
                recipientsList: recipientListArray,
                conversionType: 'earliest',
                side: 'buy',
                fundMethodId: fundingMethod === 'Push Funds' ? FUNDING_METHOD.PUSH_FUND : FUNDING_METHOD.EASY_TRANSFER,
            }

            if (scheduleDate) {
                if (scheduleDate?.isSame(dates?.next_day_conversion_date, 'day')) {
                    data.conversionType = 'next_day'
                } else if (scheduleDate?.isSame(dates?.first_conversion_date, 'day')) {
                    data.conversionType = 'earliest'
                } else {
                    data.delivery_date = scheduleDate?.format("YYYY-MM-DD");
                    data.scheduled = true;
                }
            }
            if (fundingCurrency) {
                const response = await apiRequest("exchange/b2b/self/quote/schedule", "POST", data);
                if (response && response.data) {
                    setState({
                        fullPageSpinner: false,
                        seconds: config.b2b_expires_in === seconds ? seconds - 1 : config?.b2b_expires_in,
                        quoteUuid: response?.data?.uuid,
                        displayRate: response.data.rate,
                        displayRateInversed: response.data.inverseRate,
                        totalAMount: response.data.receive,
                        weConvert: response.data.weConvert,
                        totalFees: response.data.PaymentFees,
                        youSendTotal: response.data.youSend,
                        firstDate: moment(response.data.delivery_date || response.data.expected_delivery).format('DD-MM-YYYY'),
                        approvalRequired: response?.data?.status_id === OPT_TTC_SELF_QUOTE_STATUS_ID.APPROVAL_REQUIRED ? true : false,
                        scheduled: response?.data?.status?.scheduled,
                        recipientDetails: response?.data?.recipient,
                        AmountExceed: false,
                    });
                    !!mapId && setState({ proceed: true });
                } else if (response?.response?.status === 400 && response?.response?.data?.error?.message) {
                    notification.destroy();
                    notification.error({ message: response?.response?.data?.error?.message, placement: 'top', duration: 0 });
                    setState({
                        fullPageSpinner: false,
                        AmountExceed: true,
                    });
                } else {
                    notification.error({ message: 'Something Went Wrong', description: 'There was an issue with getting a quote', placement: 'top' });
                    setState({
                        fullPageSpinner: false
                    });
                }
            }
        }
    }

    const refreshQuote = async () => {
        clearTimeout(timeout.current);
        setState({ fullPageSpinner: true });

        let data = {
            uuid: quoteUuid || mapId
        }
        const response = await apiRequest("exchange/b2b/self/quote/requote/uuid", "POST", data);
        if (response && response.data) {
            setState({
                quoteUuid: response.data.uuid,
                displayRate: response.data.rate,
                displayRateInversed: response.data.inverseRate,
                firstDate: data.scheduled && data?.infoOnly ? reviewPaymentDetails?.delivery_date && moment(reviewPaymentDetails?.delivery_date).format('DD-MM-YYYY') : moment(response?.data?.delivery_date || response.data.expected_delivery).format('DD-MM-YYYY'),
                weConvert: response?.data?.weConvert,
                fee: response?.data?.PaymentFees,
                seconds: config.b2b_expires_in === seconds ? seconds - 1 : config?.b2b_expires_in,
                totalAMount: response.data.receive,
                totalFees: response?.data?.PaymentFees,
                youSendTotal: response?.data?.youSend
            })
            if (mapId) {
                setState({ proceed: true });
            }
        } else {
            notification.error({ message: 'Something Went Wrong', description: 'There was an issue with receiving a quote', placement: 'top' });
        }
        setState({ fullPageSpinner: false });
    }

    const currentRecipientEmail = (mapId) => {
        const currentLoopedRecipient = recipients?.find((data) => (data.id === mapId));
        return currentLoopedRecipient?.email
    }

    const acceptQuote = async () => {
        if (quoteUuid) {
            setState({ fullPageSpinner: true });
            clearTimeout(timeout?.current);
            if (!mapId) {

                // eslint-disable-next-line no-unused-vars
                for (const [key, value] of Object.entries(selectedRecipients)) {
                    if (value.ccEmail) {
                        let ccData = {
                            uuid: quoteUuid,
                            cc_email: value.ccEmail
                        }
                        let ccResp = await apiRequest("tcc/paymentccemail", "POST", ccData);
                        if (!ccResp.data) {
                            notification.error({ message: 'Something Went Wrong', description: 'There was an issue with saving one of the recipient cc emails', placement: 'top' });
                        }
                    }
                    if (value.email && !currentRecipientEmail(value.id)) {
                        let mailResp = await apiRequest("tcc/beneficiary/-" + value.id + "?email=" + value.email, "PUT");
                        if (!mailResp.data) {
                            notification.error({ message: 'Something Went Wrong', description: 'There was an issue with saving one of the recipient emails', placement: 'top' });
                        }
                    }
                }
            }

            if (reviewPaymentDetails?.payment_cc_email?.length > 0) {
                reviewPaymentDetails?.payment_cc_email?.forEach(async ccEmail => {
                    let ccData = {
                        tcc_quote_bb_self_id: quoteUuid,
                        cc_email: ccEmail.cc_email
                    }
                    let ccResp = await apiRequest("tcc/paymentccemail", "POST", ccData);
                    if (!ccResp.data) {
                        notification.error({ message: 'Something Went Wrong', description: 'There was an issue with saving one of the recipient cc emails', placement: 'top' });
                    }
                })
            }

            let quoteData = {
                uuid: quoteUuid,
            }

            if (mapId && !scheduled) {
                quoteData.parentQuoteId = mapId;
            }

            const sameDay = scheduleDate?.isSame(dates?.first_conversion_date, 'day');
            const nextDay = scheduleDate?.isSame(dates?.next_day_conversion_date, 'day');

            const route = (scheduled && !sameDay && !nextDay) ?
                (
                    !mapId ?
                        APIROUTES.post.schedule_draft :
                        APIROUTES.post.approve_scheduled_payment
                ) :
                approvalRequired ?
                    APIROUTES.post.payment_draft :
                    APIROUTES.post.accept_quote_uuid;

            const response = await apiRequest(route, "POST", quoteData);
            if ((response && response.data && response.data.fxdeal) || response?.data?.success) {
                setSelectedRecipientsState({
                    buttonShow: false,
                    recipientLoader: false,
                });
                setState({ modalOpen: true })
            } else {
                notification.error({ message: 'Something Went Wrong', description: 'There was an issue with accepting our quote', placement: 'top' });
            }
            setState({ fullPageSpinner: false });
        }
    }

    const getDates = async () => {
        if (selectedRecipients && selectedRecipients[0].currency && fundingCurrency) {
            const response = await apiRequest("tcc/conversion/dates/all?currencyPair=" + fundingCurrency + selectedRecipients[0].currency);
            if (response && response.data) {
                setState({
                    firstDate: moment(response?.data?.result?.first_conversion_date).format('DD-MM-YYYY'),
                    dates: response?.data?.result
                });
            } else {
                notification.error({ message: 'Something Went Wrong', description: 'There was an issue with loading dates', placement: 'top' });
            }
        }
    }

    const returnToPaymentDetails = async () => {
        resetData();
        setSelectedRecipientsState({
            buttonShow: true,
            backFromSummary: true
        });
        getCurrencies();
        navigate(URLS.PayMultipleRecipients);
    }

    const handleDeleteDraft = async () => {
        setState({ deletingDraft: true });
        const body = { uuid: quoteUuid }
        const res = await apiRequest(APIROUTES.post.cancel_draft, 'POST', body);
        setState({ deletingDraft: false });
        notification.destroy();
        if (res?.data?.success) {
            notification.success({
                placement: 'top',
                message: 'Draft Payment Deleted'
            })
            resetData();
            navigate(URLS.DraftPayments);
        } else {
            notification.error({
                placement: 'top',
                message: 'Error',
                description: 'Draft payment delete failed'
            })
        }
    }

    const tableProps = {
        columns: [
            {
                title: 'Recipient',
                dataIndex: 'name',
                key: 'recipient',
                sorter: (a, b) => a.name && b.name && a.name.localeCompare(b.name)
            },
            {
                title: 'Recipient Receives',
                dataIndex: 'receives',
                key: 'recipientGets',
                sorter: (a, b) => a.receives && b.receives && (a.receives - b.receives),
                render: (val, recipient) => <Typography.Text>{`${accountingFormat(val)} ${recipient?.currency}`}</Typography.Text>
            },
            {
                title: 'Settlement Method',
                dataIndex: 'settlementMethod',
                key: 'settlementMethodUpper',
                render: val => `${val?.charAt(0)?.toUpperCase()}${val?.slice(1)}`,
                sorter: (a, b) => a.settlementMethod && b.settlementMethod && a.settlementMethod.localeCompare(b.settlementMethod)
            },
            {
                title: 'Payment Fees',
                dataIndex: 'paymentFees',
                key: 'paymentFees',
                render: (val, recipient) => `${accountingFormat(val)} ${recipient?.feeCurrency}`,
                sorter: (a, b) => a.paymentFees && b.paymentFees && a.paymentFees.localeCompare(b.paymentFees)
            }
        ],
        dataSource: recipientDetails?.map((recipient, key) => ({ ...recipient, key }))
    }

    const handleDisabledDates = (date) => {
        let disabled = false;
        const invalidDates = dates?.invalid_conversion_dates && Object?.keys(dates?.invalid_conversion_dates);
        if (
            date?.isBefore(dates?.first_conversion_date, 'day') ||
            invalidDates?.includes(date?.format('YYYY-MM-DD')) ||
            date?.isAfter(moment(dates?.first_conversion_date).add(1, 'year'), 'day')
        )
            disabled = true;

        return disabled;
    }

    return (
        <Spin spinning={fullPageSpinner || quoteDataLoading || recipientLoader || isApproverLoading || quoteLoading || checkRateLoading} size={'large'} >
            {TransactionComplete ?
                <YapilyCallbackComponent
                    transactionStatus={transactionStatus}
                    goToHistoryText={transactionStatus === 'COMPLETED' ? "Payment History" : "View Payment"}
                    returnToDashboardText={"Return to Dashboard"}
                    returnToDashboard={() => { navigate(URLS.PaymentsDashboard); setState({ TransactionComplete: false, modalOpen: false }) }}
                    goToHistory={!(transactionStatus === 'COMPLETED' || transactionStatus === 'PENDING') ? handlePaymentConfirmationPage : () => { navigate(URLS.PaymentHistory); setState({ TransactionComplete: false, modalOpen: false }) }}
                />
                : <Form form={form}>
                    <PaymentStatus updateFundingDetails={updateFundingDetails} resetData={resetData} />
                    <Row>
                        <Col span={24}>
                            <PageDescription title='Payment Summary' />
                        </Col>
                    </Row>
                    <Row className="m-t-20" gutter={[100, 24]}>
                        <Col xxl={14} xl={16} lg={18} md={22} sm={24} xs={24}>
                            <Row className="m-t-20" gutter={12}>
                                <Col>
                                    <Typography.Text className="dark-green fs-18px medium">Select Funding Currency</Typography.Text>
                                </Col>
                                <Col flex='90px'>
                                    <Select
                                        className="full-percent-width"
                                        onChange={handleCurrencyChange}
                                        value={fundingCurrency}
                                        allowClear
                                        disabled={!!mapId}
                                    >
                                        {commonCurrencies && commonCurrencies.length > 0 && (
                                            <Select.OptGroup label="Common">
                                                {commonCurrencies.map((val, key) => (
                                                    <Select.Option value={val.currency} key={key} className='p-l-10'>
                                                        <Space size={4} align="center">
                                                            {val.flag && (
                                                                <Image
                                                                    src={val.flag}
                                                                    preview={false}
                                                                    width={20}
                                                                    alt={val.currency}
                                                                />
                                                            )}
                                                            {val.currency}
                                                        </Space>
                                                    </Select.Option>
                                                ))}
                                            </Select.OptGroup>
                                        )}
                                        {currencies && currencies.length > 0 && (
                                            <Select.OptGroup label="Other">
                                                {currencies.map((val, key) => (
                                                    <Select.Option
                                                        key={
                                                            key +
                                                            (commonCurrencies
                                                                ? commonCurrencies.length
                                                                : 0)
                                                        }
                                                        value={val.currency}
                                                        className='p-l-10'
                                                    >
                                                        <Space size={4} align="center">
                                                            {val.flag && (
                                                                <Image
                                                                    src={val.flag}
                                                                    preview={false}
                                                                    width={20}
                                                                    alt={val.currency}
                                                                />
                                                            )}
                                                            {val.currency}
                                                        </Space>
                                                    </Select.Option>
                                                ))}
                                            </Select.OptGroup>
                                        )}
                                    </Select>
                                </Col>
                            </Row>
                            {!AmountExceed && fundingCurrency && (
                                <>
                                    <Row className="m-t-20" gutter={12}>
                                        <Col>
                                            <Space>
                                                <Typography.Text className="dark-green fs-18px medium">Select Funding Method</Typography.Text>
                                                <Tooltip
                                                    title={
                                                        <Space direction='vertical'>
                                                            <>
                                                                Push Funds: Once you submit the payment we will supply the Account details to fund the payment.
                                                            </>
                                                            <>
                                                                Easy Transfer: We will redirect you to your online banking to authorise sending the funds via Open Banking
                                                            </>
                                                        </Space>
                                                    }
                                                >
                                                    <FaInfoCircle className='dark-green' />
                                                </Tooltip>
                                            </Space>
                                        </Col>
                                        <Col flex='90px'>
                                            <Select
                                                value={fundingMethod}
                                                onChange={(e) => setState({ fundingMethod: e })}
                                                disabled={!!mapId || disabledFundingDropdown}>
                                                <Select.Option value='Push Funds'>
                                                    <Tooltip placement="right" title="Once you submit the payment, we will supply the Account details to fund the payment.">
                                                        Push Funds
                                                    </Tooltip>
                                                </Select.Option>
                                                {!disabledFundingDropdown && <Select.Option value='Easy Transfer'>
                                                    <Tooltip placement='right' title="We will redirect you to your banking platform to authorise the payment (Only available when funding in GBP or EUR)">
                                                        Easy Transfer
                                                    </Tooltip>
                                                </Select.Option>}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row className="m-t-20" gutter={100}>
                                        <Col span={24}>
                                            <Typography.Text className="dark-green fs-18px medium">Recipient Details</Typography.Text>
                                        </Col>
                                    </Row>
                                    <Row className="m-t-20" gutter={100}>
                                        <Col span={24}>
                                            <CustomTable {...tableProps} />
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Col>
                        {!AmountExceed && fundingCurrency && (
                            <Col xxl={10} xl={12} lg={14} md={18} sm={24} xs={24}>
                                <Row>
                                    <Col xxl={{ span: 20, offset: 4 }} span={24}>
                                        <Card hoverable className="b-g" bodyStyle={{ padding: '10px' }}>
                                            <Row>
                                                <Col>
                                                    <Space>
                                                        <Typography.Text className="dark-green medium fs-18px">Payment Details</Typography.Text>
                                                        {/* <FaInfoCircle className="dark-green" /> */}
                                                    </Space>
                                                </Col>
                                            </Row>
                                            <Row className='m-t-20' gutter={12}>
                                                <Col span={12}>
                                                    <Row>
                                                        <Col>
                                                            <Typography.Text className='muli semi-bold fs-18px dark-green'>Expected Delivery</Typography.Text>
                                                        </Col>
                                                        <Col>
                                                            <Form.Item name='scheduleDate'>
                                                                <DatePicker
                                                                    bordered={false}
                                                                    style={{ width: '24px', padding: '0px' }}
                                                                    suffixIcon={<FaRegCalendarAlt size={18} className='dark-green' />}
                                                                    inputReadOnly
                                                                    disabledDate={handleDisabledDates}
                                                                    showToday={false}
                                                                    placeholder=''
                                                                    disabled={proceed}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={9}>
                                                    <Typography.Text className='muli light fs-18px dark-green'>{scheduleDate?.format('DD-MM-YYYY') || firstDate}</Typography.Text>
                                                </Col>
                                            </Row>
                                            <Row className='m-t-10' gutter={12} align='middle'>
                                                <Col span={12}>
                                                    <Space>
                                                        <Typography.Text className='muli semi-bold fs-18px dark-green'>Exchange Rate</Typography.Text>
                                                        <Tooltip title="Exchange Rate has been rounded to 6 decimal points."><FaInfoCircle className='dark-green' /></Tooltip>
                                                    </Space>
                                                </Col>
                                                <Col span={9}>
                                                    <Space direction="vertical" size={0} >
                                                        <Typography.Text className='muli semi-bold fs-18px dark-green'>1 {fundingCurrency} = {displayRate} {selectedRecipients && selectedRecipients[0] && selectedRecipients[0].currency}</Typography.Text>
                                                        <Typography.Text className='muli semi-bold fs-18px dark-green'>1 {selectedRecipients && selectedRecipients[0] && selectedRecipients[0].currency} = {displayRateInversed} {fundingCurrency}</Typography.Text>
                                                    </Space>
                                                </Col>
                                                <Col span={3}>
                                                    <Space>
                                                        <Progress
                                                            type="circle"
                                                            percent={(seconds / (+config?.card_expires_in + 0.01)) * 100}
                                                            width={40}
                                                            format={() => `${seconds}s`}
                                                        />
                                                        <Tooltip title="FX rate will renew every 30 seconds."><FaInfoCircle className='dark-green' /></Tooltip>
                                                    </Space>
                                                </Col>
                                            </Row>
                                            <Row className='m-t-20' gutter={12}>
                                                <Col span={10}>
                                                    <Typography.Text className='muli extra-bold dark-green fs-18px'>Recipients Receive</Typography.Text>
                                                </Col>
                                                <Col span={8}>
                                                    <Typography.Text className='muli extra-bold dark-green fs-18px'>{accountingFormat(totalAMount)}</Typography.Text>
                                                </Col>
                                                <Col span={6}>
                                                    <Typography.Text className='muli extra-bold dark-green fs-18px'>{selectedRecipients && selectedRecipients[0] && selectedRecipients[0].currency}</Typography.Text>
                                                </Col>
                                            </Row>
                                            <Divider style={{ backgroundColor: colors.lightGreen, margin: 0 }} />
                                            <Row gutter={12}>
                                                <Col span={10}>
                                                    <Typography.Text className='muli semi-bold dark-green fs-18px'>We Convert</Typography.Text>
                                                </Col>
                                                <Col span={8}>
                                                    <Typography.Text className='muli semi-bold dark-green fs-18px'>{accountingFormat(weConvert)}</Typography.Text>
                                                </Col>
                                                <Col span={6}>
                                                    <Typography.Text className='muli semi-bold dark-green fs-18px'>{fundingCurrency}</Typography.Text>
                                                </Col>
                                            </Row>
                                            <Divider style={{ backgroundColor: colors.lightGreen, margin: 0 }} />
                                            <Row gutter={12}>
                                                <Col span={10}>
                                                    <Typography.Text type='danger' className='muli semi-bold fs-18px'>Payment Fees</Typography.Text>
                                                </Col>
                                                <Col span={8}>
                                                    <Typography.Text type='danger' className='muli semi-bold fs-18px'>{totalFees}</Typography.Text>
                                                </Col>
                                                <Col span={6}>
                                                    <Typography.Text type='danger' className='muli semi-bold fs-18px'>{fundingCurrency}</Typography.Text>
                                                </Col>
                                            </Row>
                                            <Row className='m-t-20' gutter={12}>
                                                <Col span={10}>
                                                    <Typography.Text type='danger' className='muli semi-bold fs-18px'>You Send</Typography.Text>
                                                </Col>
                                                <Col span={8}>
                                                    <Typography.Text type='danger' className='muli semi-bold fs-18px'>{accountingFormat(youSendTotal) ?? ''}</Typography.Text>
                                                </Col>
                                                <Col span={6}>
                                                    <Typography.Text type='danger' className='muli semi-bold fs-18px'>{fundingCurrency}</Typography.Text>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                                {proceed && (
                                    <Row className="m-t-20">
                                        <Col span={24}>
                                            <Typography.Text className="fs-20px dark-green medium">Funding Bank Account</Typography.Text>
                                            <Card className="bg-light-grey" bodyStyle={{ padding: '10px' }}>
                                                <Row className='m-t-10' gutter={[24, 12]} justify='space-between'>
                                                    {showPaymentsUnder && <Col>
                                                        <Button type='primary' {...(fundingLimitReached === true && { ghost: true })} onClick={(e) => updateFundingDetails(e, false)}>
                                                            {config.is_eea === 1 ? "Regular/SEPA Payment Up To A Value Of EUR 250,000" : "Regular / Faster Payment Up To A Value Of £250,000.00"}
                                                        </Button>
                                                    </Col>}
                                                    {showPaymentsOver &&
                                                        <Col>
                                                            <Button type='primary' {...(fundingLimitReached === false && { ghost: true })} onClick={(e) => updateFundingDetails(e, true)}>
                                                                {config.is_eea === 1 ? "Payments Over EUR 250,00 Or SWIFT/TARGET2 Payments" : "Payments Over £250,000.00 Or BACS/CHAPS/SWIFT Payments"}
                                                            </Button>
                                                        </Col>}
                                                </Row>
                                                <Row gutter={12}>
                                                    <Col span={10}>
                                                        <Space size={0} direction='vertical'>
                                                            <Typography.Text className="dark-green fs-18px muli semi-bold">Account Holder Name</Typography.Text>
                                                            <Typography.Text className="dark-green fs-18px muli light">{volopaAccountHolderName}</Typography.Text>
                                                        </Space>
                                                    </Col>
                                                    <Col span={9}>
                                                        {fundingLimitReached ?
                                                            <Space direction="vertical" size={0}>
                                                                <Typography.Text className="muli semi-bold fs-18px dark-green">Account Number</Typography.Text>
                                                                <Typography.Text className="muli light fs-18px">{volopaAccountNumber}</Typography.Text>
                                                            </Space> :
                                                            <Space direction="vertical" size={0}>
                                                                <Typography.Text className="muli semi-bold fs-18px dark-green">{volopaAccountNumberType === "iban" ? "IBAN" : "Account Number"}</Typography.Text>
                                                                <Typography.Text className="muli light fs-18px">{volopaAccountNumber}</Typography.Text>
                                                            </Space>
                                                        }
                                                    </Col>
                                                    <Col span={5}>
                                                        <Space size={0} direction='vertical'>
                                                            <Typography.Text className='muli semi-bold dark-green fs-18px'>{volopaAccountSortCodeLabel}</Typography.Text>
                                                            <Typography.Text className="dark-green fs-18px muli light">{volopaAccountSortCode}</Typography.Text>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                                {fundingLimitReached &&
                                                    <Row className='m-t-10'>
                                                        <Col span={16}>
                                                            <Space direction='vertical' size={0}>
                                                                <Typography.Text className='muli semi-bold dark-green fs-18px'>IBAN</Typography.Text>
                                                                <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountIban}</Typography.Text>
                                                            </Space>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Space direction='vertical' size={0}>
                                                                <Typography.Text className='muli semi-bold dark-green fs-18px'>SWIFT/BIC</Typography.Text>
                                                                <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountSwift}</Typography.Text>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                }
                                                <Row gutter={12} className="m-t-10">
                                                    <Col span={10}>
                                                        <Space size={0} direction='vertical'>
                                                            <Typography.Text className="dark-green fs-18px muli semi-bold">Bank Name</Typography.Text>
                                                            <Typography.Text className="dark-green fs-18px muli light">{volopaAccountName}t</Typography.Text>
                                                        </Space>
                                                    </Col>
                                                    <Col span={14}>
                                                        <Space size={0} direction='vertical'>
                                                            <Typography.Text className="dark-green fs-18px muli semi-bold">Bank Address</Typography.Text>
                                                            <Typography.Text className="dark-green fs-18px muli light">{volopaAccountAddress}</Typography.Text>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                                <Row gutter={12} className="m-t-10">
                                                    <Col span={24}>
                                                        {!fundingLimitReached ?
                                                            <Space direction='vertical' size={0}>
                                                                <Typography.Text className='muli semi-bold dark-green fs-18px'>Bank Country</Typography.Text>
                                                                <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountCountry}</Typography.Text>
                                                            </Space> :
                                                            <Space direction='vertical' size={0}>
                                                                <Typography.Text className='muli semi-bold dark-green fs-18px'>Mandatory Funding Reference</Typography.Text>
                                                                <Typography.Text className='muli fs-18px light dark-green'>{volopaAccountPaymentRef}</Typography.Text>
                                                            </Space>
                                                        }
                                                    </Col>
                                                </Row>
                                                {fundingLimitReached && <Typography.Text className='muli semi-bold dark-green fs-12px'>*Please note that the Payment Reference is required to ensure we identify your funds efficiently</Typography.Text>}
                                                {config.is_eea === 1 && <Row className='m-t-10 m-b-10'><Typography.Text className='muli semi-bold dark-green fs-12px'>Please ensure that you transfer the funds from the bank account which you have verified. Please note that sending the funds from a different bank account may result in the payment being declined.</Typography.Text></Row>}
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        )}
                    </Row>

                    {approvalRequired && !scheduled && proceed &&
                        <Row justify="end" className="m-t-20" gutter={[100, 24]}>
                            <Col xxl={10} xl={12} lg={14} md={18} sm={24} xs={24}>
                                <Typography.Text className='fs-18px medium center-align-text' type='danger'>
                                    The FX contract for International Payments that are pending approval is not booked.
                                    The live FX contract will be displayed to the Approver at the point of approval
                                </Typography.Text>
                            </Col>
                        </Row>
                    }

                    {!mapId && scheduled && proceed &&
                        <Row justify="end" className="m-t-20" gutter={[100, 24]}>
                            <Col xxl={10} xl={12} lg={14} md={18} sm={24} xs={24}>
                                <Typography.Text className='fs-18px medium center-align-text' type='danger'>
                                    NOTE:  Exchange Rate & Amounts are indicative only.
                                    Exchange Rates & Amounts are confirmed in time for the scheduled Expected Delivery date.
                                </Typography.Text>
                            </Col>
                        </Row>
                    }

                    {mapId && fundingCurrency && proceed &&
                        <Row justify="end" className="m-t-20" gutter={[100, 24]}>
                            <Col xxl={10} xl={12} lg={14} md={18} sm={24} xs={24}>
                                <Typography.Text className='fs-18px medium center-align-text' type='warning'>
                                    Pending Approval
                                </Typography.Text>
                            </Col>
                        </Row>
                    }

                    {!AmountExceed && fundingCurrency && !proceed && (
                        <Row justify="end" className="m-t-20">
                            <Col>
                                <Space size={24} wrap>
                                    <Button type="primary" onClick={() => returnToPaymentDetails()}>Return to Payment Details</Button>
                                    <Button type="primary" onClick={() => setState({ proceed: true })}>Proceed to Pay</Button>
                                </Space>
                            </Col>
                        </Row>
                    )}

                    {fundingCurrency && proceed && (
                        <Row justify="end" className="m-t-20" gutter={[100, 24]}>
                            <Col xxl={10} xl={12} lg={14} md={18} sm={24} xs={24}>
                                <Row justify="center">
                                    <Col>
                                        <Space size={24} wrap>
                                            {!mapId &&
                                                <Button type="primary" onClick={() => setState({ proceed: false })}>Return to Payment Summary</Button>
                                            }
                                            {mapId &&
                                                <Button
                                                    type='primary'
                                                    onClick={() => navigate(URLS.DraftPayments)}
                                                >
                                                    Return to Draft Payments
                                                </Button>
                                            }
                                            {mapId &&
                                                <Button
                                                    type='primary'
                                                    danger
                                                    onClick={handleDeleteDraft}
                                                    loading={deletingDraft}
                                                >
                                                    Delete Draft
                                                </Button>
                                            }
                                            <Button type='primary' onClick={() => acceptQuote()} disabled={mapId && !isApprover?.approver}>
                                                {approvalRequired ? 'Submit for Approval' : (scheduled ? 'Schedule Payment' : 'Pay Recipients')}
                                            </Button>
                                        </Space>
                                    </Col>
                                </Row>
                                {/* Add missing code */}
                            </Col>
                        </Row>
                    )}
                </Form>
            }
        </Spin>
    )
}

export default PaymentSummary;
