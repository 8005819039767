const pageContent = [
    {
        title: 'Creating and Validation your Recipient Bank Details',
        text: 'If you do not have the correct recipient bank information, this can delay your International Payment. To avoid unnecessary delays, below is a quick guide to International Payments.'
    },
    {
        title: 'Required Information',
        text: <>
            The list below details the information you require depending on the country you are sending money to.
            <ul>
                <ul className="dashed">
                    <li>
                        For payments to the UK (including Jersey, Guernsey and the Isle of Man) – supply Account Number and Sort Code, or IBAN & SWIFT
                    </li>
                    <li>
                        For payments to the US – supply Account Number and ABA/Fedwire/Routing or Account Number and BIC/SWIFT
                    </li>
                    <li>
                        To countries that use IBAN (all of Europe and a few other countries – see link below) – supply IBAN and BIC/SWIFT
                    </li>
                    <li>
                        For all other countries – supply Account Number and BIC/SWIFT
                    </li>
                </ul>
            </ul>
        </>
    },
    {
        text: "If you're unsure about the intermediary/correspondent bank details etc., please email our Support Team at support@volopa.com"
    }
];

export { pageContent }