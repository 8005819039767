import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { NOTIFICATIONS } from "Constants/Common";
import { useNotificationSettingsState } from "../../store";

const useSettingsQuery = () => {
  const apiRequest = useApiRequest();
  const setState = useNotificationSettingsState(state => state.setState);

  // eslint-disable-next-line no-unused-vars
  const fetchQuery = async () => {
    const { data } = await apiRequest(`${APIROUTES.get.notification_settings}`);
    return data;
  }

  const query = useQuery({
    queryKey: ['_settings'],
    queryFn: fetchQuery,
    onSuccess: (data) => {
        setState({ hideAlert: data?.platform_alert_settings?.find(
          val => val?.platform_alert_format_id === NOTIFICATIONS.paymentReports
        )?.hide_this_alert ?? 1 });
    }
  });

  return query;
}

export { useSettingsQuery };