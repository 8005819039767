import { useMutation } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useCardWithdrawConfirmMutation = ({prepaidCardId, transferTransferId}) => {
    const apiRequest = useApiRequest();

    const mutation = useMutation({
        mutationFn: ({prepaidCardId, transferTransferId}) => {
            return apiRequest(`${APIROUTES.post.card_transfer_confirm}`, 'POST', {
                prepaidCardId,
                transferTransferId,
            });
        },
        mutationKey: ['_card_transfer_request', prepaidCardId, transferTransferId],
        onSuccess: (data) => {},
        onError: (error) => {},
    });

    return mutation;
}

export { useCardWithdrawConfirmMutation };