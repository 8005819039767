import { Badge, Card, Col, Row, Space, Typography } from "antd";

function CircularCard({ children, danger = false, footer, badge, onClick }) {
  const className = onClick ? 'pointer' : '';
  const card = (
    <Card className={"circular center-align-text fit-content" + (danger ? ' b-danger' : ' b-success')} bodyStyle={{ padding: '20px 20px 15px 20px' }}>
      <Row justify="center">
        <Col>
          <Typography.Text type={danger ? 'danger' : 'success'}>
            {children}
          </Typography.Text>
        </Col>
      </Row>
    </Card>
  )
  return (
    <Space direction="vertical" className={className} onClick={onClick} data-testid='container'>
      {badge ? (
        <Badge count={<Typography.Text className="fs-18px light-green bold">{badge}</Typography.Text>} offset={[-20, 55]}>{card}</Badge>
      ) : (
        card
      )}
      <Row justify="center">
        <Col>
          {footer && (
            <Typography.Text
              className={"fs-18px regular dark-green center-align-text"}
            >
              {footer}
            </Typography.Text>
          )}
        </Col>
      </Row>
    </Space>
  )
}

export default CircularCard;
