import create from "zustand";

const useWorkflowApprovalState = create(set => ({
  commonCurrencies: undefined,
  otherCurrencies: undefined,
  isSubmitting: false,
  saveRes: undefined,
  setState: obj => set(obj),
  resetState: () => set({
    commonCurrencies: undefined,
    otherCurrencies: undefined,
    isSubmitting: false,
    saveRes: undefined,
  })
}))

export { useWorkflowApprovalState };