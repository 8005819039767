import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useTransactionCategoriesQuery = () => {
    const apiRequest = useApiRequest();

	//
    const fetchQuery = async () => {
        const { data } = await apiRequest(`${APIROUTES.get_transaction_categories}`);
        return data;
    }

	//
    const query = useQuery({
        queryKey: ['_get_transaction_categories'],
        queryFn: fetchQuery,
        onSuccess: (queryData) => queryData?.sort((a, b) => a.description.localeCompare(b.description, 'en', {numeric: true}))
    });

	//
    return query;
}

export { useTransactionCategoriesQuery };
