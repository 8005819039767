/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Button, Card, Col, Form, Image, Input, InputNumber, Row, Select, Space, Tooltip, Typography, Spin, notification } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { FaInfoCircle, FaUser } from "react-icons/fa";
import { cloneDeep } from "lodash";
import { useEffect, useRef } from 'react';
import FullScreenModal from "App/Components/FullScreenModal";
import { useSelectSearch } from "Hooks/Search";
import { FLAGS } from "Constants/Images";
import { usePayMultipleRecipientsState } from "App/Pages/Payments/BatchPayments/PayMultipleRecipients/store";
import URLS from "Routes/constants";
import PageDescription from "App/Components/PageDescription";
import AddRecipient from "App/Pages/Payments/AddRecipient";
import { usePaymentSummaryState } from "App/Pages/Payments/BatchPayments/PayMultipleRecipients/PaymentSummary/store";
import { accountingFormat, checkIfCrossBorderPurposeRecipient, getLocalOrAllCurrencyCrossBorderPurposeCode, numberFormat } from "Utils";
import { useRecipientsQuery } from "Hooks/Queries";
import { useBatchPaymentDetailQuery } from "Hooks/Queries/useBatchPaymentDetailQuery";
import { useGetPaymentPurposeCode } from "Hooks/Queries/useGetPaymentPurposeCode";
import { useNewPaymentState } from "../../NewPayment/store";
import { ALLOWED_COUNTRIES_ALL_CURRENCIES_PAYMENT_PURPOSE_CODE } from "Constants/Common";
import { useGetCurrencyRulesQuery } from "Hooks/Queries/useGetCurrencyRulesQuery";
import RecipientDetails from "../../RecipientList/RecipientDetails";

function PayMultipleRecipients() {
    const [form] = Form.useForm();
    const filterSelect = useSelectSearch();
    const navigate = useNavigate();
    const selectedRecipients = usePayMultipleRecipientsState(state => state.selectedRecipients);
    const recipientSelectedFlag = usePayMultipleRecipientsState(state => state.recipientSelectedFlag);
    const frequentRecipients = usePayMultipleRecipientsState(state => state.frequentRecipients);
    const recipientsDataGrouped = usePayMultipleRecipientsState(state => state.recipientsDataGrouped);
    const paymentPurposesOther = usePayMultipleRecipientsState(state => state.paymentPurposesOther);
    const setState = usePayMultipleRecipientsState(state => state.setState);
    const setPaymentSummarryState = usePaymentSummaryState(state => state.setState);
    const buttonShow = usePayMultipleRecipientsState(state => state.buttonShow);
    const backFromSummary = usePayMultipleRecipientsState(state => state.backFromSummary);
    const showInvoiceAndDateFields = usePayMultipleRecipientsState((state) => state.showInvoiceAndDateFields);
    const recipientModalOpen = usePayMultipleRecipientsState(state => state.recipientModalOpen);
    const viewDetailsModalOpen = usePayMultipleRecipientsState(state => state.viewDetailsModalOpen);
    const PaymentPurposeCodes = useNewPaymentState((state) => state.PaymentPurposeCodes);
    const { data: recipientsObj, isLoading: fetchingRecipients, isFetched: isRecipientFetched, refetch: refetchRecipients } = useRecipientsQuery();
    let groupedRecipients = {};
    const { uuid } = useParams();
    const { data: batchPaymentDetails, isLoading: loadingBatchPaymentDetails, isFetched: isFetchedBatchPayment } = useBatchPaymentDetailQuery(uuid);
    const lockFilterRecipients = useRef(false);
    const { isLoading: fetchingPaymentCodes } = useGetPaymentPurposeCode();
    const { data: currencyRules } = useGetCurrencyRulesQuery();

    useEffect(() => {
        if (!backFromSummary) {
            resetData();
        } else {
            setFormData();
            setState({ backFromSummary: false });
        }
        if (uuid) {
            resetData();
        }
        return () => {
            setState({ recipientSelectedFlag: false, viewDetailsModalOpen: false })
            lockFilterRecipients.current = false;
        }
    }, [])

    useEffect(() => {
        if (uuid && frequentRecipients.length > 0 && recipientsDataGrouped && isRecipientFetched && isFetchedBatchPayment && !selectedRecipients) {
            const keyArrSelectedRecipient = batchPaymentDetails?.tcc_quote_b_b_self_beneficiary_map?.filter(recipient => !recipient?.tcc_beneficiary?.deleted)?.map((recipient) => recipient?.tcc_beneficiary?.id);
            if (keyArrSelectedRecipient?.length < batchPaymentDetails?.tcc_quote_b_b_self_beneficiary_map?.length) {
                const diff = batchPaymentDetails?.tcc_quote_b_b_self_beneficiary_map?.length - keyArrSelectedRecipient?.length;
                const desc = diff === 1 ? "A recipient has been deleted." : "Recipients have been deleted."
                notification.destroy();
                notification.error({ message: 'Error', description: desc, placement: 'top' });
            }
            if (keyArrSelectedRecipient?.length > 0) {
                handleSelect(keyArrSelectedRecipient);
                if (!lockFilterRecipients.current) {
                    let filteredRecipients = recipientsObj?.recipientsData.filter((data) => data.currency === batchPaymentDetails.buy_currency);
                    updateRecipientsData(filteredRecipients);
                    lockFilterRecipients.current = true;
                }
            }
        }
    }, [batchPaymentDetails, isRecipientFetched, isFetchedBatchPayment, frequentRecipients, recipientsDataGrouped]);

    useEffect(() => {
        if (uuid && selectedRecipients?.length > 0 && recipientSelectedFlag && batchPaymentDetails?.tcc_quote_b_b_self_beneficiary_map?.length > 0) {
            let batchShowInvoiceAndDateFields = {}

            // eslint-disable-next-line no-unused-vars, array-callback-return
            selectedRecipients?.forEach((recipient, index) => {
                batchPaymentDetails?.tcc_quote_b_b_self_beneficiary_map?.forEach(item => {
                    if (recipient?.id === item?.tcc_beneficiary?.id) {
                        handleOnChange({ ['amount_' + index]: item?.beneficiary_amt });
                        handleOnChange({ ['paymentPurpose_' + index]: item?.payment_reason });
                        handleOnChange({ ['paymentReference_' + index]: item?.payment_reference });
                        if (!!item?.payment_purpose_code) {
                            let crossBorderPurpose = getLocalOrAllCurrencyCrossBorderPurposeCode(PaymentPurposeCodes, item?.tcc_beneficiary?.bank_country, item?.tcc_beneficiary?.currency)?.find((c => c.code === item?.payment_purpose_code));
                            handleOnChange({
                                ['crossBorderPaymentPurposeCode_' + index]: item?.payment_purpose_code,
                                ['paymentPurpose_' + index]: crossBorderPurpose?.label,
                            });

                            batchShowInvoiceAndDateFields[index] = crossBorderPurpose?.trade_related === 1
                        }
                        handleSettlementMethod(index, item?.payment_type);
                    }
                })
            });

            setState({ showInvoiceAndDateFields: batchShowInvoiceAndDateFields });
        }
    }, [uuid, batchPaymentDetails, recipientSelectedFlag])

    useEffect(() => {
        if (recipientsObj?.recipientsData && selectedRecipients && selectedRecipients.length === 1) {
            let filteredRecipients = recipientsObj?.recipientsData.filter((data) => data.currency === selectedRecipients[0].currency)
            updateRecipientsData(filteredRecipients);
        }
        if (recipientsObj?.recipientsData && selectedRecipients && selectedRecipients.length === 0) {
            updateRecipientsData(recipientsObj?.recipientsData);
        }

        if (!selectedRecipients?.length) {
            form.resetFields();
            setState({ showInvoiceAndDateFields: {} })
        }

        const PayProceedButtonShow = checkPaytoProceed();
        setState({ buttonShow: PayProceedButtonShow })
    }, [selectedRecipients]);

    useEffect(() => {
        if (recipientsObj?.recipientsData && !fetchingRecipients) {
            updateRecipientsData(recipientsObj?.recipientsData);
        }
    }, [recipientsObj, fetchingRecipients]);

    const getValidation = (curr, name) => {
        const amt = form.getFieldValue(name);
        let selectedCurrency;
        let currencyLimitUKOrEEA = false
        let total_daily_limit = false
        if (currencyRules) {
            selectedCurrency = currencyRules.find(item => item?.upper_limit_ccy === curr);
            if (selectedCurrency?.currency_id === 12 && selectedCurrency?.total_daily_limit && parseFloat(amt) > parseFloat(selectedCurrency?.total_daily_limit)) {
                total_daily_limit = true
            } else {
                total_daily_limit = false
            }
            if (selectedCurrency && Object.keys(selectedCurrency).length && selectedCurrency?.upper_limit && amt && parseFloat(amt) > parseFloat(selectedCurrency?.upper_limit)) {
                currencyLimitUKOrEEA = true;
            } else {
                currencyLimitUKOrEEA = false;
            }

        }
        return (
            <Typography.Text style={{ visibility: (currencyLimitUKOrEEA || total_daily_limit) ? 'visible' : 'hidden' }} className='fs-14px ant-typography-danger'>
                {total_daily_limit ? `Maximum Total Daily Limit Reached for ${curr ?? ''}` :
                    `Maximum limit for ${curr ?? ''} is ${accountingFormat(selectedCurrency?.upper_limit)}`
                }
            </Typography.Text>
        )
    }

    // Check if cross border code is needed
    // based on recipient country and ccy
    // If needed codes array will be returned ekse null
    const checkIfCrossBorderPurposeCodes = (country, ccy) => {
        if (ALLOWED_COUNTRIES_ALL_CURRENCIES_PAYMENT_PURPOSE_CODE.includes(country)) {
            for (const [key, value] of Object.entries(PaymentPurposeCodes)) {
                if (key.includes(country)) {
                    return value;
                }
            }
        }
        return PaymentPurposeCodes?.[`${country}_${ccy}`];
    }

    function checkPaytoProceed() {
        if (!Array.isArray(selectedRecipients)) {
            return false;
        }
        for (const recipient of selectedRecipients) {
            if (typeof recipient !== 'object' || recipient === null) {
                return false;
            }
            if (
                !(
                    'settlementMethod' in recipient &&
                    'paymentReference' in recipient &&
                    'paymentPurpose' in recipient &&
                    'amount' in recipient &&
                    recipient.settlementMethod.trim() &&
                    recipient.paymentReference.trim() &&
                    recipient.paymentPurpose.trim() &&
                    Number.isFinite(recipient.amount) &&
                    recipient.amount > 0
                )
            ) {
                return false;
            }

            if (checkIfCrossBorderPurposeCodes(recipient.bank_country, recipient.currency)) {
                if (!recipient?.payment_purpose_code || recipient?.payment_purpose_code === '') {
                    return false;
                }
            }
            if (recipient?.currency === 'INR') {
                const crossBorderPaymentPurposeCode = getLocalOrAllCurrencyCrossBorderPurposeCode(PaymentPurposeCodes, recipient?.bank_country, recipient?.currency)
                let currentCrossBorderPurposeCode = crossBorderPaymentPurposeCode?.find((c) => c.code === recipient.payment_purpose_code) || { trade_related: false };
                if (currentCrossBorderPurposeCode?.trade_related === 1 && !('invoiceNumber' in recipient && 'invoiceDate' in recipient && recipient?.invoiceNumber.trim() && recipient?.invoiceDate.trim())) {
                    return false;
                }
            }
        }
        return true;
    }

    const resetData = () => {
        selectedRecipients && selectedRecipients.forEach((_, key) => {
            handleDelete(key);
        })
        setState({
            selectedRecipients: undefined,
            paymentPurposesOther: [],
            buttonShow: false,
            recipientLoader: false,
            backFromSummary: false,
            recipientSelectedFlag: false
        });
        setPaymentSummarryState({
            fundingCurrency: undefined,
            proceed: false,
            recipientsFormatted: undefined,
            currencies: undefined,
            commonCurrencies: undefined,
            firstDate: undefined,
            fundingMethod: 'Push Funds',
            rate: undefined,
            youSend: 0,
            setAmount: "buy",
            commissionPercentage: undefined,
            minimumCommission: undefined,
            displayRate: "-",
            displayRateInversed: "-",
            seconds: 0,
            fullPageSpinner: false,
            quoteAccepted: false,
            quoteId: undefined,
            totalFees: 0,
            volopaAccountHolderName: undefined,
            volopaAccountSortCode: undefined,
            volopaAccountNumber: undefined,
            volopaAccountCountry: undefined,
            volopaAccountAddress: undefined,
            volopaAccountName: undefined,
            volopaAccountPaymentRef: undefined,
            volopaAccountIban: undefined,
            volopaAccountSwift: undefined,
            volopaAccountNumberType: undefined,
            volopaAccountSortCodeLabel: 'Sort Code',
            volopaAccountDetails: undefined,
            fundingLimitReached: false,
            totalAMount: 0,
            youSendTotal: 0,
            timeout: undefined,
        });
        form.resetFields();
    }

    const updateRecipientsData = (rec) => {
        let responseData = cloneDeep(rec);

        // If in a repeat batch and coming back from payment summary
        // Filter recipients by selectedRecipients ccy
        // since coming back from payment summary we do not have uuid to re-filter them
        if (selectedRecipients?.length && (!!backFromSummary || (!backFromSummary && !!uuid))) {
            responseData = responseData?.filter((data) => data.currency === selectedRecipients[0].currency);
        }

        const frequentRecipients = []?.concat(responseData)
            .sort((a, b) => a.payment_count < b.payment_count ? 1 : -1)
            .slice(0, 4);
        const obj = responseData?.reduce((acc, c) => {
            let recName = c.name !== "undefined undefined" ? c.name[0] : c.beneficiary_company_name[0];
            const letter = recName.toUpperCase();
            acc[letter] = (acc[letter] || []).concat(c);
            return acc;
        }, {})
        const sortedRecipients = !!obj && Object.entries(obj)?.map(([letter, recipients]) => {
            return {
                letter,
                recipients: recipients?.filter(recipient =>
                    frequentRecipients?.map(fRecipient => fRecipient.id).indexOf(recipient?.id) === -1
                )
            };
        }).sort((a, b) => a.letter.localeCompare(b.letter))?.filter(item => item?.recipients?.length > 0);
        sortedRecipients?.length > 0 && sortedRecipients?.forEach((val) => {
            const key = val.letter;
            if (!groupedRecipients[key])
                groupedRecipients[key] = [val.recipients];
            else
                groupedRecipients[key].push(val.recipients);
        });
        setState({ frequentRecipients: frequentRecipients, recipientsDataGrouped: groupedRecipients });
    }

    const setFormData = () => {
        selectedRecipients && selectedRecipients.forEach((val, key) => {
            form.setFieldValue('amount_' + key, val.amount);
            form.setFieldValue('ccEmail_' + key, val.ccEmail);
            form.setFieldValue('email_' + key, val.email);
            form.setFieldValue('paymentPurpose_' + key, val.paymentPurpose);
            form.setFieldValue('paymentPurposeOther_' + key, val.paymentPurposeOther);
            form.setFieldValue('paymentReference_' + key, val.paymentReference);
            form.setFieldValue('amount_' + key + '_1', val.amount);
            form.setFieldValue('ccEmail_' + key + '_1', val.ccEmail);
            form.setFieldValue('email_' + key + '_1', val.email);
            form.setFieldValue('paymentPurpose_' + key + '_1', val.paymentPurpose);
            form.setFieldValue('paymentReference_' + key + '_1', val.paymentReference);
            form.setFieldValue('paymentPurposeOther_' + key + '_1', val.paymentPurposeOther);
            form.setFieldValue('crossBorderPaymentPurposeCode_' + key + '_1', val.crossBorderPaymentPurposeCode);
            form.setFieldValue('crossBorderPaymentPurposeCode_' + key, val.crossBorderPaymentPurposeCode);
            form.setFieldValue('invoiceNumber_' + key, '');
            form.setFieldValue('invoiceDate_' + key, '');
            form.setFieldValue('invoiceNumber_' + key + '_1', '');
            form.setFieldValue('invoiceDate_' + key + '_1', '');
        })
    }


    const CrossBorderPaymentPurposeCodeChange = (key, selectedPurposeCode, crossBorderPaymentPurposeCode) => {
        let currentCrossBorderPurposeCode = crossBorderPaymentPurposeCode?.find((c) => c.code === selectedPurposeCode) || { trade_related: false };
        if (currentCrossBorderPurposeCode?.trade_related === 1) {
            setState({ showInvoiceAndDateFields: { ...showInvoiceAndDateFields, [key]: true } })
        } else {
            setState({ showInvoiceAndDateFields: { ...showInvoiceAndDateFields, [key]: false } })
        }
    }

    // eslint-disable-next-line
    let currentRecipientEmail = (id) => {
        const currentLoopedRecipient = recipientsObj?.recipientsData.find((data) => (data.id === id));
        return currentLoopedRecipient?.email ?? undefined;
    }

    const handleSelect = (keys) => {
        if (recipientsObj?.recipientsData) {
            let paymentMethodsValid = true;
            const tempRecipientsObj = cloneDeep(recipientsObj);

            const allSelectedRecipients = keys?.map(key => (
                { ...tempRecipientsObj?.recipientsData?.find(item => (item.id === key)) }
            ));

            const newSelectedRecipients = allSelectedRecipients?.filter((item, key) => {
                if (!(selectedRecipients?.find(obj => obj.id === item.id))) {
                    if (item.payment_types?.includes('priority') && !item?.payment_types?.includes('regular')) {
                        item.settlementMethod = 'priority';
                    }
                    if (item.payment_types?.includes('regular') && !item?.payment_types?.includes('priority')) {
                        item.settlementMethod = 'regular';
                    }
                    if (!item.settlementMethod) {
                        paymentMethodsValid = false;
                    }
                    form.setFieldValue('invoiceNumber_' + key, '');
                    form.setFieldValue('invoiceDate_' + key, '');
                    form.setFieldValue('invoiceNumber_' + key + '_1', '');
                    form.setFieldValue('invoiceDate_' + key + '_1', '');
                    return true;
                }
                return false;
            });

            allSelectedRecipients?.forEach((val, key) => {
                if (val.email) {
                    form.setFieldValue('email_' + key, val.email);
                    form.setFieldValue('email_' + key + '_1', val.email);
                }
            });

            const prevSelectedRecipients = selectedRecipients || [];
            setState({
                selectedRecipients: [...prevSelectedRecipients, ...newSelectedRecipients],
                buttonShow: paymentMethodsValid,
                recipientSelectedFlag: true
            });
        }
    }

    const handleDeselect = (id) => {
        for (const [key, value] of Object.entries(selectedRecipients)) {
            if (value.id === id) {
                handleDelete(key);
            }
        }
    }

    const handleDelete = key => {
        let newSelectedRecipients = [...selectedRecipients];
        newSelectedRecipients.splice(key, 1);
        form.setFieldValue('amount_' + key, undefined);
        form.setFieldValue('ccEmail_' + key, undefined);
        form.setFieldValue('email_' + key, undefined);
        form.setFieldValue('paymentPurpose_' + key, undefined);
        form.setFieldValue('paymentReference_' + key, undefined);
        form.setFieldValue('amount_' + key + '_1', undefined);
        form.setFieldValue('ccEmail_' + key + '_1', undefined);
        form.setFieldValue('email_' + key + '_1', undefined);
        form.setFieldValue('paymentPurpose_' + key + '_1', undefined);
        form.setFieldValue('paymentReference_' + key + '_1', undefined);
        form.setFieldValue('crossBorderPaymentPurposeCode_' + key + '_1', undefined);
        form.setFieldValue('crossBorderPaymentPurposeCode_' + key, undefined);
        form.setFieldValue('invoiceDate_' + key, undefined);
        form.setFieldValue('invoiceNumber_' + key, undefined);
        form.setFieldValue('invoiceDate_' + key + '_1', undefined);
        form.setFieldValue('invoiceNumber_' + key + '_1', undefined);
        showInvoiceAndDateFields[key] = false;
        newSelectedRecipients.forEach((val, key) => {
            form.setFieldValue('email_' + key, val.email);
            form.setFieldValue('email_' + key + '_1', val.email);
        })
        setState({ selectedRecipients: newSelectedRecipients });
    }

    const handleOnChange = values => {
        if (values) {
            let names = Object.keys(values);
            let keys = names[0].split('_');
            let newSelectedRecipients = [...selectedRecipients];

            form.setFieldValue(keys[0] + '_' + keys[1], values[names[0]]);
            form.setFieldValue(keys[0] + '_' + keys[1] + '_1', values[names[0]]);

            let newPaymentPurposesOther = [...paymentPurposesOther]
            if (keys[0] === "paymentPurpose" && values[names[0]] === "Other") {
                newPaymentPurposesOther[keys[1]] = true;
            }
            if (keys[0] === "paymentPurpose" && values[names[0]] !== "Other") {
                newPaymentPurposesOther[keys[1]] = false;
            }
            setState({ paymentPurposesOther: newPaymentPurposesOther });

            if (keys[0] === "crossBorderPaymentPurposeCode") {
                // We update so many unnecessary fields here
                // but complexities with state updates requires them
                // Ultimetaly recipient.payment_purpose_code is only need if cross border
                let pcodes = checkIfCrossBorderPurposeCodes(newSelectedRecipients[keys[1]]?.bank_country, newSelectedRecipients[keys[1]]?.currency);
                let pcodeLabel = pcodes?.find((c) => c.code === values[names[0]])?.label || 'Other transfers';
                form.setFieldValue('paymentPurpose_' + keys[1], pcodeLabel);
                form.setFieldValue('paymentPurpose_' + keys[1] + '_1', pcodeLabel);
                newSelectedRecipients[keys[1]]['paymentPurpose'] = pcodeLabel;
                newSelectedRecipients[keys[1]]['payment_reason'] = pcodeLabel;
                newSelectedRecipients[keys[1]]['payment_purpose_code'] = values[names[0]];
            }

            newSelectedRecipients[keys[1]][keys[0]] = values[names[0]];
            setState({ selectedRecipients: newSelectedRecipients });
        }
    }

    const handleSettlementMethod = (key, value) => {
        if (selectedRecipients) {
            let newSelectedRecipients = [...selectedRecipients];
            newSelectedRecipients[key].settlementMethod = value;
            // eslint-disable-next-line no-unused-vars
            let paymentMethodsValid = true;
            selectedRecipients?.forEach(value => {
                if (!value.settlementMethod) {
                    paymentMethodsValid = false;
                }
            })
            setState({ selectedRecipients: newSelectedRecipients, buttonShow: paymentMethodsValid });
        }
    }

    const handleSubmit = () => {
        navigate(URLS.PaymentSummary);
    }

    const handleModal = async (modalOpen, newRecipient) => {
        let newSelectedRecipientsFromModal = [...(selectedRecipients || []), newRecipient];
        let paymentMethodsValid = true;
        newSelectedRecipientsFromModal.forEach((val, key) => {
            if (val.payment_types?.includes('priority') && !val.payment_types?.includes('regular')) {
                val.settlementMethod = 'priority';
            }
            if (val.payment_types?.includes('regular') && !val.payment_types?.includes('priority')) {
                val.settlementMethod = 'regular';
            }
            if (!val.settlementMethod) {
                paymentMethodsValid = false;
            }
        })

        setState({ selectedRecipients: newSelectedRecipientsFromModal, buttonShow: paymentMethodsValid, recipientModalOpen: modalOpen });
        await refetchRecipients();
    }
    const goToSpecificRecipient = (recipient) => {
        setState({ viewDetailsModalOpen: recipient?.id })
    }
    return (
        <>
            <Spin size={'large'} spinning={fetchingRecipients || (!!uuid && loadingBatchPaymentDetails) || fetchingPaymentCodes}>
                <FullScreenModal onCancel={() => setState({ recipientModalOpen: false })} open={recipientModalOpen}><AddRecipient addedFromBatchPayment={true} handleNewRecipient={(modalOpen, newRecipient) => handleModal(modalOpen, newRecipient)} /></FullScreenModal>
                <FullScreenModal onCancel={() => setState({ viewDetailsModalOpen: false })} open={!!viewDetailsModalOpen}>
                    <RecipientDetails recipientId={viewDetailsModalOpen} />
                </FullScreenModal>
                <Row>
                    <Col span={24}>
                        <PageDescription title='Pay Multiple Recipients' />
                    </Col>
                </Row>
                <Row className="m-t-10" gutter={16}>
                    <Col flex='400px'>
                        <Select
                            dropdownStyle={{ paddingTop: 0 }}
                            listHeight={400}
                            placeholder='Search Recipient'
                            showSearch
                            filterOption={filterSelect}
                            mode='multiple'
                            onChange={handleSelect}
                            loading={fetchingRecipients}
                            onDeselect={handleDeselect}
                            value={selectedRecipients && selectedRecipients.length > 0 ? selectedRecipients.map(val => val.id) : []}
                            className="full-percent-width"
                            maxTagCount={1}
                        >
                            <Select.OptGroup
                                style={{ padding: 0, margin: 0, backgroundColor: 'red' }}
                                className='test'
                                label={
                                    <Row style={{ marginLeft: -12, marginRight: -12, marginTop: -5, marginBottom: -5 }} className='bg-dark-green p-t-5 p-b-5' align='middle'>
                                        <Col span={24} className='p-l-10 p-r-10'>
                                            <Row justify='space-between'>
                                                <Col>
                                                    <Typography.Text className='muli semi-bold fs-24px white'>
                                                        Common
                                                    </Typography.Text>
                                                </Col>
                                                <Col>
                                                    <Button type='primary' className='bg-light-green' size='small' onClick={() => setState({ recipientModalOpen: true })}>Add New Recipient</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                }
                                key='frequent-recipients'
                            >
                                {frequentRecipients && frequentRecipients.length > 0 && frequentRecipients.map((item) => (
                                    <Select.Option key={item.id} value={item.id}>{item.name === "undefined undefined" ? item.beneficiary_company_name : item.name}</Select.Option>
                                ))}
                            </Select.OptGroup>
                            {
                                recipientsDataGrouped && Object.keys(recipientsDataGrouped).map(val => (
                                    <Select.OptGroup label={val} key={val}>
                                        {recipientsDataGrouped[val][0].map(item => (
                                            <Select.Option value={item.id} key={item.id}>{item.name === "undefined undefined" ? item.beneficiary_company_name : item.name}</Select.Option>
                                        ))}
                                    </Select.OptGroup>
                                ))
                            }
                        </Select >
                    </Col >
                </Row >
                <Form
                    layout='vertical'
                    onValuesChange={handleOnChange}
                    onFinish={handleSubmit}
                    form={form}
                >
                    {selectedRecipients && selectedRecipients.length > 0 && (
                        <>
                            {selectedRecipients.map((item, key) => (
                                <Row className="m-t-10" key={key} gutter={12} justify='center'>
                                    <Col xxl={6} xl={12} lg={12} md={14} sm={16} xs={24}>
                                        <Card hoverable className="b-g full-percent-height"  >
                                            <Row justify='space-between'>
                                                <Col>
                                                    <Typography.Text className='fs-18px medium dark-green'>Recipient Details</Typography.Text>
                                                </Col>
                                                <Col><AiOutlineClose size={24} onClick={() => handleDelete(key)} /></Col>
                                            </Row>
                                            <Row gutter={[16, 12]} className='m-t-10'>
                                                <Col>
                                                    <Avatar size={70} className='bg-white b-g p-t-2' icon={<FaUser className='grey' />} />
                                                </Col>
                                                <Col flex='auto'>
                                                    <Space direction='vertical' size={0} style={{ lineHeight: '20px' }}>
                                                        <Typography.Text className='muli semi-bold fs-24px dark-green'>{item.name === "undefined undefined" ? item.beneficiary_company_name : item.name}</Typography.Text>
                                                        <Typography.Text className='muli light fs-18px dark-green'>{(item && item.beneficiary_first_name) ? item.beneficiary_first_name[0] + "." + item.beneficiary_last_name : ""}</Typography.Text>
                                                        <Typography.Text className='muli light fs-18px dark-green'>{item.currency}</Typography.Text>
                                                        <Typography.Text className='muli light fs-18px dark-green'>{item.bank_name}</Typography.Text>
                                                        <Typography.Text className='muli light fs-18px dark-green'>{item.iban ? item.iban && "**** ***** " + item.iban.substr(-4) : (item.account_number && "**** ***** " + item.account_number.substr(-4))}</Typography.Text>
                                                    </Space>
                                                    <Row justify='end'>
                                                        <Col>
                                                            <Typography.Text onClick={() => goToSpecificRecipient(item)} className='underline muli semi-bold fs-18px pointer'>View Details</Typography.Text>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={24} xxl={0}>
                                                    <Row>
                                                        <Col>
                                                            <Space>
                                                                <Typography.Text className="fs-18px medium dark-green">Enter Payment Details</Typography.Text>
                                                                <Tooltip title="Please the amount you wish this recipient to receive."><FaInfoCircle className='dark-green' /></Tooltip>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={[12, 12]}>
                                                        <Col flex='90px'>
                                                            <Input
                                                                readOnly
                                                                value={item.currency}
                                                                prefix={FLAGS[item.currency] && <Image src={FLAGS[item.currency]} alt={item.currency} preview={false} width={24} />}
                                                            />
                                                        </Col>
                                                        <Col flex='auto' style={{ marginBottom: 20 }}>
                                                            <Form.Item
                                                                name={'amount_' + key + '_1'}
                                                                //initialValue={item['amount']}
                                                                style={{ marginBottom: 5 }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please enter amount'
                                                                    }
                                                                ]}
                                                            >
                                                                <InputNumber
                                                                    controls={false}
                                                                    className='full-percent-width'
                                                                    min={0}
                                                                    formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
                                                                    parser={(val) => numberFormat(val)}
                                                                />
                                                            </Form.Item>
                                                            {getValidation(item?.currency, 'amount_' + key + '_1')}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Space>
                                                                <Typography.Text className='fs-18px medium dark-green'>Settlement Method</Typography.Text>
                                                                <Tooltip title="Priority Payments: Settled via SWIFT network. Recalls and payment tracing available. Regular Payments: Local payment method, cannot be recalled and no tracing available.
Please note that Regular Payments may not be available due to the following reasons: (1) Local payment options are not available to this payment corridor (2) You have not added the Regular Payment details for the selected recipient."><FaInfoCircle className='dark-green' /> </Tooltip>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Space>
                                                                <Button
                                                                    type='primary'
                                                                    ghost={item.settlementMethod !== 'regular' ? true : false}
                                                                    onClick={() => handleSettlementMethod(key, 'regular')}
                                                                    disabled={item.payment_types?.includes('priority') && !item.payment_types?.includes('regular')}
                                                                >
                                                                    Regular
                                                                </Button>
                                                                <Button
                                                                    type='primary'
                                                                    ghost={item.settlementMethod !== 'priority' ? true : false}
                                                                    style={item.settlementMethod === 'priority' ? { color: 'white' } : {}}
                                                                    onFocus={(e) => {
                                                                        if (item.settlementMethod === 'priority') {
                                                                            e.target.style.color = 'white';
                                                                        }
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        if (item.settlementMethod === 'priority') {
                                                                            e.target.style.color = 'white';
                                                                        } else {
                                                                            e.target.style.color = ''; // Reverts to default color
                                                                        }
                                                                    }}
                                                                    className='warning'
                                                                    onClick={() => handleSettlementMethod(key, 'priority')}
                                                                    disabled={item.payment_types?.includes('regular') && !item.payment_types?.includes('priority')}
                                                                >
                                                                    Priority
                                                                </Button>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                    <Row className="m-t-20">
                                                        <Col>
                                                            <Space>
                                                                <Typography.Text className='fs-18px medium dark-green'>Recipient Email Address</Typography.Text>
                                                                <Tooltip title="Once the payment has been sent, we will send an email confirmation to your recipient that the payment is on it's way."><FaInfoCircle className='dark-green' /></Tooltip>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                name={'email_' + key + '_1'}
                                                                initialValue={item?.email || ''}
                                                            >
                                                                <Input placeholder='Enter email' readOnly={true} />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                name={'ccEmail_' + key + '_1'}
                                                                //  initialValue={item['ccEmail']}
                                                                rules={[
                                                                    {
                                                                        type: 'email',
                                                                        message: 'please enter a valid email'
                                                                    }
                                                                ]}
                                                            >
                                                                <Input placeholder='Enter CC email' />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Space>
                                                                <Typography.Text className='fs-18px medium dark-green'>Reason For Payment</Typography.Text>
                                                                <Tooltip title="The Purpose of your payment."><FaInfoCircle className='dark-green' /></Tooltip>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                hidden={!(checkIfCrossBorderPurposeRecipient(PaymentPurposeCodes, item).length ? true : false)}
                                                                name={'crossBorderPaymentPurposeCode_' + key + '_1'}
                                                                rules={[
                                                                    {
                                                                        required: !!(checkIfCrossBorderPurposeRecipient(PaymentPurposeCodes, item).length ? true : false),
                                                                        message: 'Please select a payment purpose'
                                                                    }
                                                                ]}
                                                            >

                                                                <Select
                                                                    onChange={(value) =>
                                                                        CrossBorderPaymentPurposeCodeChange(key, value, getLocalOrAllCurrencyCrossBorderPurposeCode(PaymentPurposeCodes, item?.bank_country, item?.currency))}
                                                                    style={{ minWidth: '250px' }}
                                                                    className='b-g'
                                                                >
                                                                    {getLocalOrAllCurrencyCrossBorderPurposeCode(PaymentPurposeCodes, item?.bank_country, item?.currency)?.map((pcode) => (
                                                                        <Select.Option key={pcode.code} value={pcode.code}>{pcode.label}</Select.Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>

                                                            <Form.Item
                                                                hidden={!!(checkIfCrossBorderPurposeRecipient(PaymentPurposeCodes, item).length ? true : false)}
                                                                name={'paymentPurpose_' + key + '_1'}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please select a payment reason'
                                                                    }
                                                                ]}
                                                            >
                                                                <Select style={{ minWidth: '250px' }} className='b-g full-percent-width'>
                                                                    <Select.Option key={0} value="Payment of Invoice(s)">
                                                                        Payment of Invoice(s)
                                                                    </Select.Option>
                                                                    <Select.Option key={1} value="Internal Payment to Company own account">
                                                                        Internal Payment to Company own account
                                                                    </Select.Option>
                                                                    <Select.Option key={2} value="Payment of Expenses">
                                                                        Payment of Expenses
                                                                    </Select.Option>
                                                                    <Select.Option key={3} value="Payment of Salaries">
                                                                        Payment of Salaries
                                                                    </Select.Option>
                                                                    <Select.Option key={4} value="Payment of Commissions">
                                                                        Payment of Commissions
                                                                    </Select.Option>
                                                                    <Select.Option key={5} value="Disbursement of Dividends">
                                                                        Disbursement of Dividends
                                                                    </Select.Option>
                                                                    <Select.Option key={6} value="Director/Employee loan">
                                                                        Director/Employee loan
                                                                    </Select.Option>
                                                                    <Select.Option key={7} value="Other">
                                                                        Other (please fill in the Other Reason field below)
                                                                    </Select.Option>
                                                                </Select>
                                                            </Form.Item>
                                                            {(paymentPurposesOther && paymentPurposesOther[key]) && (
                                                                <Form.Item
                                                                    hidden={!!(checkIfCrossBorderPurposeRecipient(PaymentPurposeCodes, item).length ? true : false)}
                                                                    name={'paymentPurposeOther_' + key + '_1'}
                                                                >
                                                                    <Input placeholder='Other Reason' />
                                                                </Form.Item>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    {showInvoiceAndDateFields[key] && <Col span={24}>
                                                        <Row>
                                                            <Col>
                                                                <Space>
                                                                    <Typography.Text className='fs-18px medium dark-green'>Invoice Number</Typography.Text>
                                                                    <Tooltip title="The Invoice Number of your payment."><FaInfoCircle className='dark-green' /></Tooltip>
                                                                </Space>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    name={'invoiceNumber_' + key + '_1'}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please Enter the invoice number'
                                                                        }
                                                                    ]}
                                                                >
                                                                    <Input className='b-g' />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Space>
                                                                    <Typography.Text className='fs-18px medium dark-green'>Invoice Date</Typography.Text>
                                                                    <Tooltip title="The Invoice Date of your payment."><FaInfoCircle className='dark-green' /></Tooltip>
                                                                </Space>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    name={'invoiceDate_' + key + '_1'}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please Enter the invoice date'
                                                                        }
                                                                    ]}
                                                                >
                                                                    <Input type='date' className='b-g' />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>}
                                                    <Row>
                                                        <Col>
                                                            <Space>
                                                                <Typography.Text className='fs-18px medium dark-green'>Payment References</Typography.Text>
                                                                <Tooltip title="Add payment notes to your recipient in this field."><FaInfoCircle className='dark-green' /></Tooltip>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                name={'paymentReference_' + key + '_1'}
                                                                // initialValue={item['paymentReference']}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please select a payment reference'
                                                                    }
                                                                ]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col xxl={5} xl={0} lg={0} md={0} sm={0} xs={0}>
                                        <Card hoverable className="b-g full-percent-height"  >
                                            <Row>
                                                <Col>
                                                    <Space>
                                                        <Typography.Text className="fs-18px medium dark-green">Enter Payment Details</Typography.Text>
                                                        <Tooltip title="Please enter the amount you wish this recipient to receive."><FaInfoCircle className='dark-green' /></Tooltip>
                                                    </Space>
                                                </Col>
                                            </Row>
                                            <Row gutter={[12, 12]}>
                                                <Col flex='90px'>
                                                    <Input
                                                        readOnly
                                                        value={item.currency}
                                                        prefix={FLAGS[item.currency] && <Image src={FLAGS[item.currency]} alt={item.currency} preview={false} width={24} />}
                                                    />
                                                </Col>
                                                <Col flex='auto'>
                                                    <Form.Item
                                                        name={'amount_' + key}
                                                        //  initialValue={item['amount']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter amount'
                                                            }
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            controls={false}
                                                            className='full-percent-width'
                                                            min={0}
                                                            formatter={(val, info) => info?.userTyping ? accountingFormat(val, false) : accountingFormat(val)}
                                                            parser={(val) => numberFormat(val)}
                                                        />
                                                    </Form.Item>
                                                    {getValidation(item?.currency, 'amount_' + key + '_1')}
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col xxl={4} xl={0} lg={0} md={0} sm={0} xs={0}>
                                        <Card hoverable className="b-g full-percent-height"  >
                                            <Row>
                                                <Col>
                                                    <Space>
                                                        <Typography.Text className='fs-18px medium dark-green'>Settlement Method</Typography.Text>
                                                        <Tooltip title="Priority Payments: Settled via SWIFT network. Recalls and payment tracing available. Regular Payments: Local payment method, cannot be recalled and no tracing available.
Please note that Regular Payments may not be available due to the following reasons: (1) Local payment options are not available to this payment corridor (2) You have not added the Regular Payment details for the selected recipient."><FaInfoCircle className='dark-green' /> </Tooltip>
                                                    </Space>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Space>
                                                        <Button
                                                            type='primary'
                                                            ghost={item.settlementMethod !== 'regular' ? true : false}
                                                            onClick={() => handleSettlementMethod(key, 'regular')}
                                                            disabled={item.payment_types?.includes('priority') && !item.payment_types?.includes('regular')}
                                                        >
                                                            Regular
                                                        </Button>
                                                        <Button
                                                            type='primary'
                                                            ghost={item.settlementMethod !== 'priority' ? true : false}
                                                            style={item.settlementMethod === 'priority' ? { color: 'white' } : {}}
                                                            onFocus={(e) => {
                                                                if (item.settlementMethod === 'priority') {
                                                                    e.target.style.color = 'white';
                                                                }
                                                            }}
                                                            onBlur={(e) => {
                                                                if (item.settlementMethod === 'priority') {
                                                                    e.target.style.color = 'white';
                                                                } else {
                                                                    e.target.style.color = ''; // Reverts to default color
                                                                }
                                                            }}
                                                            className='warning'
                                                            onClick={() => handleSettlementMethod(key, 'priority')}
                                                            disabled={item.payment_types?.includes('regular') && !item.payment_types?.includes('priority')}
                                                        >
                                                            Priority
                                                        </Button>
                                                    </Space>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col xxl={5} xl={0} lg={0} md={0} sm={0} xs={0}>
                                        <Card hoverable className="b-g full-percent-height"  >
                                            <Row>
                                                <Col>
                                                    <Space>
                                                        <Typography.Text className='fs-18px medium dark-green'>Recipient Email Address</Typography.Text>
                                                        <Tooltip title="Once the payment has been sent, we will send an email confirmation to your recipient that the payment is on it's way."><FaInfoCircle className='dark-green' /></Tooltip>
                                                    </Space>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name={'email_' + key}
                                                        initialValue={item?.email || ''}
                                                    >
                                                        <Input placeholder='Enter email' readOnly={true} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name={'ccEmail_' + key}
                                                        // initialValue={item['ccEmail']}
                                                        rules={[
                                                            {
                                                                type: 'email',
                                                                message: 'please enter a valid email'
                                                            }
                                                        ]}
                                                    >
                                                        <Input placeholder='Enter CC email' />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col xxl={4} xl={0} lg={0} md={0} sm={0} xs={0}>
                                        <Row align="middle" className="full-percent-height">
                                            <Col span={24}>
                                                <Row justify="center">
                                                    <Col>
                                                        <Space>
                                                            <Typography.Text className='fs-18px medium dark-green'>Reason For Payment</Typography.Text>
                                                            <Tooltip title="The Purpose of your payment."><FaInfoCircle className='dark-green' /></Tooltip>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            hidden={!(checkIfCrossBorderPurposeRecipient(PaymentPurposeCodes, item).length ? true : false)}
                                                            name={'crossBorderPaymentPurposeCode_' + key}
                                                            rules={[
                                                                {
                                                                    required: !!(checkIfCrossBorderPurposeRecipient(PaymentPurposeCodes, item).length ? true : false),
                                                                    message: 'Please select a payment purpose'
                                                                }
                                                            ]}
                                                        >
                                                            <Select
                                                                onChange={(value) =>
                                                                    CrossBorderPaymentPurposeCodeChange(key, value, getLocalOrAllCurrencyCrossBorderPurposeCode(PaymentPurposeCodes, item?.bank_country, item?.currency))}
                                                                style={{ minWidth: '250px' }}
                                                                className='b-g'
                                                            >
                                                                {getLocalOrAllCurrencyCrossBorderPurposeCode(PaymentPurposeCodes, item?.bank_country, item?.currency)?.map((pcode) => (
                                                                    <Select.Option key={pcode.code} value={pcode.code}>{pcode.label}</Select.Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>

                                                        <Form.Item
                                                            hidden={!!(checkIfCrossBorderPurposeRecipient(PaymentPurposeCodes, item).length ? true : false)}
                                                            name={'paymentPurpose_' + key}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please select a payment reason'
                                                                }
                                                            ]}
                                                        >
                                                            <Select style={{ minWidth: '250px' }} className='b-g full-percent-width'>
                                                                <Select.Option key={0} value="Payment of Invoice(s)">
                                                                    Payment of Invoice(s)
                                                                </Select.Option>
                                                                <Select.Option key={1} value="Internal Payment to Company own account">
                                                                    Internal Payment to Company own account
                                                                </Select.Option>
                                                                <Select.Option key={2} value="Payment of Expenses">
                                                                    Payment of Expenses
                                                                </Select.Option>
                                                                <Select.Option key={3} value="Payment of Salaries">
                                                                    Payment of Salaries
                                                                </Select.Option>
                                                                <Select.Option key={4} value="Payment of Commissions">
                                                                    Payment of Commissions
                                                                </Select.Option>
                                                                <Select.Option key={5} value="Disbursement of Dividends">
                                                                    Disbursement of Dividends
                                                                </Select.Option>
                                                                <Select.Option key={6} value="Director/Employee loan">
                                                                    Director/Employee loan
                                                                </Select.Option>
                                                                <Select.Option key={7} value="Other">
                                                                    Other (please fill in the Other Reason field below)
                                                                </Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                        {showInvoiceAndDateFields[key] && <Col span={24}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Space>
                                                                        <Typography.Text className='fs-18px medium dark-green'>Invoice Number</Typography.Text>
                                                                        <Tooltip title="The Invoice Number of your payment."><FaInfoCircle className='dark-green' /></Tooltip>
                                                                    </Space>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Form.Item
                                                                        name={'invoiceNumber_' + key}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please Enter the invoice number'
                                                                            }
                                                                        ]}
                                                                    >
                                                                        <Input style={{ minWidth: '250px' }} />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Space>
                                                                        <Typography.Text className='fs-18px medium dark-green'>Invoice Date</Typography.Text>
                                                                        <Tooltip title="The Invoice Date of your payment."><FaInfoCircle className='dark-green' /></Tooltip>
                                                                    </Space>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Form.Item
                                                                        name={'invoiceDate_' + key}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please Enter the invoice date'
                                                                            }
                                                                        ]}
                                                                    >
                                                                        <Input type='date' style={{ minWidth: '250px' }} />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Col>}
                                                        {(paymentPurposesOther && paymentPurposesOther[key]) && (
                                                            <Form.Item
                                                                hidden={!!(checkIfCrossBorderPurposeRecipient(PaymentPurposeCodes, item).length ? true : false)}
                                                                name={'paymentPurposeOther_' + key + '_1'}
                                                            >
                                                                <Input placeholder='Other Reason' />
                                                            </Form.Item>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <Card hoverable className='b-g'>
                                                            <Row>
                                                                <Col>
                                                                    <Space>
                                                                        <Typography.Text className='fs-18px medium dark-green'>Payment References</Typography.Text>
                                                                        <Tooltip title="Add payment notes to your recipient in this field."><FaInfoCircle className='dark-green' /></Tooltip>
                                                                    </Space>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Form.Item
                                                                        name={'paymentReference_' + key}
                                                                        // initialValue={item['paymentReference']}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please select a payment reference'
                                                                            }
                                                                        ]}
                                                                    >
                                                                        <Input />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            ))}
                            <Row className="m-t-10" justify="space-between" gutter={[12, 24]}>
                                <Col xxl={6} xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <Row justify="center">
                                        <Col xxl={24} xl={6} lg={8} md={10} sm={12} xs={24} >
                                            <Card hoverable className="b-g full-percent-height" onClick={() => setState({ recipientModalOpen: true })} >
                                                <Row gutter={24} align='middle'>
                                                    <Col>
                                                        <Avatar size={70} className='bg-white b-g p-t-2' icon={<AiOutlinePlus className='dark-green' />} />
                                                    </Col>
                                                    <Col>
                                                        <Typography.Text className="dark-green muli semi-bold fs-24px" >Add New Recipient</Typography.Text>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xxl={2} xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <Row justify="center">
                                        <Col>
                                            {buttonShow && <Button type="primary" htmlType="submit">Proceed to pay</Button>}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    )}
                </Form>
            </Spin>
        </>
    )
}

export default PayMultipleRecipients;
