import { useQuery } from "@tanstack/react-query";
import { useExtendedApiRequest } from "Hooks/ExtendedAPI";
import { APIROUTES } from "Constants/ApiRoutes";
import { useTransactionState } from "../SpecificCardTransaction/store";
import { ATTACHMENT_EXTENSIONS, ATTACHMENT_IMG_EXTENSIONS } from "Constants/Common";
import { useApiRequest } from "Hooks/API";
import { notification } from "antd";

const useTransactionQuery = (transactionId) => {
    const extendedApiRequest = useExtendedApiRequest();
    const apiRequest = useApiRequest();
    const setState = useTransactionState(state => state.setState);

    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
        const { data } = await apiRequest(`${APIROUTES.get.transaction_settled}?transactionId=${transactionId}&$with=All`);
        return data;
    }

    // eslint-disable-next-line no-unused-vars
    const _fetchQuery = async () => {
        return new Promise(resolve => {
            setTimeout(resolve([]), 1200);
        });
    }

    const fetchFiles = async (fileStore) => {
        const filePromises = fileStore.map(async (file) => {
            // if (
            // 	!(
            // 		file.file_extension.includes('jpg') ||
            // 		file.file_extension.includes('png') ||
            // 		file.file_extension.includes('jpeg') ||
            // 		file.file_extension.includes('pdf')
            // 	)
            // ) {
            //     return;
            // }

            const extension = file?.file_extension || file?.file_name?.split('.')?.pop();

            if (!ATTACHMENT_EXTENSIONS.includes(extension?.toLowerCase())) {
                return;
            }

            const urlRes = await extendedApiRequest({
                path: `file/file?uuid=${file.uuid}`,
                config: {
                    responseType: 'blob',
                },
                fullResponse: true,
            });

            if (!urlRes || urlRes?.code === "ERR_BAD_RESPONSE" || urlRes.data?.error || urlRes?.response?.data?.error) {
                return;
            }

            let thumbRes = null;
            if (ATTACHMENT_IMG_EXTENSIONS.includes(extension?.toLowerCase())) {
                thumbRes = await extendedApiRequest({
                    path: `file/file/thumbnail?uuid=${file.uuid}`,
                    config: {
                        responseType: 'blob',
                    },
                    fullResponse: true,
                });

                if (thumbRes.data?.error || thumbRes?.response?.data?.error) {
                    return;
                }
            }

            const url = await readFile(urlRes.data);
            const thumb = thumbRes ? await readFile(thumbRes.data) : null;

            return {
                ...file,
                url: url || null,
                thumb: thumb,
                file_extension: extension,
            }
        });
        return await Promise.all(filePromises);
    }

    //
    const readFile = async (file) => {
        return new Promise(function (resolve, reject) {
            var reader = new FileReader();
            reader.onload = (evt) => resolve(evt.target.result);
            reader.onerror = (err) => reject(err)
            reader.readAsDataURL(file);
        });
    }

    //
    const query = useQuery({
        queryKey: ['_transaction', transactionId],
        queryFn: fetchQuery,
        refetchOnMount: 'always',
        select: (res) => res[0],
        refetchOnWindowFocus: false,
        staleTime: 0,
        cacheTime: 0,
        refetchInterval: 0,
        onSuccess: async (resData) => {
            setState({ fileLoading: true })
            let filteredFileStoreWithUrls = [];
            if (resData?.expense?.file_store) {
                const fileStoreWithUrls = await fetchFiles(resData?.expense?.file_store);
                const error = fileStoreWithUrls?.findIndex(file => !file);
                if (error !== -1) {
                    notification.destroy();
                    notification.error({ placement: "top", message: "Error", description: "Retrieving one or more attachments failed." })
                }
                filteredFileStoreWithUrls = fileStoreWithUrls.filter((item) => {
                    return typeof item === 'object';
                });
            }

            const state = {
                transactionId: resData?.id,
                amount: resData?.amount,
                description: resData?.description,
                transaction_category: resData?.transaction_category,
                transaction_vat: resData?.transaction_vat,
                expenseNotes: resData?.expense?.notes || '',
                projectId: resData?.expense?.project_id || '',
                expenseId: resData?.expense?.id,
                expenseTypeDesc: resData?.expense?.expense_type_description,
                fileStore: filteredFileStoreWithUrls,
                attachments: filteredFileStoreWithUrls,
                submitted: resData?.expense?.submitted || 0,
                expense_type_id: resData?.expense?.expense_type_id,
                initData: null,
                fileLoading: false,
            }

            state.initData = {
                fileStore: state.fileStore,
                expenseNotes: state.expenseNotes,
                projectId: resData?.expense?.project_id || '',
                transaction_category: state.transaction_category,
                transaction_vat: state.transaction_vat
            }

            setState(state);
        },
    });

    return query;
}

export { useTransactionQuery };
