import create from "zustand";

const useFundingHistoryState = create((set) => ({
    fundingHistory: undefined,
    fundingHistoryLoading: false,
    showModal: false,
    currency: undefined,
    amount: undefined,
    description: undefined,
    fundingMethod: undefined,
    loadingFund: false,
    message: undefined,
    admin: undefined,
    contentIndex: 0,
    fundingIndex: undefined,
    volopaAccountHolderName: undefined,
    volopaAccountSortCode: undefined,
    volopaAccountNumber: undefined,
    volopaAccountCountry: undefined,
    volopaAccountAddress: undefined,
    volopaAccountName: undefined,
    volopaAccountPaymentRef: undefined,
    volopaAccountIban: undefined,
    volopaAccountSwift: undefined,
    volopaAccountNumberType: undefined,
    volopaAccountSortCodeLabel: 'Sort Code',
    volopaAccountDetails: undefined,
    fundingLimitReached: false,
    loadingFundingData: false,
    totalPages: 1,
    setState: (data) => set({ ...data })
}));

export { useFundingHistoryState };