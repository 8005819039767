import { Link } from "react-router-dom";
import URLS from "Routes/constants";

const pageContent = [
    {
        title: 'User Permissions',
        text: 'User permissions are a function of the user role and the products that are assigned to the users. We have built it to give you a high degree of control which allows you to create granular permissions to suit the specific needs of your business.'
    },
    {
        title: 'User Access',
        text: [
            <>You can control the access each user has to your Volopa account by assigning them products in the <Link to={URLS.ManageUsers} className='underline'>Manage Users</Link> section. By assigning the product to the user, you are happy for them to have access to it.</>,
            "What they can do with that access depends on the user role assigned to them."
        ]
    },
    {
        title: 'User Role',
        text: [
            'The user role controls what functionality the user has for the products assigned to them.',
            'There are four user roles that can be assigned to each user:',
            (
                <ul>
                    <ul className="dashed">
                        <li className="purple">Primary Administrator</li>
                        <li className="error-hover">Administrator</li>
                        <li className="light-green">Business User</li>
                        <li className="dark-green">Card User</li>
                    </ul>
                </ul>
            ),
            'Here is a table breaking down the functionality each role has for the various parts of your Volopa account:'
        ]
    }
];

const pageEndContent = [
    {
        text: [
            'Remember: this table breaks down the functionality each role has but the user requires access to the account to actually use it.',
            'For example, a Business User can have access to your payments section, but if you do not assign the payments account to the user, they will not have access.'
        ]
    },
    {
        title: 'Tips/Notes',
        text: ['Card User cannot be assigned the payments product']
    },
];

const dataSource = [
    {
        type: 'Volopa Account Management',
        userRole: [
            'Manage Primary Administrators',
            '(Add/Edit/Delete)'
        ],
        primaryAdministrator: 'YES',
        administrator: 'NO',
        businessUser: 'NO',
        cardUser: 'NO',
        key: 0
    },
    {
        type: 'Volopa Account Management',
        userRole: [
            'Manage Primary Administrators, Business Users & Card Users',
            '(Add / Edit / Delete)',
            'Access to Manage Account -> Company Account Section'
        ],
        primaryAdministrator: 'YES',
        administrator: 'YES',
        businessUser: 'NO',
        cardUser: 'NO',
        key: 1
    },
    {
        type: 'International Payments',
        userRole: ['Entire Section'],
        primaryAdministrator: 'YES',
        administrator: 'YES',
        businessUser: 'YES',
        cardUser: 'NO',
        key: 2
    },
    {
        type: 'Cards Account Management',
        userRole: [
            'Visibility of Company Wallet',
            'Exchange Currency in Company Wallet',
            'Fund Company Wallet',
            'Load cards from Company Wallet'
        ],
        primaryAdministrator: 'YES',
        administrator: 'YES',
        businessUser: 'NO',
        cardUser: 'NO',
        key: 3
    },
    {
        type: 'Cards Account Management',
        userRole: [
            'Unload card to Company Wallet',
            'Access to Prepaid Cards -> Transactions page'
        ],
        primaryAdministrator: 'YES',
        administrator: 'YES',
        businessUser: 'NO',
        cardUser: 'NO',
        key: 4
    },
    {
        type: 'Cards Control Management',
        userRole: [
            'Manage Auto Loads (Add/Edit/Delete)',
            'Manage Transaction Limits(Add / Edit / Delete)',
            'Manage Authorised Payment Types(Add / Edit / Delete)'
        ],
        primaryAdministrator: 'YES',
        administrator: 'YES',
        businessUser: 'NO',
        cardUser: 'NO',
        key: 5
    },
    {
        type: 'Cards Control Management',
        userRole: [
            'See card PIN (only if user is the cardholder)',
            'Freeze / Unfreeze card'
        ],
        primaryAdministrator: 'YES',
        administrator: 'YES',
        businessUser: 'YES',
        cardUser: 'YES',
        key: 6
    },
    {
        type: 'Card Exchange Currency',
        userRole: ['Entire Section'],
        primaryAdministrator: 'YES',
        administrator: 'YES',
        businessUser: 'NO',
        cardUser: 'NO',
        key: 7
    },
]

export { pageContent, dataSource, pageEndContent }