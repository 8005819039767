import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { accountingFormat } from "Utils";

const useCardAutofundQuery = (cardId) => {
    const apiRequest = useApiRequest();

    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
        // autoload_type: 1 - load by amount, 2 - load by reach amount
        const { data } = await apiRequest(`${APIROUTES.get.prepaidcard_autoload}?prepaidCardId=${cardId}`);
        return data;
    }

    // eslint-disable-next-line no-unused-vars
    const _fetchQuery = async () => {
        return new Promise(resolve => {
            setTimeout(resolve([]), 1200);
        });
    }

    const query = useQuery({
        queryKey: ['_prepaidcard_autoload', cardId],
        queryFn: fetchQuery,
        enabled: !!cardId,
        select: (resData) => {
            return {
                amountBelow: resData?.[0]?.amount_below || '',
                amountLoad: resData?.[0] ? (resData[0]?.autoload_type_id === 1 ? resData[0]?.amount_load : '') : '',
                amountLoadTo: resData?.[0] ? (resData[0]?.autoload_type_id === 2 ? resData[0]?.amount_load : '') : '',
                amountBelowTxt: resData?.[0] ? accountingFormat(resData[0]?.amount_below) : 'Not Set',
                amountLoadTxt: resData?.[0] ? (resData[0]?.autoload_type_id === 1 ? accountingFormat(resData[0]?.amount_load) : 'Not Set') : 'Not Set',
                amountLoadToTxt: resData?.[0] ? (resData[0]?.autoload_type_id === 2 ? accountingFormat(resData[0]?.amount_load) : 'Not Set') : 'Not Set',
                id: resData?.[0] ? resData[0]?.id : 0,
                autoloadType: resData?.[0] ? resData[0]?.autoload_type_id : '',
                prepaidCardId: resData?.[0] ? resData[0]?.prepaid_card_id : null,
                notSet: !resData.length
            }
        }
    });

    return query;
}

export { useCardAutofundQuery };