import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const usePersonalProfileQuery = () => {
  const apiRequest = useApiRequest();

  // eslint-disable-next-line no-unused-vars
  const fetchQuery = async () => {
    const { data } = await apiRequest(`${APIROUTES.get.personal_profile}`);
    return data;
  }

  const query = useQuery({
    queryKey: ['_personal_profile'],
    queryFn: fetchQuery,
    refetchOnMount: 'always'
  });

  return query;
}

export { usePersonalProfileQuery };