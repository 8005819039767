import { useNavbarState } from "../store";
import { Menu } from "antd";

function NavDrawerMenu({ onSelect }) {
	const drawerMenuItems = useNavbarState(state => state.drawerMenuItems);
	const activeModuleKey = useNavbarState(state => state.activeModuleKey);
	const activeModuleItemKey = useNavbarState(state => state.activeModuleItemKey);

	return (
		<Menu
			mode="inline"
			items={drawerMenuItems}
			defaultOpenKeys={[activeModuleKey]}
			selectedKeys={[activeModuleItemKey]}
			onSelect={(item) => onSelect(item)}
			className="medium"
		/>
	)
}

export default NavDrawerMenu;
