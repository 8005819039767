import { useMutation } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

// txId is specific transaction_id
const useTxCategoryMapMutation = ({txCat}) => {
    const apiRequest = useApiRequest();

    const mutation = useMutation({
        mutationFn: ({txId, id, txCat}) => {
            const payload = {
                transaction_category_id: txCat.transaction_category_id,
                other: txCat?.is_other ? (txCat?.other || '') : '',
                is_other: txCat?.is_other ?? false,
            }
            if(id) {
                payload.id = id;
            } else {
				payload.transaction_id = Number(txId);
			}
            return apiRequest(`${APIROUTES.post.transaction_category_map_create_update}`, 'POST', payload);
        },
        mutationKey: ['_transaction_category_map_create_update', txCat?.transaction_category_id],
        onMutate: () => {},
        onSuccess: (data) => {},
        onError: (error) => {},
    });

    return mutation;
}

export { useTxCategoryMapMutation };
