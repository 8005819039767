import { Navigate, Outlet } from "react-router-dom";
import { useLoginState } from "App/Pages/Login/store";
import { USER_ROLES } from "Constants/UserRoles";
import URLS from "Routes/constants";

function RoleGuard({
    allow = true,
    roles = [],
    redirectTo,
}) {
    const roleId = useLoginState(state => state.roleId);
    const url = redirectTo || (
        (roleId === USER_ROLES.primary_admin.roleId || roleId === USER_ROLES.admin.roleId) && URLS.WalletDashboard
    ) || (
            roleId === USER_ROLES.business_user.roleId && URLS.PaymentsDashboard
        ) || (
            roleId === USER_ROLES.card_user.roleId && URLS.PersonalCardsDashboard
        ) || (
            URLS.Error
        )

    return (allow ? roles.includes(roleId) : !roles.includes(roleId))
        ? <Outlet />
        : <Navigate to={url} replace />

}

export default RoleGuard;
