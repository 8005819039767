import { colors } from "Constants/Style";

function CheckDotButton({
    checked = false,
    onClick = () => {},
    disabled = false,
}) {
    return (
        <button disabled={disabled} onClick={onClick} type="button" className="app-checkdot-btn">
            <span
                style={{
                    background: checked ? colors.lightGreen : '#fff',
                    borderColor: checked ? colors.lightGreen : '#d9d9d9'
                }}
                className="app-checkdot-btn-dot">
            </span>
        </button>
    )
}

export default CheckDotButton;