import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useConvertBalancesState } from "App/Pages/Wallet/ConvertBalances/store";
import { useCompanyWalletBalanceState } from "Hooks/Store";

const useCompanyWalletBreakdownQuery = () => {
    const apiRequest = useApiRequest();
    const setConvertBalancesState = useConvertBalancesState((state) => state.setState);
    const setCompanyWalletState = useCompanyWalletBalanceState(state => state.setState)

    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
        const { data } = await apiRequest(`${APIROUTES.get.company_wallet_breakdown}`);
        return data;
    }

    // eslint-disable-next-line no-unused-vars
    const _fetchQuery = async () => {
        return new Promise(resolve => {
            setTimeout(resolve([]), 1200);
        });
    }

    const query = useQuery({
        queryKey: ['_company_wallet_breakdown'],
        queryFn: fetchQuery,
        onSuccess: (resData) => {
            setConvertBalancesState({ balances: resData?.breakdown });

            setCompanyWalletState({
                allVisible: false,
                balances: resData?.breakdown ?? [],
                total: resData?.total
            });
        },
        select: (resData) => {
            let breakdown = resData?.breakdown?.map((val, idx) => {
                return ({
                    ...val,
                    key: `${val.currency}_${idx}`,
                    flag: val.currency,
                    availableToSpend: val?.amount_available,
                    GBPValue: val?.amount_equivalent,
                });
            });

            return {
                ...resData,
                breakdown: breakdown.sort((a,b) => a.currency === 'GBP' ? -1 : b.currency === 'GBP' ? 1 : 0),
            };
        }
    });

    return query;
}

export { useCompanyWalletBreakdownQuery };