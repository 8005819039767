import { Button, Col, notification, Row, Typography } from 'antd';
import { APIROUTES } from 'Constants/ApiRoutes';
import { useApiRequest } from 'Hooks/API';
import { useNavigate } from 'react-router-dom';
import URLS from 'Routes/constants';
import create from 'zustand';
const useState = create(set => ({
  activateLoading: false,
  setState: (obj) => set(obj),
}));

function ActivateCard({ id, activated }) {
  const activateLoading = useState(s => s.activateLoading);
  const setState = useState(s => s.setState);
  const apiRequest = useApiRequest();
  const navigate = useNavigate();

  const activateCard = async () => {
    setState({ activateLoading: true });
    try {
      const data = {
        prepaidCardId: id,
        additional_info: 'web-app',
        ip: '1.1.1.1',
      }
      await apiRequest(APIROUTES.put.activate_card, 'PUT', data);
      activated(true);
    } catch (err) {
      notification.error({
        message: 'Something Went Wrong',
        description: 'There was a problem with your request, please try again later and if the problem persist contact our support team.',
        placement: 'top'
      });
      activated(false)
    }
    setState({ activateLoading: false })
  }
  return (
    <>
      <Row>
        <Col>
          <Typography.Text className="muli semi-bold fs-18px dark-green">Activate Card</Typography.Text>
        </Col>
      </Row>
      <Row className="m-t-30" justify='center'>
        <Col>
          <Typography.Text className="muli fs-16px semi-bold dark-green">
            Please only activate card <Typography.Text className="muli fs-16px extra-bold" type="danger">
              once </Typography.Text>
            you have received it
          </Typography.Text>
        </Col>
      </Row>
      <Row className="m-t-20" justify="space-around">
        <Col>
          <Button type="primary" danger onClick={() => navigate(URLS.Cards)}>Back</Button>
        </Col>
        <Col>
          <Button type="primary" onClick={activateCard} loading={activateLoading}>Activate Card</Button>
        </Col>
      </Row>
    </>
  )
}

export default ActivateCard;