import create from "zustand";

const useTransactionsExpensesState = create((set, get) => ({
    loading: false,
    page: 1,
    totalPages: 1,
    limit: 10,
    syncQueue: [],
    syncStatus: '2,3,5',
    accountOrTaxCodeChangedItems: [],
    syncAllChecked: false,
    withAttachments: null,
    currencyId: null,
    prepaidCardId: null,
    searchFilter: null,
    syncStatuses: [
        {
            id: 1,
            name: 'View Synced Transactions',
            val: '1',
        },
        {
            id: 235,
            val: '2,3,5',
            name: 'View Unsynced Transactions',
        },
    ],
    isSyncing: false,
    orderByField: null,
    isSyncingDirectCost: false,
    isSyncingBillPayment: false,
    setState: (data) => set(() => data),
    isSyncCheckboxEnabled: (id) => {
        return !!get().accountOrTaxCodeChangedItems.includes(id);
    }
}));

export { useTransactionsExpensesState };