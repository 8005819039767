import { Button, Card, Col, Image, Input, Row, Typography } from 'antd';
import PageDescription from 'App/Components/PageDescription';
import { useNavigate } from 'react-router-dom';
import URLS from 'Routes/constants';
import squareLogoDark from 'Assets/Images/squareLogoDark.png';

function UserGuides() {
    const navigate = useNavigate();
    const cards = [
        {
            image: <Image src={squareLogoDark} preview={false} width={163} />,
            text: 'Getting Started',
            onClick: () => navigate(URLS.GettingStarted)
        },
        {
            image: <Image src={squareLogoDark} preview={false} width={163} />,
            text: 'Bank to Bank Delivery Timescales',
            onClick: () => navigate(URLS.BankToBankDeliveryTimescales)
        },
        {
            image: <Image src={squareLogoDark} preview={false} width={163} />,
            text: 'Verifying Your Beneficiary Bank Details',
            onClick: () => navigate(URLS.VerifyingYourBeneficiaryBank)
        },
        {
            image: <Image src={squareLogoDark} preview={false} width={163} />,
            text: 'User Permissions',
            onClick: () => navigate(URLS.UserPermissions)
        },
    ]
    return (
        <>
            <Row>
                <Col span={24}>
                    <PageDescription title='User Guides' />
                </Col>
            </Row>
            <Row className='m-t-10' justify='space-between' gutter={[12, 12]}>
                <Col>
                    <Input.Search placeholder='Search for a topic' />
                </Col>
                <Col>
                    <Button type='primary' onClick={() => navigate(URLS.Help)}>Return to Main Page</Button>
                </Col>
            </Row>
            <Row className="m-t-20" gutter={[72, 48]}>
                {cards && cards.length > 0 && cards.map((val, key) => (
                    <Col xxl={6} xl={8} lg={10} md={12} sm={16} xs={24} key={key}>
                        <Card hoverable className="b-g p-10" onClick={val.onClick} style={{ minHeight: '100%' }}>
                            <Row justify='center'>
                                <Col>
                                    {val.image && val.image}
                                </Col>
                            </Row>
                            <Row justify='center' className="m-t-20">
                                <Col>
                                    <Typography.Text className="muli semi-bold fs-18px center-align-text">{val.text}</Typography.Text>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}
            </Row>
        </>
    )
}

export default UserGuides;