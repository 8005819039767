import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useRefreshTransactionsQuery } from "App/Pages/Accounting/Transactions/query/useRefreshTransactionsQuery";

function useUnsyncExpensesCountQuery() {
    const apiRequest = useApiRequest();
    const { isFetching, isLoading } = useRefreshTransactionsQuery();
    const syncStatusKey = 'notsynced';

    const fetchQuery = async () => {
        const transactions = await apiRequest(
            `${APIROUTES.get_accounting_transactions_expenses}&inSynchId=2,3,5`
        );
        return transactions;
    }

    const query = useQuery({
        queryKey: [`_get_${syncStatusKey}_count`],
        queryFn: fetchQuery,
        enabled: !isFetching && !isLoading
    });

    return query;
}

export { useUnsyncExpensesCountQuery };