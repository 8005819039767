import create from "zustand";

const usePaymentFilesState = create((set, get) => ({
    previewImage: null,
    previewTitle: '',
    previewOpen: false,
    uploads: [],
    fileStore: [],
    currentPreviewSlide: 0,
    loading: false,
    setState: (data) => set(() => data),
}));

export { usePaymentFilesState };