import { Card, Col, Image, Row, Space, Typography } from 'antd';

function ModulesOverlay({ moduleData, onClick = () => { } }) {
    return (
        <Card className='navbar-overlay'>
            <Row gutter={[16, 16]}>
                {moduleData.map((val, key) => (
                    <Col span={8} key={key}>
                        <Card className='b-g center-align-text' hoverable onClick={() => onClick(val.url)} bodyStyle={{ padding: '10px' }}>
                            <Space direction='vertical' align='center' size='small'>
                                <Image src={val.image} preview={false} height={40} />
                                <Typography.Text className='muli semi-bold'>{val.name}</Typography.Text>
                            </Space>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Card>
    );
}

export default ModulesOverlay;