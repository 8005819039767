import { Card, Col, Row, Typography } from "antd";

function PageDescription({ title = '', text = [], defaultStyle = true }) {
    const titleClassName = (defaultStyle ? 'medium' : 'regular') + ' dark-green fs-28px';
    const textClassName = (defaultStyle ? 'dark-green' : 'muli regular') + ' fs-18px';

    return (
        <Card className="b-g" data-testid='container'>
            <Row>
                <Col span={24} className='m-b-10'>
                    <Typography.Text className={titleClassName}>{title}</Typography.Text>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    {text && (
                        Array.isArray(text) ?
                            text.map((text, key) => (
                                <Typography.Paragraph className={textClassName} key={key}>{text}</Typography.Paragraph>
                            )) : (
                                <Typography.Paragraph className={textClassName}>{text}</Typography.Paragraph>
                            )
                    )}
                </Col>
            </Row>
        </Card>
    );
}

export default PageDescription;