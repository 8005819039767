import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
// eslint-disable-next-line
import { notification } from "antd";
import { useManageSpecificCardState } from "App/Pages/Cards/Cards/ManageSpecificCard/store";

const useReportStolenCardMutation = ({cardId}) => {
    const apiRequest = useApiRequest();
    const queryClient = useQueryClient();
    const setManageCardState = useManageSpecificCardState((s) => s.setState);

    const mutation = useMutation({
        mutationFn: ({cardId}) => {
            return apiRequest(`${APIROUTES.post.gps_stolencard}`, 'POST', {
                prepaid_card_id: parseInt(cardId),
                additional_info: 'web-app',
                ip: '1.1.1.1',
            });
        },
        mutationKey: ['_gps_stolencard', cardId],
        onMutate: () => {},
        onSuccess: (data, context) => {
            if(!data?.data?.success) {
				notification.error({ message: 'Something went wrong.',  placement: 'top' });
				return;
			}
            queryClient.invalidateQueries({ queryKey: ['_prepaidcard', context.cardId], exact: true});
            setManageCardState({
                modalContent: 'returnToDashboard',
                modalOpen: true,
                returnToDashboardCardHeading: `CARD REPORTED`,
                returnToDashboardCardDescription: 'Your card has been reported as LOST/STOLEN and is no longer usable',
                returnToDashboardCardBold: '',
            });
        },
        onError: (error) => {
            notification.error({ message: error.message,  placement: 'top' });
        },
    });

    return mutation;
}

export { useReportStolenCardMutation };