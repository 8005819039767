import { Button, Col, Image, Popconfirm, Row, Space, Tooltip, Typography } from "antd";
import CustomTable from "App/Components/CustomTable";
import FullScreenModal from "App/Components/FullScreenModal";
import PageDescription from "App/Components/PageDescription";
import AddRecipient from "App/Pages/Payments/AddRecipient";
import { useRefundDetailsState } from "App/Pages/Settings/CompanyAccount/RefundDetails/store";
import { FLAGS } from "Constants/Images";
import { FaInfoCircle, FaPlusCircle } from "react-icons/fa";
import { useRefundDetailsQuery } from "App/Pages/Settings/CompanyAccount/RefundDetails/query";
import CustomPagination from "App/Components/CustomPagination";
import { useNavigate } from "react-router-dom";
import URLS from "Routes/constants";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

function RefundDetails() {
    const modalOpen = useRefundDetailsState(state => state.modalOpen);
    const currentPage = useRefundDetailsState(state => state.currentPage);
    const limit = useRefundDetailsState(state => state.limit);
    const isRemoving = useRefundDetailsState(state => state.isRemoving);
    const { data, isFetching, refetch } = useRefundDetailsQuery();
    const setState = useRefundDetailsState(state => state.setState);
    const navigate = useNavigate();
    const apiRequest = useApiRequest();
    const columns = [
        {
            title: 'Recipient Name',
            dataIndex: 'name',
            key: 'recipientName'
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
            render: text => <Space>
                {FLAGS[text] && <Image src={FLAGS[text]} preview={false} width={24} />}
                {text}
            </Space>
        },
        {
            title: 'Type',
            dataIndex: 'beneficiary_entity_type',
            key: 'type',
            render: val => val?.charAt(0)?.toUpperCase() + val?.slice(1)
        },
        {
            title: 'Recipient Country',
            dataIndex: 'beneficiary_country',
            key: 'recipeintCountry'
        },
        {
            title: 'Bank Country',
            dataIndex: 'bank_country',
            key: 'bankCountry'
        },
        {
            title: 'Payment Methods',
            dataIndex: 'payment_types',
            key: 'paymentMethods',
            render: values => values?.map((val, key) => `${key > 0 ? ' ,' : ''}${val?.charAt(0)?.toUpperCase()}${val?.slice(1)}`)
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'action',
            align: 'right',
            render: key => (
                <div onClick={(e) => e.stopPropagation()}>
                    <Popconfirm
                        title='Are you sure you want to remove this recipient?'
                        onConfirm={() => handleRemove(key)}
                        placement='topLeft'
                        okButtonProps={{ danger: true, loading: isRemoving }}
                        okText='Remove'
                    >
                        <Button type="primary" danger>Remove</Button>
                    </Popconfirm>
                </div>
            )
        }
    ];

    const handleNewRecipient = (modalOpen) => {
        setState({ modalOpen });
    }

    const handleRemove = async (key) => {
        setState({ isRemoving: true });
        await apiRequest(`${APIROUTES.del.refund_beneficiary}${key}`, 'DELETE');
        setState({ isRemoving: false });
        refetch();
    }

    return (
        <>
            <FullScreenModal
                open={modalOpen}
                onCancel={() => setState({ modalOpen: false })}
            >
                <AddRecipient
                    handleNewRecipient={handleNewRecipient}
                    refundRecipient
                />
            </FullScreenModal>
            <Row>
                <Col span={24}>
                    <PageDescription title='Refund Details' />
                </Col>
            </Row>
            <Row className="m-t-20" gutter={[12, 12]}>
                <Col>
                    <Typography.Text className="fs-18px">Refund Account Details</Typography.Text>
                </Col>
                <Col>
                    <Button type="primary" size="small" shape="round" onClick={() => setState({ modalOpen: true })}>
                        <Space>
                            <FaPlusCircle style={{ marginBottom: '-2px', marginLeft: '-8px' }} />
                            Add
                        </Space>
                    </Button>
                </Col>
                <Col>
                    <Tooltip title='In the event that we need to return funds to you, we will return them to the account details you provide here'>
                        <FaInfoCircle className="dark-green pointer" size={16} />
                    </Tooltip>
                </Col>
            </Row>
            <Row className="m-t-10">
                <Col span={24}>
                    <CustomTable
                        columns={columns}
                        dataSource={data?.data}
                        loading={isFetching}
                        rowKey="id"
                        onRow={record => ({ onClick: () => navigate(`${URLS.RefundRecipientDetails}/${record?.id}`) })}
                        rowClassName='medium fs-18px pointer'
                    />
                    <CustomPagination
                        loading={isFetching}
                        onPageChange={(page) => setState({ currentPage: page })}
                        total={data?.pager?.count ?? 0}
                        pageSize={limit}
                        current={currentPage}
                        onPageSizeChange={(_, size) => setState({ limit: size, currentPage: 1 })}
                    />
                </Col>
            </Row>
        </>
    );
}

export default RefundDetails;
