import { Space, Row, Col, Button, Radio, Input, Image, Checkbox, notification } from "antd";
import { FLAGS } from "Constants/Images";
import CustomTable from "App/Components/CustomTable";
import { usePrepaidCardsQuery } from "App/Pages/Cards/query";
import { useCardsState } from "App/Pages/Cards/Cards/store";
import { useEffect } from "react";
import { debounce } from "lodash";
import CustomPagination from "App/Components/CustomPagination";
import { pluralize } from "Utils";
import { useCardPaymentTypesState } from "Hooks/Store";
import { OPT_DISABLED_PAYMENT_TYPES } from "Constants/Opts";
import { useDisablePaymentTypesMutation } from "Hooks/Mutations";
import { useQueryClient } from "@tanstack/react-query";

function BulkPaymentTypes() {

	const { isLoading: prepaidCardsFetching, data: prepaidCards } = usePrepaidCardsQuery();
	const busy = useCardsState(state => state.busy);
	const currentPage = useCardsState(state => state.currentPage);
	const limit = useCardsState(state => state.limit);
	const setCardsState = useCardsState(state => state.setState);

	const cardId = useCardPaymentTypesState(state => state.cardId);
	const authPayTypesChanged = useCardPaymentTypesState(state => state.authPayTypesChanged);
	const editMode = useCardPaymentTypesState(state => state.editMode);
	const initialData = useCardPaymentTypesState(state => state.initialData);
	const disabled = useCardPaymentTypesState(state => state.disabled);
	const paymentTypeIds = useCardPaymentTypesState(state => state.paymentTypeIds);
	const loading = useCardPaymentTypesState(state => state.loading);
	const setPaymentTypesState = useCardPaymentTypesState(state => state.setState);
	const resetPaymentTypesState = useCardPaymentTypesState(state => state.reset);

	const queryClient = useQueryClient();
	const disablePaymentTypesMutation = useDisablePaymentTypesMutation(payload => payload);

	//
	useEffect(() => {
		return () => {
			resetPaymentTypesState();

			setCardsState({
				cardSearchFilter: null,
				currentPage: 1,
				limit: 10,
				totalPages: 0,
			});
		}

		// eslint-disable-next-line
	}, []);

	//
	const handleSaveCardPaymentTypes = async () => {

		// Def rethink the implementation...
		if (authPayTypesChanged() && cardId) {
			setPaymentTypesState({ loading: true });
			const mutations = [];

			paymentTypeIds.forEach(typeId => {
				const existed = initialData.find(item => item.opt_disable_type_id === typeId);
				const inDisabled = disabled.find(item => item.opt_disable_type_id === typeId);
				const action = inDisabled ? (existed ? null : 'POST') : (existed ? 'DELETE' : null);

				if (!action) return;

				mutations.push(disablePaymentTypesMutation.mutateAsync(
					{
						cardId,
						typeId: typeId,
						recordId: existed?.id || null,
						method: action,
					},
				));
			});

			try {
				await Promise.all(mutations);
				notification.success({ message: `Payment types updated for card ID:${cardId}`, placement: 'top' });
			} catch (err) {
				notification.error({ message: 'Not all changes where saved. Please check and try again.', placement: 'top' });
			} finally {
				queryClient.invalidateQueries({ queryKey: ['_prepaid_cards'], exact: true });
				queryClient.invalidateQueries({ queryKey: ['_prepaidcard_disabledtxtype_by_card_id', cardId] });
				setPaymentTypesState({ loading: false });
			}
		}

		resetPaymentTypesState();
	}

	//
	const handleCheckboxChange = (evt, key) => {
		setPaymentTypesState({
			disabled: evt.target.checked
				? [...disabled.filter(item => item.opt_disable_type_id !== OPT_DISABLED_PAYMENT_TYPES[key])]
				: [...disabled, { id: null, opt_disable_type_id: OPT_DISABLED_PAYMENT_TYPES[key] }]
		});
	}

	//
	const handleEditClick = (record) => {
		setPaymentTypesState({
			editMode: record.prepaid_card_id,
			initialData: record.prepaid_card_disabled_tx_type,
			disabled: record.prepaid_card_disabled_tx_type,
			cardId: record.prepaid_card_id,
		});
	}

	//
	const cancelEditMode = () => {
		resetPaymentTypesState();
	}

	//
	const columns = [
		{
			title: 'Card',
			dataIndex: 'prepaid_card_id',
			key: 'prepaid_card_id',
			render: (v, record) => <Space direction='vertical' size={0}>
				{record?.card_holder?.name}
				{`**** **** **** ${record?.card_number}`}
			</Space>
		},
		{
			title: 'ATM Withdrawals',
			dataIndex: 'prepaid_card_disabled_tx_type',
			key: 'ATMWithdrawals',
			render: (value, record) => <>{editMode === record?.prepaid_card_id
				? <Checkbox
					onChange={(evt) => handleCheckboxChange(evt, 'atmWithdraw')}
					checked={disabled?.find(item => item.opt_disable_type_id === OPT_DISABLED_PAYMENT_TYPES.atmWithdraw) ? false : true} />
				: <Radio
					checked={value?.find(item => item.opt_disable_type_id === OPT_DISABLED_PAYMENT_TYPES.atmWithdraw) ? false : true}
					className='b-2-grey big-rounded p-r-2 p-l-2'
				/>
			}</>,
			align: 'center'
		},
		{
			title: 'Contactless',
			dataIndex: 'prepaid_card_disabled_tx_type',
			key: 'contactless',
			render: (value, record) => <>{editMode === record?.prepaid_card_id
				? <Checkbox
					onChange={(evt) => handleCheckboxChange(evt, 'contactless')}
					checked={disabled?.find(item => item.opt_disable_type_id === OPT_DISABLED_PAYMENT_TYPES.contactless) ? false : true} />
				: <Radio
					checked={value?.find(item => item.opt_disable_type_id === OPT_DISABLED_PAYMENT_TYPES.contactless) ? false : true}
					className='b-2-grey big-rounded p-r-2 p-l-2'
				/>
			}</>,
			align: 'center'
		},
		{
			title: 'E-commerce',
			dataIndex: 'prepaid_card_disabled_tx_type',
			key: 'eCommerce',
			render: (value, record) => <>{editMode === record?.prepaid_card_id
				? <Checkbox
					onChange={(evt) => handleCheckboxChange(evt, 'ecom')}
					checked={disabled?.find(item => item.opt_disable_type_id === OPT_DISABLED_PAYMENT_TYPES.ecom) ? false : true} />
				: <Radio
					checked={value?.find(item => item.opt_disable_type_id === OPT_DISABLED_PAYMENT_TYPES.ecom) ? false : true}
					className='b-2-grey big-rounded p-r-2 p-l-2'
				/>
			}</>,
			align: 'center'
		},
		{
			title: 'Currency',
			dataIndex: 'base_currency',
			key: 'currency',
			render: (value) => <Space size={4}>
				{FLAGS[value] && <Image src={FLAGS[value]} alt={value} preview={false} width={36} />}
				{value}
			</Space>
		},
		{
			title: '',
			key: 'cancel',
			width: 74,
			render: (v, record) => <>
				{editMode === record?.prepaid_card_id ? <Button onClick={() => cancelEditMode()}>Cancel</Button> : ''}
			</>,
			align: 'right'
		},
		{
			title: '',
			key: 'action',
			width: 74,
			render: (v, record) => (<>
				{editMode === record?.prepaid_card_id
					? <Button type='primary' onClick={() => handleSaveCardPaymentTypes()} disabled={!authPayTypesChanged() || loading}>
						Save
					</Button>
					: <Button type='primary' onClick={() => handleEditClick(record)} disabled={loading}>
						Edit
					</Button>
				}
			</>),
			align: 'right'
		}
	];

	//
	const handleSearch = debounce((evt) => {
		if (evt.target.value?.length > 2) {
			setCardsState({ cardSearchFilter: evt.target.value.toLowerCase() });
		} else {
			setCardsState({ cardSearchFilter: null });
		}

		setCardsState({ currentPage: 1 });
	}, 400);

	//
	const handlePageChange = (page) => {
		setCardsState({ currentPage: page });
	}

	//
	const handlePageSizeChange = (current, size) => {
		setCardsState({ limit: size, currentPage: 1 });
	}

	//
	return (
		<>
			<Row className="m-t-20">
				<Col xxl={4}>
					<Input.Search onChange={handleSearch} placeholder="Search By Card Holder" />
				</Col>
			</Row>

			<Row className="m-t-10">
				<Col span={24}>
					<CustomPagination
						loading={busy || prepaidCardsFetching}
						onPageChange={handlePageChange}
						total={prepaidCards?.pager?.count ?? 1}
						pageSize={limit}
						current={currentPage}
						onPageSizeChange={handlePageSizeChange}
						singlePageMessage={`${prepaidCards?.pager?.count ?? 1} ${pluralize(prepaidCards?.pager?.count ?? 1, 'card', 'cards')} found.`}
					/>
					<CustomTable
						columns={columns}
						dataSource={prepaidCards?.data || []}
						styleAllRows
						className="spaced-rows"
						headerColor="green"
						rowKey="prepaid_card_id"
						loading={loading || prepaidCardsFetching}
					/>
					<CustomPagination
						loading={busy || prepaidCardsFetching}
						onPageChange={handlePageChange}
						total={prepaidCards?.pager?.count ?? 1}
						pageSize={limit}
						current={currentPage}
						onPageSizeChange={handlePageSizeChange}
						singlePageMessage={`${prepaidCards?.pager?.count ?? 1} ${pluralize(prepaidCards?.pager?.count ?? 1, 'card', 'cards')} found.`}
					/>
				</Col>
			</Row>
		</>
	)
}

export default BulkPaymentTypes;
