import create from 'zustand'

const usePersonalProfileState = create(set => ({
    editTelephoneWork: false,
    editTelephoneMobile: false,
    modalOpen: false,
    profileLoading: false,
    profileData: [],
    telephoneWork: false,
    telephoneMobile: false,
    primaryEmail: false,
    secondaryEmail: false,
    pass: false,
    passNew: false,
    btnLoading: false,
    passwordValidation: undefined,
    updateLoading: false,
    loading2fa: false,
    userId: undefined,
    setState: obj => set(obj)
}));

export { usePersonalProfileState };