import { Button, Col, Row, Space, Typography } from "antd";
import PageDescription from "App/Components/PageDescription";

function UploadUserList() {
    return (
        <>
            <Row>
                <Col span={24}>
                    <PageDescription title='Upload User List' />
                </Col>
            </Row>
            <Row className="m-t-20">
                <Col>
                    <Space wrap>
                        <Typography.Text keyboard className="bg-white roboto fs-18px medium">Click Download button to generate an empty template</Typography.Text>
                        <Button type="primary">Download Excel Template</Button>
                    </Space>
                </Col>
            </Row>
            <Row className="m-t-20" justify="space-between">
                <Col>
                    <Typography.Text className='dark-green fs-28px medium'>Upload User List</Typography.Text>
                </Col>
                <Col>
                    <Button type="primary">Upload User List</Button>
                </Col>
            </Row>
        </>
    )
}

export default UploadUserList;