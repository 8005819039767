import create from "zustand";

const useNotificationsState = create(set => ({
  notifications: undefined,
  allSelected: false,
  isDeleting: false,
  modalOpen: false,
  selected: false,
  currentPage: 1,
  limit: 10,
  setState: obj => set(obj)
}));

export { useNotificationsState };