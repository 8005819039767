import create from "zustand";
import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useEffect } from "react";

const useAppStore = create((set) => ({
    companyWalletId: null,
    loading: false,
    setState: (data) => set(data),
}));

const useAppState = () => {
    const setState = useAppStore(state => state.setState);
    const apiRequest = useApiRequest();

    //
    const fetchCompanyWalletQuery = async () => {
        const { data } = await apiRequest(`${APIROUTES.get.company_wallet_card}`);
        return data;
    }

    //
    const { isLoading: companyWalletLoading } = useQuery({
        queryKey: ['_company_wallet_card'],
        queryFn: fetchCompanyWalletQuery,
        onSuccess: (resData) => {
            setState({companyWalletId: resData?.company_wallet_id});
        }
    });

    //
    useEffect(() => {
        setState({loading: companyWalletLoading});

    // eslint-disable-next-line
    }, [companyWalletLoading]);

    return {
        companyWalletId: useAppStore(state => state.companyWalletId),
        setState,
        loading: useAppStore(state => state.loading),
    }
}

export { useAppState };
