/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Button, Col, Input, Row, Space, Typography } from "antd";
import { useEffect } from "react";
import { FaRegUser, FaTrashAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import URLS from "Routes/constants";
import PageDescription from "App/Components/PageDescription";
import CustomTable from "App/Components/CustomTable";
import { useManageUsersState } from "App/Pages/Settings/CompanyAccount/ManageUsers/store";
import { FORMATS } from "Constants/Formats";
import CustomPagination from "App/Components/CustomPagination";
import { useUserListQuery } from "App/Pages/Settings/CompanyAccount/ManageUsers/hooks/queries/useUserListQuery";
import { debounce } from "lodash";
import { useResendActivationCode } from "./hooks/queries/useResendActivationCode";
import DeleteConfirmationModal, { useState } from "./components/DeleteConfirmationModal";

function ManageUsers() {
  const navigate = useNavigate();
  const showPending = useManageUsersState((state) => state.showPending);
  const userList = useManageUsersState((state) => state.userList);
  const currentPage = useManageUsersState((state) => state.currentPage);
  const limit = useManageUsersState((state) => state.limit);
  const statusIdIn = useManageUsersState((state) => state.statusIdIn);
  const setState = useManageUsersState((state) => state.setState);
  const DeleteModalState = useState(state => state.setState)
  const { data, isLoading, refetch } = useUserListQuery(statusIdIn);
  const { mutate: ResendActivationCode} = useResendActivationCode();
  const columns = [
    [
      {
        title: "User ID",
        dataIndex: "user_id",
        key: "user_id",
        sorter: (a, b) =>
          a?.user_id && b?.user_id && a.user_id - b.user_id,
        render: (a) => (
          <Space>
            <Avatar size={36} icon={<FaRegUser />} />
            {a}
          </Space>
        ),
      },
      {
        title: "Name",
        dataIndex: "first_name",
        key: "name",
        width: 300,
        sorter: true,
        render: (_, obj) =>
          (obj?.first_name ? obj.first_name + ' ' : '') +
          (obj?.middle_name ? obj.middle_name + ' ' : '') +
          (obj?.last_name ? obj?.last_name : ''),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: (a, b) =>
          a.user.email && b.user.email && a.user.email.localeCompare(b.user.email),
        render: (a, b) => (
          b.user.email === null ? "-" : b.user.email
        ),
      },
      {
        title: "Role",
        dataIndex: "user",
        key: "role",
        sorter: true,
        render: a => (
          <Typography.Text
            className={
              a?.role?.permission_name === "Business User"
                ? "light-green"
                : a?.role?.permission_name === "Card User"
                  ? "dark-green"
                  : a?.role?.permission_name === "Primary Administrator"
                    ? "purple"
                    : a?.role?.permission_name === "Administrator"
                      ? "error-hover"
                      : ""
            }
          >
            {a?.role?.permission_name || "-"}
          </Typography.Text>
        ),
      },
      {
        title: "Date Created",
        dataIndex: "user",
        key: "date_created",
        sorter: true,
        render: (a) => (
          a.create_time ? moment(a.create_time).format(FORMATS.date) : '-'
        ),

      },
      {
        title: "Card Expiry Date",
        dataIndex: "user_registered_cards",
        key: "card_expiry_date",
        sorter: true,
        render: text => (text?.length > 0 &&
          text[0]?.prepaid_card?.expiry_month &&
          text[0]?.prepaid_card?.expiry_year &&
          moment(`${text[0]?.prepaid_card?.expiry_month} ${text[0]?.prepaid_card?.expiry_year}`, "MM YYYY").format(FORMATS.cardDate)) ||
          "-"
      },
      {
        title: "Card Status",
        dataIndex: "user_registered_cards",
        key: "card_status",
        sorter: true,
        render: (obj) => {
          const text = obj?.length > 0 &&
            obj[0]?.prepaid_card?.opt_card_status?.option
          return (
            < Typography.Text
              type={
                text === "Active" || text === "Renewed"
                  ? "success"
                  : text === "Expiring" || text === "Suspended"
                    ? "warning"
                    : text === "Expired" || text === "Cancelled" ||
                      text === "Not Activated" || text === "Blocked" ||
                      text === "Lost/Stolen" || text === "Destroyed"
                      ? "danger"
                      : ""
              }
            >
              {text ? text : '-'}
            </Typography.Text >
          )
        },
      },
      {
        title: "",
        dataIndex: "key",
        key: "action",
        render: (a, b) => (
          <Button
            type="primary"
            onClick={() => navigate(`${URLS.ManageSpecificUser}/${b.user_id}`)}
            style={{ width: '110px' }}
          >
            Edit
          </Button>
        ),
        align: "right",
      },
    ],
    [
      {
        title: 'User ID',
        dataIndex: 'user_id',
        key: 'userID',
        sorter: (a, b) => a.userID && b.userID && a.userID.localeCompare(b.userID),
        render: val => <Space><Avatar size={36} icon={<FaRegUser />} /> {val}</Space>
      },
      {
        title: "Name",
        dataIndex: "first_name",
        key: "name",
        width: 300,
        sorter: true,
        render: (_, obj) =>
          (obj?.first_name ? obj.first_name + ' ' : '') +
          (obj?.middle_name ? obj.middle_name + ' ' : '') +
          (obj?.last_name ? obj?.last_name : ''),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: (a, b) =>
          a.user.email && b.user.email && a.user.email.localeCompare(b.user.email),
        render: (a, b) => (
          b.user.email === null ? "-" : b.user.email
        ),
      },
      {
        title: 'Role',
        dataIndex: 'user',
        key: 'role',
        sorter: (a, b) => a.role && b.role && a.role.localeCompare(b.role),
        // sorter: true,
        render: a => (
          <Typography.Text
            className={
              a?.role?.permission_name === "Business User"
                ? "light-green"
                : a?.role?.permission_name === "Card User"
                  ? "dark-green"
                  : a?.role?.permission_name === "Primary Administrator"
                    ? "purple"
                    : a?.role?.permission_name === "Administrator"
                      ? "error-hover"
                      : ""
            }
          >
            {a?.role?.permission_name || "-"}
          </Typography.Text>
        ),
      },
      {
        title: "Date Created",
        dataIndex: "user",
        key: "date_created",
        sorter: true,
        render: (a) => (
          a.create_time ? moment(a.create_time).format(FORMATS.date) : '-'
        ),

      },
      {
        title: 'Expense Card Currency',
        dataIndex: 'user_registered_cards',
        key: 'expenseCardCurrency',
        sorter: true,
        render: (obj) => {
          const text = obj?.length > 0 &&
            obj[0]?.prepaid_card?.card_currency
          return (
            < Typography.Text>
              {text ? text : '-'}
            </Typography.Text >
          )
        },
      },
      {
        title: 'Status',
        dataIndex: 'user',
        key: 'status',
        sorter: (a, b) => a.status_id && b.status_id && a.status_id.localeCompare(b.status_id),
        render: user => <Typography.Text className={
          user?.status_id === 1
            ? "dark-green"
            : user?.status_id === 2
              ? "error"
              : user?.status_id === 3
                ? "purple"
                : user?.status_id === 4
                  ? "warning"
                  : user?.status_id === 5 
                  ? "error"
                  : ""
        }
        >
        {user?.status_id === 1
            ? "Active"
            : user?.status_id === 2
              ? "Inactive"
              : user?.status_id === 3
                ? "Pending"
                : user?.status_id === 4
                  ? "Awaiting KYC"
                  :  user?.status_id === 5
                  ? "Failed KYC"
                  : "" }
        </Typography.Text>
      },
      {
        title: '',
        dataIndex: 'key',
        key: 'action',
        render: (a,b) => <Space><Link to='' onClick={() => handleResendActivation(b.user_id, b.first_name + b.middle_name + b.last_name)} className="underline">Resend Activation Code</Link> <Button onClick={() => handleDelete(b.user_id)} type='primary' danger><FaTrashAlt /></Button></Space>,
        align: 'right'
      }
    ]
  ];

  useEffect(() => {
    setState({
      showPending: false,
      searchFilter: undefined,
      currentPage: 1
    });
    refetch();
  }, []);

  useEffect(() => {
    setState({
      statusIdIn: showPending ? '2,3,4,5' : '1',
      currentPage: 1
    })
  }, [showPending]);

  useEffect(() => {
    setState({ userList: data?.data })
  }, [data]);

  const handleSearch = debounce((e) => {
    if (e?.target?.value?.length > 2) {
      setState({ searchFilter: e.target.value.toLowerCase(), currentPage: 1 });
    } else if (e?.target?.value?.length < 1) {
      setState({ searchFilter: undefined, currentPage: 1 });
    }
  }, 400);

  const handleTableChange = (pagination, filters, sorter) => {
    const column = sorter?.order && sorter?.columnKey ? sorter.columnKey : '';
    const order = sorter?.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '';
    const sort = column && order && `${column},${order}`;
    setState({ sort });
  }

  const handleResendActivation = async (user_id) => {
    ResendActivationCode({ user_id })
  }

  const handleDelete = async (user_id, first_name) => {
    setState({ deleteLoading: false })
    DeleteModalState({ username: first_name, user_id, modalOpen: true, modalType: 'confirm' })
  }

  return (
    <>
    <DeleteConfirmationModal />
      <Row>
        <Col span={24}>
          <PageDescription
            title={(showPending ? "Pending" : "Manage") + " Users"}
            text="- Manage your users access to your company prepaid cards and/or international bank payments platform, alongside maintaining user details."
          />
        </Col>
      </Row>
      <Row className="m-t-10">
        <Col xxl={4}>
          <Input.Search placeholder="Search by User ID & Name" onChange={handleSearch} />
        </Col>
      </Row>
      <Row className="m-t-10" justify="end">
        <Col>
          <Space wrap>
            {/* <Button type="text" onClick={() => navigate(URLS.UploadUserList)}>
              <Space size={4}>
                <FaFileUpload className="dark-green" size={18} /> Upload User
                List
              </Space>
            </Button> */}
            {/* <Button type="primary">
              <div style={{
                width: '100%', whiteSpace: 'nowrap',
                display: 'inline', fontWeight: '700'
              }}>User Roles & Permissions</div>
            </Button> */}
            <Button
              type="primary"
              onClick={() => setState({ showPending: !showPending })}
            >Show {showPending ? "Active" : "Pending"} Users
            </Button>
            <Button type="primary" onClick={() => navigate(URLS.AddNewUser)}>
              + Add new user
            </Button>
          </Space>
        </Col>
      </Row>
      <Row className="m-t-20">
        <Col span={24}>
          <CustomTable
            rowKey="user_id"
            loading={isLoading}
            columns={columns[showPending ? 1 : 0]}
            dataSource={userList}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CustomPagination
            loading={isLoading}
            onPageChange={(page) => setState({ currentPage: page })}
            total={data?.pager?.count ?? 0}
            pageSize={limit}
            current={currentPage}
            onPageSizeChange={(_, size) => setState({ limit: size, currentPage: 1 })}
          />
        </Col>
      </Row>
    </>
  );
}

export default ManageUsers;
