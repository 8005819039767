/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Input, Row, Select, Space, Typography } from 'antd';
import PageDescription from 'App/Components/PageDescription';
import SwitchWithText from 'App/Components/SwitchWithText';
import { useLoginStore } from 'App/Pages/Login/store';
import { useAddNewUserState } from 'App/Pages/Settings/CompanyAccount/ManageUsers/AddNewUser/store';
import { useNavigate } from 'react-router-dom';
import URLS from 'Routes/constants';
import { useAddNewUser } from '../hooks/queries/useAddNewUser';
import { useEffect } from 'react';

function AddNewUser() {
    const orderCard = useAddNewUserState(state => state.orderCard);
    const residentialDelivery = useAddNewUserState(state => state.residentialDelivery);
    const user_email_address = useAddNewUserState(state => state.user_email_address);
    const setState = useAddNewUserState(state => state.setState);
    const config = useLoginStore(state => state.config)
    const AddNewUser = useAddNewUser(payload => payload)
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const roleId = Form.useWatch('role_id', form);

    useEffect(() => {
        form.setFieldValue('role_id', undefined)
        form.setFieldValue('user_email_address', undefined)
        form.setFieldValue('currency_list', undefined)
        setState({
            orderCard: false,
            residentialDelivery: false
        })
    }, []);

    useEffect(() => {
        if (roleId !== 5) {
            setState({
                orderCard: false
            })
        } else {
            setState({
                orderCard: true
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleId])

    //
    const handleWithdrawConfirmMutation = async () => {
        const selectedCurr = { prepaid_card_ccy: form.getFieldValue('currency_list') }
        let props = {
            role_id: form.getFieldValue('role_id'),
            list_of_account_ids: [],
            user_email_address: form.getFieldValue('user_email_address'),
            ...(roleId !== 5 ? orderCard && selectedCurr : selectedCurr)
        }
        if (residentialDelivery)
            props.send_card_to_residential_address = 1;
        AddNewUser.mutate(props);
    }
    //
    return (
        <>
            <Row>
                <Col span={24}>
                    <PageDescription title='Add New User' text='- Once added the new user will receive an email with further instructions.' />
                </Col>
            </Row>
            <Row className='m-t-40'>
                <Col span={24}>
                    <Form layout='vertical' requiredMark={false} form={form} onFinish={handleWithdrawConfirmMutation}>
                        <Row>
                            <Col xxl={4} xl={5} lg={6} md={8} sm={12} xs={24}>
                                <Form.Item
                                    label='Role'
                                    name="role_id"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select Role'
                                        }
                                    ]}
                                >
                                    <Select
                                        name="role_id"
                                        placeholder='Select Role'
                                        className='select-b-g'
                                        showSearch
                                        allowClear
                                        filterOption={(input, option) =>
                                            option?.key?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                                        }
                                    >
                                        {Object.entries(config.user_role_opts).length > 0 && (
                                            Object.entries(config.user_role_opts).map(([role, value]) => (
                                                <Select.Option key={role} value={value}>
                                                    <Typography.Text
                                                        className={
                                                            role === "Business User"
                                                                ? "light-green"
                                                                : role === "Card User"
                                                                    ? "dark-green"
                                                                    : role === "Primary Administrator"
                                                                        ? "purple"
                                                                        : role === "Administrator"
                                                                            ? "error-hover"
                                                                            : undefined}
                                                    >
                                                        {role}
                                                    </Typography.Text>
                                                </Select.Option>
                                            ))
                                        )}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label='Business Email Address'
                                    name="user_email_address"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail!',
                                        },
                                        {
                                            required: true,
                                            message: 'Please Enter Email Address'
                                        }
                                    ]}
                                >
                                    <Input value={user_email_address} placeholder='Enter Email Address' />
                                </Form.Item>
                                <Form.Item
                                    label='Order Prepaid Card?'
                                >
                                    <SwitchWithText checked={orderCard} onChange={val => setState({ orderCard: val })} />
                                </Form.Item>
                            </Col>
                        </Row>

                        {orderCard && (
                            <>
                                <Row>
                                    <Col xxl={4} xl={5} lg={6} md={8} sm={12} xs={24}>
                                        <Form.Item
                                            label='Select Currency'
                                            name="currency_list"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please Select Currency'
                                                }
                                            ]}
                                        >
                                            <Select
                                                name="currency_list"
                                                placeholder='Currency'
                                                showSearch
                                                allowClear
                                                className='select-b-g'
                                                filterOption={(input, option) =>
                                                    option?.key?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                                                }
                                            >
                                                {Object.entries(config.card_currency_opts).length > 0 && (
                                                    Object.entries(config.card_currency_opts).map(([curr, value]) => (
                                                        <Select.Option key={curr} value={value}>
                                                            {curr}
                                                        </Select.Option>
                                                    ))
                                                )}
                                            </Select>

                                        </Form.Item>
                                        <Form.Item
                                            label={`Deliver Card To The User's Residential Address?`}
                                        >
                                            <SwitchWithText checked={residentialDelivery} onChange={val => setState({ residentialDelivery: val })} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}

                        <Row justify='end'>
                            <Col>
                                <Space size={24}>
                                    <Button type='danger' onClick={() => navigate(URLS.ManageUsers)}>Cancel</Button>
                                    {roleId === 5 ? orderCard && <Button type='primary' htmlType='submit' loading={AddNewUser.isLoading} ghost>Add User</Button> : <Button type='primary' htmlType='submit' loading={AddNewUser.isLoading} ghost>Add User</Button>}
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    );
}

export default AddNewUser;