//useFrequentPrePaidCardsQuery

import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useFrequentPrepaidCardsQuery = () => {
    const apiRequest = useApiRequest();

    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
        const { data } = await apiRequest(APIROUTES.get.prepaidcard_frequent);
        return data;
    }

    // eslint-disable-next-line no-unused-vars
    const _fetchQuery = async () => {
        return new Promise(resolve => {
            setTimeout(resolve([]), 1200);
        });
    }

    const query = useQuery({
        queryKey: ['_prepaid_cards_frequent'],
        queryFn: fetchQuery,
        onSuccess: (resData) => {
        },
    });

    return query;
}

export { useFrequentPrepaidCardsQuery };