import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useBellNotificationsQuery = () => {
  const apiRequest = useApiRequest();

  // eslint-disable-next-line no-unused-vars
  const fetchQuery = async () => {
    const data = await apiRequest(`${APIROUTES.get.notifications}?$with=All&$pageCount&$skip=0&$limit=10&alertType=N&accepted_level=5`);
    return data || [];
  }

  const query = useQuery({
    queryKey: ['_notifications', 1, 10],
    queryFn: fetchQuery
  });

  return query;
}

export { useBellNotificationsQuery };