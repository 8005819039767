import { Button, Card, Col, Form, Input, Modal, Row, Space, Tooltip, Typography, notification } from "antd"
import { FaTrashAlt } from "react-icons/fa";
import CustomTable from "App/Components/CustomTable";
import PageDescription from "App/Components/PageDescription";
import { useTrackingCodesState } from "./store";
import { useEffect } from "react";
import moment from "moment";
import { FORMATS } from "Constants/Formats";
import { useGetAllTrackingCodeQuery } from "./query/useGetAllTrackingCodeQuery";
import CustomPagination from "App/Components/CustomPagination";
import { useCreateTrackingCodeMutation } from "./query/useCreateTrackingCodeMutation";
import { useDeleteTrackingCodeMutation } from "./query/useDeleteTrackingCodeMutation";

const TrackingCodes = () => {
  const mode = useTrackingCodesState(state => state.mode);
  const rows = useTrackingCodesState(state => state.rows);
  const newTrackingCode = useTrackingCodesState(state => state.newTrackingCode);
  const currentPage = useTrackingCodesState(state => state.currentPage);
  const limit = useTrackingCodesState(state => state.limit);
  const deleteConfirmation = useTrackingCodesState(state => state.deleteConfirmation);
  const selectedRow = useTrackingCodesState(state => state.selectedRow);
  const setState = useTrackingCodesState(state => state.setState);
  const { data: trackingCodesData, isLoading } = useGetAllTrackingCodeQuery();
  const createTrackingCodeMutation = useCreateTrackingCodeMutation();
  const deleteTrackingCodeMutation = useDeleteTrackingCodeMutation();
  const [modalForm] = Form.useForm();


  useEffect(() => {
    const skip = (currentPage - 1) * limit
    setState({ rows: trackingCodesData?.data?.map((item, index) => ({ trackingCodeNumber: skip + index + 1, uuid: item?.uuid, description: item?.description, create_time: item?.create_time })) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingCodesData?.data]);

  const addNewTrackingCode = () => {
    setState({ rows: [...rows, { description: '', isNew: true }], mode: 'edit', newTrackingCode: '' })
  }
  const handleDelete = (deleteRow) => {
    const data = rows?.filter((item) => item?.uuid === deleteRow?.uuid && item?.isNew === true);
    if (data?.length) {
      setState({ rows: rows?.filter((item) => item?.isNew !== true), mode: 'view' })
    } else {
      setState({ deleteConfirmation: true, selectedRow: deleteRow })
    }
  }
  const confirmTrackingCode = () => {
    if (newTrackingCode) {
      createTrackingCodeMutation.mutateAsync({ description: newTrackingCode })
      setState({ rows: rows?.filter((item) => item?.isNew !== true), mode: 'view' });
    } else {
      notification.error({ message: 'Tracking Code name is required! Please Enter Tracking Code name', placement: 'top' })
    }
  }
  const handleCancel = () => {
    const data = rows?.filter((item) => item?.isNew !== true);
    setState({ rows: data, mode: 'view' })
  }
  const handlePageChange = (page) => {
    setState({ currentPage: page });
  }

  //
  const handlePageSizeChange = (current, size) => {
    setState({ limit: size, currentPage: 1 });
  }
  const cancelDeleteConfirmation = () => {
    setState({ deleteConfirmation: false })
  }

  const submitDeleteConfirmation = (data) => {
    deleteTrackingCodeMutation.mutateAsync({ uuid: selectedRow?.uuid, password: data?.password })
    modalForm.resetFields();
  }
  const columns = [
    {
      title: 'Tracking Code Number',
      dataIndex: 'trackingCodeNumber',
      key: 'type',
      align: 'left',
      width: 300,
      render: (value, _record, index) => value ? value : ++index + (currentPage - 1) * limit,
    },
    {
      title: 'Tracking Code Name',
      dataIndex: 'description',
      key: 'description',
      align: 'left',
      width: 300,
      render: (val, row, key) => mode === 'edit' && row?.isNew ?
        <Input placeholder='Enter Tracking Code Name' onChange={(val) => setState({ newTrackingCode: val.target.value })} maxLength={255} />
        : <Tooltip title={val}>
          <Typography
            style={{
              width: 700,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>
            {val}
          </Typography>
        </Tooltip>
    },
    {
      title: 'Added on',
      dataIndex: 'create_time',
      key: 'create_time',
      width: 600,
      render: (val) => moment(val).format(FORMATS.date),
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'delete',
      render: (val, row, key) => <div style={{ padding: '20px' }}>
        <Button type="primary" danger size="small" onClick={() => handleDelete(row)}>
          <FaTrashAlt style={{ marginBottom: '-2px' }} />
        </Button>
      </div>
    }
  ];
  return (
    <>
      <Row>
        <Col span={24}>
          <PageDescription title="Tracking Codes" text="- Setup and manage your tracking codes here. Your cardholders will be able to select one of these tracking codes for each transaction they make" />
        </Col>
      </Row>
      <Row>
      </Row>
      <Row className="m-t-10">
        <Col span={24}>
          <CustomPagination
            loading={isLoading || createTrackingCodeMutation?.isLoading || deleteTrackingCodeMutation?.isLoading}
            onPageChange={handlePageChange}
            total={trackingCodesData?.pager?.count ?? 1}
            pageSize={limit}
            current={currentPage}
            onPageSizeChange={handlePageSizeChange}
          />
          <CustomTable
            columns={columns}
            dataSource={rows}
            className='spaced-rows'
            headerColor='green'
            rowKey="uuid"
            rowClassName='regular fs-18px'
            loading={isLoading || createTrackingCodeMutation?.isLoading || deleteTrackingCodeMutation?.isLoading}
            styleAllRows />
          {mode !== 'edit' && <Button
            onClick={addNewTrackingCode}
            style={{
              width: '100%',
              padding: '40px',
              background: 'transparent',
              border: '1px solid #0F99A3',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 'bold'
            }}>
            + Add New Tracking Code
          </Button>}
        </Col>
      </Row>
      {mode === 'edit' && <Row justify="end">
        <Col>
          <Space>
            <Button type="danger" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" onClick={confirmTrackingCode}>
              Confirm
            </Button>
          </Space>
        </Col>
      </Row>}
      <Row>
        <Col span={24}>
          <CustomPagination
            loading={isLoading || createTrackingCodeMutation?.isLoading || deleteTrackingCodeMutation?.isLoading}
            onPageChange={handlePageChange}
            total={trackingCodesData?.pager?.count ?? 1}
            pageSize={limit}
            current={currentPage}
            onPageSizeChange={handlePageSizeChange}
          />
        </Col>
      </Row>

      <Modal
        open={deleteConfirmation}
        onCancel={cancelDeleteConfirmation}
        closable={false}
        footer={null}
        bodyStyle={{ padding: '0px' }}
        width={600}
        destroyOnClose>
        <Card.Grid className="full-percent-width rounded p-30">
          <Row justify="center">
            <Col>
              <Typography.Text className="medium bold dark-green">Please confirm you wish to delete the following Tracking Code</Typography.Text>
            </Col>
          </Row>
          <Row className="m-t-30" justify="center">
            <Col span={21}>
              <Row justify="start">
                <Col span={9}>
                  <Typography.Text className="bold dark-green">Tracking Code Number</Typography.Text>
                </Col>
                <Col span={9}>
                  <Typography.Text className="bold dark-green">Tracking Code Name</Typography.Text>
                </Col>
                <Col span={6}>
                  <Typography.Text className="bold dark-green">Added on</Typography.Text>
                </Col>
              </Row>
              <Row justify="start">
                <Col span={9}>
                  <Typography.Text className="dark-green">{selectedRow?.trackingCodeNumber}</Typography.Text>
                </Col>
                <Col span={9}>
                  <Typography.Text className="dark-green">{selectedRow?.description}</Typography.Text>
                </Col>
                <Col span={6}>
                  <Typography.Text className="dark-green">{moment(selectedRow?.create_time).format(FORMATS.date)}</Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
          <Form
            layout="vertical"
            onFinish={submitDeleteConfirmation}
            form={modalForm}
          >
            <Row justify="center" className="m-t-40">
              <Col>
                <Form.Item
                  name='password'
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Your Password'
                    }
                  ]}
                >
                  <Input.Password autoComplete="false" placeholder="Enter Password" className="b-g" />
                </Form.Item>
              </Col>
              <Col>
                <Typography.Text className="error bold">Once deleted, your cardholders will no longer be able to use this Tracking Code</Typography.Text>
              </Col>
            </Row>
            <Row className="m-t-20" gutter={16}>
              <Col span={24} className='center-align-text'>
                <Space size={108}>
                  <Button type="primary" onClick={cancelDeleteConfirmation}>Cancel</Button>
                  <Button type="danger" htmlType="submit" loading={deleteTrackingCodeMutation.isLoading}>Confirm</Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Card.Grid>
      </Modal>
    </>
  )
}

export default TrackingCodes