import { MESSAGES } from "App/Components/ErrorView/store/data"
import { IMAGES } from "Constants/Images"
import { Col, Row, Spin, Typography } from "antd"

function ErrorView({ title = '', message = '', serviceKey = '', loading = false, exclamation = false }) {
  if (serviceKey) {
    if (MESSAGES?.[serviceKey]) {
      title = MESSAGES?.[serviceKey]?.title;
      message = MESSAGES?.[serviceKey]?.message;
    } else if (!title && !message) {
      title = MESSAGES?.other?.title;
      message = MESSAGES?.other?.message;
      exclamation = true;
    }
  }
  return (
    <Spin size='large' spinning={loading}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: 'calc(100vh - 100px)',
          backgroundImage: `url(${exclamation ? IMAGES.question : IMAGES.errorIcon})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 60%'
        }}>
        <Col>
          {!loading && title && (Array.isArray(title) ? title.map(text =>
            <Row justify="center">
              <Col>
                <Typography.Text level={1} className="bold dark-green fs-32px center-align-text">{text}</Typography.Text>
              </Col>
            </Row>) : <Row justify="center">
            <Col>
              <Typography.Text level={1} className="bold dark-green fs-32px center-align-text">{title}</Typography.Text>
            </Col>
          </Row>
          )}
          {!loading && message && (Array.isArray(message) ? message.map(text =>
            <Row justify="center">
              <Col>
                <Typography.Text level={2} className="medium dark-green fs-28px center-align-text">{text}</Typography.Text>
              </Col>
            </Row>) : <Row justify="center">
            <Col>
              <Typography.Text level={2} className="medium dark-green fs-28px center-align-text">{message}</Typography.Text>
            </Col>
          </Row>
          )}
        </Col>
      </Row>
    </Spin>
  )
}

export { ErrorView }