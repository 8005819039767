import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const usePrepaidCardQuery = (cardId, enabled = true) => {
    const apiRequest = useApiRequest();

    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
        const { data } = await apiRequest(APIROUTES.get.prepaidcard + cardId + '?$with=All');
        return data;
    }

    // eslint-disable-next-line no-unused-vars
    const _fetchQuery = async () => {
        return new Promise(resolve => {
            setTimeout(resolve([]), 1200);
        });
    }

    //
    const query = useQuery({
        queryKey: ['_prepaidcard', cardId],
        queryFn: fetchQuery,
		enabled,
    });

    return query;
}

export { usePrepaidCardQuery };
