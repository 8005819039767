import { Avatar, Button, Col, Collapse, Row, Space, Typography } from "antd";
import { FORMATS } from "Constants/Formats";
import moment from "moment";

function AccountingSystem({ id, alert_json_object, onClear = () => { } }) {
  const date = alert_json_object?.date?.data;
  const name = alert_json_object?.name?.data;
  const logo = alert_json_object?.logo_url?.data;
  const title = alert_json_object?.title;

  return (
    <Collapse ghost className="bg-white">
      <Collapse.Panel className="full-percent-width-pannel-header" header={
        <>
          <Row align="middle" justify="space-between" gutter={16}>
            <Col>
              <Typography.Text className="fs-18px medium">{title}</Typography.Text>
            </Col>
            <Col>
              <Avatar src={logo} size={64} />
            </Col>
          </Row>
        </>
      }
        showArrow={false}>
        <Row>
          <Col>
            <Space direction="vertical" size={0}>
              <Typography.Text className="medium fs-18px">Accounting System</Typography.Text>
              <Space align='center'>
                <Typography.Text className="light-green muli semi-bold fs-18px">{name}</Typography.Text>
              </Space>
            </Space>
          </Col>
        </Row>
        <Row className="m-t-20">
          <Col>
            <Space direction="vertical" size={0}>
              <Typography.Text className="medium fs-18px">Date of Connection</Typography.Text>
              <Typography.Text className="light-green muli semi-bold fs-18px">
                {date && moment(date).isValid() ? moment(date).format(FORMATS.date) : ''}
              </Typography.Text>
            </Space>
          </Col>
        </Row>
        <Row justify="end">
          <Col>
            <Button type="primary" onClick={() => onClear(id)}>Clear</Button>
          </Col>
        </Row>
      </Collapse.Panel>
    </Collapse>
  )
}

export default AccountingSystem;