import { Badge, Card, Col, Row, Typography } from "antd";
import PageDescription from "App/Components/PageDescription";
// import squareLogo from 'Assets/Images/squareLogo.png';
// import bills from 'Assets/Images/bills.png';
import { FaUser, FaCoins, FaScroll, FaRegBell } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import URLS from "Routes/constants";

function CompanyAccount() {
    const navigate = useNavigate();
    const cards = [
        // {
        //     image: <Image src={squareLogo} preview={false} width={163} />,
        //     text: 'Company Summary'
        // },
        {
            image: <FaUser size={120} className='dark-green' />,
            text: 'Manage Users',
            onClick: () => navigate(URLS.ManageUsers)
        },
        // {
        //     image: <FaUsers size={120} className='dark-green' />,
        //     text: 'User Roles & Permissions',
        //     onClick: () => navigate(URLS.UserRolesAndPermissions)
        // },
        {
            image: <FaCoins size={120} className='dark-green' />,
            text: 'Refund Details',
            onClick: () => navigate(URLS.RefundDetails)
        },
        // {
        //     image: <Image src={bills} preview={false} width={126} />,
        //     text: 'Bills'
        // },
        {
            image: <FaScroll size={120} className='dark-green' />,
            text: 'Terms & Conditions',
            onClick: () => navigate(URLS.Terms)
        },
        // {
        //     image: <FaScroll size={120} className='light-green' />,
        //     text: 'Your Application',
        //     onClick: () => navigate(URLS.YourApplication)
        // },
        {
            image: <Badge count=' ' offset={[-30, 20]}><FaRegBell size={120} className='grey' /></Badge>,
            text: 'Notification Settings',
            onClick: () => navigate(URLS.NotificationSettings)
        },
    ]
    return (
        <>
            <Row>
                <Col span={24}>
                    <PageDescription title='Company Account' />
                </Col>
            </Row>
            <Row className="m-t-20" gutter={[72, 48]}>
                {cards && cards.length > 0 && cards.map((val, key) => (
                    <Col xxl={6} xl={8} lg={10} md={12} sm={16} xs={24} key={key}>
                        <Card hoverable className="b-g p-10" onClick={val.onClick}>
                            <Row justify='center'>
                                <Col>
                                    {val.image && val.image}
                                </Col>
                            </Row>
                            <Row justify='center' className="m-t-20">
                                <Col>
                                    <Typography.Text className="muli semi-bold fs-18px">{val.text}</Typography.Text>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}
            </Row>
        </>
    );
}

export default CompanyAccount;