import { Table } from "antd";

function CustomTable({
  rowType: rowTypeProp,
  bodyColor: bodyColorProp,
  styleAllRows: styleAllRowsProp,
  headerColor: headerColorProp,
  headerFont: headerFontProp,
  rowClassName: rowClassNameProp,
  className: classNameProp,
  scroll: scrollProp,
  onRow: onRowProp,
  rowKey = "key",
  columns,
  dataSource,
  loading,
  showHeader,
  pagination = false,
  onChange = () => { }
}) {
  const rowType = rowTypeProp === "filled" ? "row-filled " : "row-border ";
  const bodyColor = bodyColorProp === "green" ? "dark-green " : "";
  const styleAllRows = styleAllRowsProp;
  const headerColor = headerColorProp === "green" ? "dark-green-header " : "";
  const headerFont = headerFontProp === "muli" ? "muli-header " : "";
  const rowClassName = rowClassNameProp ? rowClassNameProp + " " : "medium fs-18px ";
  const rowClassName1 = rowType + rowClassName + bodyColor;
  const rowClassName2 = styleAllRows === true ? rowClassName1 : rowClassName + bodyColor;
  const className = headerColor + headerFont + classNameProp;
  const scroll = scrollProp ? scrollProp : { x: 390 };
  const onRow = onRowProp;

  columns?.forEach(item => {
    if (!item.align)
      item.align = 'center'
  });

  return (
    <Table
      pagination={pagination}
      size="small"
      rowKey={rowKey}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      showHeader={showHeader === false ? false : true}
      className={className}
      rowClassName={(data, index) =>
        index % 2 === 0 ? rowClassName1 : rowClassName2
      }
      data-testid="container"
      scroll={scroll}
      onRow={onRow}
      onChange={onChange}
    />
  );
}

export default CustomTable;
