import { userMenuItems } from "App/Components/NavBar/store/data";
import { useLogout } from "App/Pages/Login/store";
import { usePersonalProfileQuery } from "App/Pages/Settings/PersonalProfile/query";
import URLS from "Routes/constants";
import { Avatar, Card, Col, Menu, Row, Typography } from "antd";
import { FaRegUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom"

function UserMenu() {
  const navigate = useNavigate();
  const logout = useLogout();
  const { data: personalProfile } = usePersonalProfileQuery();

  const onUserMenuItemClick = (item) => {
    item.key === "my-profile" && navigate(URLS.PersonalProfile)
    item.key === "settings" && navigate(URLS.CompanyAccount)
    item.key === "help" && navigate(URLS.Help)
    item.key === "logout" && logout();
  }
  return (
    <Card bodyStyle={{ padding: '0px' }} style={{ minWidth: '150px' }}>
      <Row justify="center" className="m-t-5">
        <Col>
          <Avatar icon={<FaRegUser />} size={42} />
        </Col>
      </Row>
      <Row justify="center" className="m-l-5 m-r-5">
        <Col>
          <Typography.Text className="dark-green fs-18px bold center-align-text">{personalProfile?.company_name}</Typography.Text>
        </Col>
      </Row>
      <Row justify="center" className="m-l-5 m-r-5">
        <Col>
          <Typography.Text className="dark-green fs-16px center-align-text">{personalProfile?.first_name} {personalProfile?.middle_name} {personalProfile?.last_name}</Typography.Text>
        </Col>
      </Row>
      <Menu onClick={onUserMenuItemClick} items={userMenuItems} />
    </Card>
  );
}

export { UserMenu }