import {
	Button,
	Col,
	Row,
	Typography,
	Form,
    Input,
    Modal,
} from "antd";
import create from "zustand";
import { useUserDelete } from "../hooks/queries/useUserDelete";
export const useState = create(set => ({
    passwd: '',
    username: '',
    user_id: '',
    modalOpen: false,
    modalType: 'confirm',
    setState: (payload) => set(payload),
}));

function DeleteConfirmationModal({cardId, onCancel = () => {} }) {
    const username= useState(s => s.username)
    const modalOpen= useState(s => s.modalOpen)
    const [form] = Form.useForm();
    const user_id= useState(s => s.user_id)
    const setState= useState(s => s.setState)
    const modalType= useState(s => s.modalType)
    const { mutate: UserDelete, isLoading} = useUserDelete();
    const onConfirm = () => {
        UserDelete({ user_id, password: form.getFieldValue('password')})
    }
    const toggleModal = (type) => {
        if(type === 'close') {
            setState({ modalOpen: false, user_id: '', username: '' });
			form.resetFields();
        }
    }
	return (
		<>
        <Modal
            destroyOnClose
            footer={false}
            open={modalOpen}
            onCancel={() => toggleModal('close')}
            closable={false}
          >
            {modalType === 'confirm' && <>
			<Row>
				<Col>
					<Typography.Text className="muli semi-bold fs-18px dark-green">
						DELETE Pending User
					</Typography.Text>
				</Col>
			</Row>
            <Row className="m-t-20" justify="center">
				<Col>
					<Typography.Text className="muli semi-bold fs-16px dark-green">
						Please confirm you wish to delete the following <br/>
                        pending user: {username}#{user_id}?
					</Typography.Text>
				</Col>
			</Row>
			<Form
				layout="vertical"
				onFinish={onConfirm}
                form={form}
				requiredMark={false}
			>
				<Row className="m-t-20" justify="center">
					<Col span={12}>
						<Form.Item
						name="password"
						rules={[
							{
							required: true,
							message: "Please Enter Password",
							},
						]}
						>
						<Input.Password placeholder="Enter Password" />
						</Form.Item>
					</Col>
				</Row>
				<Row className="m-t-20" justify="space-between">
					<Col>
						<Button type="primary" onClick={() => toggleModal('close')}>
							Back
						</Button>
					</Col>
					<Col>
						<Button htmlType="submit" type="danger" loading={isLoading}>
							Delete User
						</Button>
					</Col>
				</Row>
			</Form>
            </>}
            {modalType === 'success' && 
            <>
              <Row>
				<Col>
					<Typography.Text className="muli semi-bold fs-18px dark-green">
						Pending User Deleted
					</Typography.Text>
				</Col>
			</Row>
            <Row className="m-t-20" justify="center">
				<Col>
					<Typography.Text className="muli semi-bold fs-16px dark-green">
						{username}#{user_id} has been deleted from the <br/>
                        pending user list
					</Typography.Text>
				</Col>
			</Row>
            <Row className="m-t-20" justify="center">
					<Col>
						<Button type="primary" onClick={() => toggleModal('close')}>
							Return
						</Button>
					</Col>
				</Row>
            </>}
            </Modal>
	  </>
	)
}

export default DeleteConfirmationModal;
