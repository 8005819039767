import { Button, Image } from 'antd';
import { AiOutlineClose } from "react-icons/ai";
import pdfIconImg from "Assets/Images/ico-pdf.png";
import docIconImg from "Assets/Images/ico-doc.png";
import { useState } from 'react';

const ThumbBox = ({origin, file, onClick, onDelete}) => {
	const [showDeleteBtn, setShowDeleteBtn] = useState(false);
	let src = (file.file_extension === 'docx' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ? 
	docIconImg : (file.file_extension === 'pdf' || file?.type === 'application/pdf') ? 
	pdfIconImg : file.thumb;

	//
	return (
		<div
			style={{position: 'relative'}}
			onMouseEnter={() => setShowDeleteBtn(true)}
			onMouseLeave={() => setShowDeleteBtn(false)}
		>
			<Button
				onClick={onClick}

				style={{
					height: '101px',
					width: '101px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					overflow: 'hidden',
				}}
			>
				<Image preview={false} src={src} style={{width: '100%'}} alt={file.file_name}/>
			</Button>
			{showDeleteBtn &&
					<AiOutlineClose
					onClick={onDelete}
					size={26}
					style={{
						position: 'absolute',
						top: '-9px',
						right: '-3px',
						backgroundColor: '#111',
						borderRadius: '25px',
						color: 'white',
						transition: 'all 0.3s',
						cursor: 'pointer',
						padding: '4px'
					}}
				/>
			}{/* -- showDeleteBtn */}
		</div>
	)
}

export default ThumbBox;
