import create from "zustand";

const useWalletDashboardState = create((set) => ({
    currencies: undefined,
    rateCheckerLoading: false,
    walletBreakdownLoading: false,
    walletBalances: undefined,
    balances: undefined,
    fundingHistory: undefined,
    fundingHistoryLoading: false,
    showFundRepeatModal: false,
    cwSummaryCurrency: '',
    cwSummaryTotalCompanyBalance: '',
    cwSummaryWalletBalance: '',
    cwSummaryCardsBalance: '',
    modalTitle: undefined,
    modalType: undefined,
    modalVisible: false,
    volopaAccountHolderName: undefined,
    volopaAccountSortCode: undefined,
    volopaAccountNumber: undefined,
    volopaAccountCountry: undefined,
    volopaAccountAddress: undefined,
    volopaAccountName: undefined,
    volopaAccountPaymentRef: undefined,
    volopaAccountIban: undefined,
    volopaAccountSwift: undefined,
    volopaAccountNumberType: undefined,
    volopaAccountSortCodeLabel: 'Sort Code',
    volopaAccountDetails: undefined,
    fundingLimitReached: false,
    loadingFundingData: false,
    setState: (data) => set({ ...data })
}));

export { useWalletDashboardState };