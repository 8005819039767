/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Col, Form, Image, Input, Row, Typography, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";
import logo from 'Assets/Images/logo.png';
import one from '../store/images/one.png';
import two from '../store/images/two.png';
import three from '../store/images/three.png';
import URLS from "Routes/constants";
import { colors } from "Constants/Style";
import { FaRegCheckCircle } from "react-icons/fa";
import { useEffect } from "react";
import { useForgotPasswordState } from "App/Pages/Login/ForgotPassword/store";
import { apiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

function ForgotPassword() {
    const submitted = useForgotPasswordState(state => state.submitted);
    const loading = useForgotPasswordState(state => state.loading);
    const setState = useForgotPasswordState(state => state.setState);
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const handleSubmit = async () => {
        try {
            setState({loading: true});
            const payload = {
                email: form.getFieldValue('username'),
            }

            // Public route so no useApiRequest
            const res = await apiRequest(
                APIROUTES.put.users_forgot_password,
                'PUT',
                payload,
            );

            // @Craig, our api hook does not throw errors ?
            if( !! res?.response?.data?.error ) {
                throw new Error();
            }

            setState({ submitted: true });
        } catch(err) {
            setState({ submitted: false });
            notification.error({ message: 'There was an error while sending your reset link. Please try again.', placement: 'top' });
        } finally {
            setState({loading: false});
        }
    }

    useEffect(() => {
        setState({ submitted: false });
    }, [])

    return (
        <Row className="full-height" align="middle" justify="center" style={{ overflow: 'hidden' }}>
            <Col xxl={6} xl={9} lg={12} md={12} sm={18} xs={22}>
                <img src={one} className='one' alt="1" />
                <img src={two} className='two' alt="2" />
                <img src={three} className='three' alt="3" />
                <Card hoverable >
                    <Row justify="center" className="m-t-100">
                        <Col>
                            <Image src={logo} alt='logo' preview={false} width={214} />
                        </Col>
                    </Row>
                    <Row justify="center" className="m-t-10">
                        <Col>
                            <Typography.Text className="fs-18px medium center-align-text">
                                {!submitted ?
                                    'Please enter your email to receive a link to reset your password' :
                                    'Please check your email for a link to reset your password'}
                            </Typography.Text>
                        </Col>
                    </Row>
                    <Row className="m-t-40" justify="center">
                        <Col span={18}>
                            {!submitted ? (
                                <Form
                                    form={form}
                                    layout="vertical"
                                    requiredMark={false}
                                    onFinish={handleSubmit}
                                >
                                    <Form.Item
                                        name='username'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Enter Your Username/email'
                                            },
                                            {
                                                type: 'email',
                                                message: 'please enter a valid email'
                                            }
                                        ]}
                                    >
                                        <Input size="large" placeholder="Username/email" className="b-g placeholder-green" />
                                    </Form.Item>
                                    <Row justify="center" className="m-t-40">
                                        <Col>
                                            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>Reset Password</Button>
                                        </Col>
                                    </Row>
                                    <Row justify="center" className="m-t-10 m-b-40">
                                        <Col>
                                            <Link to={URLS.Login} className="dark-green fs-18px bold underline">Cancel</Link>
                                        </Col>
                                    </Row>
                                </Form>
                            ) : (
                                <>
                                    <Row justify="center">
                                        <Col>
                                            <FaRegCheckCircle color={colors.success} size={100} />
                                        </Col>
                                    </Row>
                                    <Row justify="center" className="m-t-40 m-b-40">
                                        <Col>
                                            <Button type="primary" onClick={() => navigate(URLS.Login)} >Return to Login</Button>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    )
}

export default ForgotPassword;