import create from 'zustand'

const useManageUsersState = create(set => ({
    showPending: false,
    userList: undefined,
    currentPage: 1,
    limit: 10,
    searchFilter: undefined,
    sort: undefined,
    deleteLoading: false,
    statusIdIn: '1',
    setState: obj => set(obj)
}));

export { useManageUsersState };