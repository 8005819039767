import { Space } from "antd";
const pageContent = [
    {
        title: 'What is the Volopa Mastercard?',
        text: [
            'The Volopa Mastercard is a multi-currency prepaid payment card.',
            'A prepaid card is not linked to a bank account and you can only spend money that you have already topped-up onto the card providing you with an increased level of control over your expenses.',
            'Your Volopa Mastercard can hold and spend in up to 14 local currencies.',
            'All currency is converted on the card at the real market exchange rate without incurring hidden fees.',
            'When spending abroad at a merchant that accepts Mastercard or withdrawing cash from an ATM, the card reader will automatically detect that you have the local currency on the card and take payment in that currency for the value of the transaction.',
            "You will not incur foreign transaction charges typically associated with using a debit or credit card abroad. It's like having 14 local cards in your wallet.",
            'You can also use your Volopa Mastercard to spend in any other currency as long as you have the base currency on the card.'
        ]
    },
    {
        title: 'Is Volopa authorised?',
        text: 'Yes. Volopa is authorised by the UK Financial Conduct Authority (FCA Number 554549) to carry out payment services and is licenced by Mastercard to issue cards across the European Economic Area (EEA).'
    },
    {
        title: 'Is my money safe with Volopa?',
        text: [
            "Certainly, we safeguard all of your funds. Volopa holds all client money in accordance with the UK Financial Conduct Authority's (FCA) Client Money rules.",
            "Client money is held separately from the company's money in designated Client Trust Accounts with a EU regulated bank.",
        ]
    },
    {
        title: 'What is my base currency?',
        text: [
            "The base currency on your card is typically your home currency of where your business is registered.",
            "This will either be GBP or EUR and the relevant currency symbol will be displayed on the back of your card.",
        ]
    },
    {
        title: 'How do I activate my card?',
        text: [
            "You can activate your card using the app or online.",
            `Within the 'Action' menu, select 'Activate Card' and confirm by tapping the 'Activate Card' button.`,
            `Your card will be activated instantly. Your card PIN number will be displayed on screen once your card is activated.`,
        ]
    },
    {
        title: 'Where can I see my card PIN?',
        text: [
            "You can see your PIN via the app at any time",
            `Within the 'Action' menu, select 'Card Controls' and tap 'Show PIN'. To keep you secure, we will request your 6-digit passcode and your PIN will be displayed for a few seconds.`,
            `Repeat the steps above should you want to see your PIN again.`,
        ]
    },
    {
        title: 'How do I change my card PIN?',
        text: [
            "You can change your PIN at any ATM machine that provides this service.",
            `You will be asked to enter your existing PIN and to select a new PIN.`,
            `Please choose a PIN that can't be easily guessed by anyone else - E.g. don't use 1111, 1234 or your date or birth.`,
        ]
    },
    {
        title: 'How do I load my card?',
        text: [
            "Your card can only be loaded by either the Primary or Corporate Administrator.",
            `Your card can be loaded 24/7 and funds will appear instantly on your card balance.`,
        ]
    },
    {
        title: 'How do I exchange currency?',
        text: [
            "Employees with account roles of Primary Administrator, Corporate Administrator or Business User can exchange instantly between currencies on cards 24/7 using the app or online.",
            `Within the “Action” menu, select “Exchange Currency”. Select the currency and amount that you would like to exchange.`,
            `We will show you the exchange rate and amount of currency that you would be selling and buying before you accept the transaction.`,
            `The new currency will appear instantly on the relevant cards ready to use.`,
        ]
    },
    {
        title: 'How do I block my card?',
        text: [
            "You can temporarily block and unblock your card.",
            `Within the 'Action' menu, select 'Card Controls' and tap 'Freeze Card'. Your card will be instantly blocked.`,
            `You can unblock your card at any time by taping on 'Unfreeze Card'.`,
            `You can also report your card as lost or stolen which will permanently block the card.`,
            `You can request a replacement card by e-mailing us at support@volopa.com or calling our customer support team on +44 (0)333 400 1287, Mon - Fri, 09:00 to 17:00 UK.`,
        ]
    },
    {
        title: 'How do I earn cashback?',
        text: [
            "Simply use your Volopa Mastercard at any participating retailer when an offer is active.",
            `You can explore cashback offers and when these are active within the 'Lifestyle' section of the app.`,
            `There are no vouchers or promo codes, and you don't need to notify the retailer in order to get your cashback.`,
            `We will send you a notification to confirm your cashback once you have made payment.`,
        ]
    },
    {
        title: 'Where can I track my cashback?',
        text: [
            "To view your cashback transactions, go to the 'Lifestyle' section or the app and select 'History'.",
            `You will be able to see all cashback transactions including those that have been paid out on the card and those which are due to be paid including the date on which these should be paid.`,
        ]
    },
    {
        title: 'When will my cashback be paid?',
        text: [
            "Cashback is usually paid out on Fridays.",
            `If this falls on a Bank Holiday, you will receive your cashback in the following week.`,
            `You will be able to see your paid cashback under 'History' within the 'Lifestyle' section of the app as well as a credit on your card within your transactions in the 'Card Management' section.`,
        ]
    },
    {
        title: 'What do I do if my cashback is missing?',
        text: [
            `If you cannot see your cashback transaction under 'History' within the 'Lifestyle' section of the app within a few minutes of making the payment on your Volopa card, then please report this as missing to us. You can do this by going to 'Contact Us' within the 'Help Centre' of the app. Under 'Type of Request' please select 'I have a general account query' and in “Your Message” notify us of the date and time of the transaction, and the merchant name.`,
            `We will investigate the missing cashback and get in touch with you usually within up to 2 working days.`
        ]
    },
    {
        title: 'Is the Volopa Mastercard contactless?',
        text: `Yes. You can make contactless payments at any merchant terminal which accepts this at home and abroad.`
    },
    {
        title: 'Is there a minimum card load amount?',
        text: `There is no minimum load amount.`
    },
    {
        title: 'How do I get in touch with customer support?',
        text: [
            `You can contact the customer support team by e-mail at support@volopa.com or call on +44 (0)333 400 1287, Mon - Fri, 09:00 to 17:00 UK excluding Public Holidays in England.`,
            <Space direction="vertical" size={0}>
                Any other questions or feedback about your Volopa Mastercard?
                Please contact us on +44 (0) 333 400 1287 or e-mail support@volopa.com
            </Space>
        ]
    }
];


export { pageContent }