import { Button, Modal, Upload, Carousel, Typography, Row, Col, notification, Image, Spin, Skeleton } from 'antd';
import { useRef } from 'react';
import pdfIconImg from "Assets/Images/ico-pdf.png";
import docIconImg from "Assets/Images/ico-doc.png";
import { FaPaperclip, FaChevronLeft, FaChevronRight, FaDownload, FaTrash, FaWindowClose } from 'react-icons/fa';
import { useTransactionFilesState } from './store';
import { useTransactionState } from 'App/Pages/Cards/TransactionHistory/SpecificCardTransaction/store';
import ThumbBox from './components/ThumbBox';
import { useApiRequest } from 'Hooks/API';
import { APIROUTES } from 'Constants/ApiRoutes';
import { useTransactionQuery } from 'App/Pages/Cards/TransactionHistory/query';
import { useLoginStore } from 'App/Pages/Login/store';

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const TransactionAttachmentUpload = ({ onChange = () => { } }) => {
    const previewOpen = useTransactionFilesState((state) => state.previewOpen);
    const currentPreviewSlide = useTransactionFilesState((state) => state.currentPreviewSlide);
    const fileStore = useTransactionState((state) => state.fileStore);
    const setState = useTransactionFilesState((state) => state.setState);
    const setTxState = useTransactionState((state) => state.setState);
    const fileLoading = useTransactionState((state) => state.fileLoading);
    const config = useLoginStore(state => state.config);
    const fileQuery = useTransactionQuery();
    const previewCarouselRef = useRef();
	const apiRequest = useApiRequest();

    //
    const handleCancel = () => setState({ previewOpen: false });

    //
    const handleChange = async ({ file, fileList }) => {
        setState({ loading: true });

        if ((file.size / 1048576) > 11) {
            fileList = fileList.filter(item => item.uid !== file.uid);
            notification.error({ message: 'Maximum file size is 11MB.', placement: 'top' });
            setState({ loading: false });
            return;
        }

        // Uploaded file has thumbUrl dataSrc,
        // but we need a large img for modal
        const fileListPromises = fileList.map(async file => {
            if (file.originFileObj && file.originFileObj.type.includes('image') && (!file.thumb || !file.url)) {
        const preview = await getBase64(file.originFileObj);
                return {
                ...file,
                    id: file.uid, // aligned with api response,
                thumb: preview,
                url: preview,
                }
            } else {
                return file;
            }
        });

        const newFileList = await Promise.all(fileListPromises);

        setTxState({
            fileStore: newFileList,
        });

        setState({ loading: false });
        onChange();
    };

    //
    const handleOpenPreviewImage = (file) => {
        let src = null;
        if(file.file_extension === 'pdf' || file?.type === 'application/pdf') {
            src = pdfIconImg;
        } else if(file.file_extension === 'docx' || file?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            src = docIconImg;
        } else {
            src = file.url || null
        }

        let slideIdx = fileStore.findIndex(fileItem => fileItem.id === file.id);
        setState({
            previewImage: src,
            previewOpen: true,
            setPreviewTitle: file?.name || file?.url?.substring(file?.url?.lastIndexOf('/') + 1) || '',
            currentPreviewSlide: slideIdx ?? 0,
        });

        if (previewCarouselRef.current) {
            previewCarouselRef.current.goTo(slideIdx ?? 0);
        }
    }

    //
    const handleSlidePage = (prev = false) => {
        if (previewCarouselRef.current) {
            if (prev) {
                previewCarouselRef.current.prev();
                return;
            }

            previewCarouselRef.current.next();
        }
    }

    //
    const uploadButton = (
        <div>
            <FaPaperclip />
            <div style={{ marginTop: 8, position: 'relative' }}>Upload</div>
        </div>
    );

    //
    const handleDelete = async (file) => {


		if(!file?.originFileObj) {

			try {

				const { data } = await apiRequest(APIROUTES.del.file, 'DELETE', {uuid: file.uuid});

				if(data?.error) {
					notification.error({message: 'Something', placement: 'top'});
					return;
				}

				setState({
					previewOpen: false,
				});

				setTxState({
					fileStore: [...fileStore.filter(item => item.id !== file.id)],
				});

				notification.success({message: 'Attachment deleted.', placement: 'top'});

			} catch(err) {
				notification.error({message: err, placement: 'top'});
			}

			return;
		}

		setState({
			previewOpen: false,
		});

		setTxState({
			fileStore: [...fileStore.filter(item => item?.uid !== file?.uid)],
		});

		notification.success({message: 'Attachment deleted.', placement: 'top'});
    }

    return (
        <>
            {/* Upload box ======================== */}
            <Spin spinning={fileQuery.isLoading || fileLoading}>
            {fileQuery.isLoading && <Skeleton.Image active={fileQuery.isLoading || fileLoading} />}
                <Upload
                    className="transaction-upload-component"
                    customRequest={() => { }}
                    listType="picture-card"
                    fileList={fileStore}
                    onChange={handleChange}
                    accept=".png,.jpg,.jpeg,.pdf,.docx"
                    itemRender={(origin, file) => <ThumbBox onDelete={() => handleDelete(file)} origin={origin} file={file} onClick={() => handleOpenPreviewImage(file)}/>}
                    multiple={true}
                    maxCount={config.max_no_attachments}
                    >
                {fileStore.length >= config.max_no_attachments ? null : uploadButton}
                </Upload>
            </Spin>

            {/* Preview modal ======================== */}
            <Modal

                open={previewOpen}
                footer={false}
                closable={false}
                onCancel={handleCancel}
                iconRender={<FaPaperclip />}
            >
                <div style={{ position: 'relative', padding: '0 30px' }}>
                    <FaWindowClose onClick={handleCancel} size={24} className='dark-green' style={{ position: 'absolute', right: 0, top: 0, cursor: 'pointer' }} />
                    <FaChevronLeft onClick={() => handleSlidePage(true)} size={30} style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 2, cursor: 'pointer' }} />
                    <FaChevronRight onClick={() => handleSlidePage()} size={30} style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 2, cursor: 'pointer' }} />

                    <Carousel adaptiveHeight={true} infinite={true} initialSlide={currentPreviewSlide} ref={(ref) => previewCarouselRef.current = ref}>
                        {fileStore.length && fileStore.map((file, idx) => {
                            let src = null;
                            if(file?.file_extension === 'pdf' || file?.type === 'application/pdf') {
                                src = pdfIconImg;
                            } else if(file?.file_extension === 'docx' || file?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                                src = docIconImg;
                            }
                             else {
                                src = file.url || null;
                            }

                            return (
                                <div key={idx} style={{position: 'relative'}}>
									<div style={{width: '100%', maxHeight: '80vh'}}>
										<Image preview={false} alt={file.name} style={{ width: '100%', padding: 4}} src={src} />
									</div>

                                    <Row style={{ marginTop: 30, padding: '0 5px' }} align="middle" justify="space-between">
                                        <Col span={3}>
                                            <Button onClick={() => handleDelete(file)} type="danger">
                                                <FaTrash />
                                            </Button>
                                        </Col>
                                        <Col span={18} >
                                            <Typography.Text style={{ display: 'flex', justifyContent: 'center' }} className="dark-green">{file.file_name}</Typography.Text>
                                        </Col>
                                        <Col span={3} style={{ textAlign: 'right' }}>
                                            <Button type="info" className='dark-green' href={file.url || null} download={file.file_name}>
                                                <FaDownload />
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })}
                    </Carousel>
                </div>
            </Modal>
        </>
    );
};

export default TransactionAttachmentUpload;
