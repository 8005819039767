import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useCardBalanceState } from "Hooks/Store";

const usePrepaidcardBalanceQuery = (cardId) => {
    const apiRequest = useApiRequest();
    const setState = useCardBalanceState(state => state.setState);

    // eslint-disable-next-line no-unused-vars
    const fetchQuery = async () => {
        const { data } = await apiRequest(`${APIROUTES.get.users_card_balance}?prepaid_card_id=${cardId}`);
        return data?.card_balance;
    }

    // eslint-disable-next-line no-unused-vars
    const _fetchQuery = async () => {
        return new Promise(resolve => {
            setTimeout(resolve([]), 1200);
        });
    }

    const query = useQuery({
        queryKey: ['_users_card_balance', cardId],
        queryFn: fetchQuery,
        enabled: !!cardId,
        cacheTime: 0,
        staleTime: 0,
        onSuccess: (resData) => {
            setState({
                balances: resData.sort((a,b) => a.currency === 'GBP' ? -1 : b.currency === 'GBP' ? 1 : 0),
                total: resData?.total ?? 0,
            })
        }
    });

    return query;
}

export { usePrepaidcardBalanceQuery };