import create from "zustand";

const useNotificationSettingsState = create(set => ({
    modalOpen: false,
    modalKey: undefined,
    toggle: undefined,
    ccEmail: undefined,
    isSubmitting: false,
    rowsData: [],
    setState: obj => set(obj)
}));

export { useNotificationSettingsState };