/* eslint-disable react-hooks/exhaustive-deps */
import PageDescription from "App/Components/PageDescription";
import { useChangeEmailState } from "App/Pages/Settings/PersonalProfile/ChangeEmail/store";
import { APIROUTES } from "Constants/ApiRoutes";
import { useApiRequest } from "Hooks/API";
import URLS from "Routes/constants";
import { Button, Col, Form, Input, Row, Typography, notification } from "antd";
import { useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

function ChangeEmail() {
  const navigate = useNavigate();
  const { userId } = useParams();
  const location = useLocation();
  const apiRequest = useApiRequest();
  const emailSent = useChangeEmailState(s => s.emailSent);
  const showPendingStatus = useChangeEmailState(s => s.showPendingStatus);
  const email = useChangeEmailState(s => s.email);
  const submitting = useChangeEmailState(s => s.submitting);
  const passwordStatus = useChangeEmailState(s => s.passwordStatus);
  const passwordHelp = useChangeEmailState(s => s.passwordHelp);
  const setState = useChangeEmailState(s => s.setState);
  const [form] = Form.useForm();
  const password = Form.useWatch('password', form);
  let path = location?.pathname?.split('/');
  path.length--;
  path = path?.join('/');
  const description = path === URLS.ChangeEmail ? [
    `If you wish to change your email, then simply enter your new email address. 
              You will receive an email confirmation to your new email address. 
              You will have to confirm this change by clicking the link sent to you. 
              After the confirmation, new emails will then only be sent to your new email address.`,
    'If you no longer have access to your email account, please contact our support team.'
  ] : [
    `If you wish to change the email address of another user, then simply enter their new email address. 
  They will receive an email confirmation to their new email address.
  They will have to confirm this change by clicking the link sent to them.`,
    `After the confirmation, new emails will then only be sent to their new email address.`
  ];

  const labels = {
    email: path === URLS.ChangeEmail ? 'Your New Email' : 'New User Email',
    password: path === URLS.ChangeEmail ? 'Enter Password' : 'Enter Your Password to Confirm Change'
  }

  const sourceURL = path === URLS.ChangeEmail ? URLS.PersonalProfile : `${URLS.ManageSpecificUser}/${userId}`;

  useEffect(() => { (passwordHelp || passwordStatus) && setState({ passwordHelp: '', passwordStatus: '' }) }, [password])

  useEffect(() => {
    setState({ emailSent: false, showPendingStatus: false })
    checkPendingStatus()
  }, []);
  const checkPendingStatus = async () => {
    const response = await apiRequest(APIROUTES.get.get_pending_email_status + userId, 'GET');
    if (response?.data?.success && response?.data?.data?.new_email) {
      setState({ showPendingStatus: true, email: response?.data?.data?.new_email })
    }
  }
  const handleSubmit = async (values) => {
    setState({ submitting: true });
    const payload = {
      target_user_id: userId,
      new_email: values?.new_email,
      $password: values?.password
    }
    const res = await apiRequest(APIROUTES.put.change_email, 'PUT', payload);
    if (res?.data?.success) {
      setState({ email: values?.new_email, emailSent: true });
    } else if (res?.response?.data?.error?.message) {
      if (res?.response?.data?.error?.message === 'Incorrect password.') {
        setState({ passwordStatus: 'error', passwordHelp: 'Incorrect password.' });
      } else {
        notification.error({ message: res?.response?.data?.error?.message, description: res?.response?.data?.error?.details, placement: "top" })
      }
    } else {
      notification.error({ message: 'Error', description: 'Email change failed', placement: "top" })
    }
    setState({ submitting: false });
  }

  const handleResend = async () => {
    const response = await apiRequest(APIROUTES.post.email_change_resend, 'POST', { target_user_id: userId });
    if (response?.data?.success) {
      notification.success({ message: 'Success', description: response?.data?.data?.message, placement: 'top' })
      setState({ emailSent: false })
    }
  }

  const handleCancel = async () => {
    const response = await apiRequest(APIROUTES.del.email_change, 'DELETE', { target_user_id: userId })
    if (response?.data?.success) {
      setState({ emailSent: false, showPendingStatus: false })
      form.setFieldValue("new_email", undefined)
      form.setFieldValue("password", undefined)
    }
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <PageDescription
            title='Change Email'
            text={description}
          />
        </Col>
      </Row>
      {emailSent ? (<>
        <Row className="m-t-20">
          <Col span={24}>
            <Typography.Text className="muli semi-bold fs-18px">Pending email Confirmation: {email}</Typography.Text>
          </Col>
          <Col>
            <Typography.Text className="muli semi-bold fs-18px"><Link className="link" onClick={handleResend} >Resend</Link> or <Link className="link danger" onClick={handleCancel} >Cancel</Link></Typography.Text>
          </Col>
        </Row>
        <Row className="m-t-100" justify="end" gutter={12}>
          <Col>
            <Button type="primary" onClick={() => navigate(sourceURL)}>Return</Button>
          </Col>
        </Row>
      </>
      ) : (
        <>
          <Form
            layout="vertical"
            requiredMark={false}
            onFinish={handleSubmit}
            form={form}
          >
            <Row className="m-t-10">
              <Col flex='400px'>
                <Form.Item
                  label={<Typography.Text className="muli semi-bold fs-18px dark-green">{labels.email}</Typography.Text>}
                  name='new_email'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your new email',
                    },
                    {
                      type: 'email',
                      message: 'Please enter a valid email address'
                    }
                  ]}
                >
                  <Input placeholder="New Email" />
                </Form.Item>
                <Form.Item
                  label={<Typography.Text className="muli semi-bold fs-18px dark-green">{labels.password}</Typography.Text>}
                  name='password'
                  validateStatus={passwordStatus}
                  help={passwordHelp}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your password'
                    }
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
              </Col>
            </Row>
            {showPendingStatus && <Row className="m-t-20">
              <Col span={24}>
                <Typography.Text className="muli semi-bold fs-18px">Pending email Confirmation: {email}</Typography.Text>
              </Col>
              <Col>
                <Typography.Text className="muli semi-bold fs-18px"><Link className="link" onClick={handleResend} >Resend</Link> or <Link className="link danger" onClick={handleCancel} >Cancel</Link></Typography.Text>
              </Col>
            </Row>}
            <Row className="m-t-20" justify="end" gutter={12}>
              <Col>
                <Button type="primary" danger onClick={() => navigate(sourceURL)}>Cancel</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit" loading={submitting}>Confirm</Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </>
  );
}
export { ChangeEmail };