/* eslint-disable react-hooks/exhaustive-deps */
import {
  Col,
  Modal,
  Row,
  Spin,
  Typography,
} from "antd";
import PageDescription from "App/Components/PageDescription";

import { BsEyeFill } from "react-icons/bs";
import {
  FaRegSnowflake,
  FaExclamationTriangle,
  FaRegTimesCircle,
  FaCoins,
  FaSync,
  FaEdit,
} from "react-icons/fa";
import CircularCard from "App/Components/CircularCard";
import CardAutoFund from "App/Components/CardAutoFund";
import { useManageSpecificCardState } from "./store";
import { useNavigate, useParams } from "react-router-dom";
import URLS from "Routes/constants";
import CardDetails from "App/Components/CardDetails";
import AuthorisedPaymentTypes from "App/Components/AuthorisedPaymentTypes";
import TransactionLimit from "App/Components/TransactionLimit";
import { usePrepaidCardQuery } from "Hooks/Queries";
import Spinner from "App/Components/Spinner";
import { useBlockCardMutation, useDestroyCardMutation, useReportStolenCardMutation } from "Hooks/Mutations";
import CardBalanceListing from "App/Components/CardBalanceListing";
import FullPrepaidCardDetailsModal from "App/Components/FullPrepaidCardDetailsModal";
import ViewCardPin from "./components/ViewCardPin";
import FreezeCard from "./components/FreezeCard";
import CancelCard from "./components/CancelCard";
import ManualPayment from "./components/ManualPayment";
import ReportCard from "./components/ReportCard";
import { useEffect } from "react";
import ActivateCard from "App/Pages/Cards/Cards/ManageSpecificCard/components/ActivateCard";
import CardActivated from "App/Pages/Cards/Cards/ManageSpecificCard/components/CardActivated";
import ReturnToDashboardCard from "./components/ReturnToDashboardCard";
import UnFreezeCard from "./components/UnFreezeCard";
import { useEnableDisableManualPayments } from "Hooks/Mutations/useEnableDisableManualPayments";
import CancelCardReturn from "./components/CancelCardReturn";
import GoBackCard from "./components/GoBackCard";

function ManageSpecificCard() {
  const navigate = useNavigate();
  const fullScreenModalOpen = useManageSpecificCardState((s) => s.fullScreenModalOpen);
  const modalOpen = useManageSpecificCardState((s) => s.modalOpen);
  const modalContent = useManageSpecificCardState((s) => s.modalContent);
  const setState = useManageSpecificCardState((s) => s.setState);
  const { cardId } = useParams();

  const { data: card, isLoading, refetch: refetchCard } = usePrepaidCardQuery(cardId);

  const blockCardMutation = useBlockCardMutation(payload => payload);
  const destroyCardMutation = useDestroyCardMutation(payload => payload);
  const reportStolenCardMutation = useReportStolenCardMutation(payload => payload);
  const enableDisableManualPayments = useEnableDisableManualPayments(payload => payload);

  useEffect(() => {
    if(card?.card_status_id === 1) {
      toggleModal('activateCard')
    } else if(card?.card_status_id === 7) {
      setState({
        returnToDashboardCardHeading: 'CARD CANCELLED',
        returnToDashboardCardDescription: 'The Card has been',
        returnToDashboardCardBold: 'Cancelled',
        modalContent: 'goback',
        modalOpen: true
      })
    }else if(card?.card_status_id === 9) {
      setState({
        returnToDashboardCardHeading: 'CARD LOST/STOLEN',
        returnToDashboardCardDescription: 'The Card has been Reported',
        returnToDashboardCardBold: 'Lost/Stolen',
        modalContent: 'goback',
        modalOpen: true
      })
    }
    
  }, [card])

  //
  const toggleFullScreenModal = () => {
    setState({ fullScreenModalOpen: !fullScreenModalOpen });
  };

  //pass true will freeze card and passing false will unfreeze card
  const handleFreezeCard = (freeze) => {
    setState({ modalOpen: false, modalContent: null });
    blockCardMutation.mutate({ cardId, block: freeze });
  }

  //
  const handleManualPaymentCard = () => {
    setState({ modalOpen: false, modalContent: null });
    const method = card?.prepaid_card_features?.map(item => item.card_feature_id).indexOf(7) === -1 ? 'POST' : 'DELETE'
    enableDisableManualPayments.mutate({ cardId, method});
  }

  //
  const handleReportStolenCard = () => {
    setState({ modalOpen: false, modalContent: null });
    reportStolenCardMutation.mutate({ cardId, block: false });
  }

  //
  const handleDestroyCard = (pass) => {
    destroyCardMutation.mutate({ cardId, pass });
  }

  //
  const handleActivateCard = (activated) => {
    refetchCard();
    if (activated) {
      toggleModal('cardActivated')
    }
  }

  //
  const toggleModal = (type) => {
    if (card?.card_status_id !== 1 && type === 'close') {
      setState({ modalOpen: false, modalContent: null });
    } else if (type !== 'close') {
      setState({ modalContent: type, modalOpen: true });
    }
  };

  const getCardStatus = () => {
    if(card?.card_status_id === 2) {
        return 'Active'
    } else if(card?.card_status_id === 4){
      return "Cancelled"
    } else if(card?.card_status_id === 5){
      return "Expired"
    } else if(card?.card_status_id === 9){
      return "Lost/Stolen"
    } else {
      return  ""
    }
  }

  return (
    <>
    <Spin
      size="large"
      spinning={
        blockCardMutation.isLoading ||
        reportStolenCardMutation.isLoading ||
        enableDisableManualPayments.isLoading
        }
      >
      {isLoading ?
        <Spinner /> :
        <>
          <Modal
            destroyOnClose
            footer={false}
            open={modalOpen}
            onCancel={() => toggleModal('close')}
            closable={false}
          >
            {modalContent === 'returnToDashboard' && 
              <ReturnToDashboardCard />
            }

            {modalContent === 'goback' && 
              <GoBackCard />
            }

            {modalContent === 'cancelCardReturnToDashboard' && 
              <CancelCardReturn />
            }
            {modalContent === 'viewPin' &&
              <ViewCardPin
                onCancel={() => toggleModal('close')}
                cardId={cardId}
              />
            }

            {modalContent === 'freeze' &&
              <FreezeCard
                onCancel={() => toggleModal('close')}
                onConfirm={handleFreezeCard}
                cardNumber={card?.card_number}
              />
            }
            {modalContent === 'unfreeze' &&
              <UnFreezeCard
                onCancel={() => toggleModal('close')}
                onConfirm={handleFreezeCard}
                cardNumber={card?.card_number}
              />
            }

            {modalContent === 'report' &&
              <ReportCard
                onCancel={() => toggleModal('close')}
                onConfirm={handleReportStolenCard}
                cardId={cardId}
              />
            }

            {modalContent === 'cancel' &&
              <CancelCard
                onClose={() => toggleModal('close')}
                onConfirm={handleDestroyCard}
                isLoading={destroyCardMutation.isLoading}
                cardId={cardId}
              />
            }

            {modalContent === 'manualPayment' &&
              <ManualPayment
                onCancel={() => toggleModal('close')}
                onConfirm={handleManualPaymentCard}
                cardNumber={card?.card_number}
                type={card?.prepaid_card_features?.map(item => item.card_feature_id).indexOf(7) === -1 ? 'enable' : 'disable'}
              />
            }

            {modalContent === 'activateCard' &&
              <ActivateCard
                id={cardId}
                activated={handleActivateCard}
              />
            }

            {modalContent === 'cardActivated' &&
              <CardActivated
                onCancel={() => toggleModal('close')}
              />
            }

          </Modal>

          {/* Card full details modal ==================================== */}
          <FullPrepaidCardDetailsModal open={fullScreenModalOpen} cardId={card?.id} onCancel={toggleFullScreenModal} />

          <Row>
            <Col span={24}>
              <PageDescription
                title="Manage Specific Card"
                text="- Manage and Configure Your Card Settings"
              />
            </Col>
          </Row>

          <Row
            className="m-t-10"
            gutter={[48, 36]}
            align="bottom"
          >
            <Col xxl={8} xl={11} lg={13} md={18} sm={24} xs={24}>
              <CardDetails
                cardHolderName={`${card?.card_holder?.first_name} ${card?.card_holder?.last_name}`}
                cardNumber={`**** **** **** ${card?.card_number}`}
                expiryDate={`${String(card?.expiry_month).padStart(2, '0')} / ${card?.expiry_year}`}
                cardType={card?.card_program_id || card?.card_type}
                cardStatus={getCardStatus()}
                footer={
                  <Row justify="end">
                    <Col>
                      <Typography.Text
                        className="fs-18px medium dark-green pointer"
                        onClick={toggleFullScreenModal}
                      >
                        View Full Details
                      </Typography.Text>
                    </Col>
                  </Row>
                }
              />
            </Col>

            <Col flex='auto'>
              <Row align='top' gutter={[48, 36]}>
                {card?.card_status_id === 2 && (
                  <>
                    {!!card?.registered && (
                      <Col>
                        <CircularCard
                          onClick={() => toggleModal("viewPin")}
                          footer="View Pin"
                        >
                          <BsEyeFill size={42} />
                        </CircularCard>
                      </Col>
                    )}
                    <Col>
                      <CircularCard
                        onClick={() => toggleModal("freeze")}
                        footer="Freeze"
                      >
                        <FaRegSnowflake size={42} />
                      </CircularCard>
                    </Col>
                    <Col>
                      <CircularCard
                        onClick={() => toggleModal("report")}
                        footer="Report"
                        danger
                      >
                        <FaExclamationTriangle size={42} style={{ marginLeft: '0px', transform: 'translateY(-2px)' }} />
                      </CircularCard>
                    </Col>
                    <Col>
                      <CircularCard
                        onClick={() => toggleModal("cancel")}
                        footer="Cancel"
                        danger
                      >
                        <FaRegTimesCircle size={42} />
                      </CircularCard>
                    </Col>
                    <Col>
                      <CircularCard
                        onClick={() => navigate(`${URLS.NewCardFund}/${cardId}`)}
                        footer="Fund"
                        badge="+"
                      >
                        <FaCoins size={42} />
                      </CircularCard>
                    </Col>
                    <Col>
                      <CircularCard
                        onClick={() => navigate(`${URLS.NewCardWithdrawal}/${cardId}`)}
                        footer="Withdraw"
                        badge="-"
                      >
                        <FaCoins size={42} />
                      </CircularCard>
                    </Col>
                    <Col>
                      <CircularCard
                        onClick={() => navigate(`${URLS.ConvertCardBalances}/${cardId}`)}
                        footer="Convert"
                      >
                        <FaSync size={42} />
                      </CircularCard>
                    </Col>
                    {card.prepaid_card_features.map(item => item.card_feature_id).indexOf(8) !== -1 &&
                    (<Col flex='175px'>
                      <CircularCard
                        danger={!(card?.prepaid_card_features?.map(item => item.card_feature_id).indexOf(7) === -1)}
                        footer={(card?.prepaid_card_features?.map(item => item.card_feature_id).indexOf(7) === -1 ? 'Enable ' : 'Disable ') + "Manual Payment"}
                        onClick={() => toggleModal("manualPayment")}
                      >
                        <FaEdit style={{ transform: 'translate(3px, -2px)' }} size={42} />
                      </CircularCard>
                    </Col>)}
                  </>
                )}
                {card?.card_status_id === 3 && (
                  <><Col>
                    <CircularCard
                      footer="Unfreeze"
                      danger
                      onClick={() => toggleModal("unfreeze")}
                    >
                      <FaRegSnowflake size={42} />
                    </CircularCard>
                  </Col><Col>
                      <CircularCard
                        onClick={() => toggleModal("report")}
                        footer="Report"
                        danger
                      >
                        <FaExclamationTriangle size={42} style={{ marginLeft: '0px', transform: 'translateY(-2px)' }} />
                      </CircularCard>
                    </Col><Col>
                      <CircularCard
                        onClick={() => toggleModal("cancel")}
                        footer="Cancel"
                        danger
                      >
                        <FaRegTimesCircle size={42} />
                      </CircularCard>
                    </Col></>
                )}
              </Row>
            </Col>
          </Row>

          <Row gutter={24} className="m-t-20">
            <Col xxl={12} xl={12} lg={18} md={22} sm={24} xs={24}>
              <Row>
                <Col span={24}>
                  <Typography.Text className="fs-25px medium dark-green">
                    Card Auto Fund
                  </Typography.Text>
                  <CardAutoFund cardId={cardId} disabled={(card?.card_status_id === 2 || card?.card_status_id === 3) ? false : true} />
                </Col>
              </Row>
              <Row className="m-t-20">
                <Col span={24}>
                  <Typography.Text className="fs-25px medium dark-green">
                    Authorised Payment Types
                  </Typography.Text>
                  <AuthorisedPaymentTypes cardId={cardId} disabledFlag={(card?.card_status_id === 2 || card?.card_status_id === 3) ? false : true} />
                </Col>
              </Row>
              <Row className="m-t-20">
                <Col span={24}>
                  <Typography.Text className="fs-25px medium dark-green">
                    Transaction Limits
                  </Typography.Text>
                  <TransactionLimit cardId={cardId} disabled={(card?.card_status_id === 2 || card?.card_status_id === 3) ? false : true} />
                </Col>
              </Row>
            </Col>

            {/* Card balance =========================================== */}
            <Col xxl={12} xl={12} lg={18} md={22} sm={24} xs={24}>
              <Typography.Text className="fs-25px medium dark-green">
                Card Balance
              </Typography.Text>
              <CardBalanceListing cardId={cardId} />
            </Col>
          </Row>
        </>
      }
      </Spin>
    </>
  );
}

export default ManageSpecificCard;
