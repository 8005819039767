/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Space, Image, Row, Typography, Button } from "antd";
import { FLAGS } from "Constants/Images";
import { useCardBalanceState } from "Hooks/Store";
import { usePrepaidcardBalanceQuery } from "Hooks/Queries";
import { accountingFormat } from "Utils";
import CustomTable from "App/Components/CustomTable";

function CardBalanceListing({
    cardId,
    rowType,
    rowKey = 'id',
    showTotal = false,
    viewAllToggle = true,
}) {
    const { isLoading, isFetching } = usePrepaidcardBalanceQuery(cardId);
    const setState = useCardBalanceState(state => state.setState);
    const allVisible = useCardBalanceState(state => state.allVisible);
    const balancesTotal = useCardBalanceState(state => state.total);
    const visibleBalances = useCardBalanceState(state => state.visibleBalances());

    let walletCurrency = visibleBalances.filter((data) => data.breakdown.rate === 1);

    //
    const columns = [
        {
            title: "Currency",
            dataIndex: "currency",
            key: "currency",
            render: (value, record) => (
                <Space size={3}>
                    {!!record?.currency && <Image src={FLAGS[record.currency]} preview={false} alt={value} width={36} />}
                    {value}
                </Space>
            ),
        },
        {
            title: "Available to Spend",
            dataIndex: "availableToSpend",
            key: "availableToSpend",
            render: (v, record) => (
                (Number(record.cleared) - Number(record.pending)).toLocaleString()
            ),
        },
        {
            title: "Reserved",
            dataIndex: "pending",
            key: "pending",
            render: (v, record) => (
                Number(record.pending).toLocaleString()
            ),
        },
        {
            title: "Rate",
            dataIndex: ['breakdown', 'rate'],
            key: "rate",
            render: (value, record) => (
                value
            ),
        },
        {
            title: walletCurrency[0]?.currency + ' Value',
            dataIndex: ['breakdown', 'equivalent'],
            key: "GBPValue",
            render: (value, record) => (
                accountingFormat(value)
            ),
        },
    ];

    // eslint-disable-next-line
    const toggleShowAll = () => {
        setState({ allVisible: !allVisible });
    };

    return (
        <>
            <Row>
                <Col span={24}>
                    <CustomTable
                        columns={columns}
                        dataSource={visibleBalances ?? []}
                        headerColor="green"
                        headerFont="muli"
                        rowType={rowType}
                        rowKey={rowKey}
                        loading={isFetching || isLoading}
                    />
                </Col>
            </Row>
            <Row className="m-t-10">
                <Col span={24}>
                    <>
                        {!!showTotal && <Typography.Text className="muli semi-bold fs-18px dark-green">Total GBP Value: {balancesTotal.toLocaleString()}</Typography.Text>}
                        {(!!viewAllToggle && !!visibleBalances.length) && <Button type="link" className="medium fs-18px right-align-text" onClick={toggleShowAll}>View {allVisible ? 'Less' : 'All'} Currencies</Button>}
                    </>
                </Col>
            </Row>
        </>
    );
}

export default CardBalanceListing;
