import create from "zustand";

const usePaymentsDashboardState = create(set => ({
    frequentRecipientsData: undefined,
    recentPaymentsData: undefined,
    recipientsLoading: false,
    paymentsLoading: false,
    setState: obj => set(obj)
}));

export { usePaymentsDashboardState };