import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
// eslint-disable-next-line
import { notification } from "antd";
import { useManageSpecificCardState } from "App/Pages/Cards/Cards/ManageSpecificCard/store";

const useBlockCardMutation = ({cardId, block}) => {
    const apiRequest = useApiRequest();
    const queryClient = useQueryClient();
    const setManageCardState = useManageSpecificCardState((s) => s.setState);

    const mutation = useMutation({
        mutationFn: ({cardId, block}) => {
            const url = block ? `${APIROUTES.post.gps_blockcard}` : `${APIROUTES.post.gps_unblockcard}`;

            return apiRequest(url, 'POST', {
                prepaid_card_id: parseInt(cardId),
                additional_info: 'web-app',
                ip: '1.1.1.1',
                // test: true,
            });
        },
        mutationKey: ['_gps_blockcard_unblockcard', cardId, block],
        onMutate: () => {},
        onSuccess: (data, context) => {
            if(!data?.data?.success) {
				notification.error({ message: 'Something went wrong.',  placement: 'top' });
				return;
			}
            queryClient.invalidateQueries({ queryKey: ['_prepaidcard', context.cardId], exact: true});
            setManageCardState({
                modalContent: 'returnToDashboard',
                modalOpen: true,
                returnToDashboardCardHeading: `CARD ${context.block ? 'FROZEN': 'UNFROZEN'}`,
                returnToDashboardCardDescription: 'Your card is now',
                returnToDashboardCardBold: context.block ? 'Frozen': 'Unfrozen',
            });
        },
        onError: (error) => {
            notification.error({ message: error.message,  placement: 'top' });
        },
    });

    return mutation;
}

export { useBlockCardMutation };