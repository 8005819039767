import { Button, Card, Col, Form, Image, Input, notification, Row, Space, Typography } from 'antd';
import PageDescription from 'App/Components/PageDescription';
import { useNavigate } from 'react-router-dom';
import URLS from 'Routes/constants';
import one from 'Assets/Images/loginOne.png';
import two from 'Assets/Images/loginThree.png';
import location from 'Assets/Images/location.png';
import { useApiRequest } from 'Hooks/API';
import { APIROUTES } from 'Constants/ApiRoutes';
import { useContactDetailsState } from 'App/Pages/Settings/CompanyAccount/Help/ContactUs/ContactDetails/store';

function ContactDetails() {
    const navigate = useNavigate();
    const apiRequest = useApiRequest();
    const loading = useContactDetailsState(state => state.loading);
    const setState = useContactDetailsState(state => state.setState);

    const handleOnFinish = async (input) => {
        setState({ loading: true });
        const res = await apiRequest(`${APIROUTES.post.contact}`, 'POST', input);
        if (res && res.success) {
            notification.success({
                placement: 'top',
                message: 'Success',
                description: res.details
            })
        } else {
            notification.error({
                placement: 'top',
                message: 'Error',
                description: 'Something went wrong'
            })
        }
        setState({ loading: false });
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <PageDescription title='Contact Details' />
                </Col>
            </Row>
            <Row className='m-t-10' justify='end'>
                <Col>
                    <Button type='primary' onClick={() => navigate(URLS.ContactUs)}>Return to Contact Us</Button>
                </Col>
            </Row>
            <Row className='m-t-20'>
                <Col span={24}>
                    <Card className='b-g'>
                        <Row gutter={[48, 24]}>
                            <Col xxl={8} xl={8} lg={12} md={14} sm={18} xs={24}>
                                <Typography.Text className='dark-green fs-28px regular'>Contact Form</Typography.Text>
                                <Form
                                    layout='vertical'
                                    onFinish={handleOnFinish}
                                    requiredMark={false}
                                >
                                    <Row className='m-t-20'>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={18}>
                                            {/* <Form.Item
                                                label='Name'
                                                name='name'
                                            >
                                                <Input placeholder='Enter name' className='b-g' />
                                            </Form.Item>
                                            <Form.Item
                                                label='eMail'
                                                name='email'
                                            >
                                                <Input placeholder='Enter email' className='b-g' />
                                            </Form.Item> */}
                                            <Form.Item
                                                label='Subject'
                                                name='subject'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter the subject'
                                                    }
                                                ]}
                                            >
                                                <Input placeholder='Enter subject' className='b-g' />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item
                                                label='Body'
                                                name='message'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter the body of your message'
                                                    }
                                                ]}
                                            >
                                                <Input.TextArea placeholder='Enter Additional Information' rows={5} className='b-g' />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={18}>
                                            <Form.Item
                                                name='verificationCode'
                                                label='Verification Code'
                                            >
                                                <Input className='b-g' />
                                            </Form.Item>
                                        </Col>
                                    </Row> */}
                                    <Button type='primary' htmlType='submit' loading={loading}>Send</Button>
                                </Form>
                            </Col>
                            <Col xxl={16} xl={16} lg={24} md={24} sm={24} xs={24}>
                                <Row>
                                    <Col span={24} style={{ overflow: 'auto' }}>
                                        <Row>
                                            <Col>
                                                <Image
                                                    src={one}
                                                    preview={false}
                                                    width={258}
                                                    style={{ pointerEvents: 'none' }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                flex='500px'
                                                style={{
                                                    marginTop: '-60px',
                                                    marginLeft: '190px',
                                                    marginBottom: '-60px'
                                                }}
                                            >
                                                <Row>
                                                    <Col>
                                                        <Typography.Text className='dark-green fs-28px medium'>Contact Volopa</Typography.Text>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col flex='130px'>
                                                        <Typography.Text className='fs-18px bold'>Email:</Typography.Text>
                                                    </Col>
                                                    <Col>
                                                        <Typography.Text className='fs-18px bold'>support@volopa.com</Typography.Text>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col flex='130px'>
                                                        <Typography.Text className='fs-18px bold'>Telephone:</Typography.Text>
                                                    </Col>
                                                    <Col>
                                                        <Typography.Text className='fs-18px bold'>+44 (0)333 400 1287</Typography.Text>
                                                    </Col>
                                                </Row>
                                                <Row className='m-t-10'>
                                                    <Col flex='130px'>
                                                        <Typography.Text className='fs-18px bold'>Letter:</Typography.Text>
                                                    </Col>
                                                    <Col>
                                                        <Space direction='vertical' size={0}>
                                                            <Typography.Text className='fs-18px bold'>Volopa Support</Typography.Text>
                                                            <Typography.Text className='fs-18px bold'>15 Belgrave Square</Typography.Text>
                                                            <Typography.Text className='fs-18px bold'>London, SW1X 8PS</Typography.Text>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Image
                                                    src={two}
                                                    preview={false}
                                                    width={224}
                                                    style={{
                                                        marginLeft: '440px',
                                                        marginTop: '-50px'
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Typography.Text className='dark-green fs-28px medium'>Volopa Head Office</Typography.Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Image src={location} preview={false} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default ContactDetails;