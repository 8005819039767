import { useNavbarState } from "App/Components/NavBar/store";
import { APIROUTES } from "Constants/ApiRoutes";
import { useApiRequest } from "Hooks/API";
import URLS from "Routes/constants";
import { Button, Card, Col, Row, Space, Typography } from "antd";
import { BsFillCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function PaymentApproval({ acknowledged, alert_json_object, id }) {
  const title = alert_json_object?.title;
  const setNavbarState = useNavbarState(state => state.setState);
  const navigate = useNavigate();
  const apiRequest = useApiRequest();

  const handleAction = () => {
    apiRequest(`${APIROUTES.put.notifications}${id}`, 'PUT', { acknowledged: 1 });
    setNavbarState({ notificationsOpen: false });
    const url = alert_json_object?.single_payment?.data === "1" ? URLS.NewPayment : URLS.PaymentSummary;
    navigate(`${url}/${alert_json_object?.quote_id?.data}`);
  }
  return (
    <Card className='bg-extra-light-grey'>
      <Row className={acknowledged ? 'm-l-20' : ''}>
        <Col flex='auto'>
          <Space size={10}>
            {!acknowledged && <BsFillCircleFill size={10} className='light-green' />}
            <Typography.Text className="fs-20px regular">{title}</Typography.Text>
          </Space>
        </Col>
      </Row>
      <Row justify='end' className="m-t-10">
        <Col>
          <Button type='primary' onClick={handleAction}>Review</Button>
        </Col>
      </Row>
    </Card>
  );
}

export default PaymentApproval;