import create from "zustand"

const useAddRecipientState = create((set, get) => ({
    recipientType: undefined,
    countries: undefined,
    currencies: undefined,
    commonCurrencies: undefined,
    commonCountries: undefined,
    recipientEmail: undefined,
    recipientIban: undefined,
    recipientSwift: undefined,
    recipientSortCode: undefined,
    recipientAcc: undefined,
    accountLocal: false,
    paymentPurpose: undefined,
    recipientCurrency: undefined,
    bankCountry: undefined,
    recipientCountry: undefined,
    hasIban: false,
    bankDetailsLoading: false,
    bankDetailsName: undefined,
    bankDetailsAddress1: undefined,
    bankDetailsAddress2: undefined,
    bankDetailsPostcode: undefined,
    bankDetailsCountry: undefined,
    bankDetailsIdentifierValue: undefined,
    bankDetailsIdentifierType: undefined,
    bankDetailsSwift: undefined,
    bankDetailsSortCode: undefined,
    bankDetailsAccountNumber: undefined,
    bankDetailsAba: undefined,
    disableSubmit: true,
    disableFormSubmitBtn: true,
    submitLoading: false,
    showBankDetails: false,
    loading: false,
    bankLabel: undefined,
    bankInput: [],
    paymentTypes: [],
    hasAba: false,
    hasBsb: false,
    hasPostCode: false,
    hasState: false,
    hasCnaps: false,
    hasBankCode: false,
    hasBranchCode: false,
    modalOpen: false,
    beneficiaryDetails: undefined,
    last_inserted_id: undefined,
    paymentPurposeOther: undefined,
    postCodeRequired: false,
    paymentPurposeCode: undefined,
    bankDetailsError: false,
    setState: obj => set(obj)
}));

export { useAddRecipientState };