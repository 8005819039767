import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useCardImageQuery = (cardProgram) => {
  const apiRequest = useApiRequest();

  const fetchQuery = async () => {
    const data = await apiRequest(`${APIROUTES.get.card_image_url}?cardProgram=${cardProgram}`, 'GET', null, null, null, true);
    return data;
  }

  const query = useQuery({
    queryKey: ['_card_image_url', cardProgram],
    queryFn: fetchQuery,
    enabled: !!cardProgram,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return query;
}

export { useCardImageQuery };