import { useAccountingCompanyMapQuery } from "App/Pages/Accounting/Configuration/query";
import { Navigate, Outlet } from "react-router-dom";
import Spinner from "App/Components/Spinner";
import URLS from "Routes/constants";

const AccountingNotConnectedGuard = ({
    or = URLS.Configuration,
}) => {
    const { data, isLoading } = useAccountingCompanyMapQuery();

    return !!isLoading
        ? <Spinner />
        : (
            data?.[0]?.status_id !== 3 ? (
                data?.[0]?.status_id === 2
                ? <Navigate to={or} replace={true} />
                : <Outlet />
            )
            : <Navigate to={or} replace={true} />
        );
}

export default AccountingNotConnectedGuard;
