import { Button, Col, Form, Modal, Row, Typography } from "antd";

function CancelFundingConfirmation({ modalOpen, onCancel = () => { }, onFinish, loading }) {
  const [form] = Form.useForm();

  const handleFinish = () => {
    const pass = form.getFieldValue('password');
    onFinish(pass);
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (<>
    <Modal
      destroyOnClose
      footer={false}
      open={modalOpen}
      onCancel={handleCancel}
      closable={false}
    >
      <Row>
        <Col>
          <Typography.Text className="muli semi-bold fs-18px dark-green">WALLET FUNDING</Typography.Text>
        </Col>
      </Row>
      <Form
        layout="vertical"
        onFinish={handleFinish}
        form={form}
        requiredMark={false}
      >
        <Row className="m-t-20" justify="center">
          <Col span={24}>
            <Row justify="center m-b-10">
              <Col>
                <Typography.Text className="center">Are you sure you wish to cancel this funding?</Typography.Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="space-between m-t-20">
          <Col>
            <Button type="primary" onClick={handleCancel}>Back</Button>
          </Col>
          <Col>
            <Button type="danger" htmlType="submit" loading={loading}>Cancel Funding</Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  </>)
}

export default CancelFundingConfirmation;
