import { Card, Col, Image, Row, Space, Typography } from "antd";
import PageDescription from "App/Components/PageDescription";
import { useNavigate } from "react-router-dom";
import batchPayments from 'App/Pages/Payments/BatchPayments/store/images/batchPayments.png';
import URLS from 'Routes/constants';

function BatchPayments() {
    const navigate = useNavigate();
    return (
        <>
            <Row>
                <Col span={24}>
                    <PageDescription title='Batch Payments' />
                </Col>
            </Row>
            <Row className="m-t-20">
                <Col span={24}>
                    <Row gutter={16}>
                        <Col flex='318px'>
                            <Card hoverable className="b-g hover-no-border" onClick={() => navigate(URLS.PayMultipleRecipients)}>
                                <Row justify='center'>
                                    <Col>
                                        <Space direction="vertical">
                                            <Image src={batchPayments} preview={false} alt='Batch Payments' width={200} />
                                            <Typography.Text className="fs-18px muli semi-bold">Pay Multiple Recipients</Typography.Text>
                                        </Space>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default BatchPayments;