import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useConfigurationState } from "../store/index";

const useAccountingCompanyMapQuery = () => {
    const apiRequest = useApiRequest();
    const setState = useConfigurationState(state => state.setState);
    const pollForStatusInterval = useConfigurationState(state => state.pollForStatusInterval);

    // eslint-disable-next-line no-unused-vars
    const fetchAccountingCompanyMap = async () => {
        const { data } = await apiRequest(`${APIROUTES.accounting_company_map}`);
        return data;
    }

    // @todo delete
    // eslint-disable-next-line no-unused-vars
    const _fetchAccountingCompanyMap = async () => {
        return new Promise(resolve => setTimeout(resolve([
                {
                    id: 115,
                    client_id: 213321,
                    company_uuid: "1ad3d2e9-20f0-4875-8c24-ccc0a3deb694",
                    platform_type: "Xero",
                    status_id: 3,
                    currency: "GBP",
                    redirect: "https://link.codat.io/company/8f7cb9ee-d17a-41fc-8d46-32d06e85d611",
                    logo_url: "https://static.codat.io/public/platforms/gbol.png",
                    user_id: 3889,
                    create_time: "2022-11-03 14:19:31",
                    update_time: "2022-11-04 12:15:30",
                    deleted: 0,
                    delete_time: null
                }
            ]
        ), 1500));
    }


    const query = useQuery({
        queryKey: ['_accounting_company_map'],
        queryFn: fetchAccountingCompanyMap,
        refetchInterval: pollForStatusInterval,
		refetchOnMount: true,
		refetchOnReconnect: true,
		refetchOnWindowFocus: true,
        onSuccess: (data) => {
            setState({
                statusId: data?.[0]?.status_id,
                accountingLogo: data?.[0]?.logo_url,
				clientId: data?.[0]?.client_id,
                platform_type: data?.[0]?.platform_type
            });

            if(data?.[0]?.status_id === 3) {
                setState({pollForStatusInterval: false})
            } else {
				// Set a milisecond value if we wanna poll will while
				// codat account connection is pending
                setState({pollForStatusInterval: 30000})
            }
        }
    });

    return query;
}

export { useAccountingCompanyMapQuery };
