import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
import { useConfigurationState } from "App/Pages/Accounting/Configuration/store";
import { notification } from "antd";

const useAccountingConfigMutation = () => {
    const apiRequest = useApiRequest();
    const queryClient = useQueryClient();
    const {
        allCards,
        syncBankFeeds,
        syncExpenses,
        categoryMap,
        trackingCodeMap,
        codatBankAccountId,
        codatBillPaymentBankAccountId,
        setState,
        selectedCards,
    } = useConfigurationState();

    const mutation = useMutation({
        mutationFn: () => {
            return apiRequest(APIROUTES.post.set_all_accounting_config, 'POST', {
                bankAccountId:  codatBankAccountId,
                billPaymentBankAccountId: codatBillPaymentBankAccountId,
                categoryMap,
                tracking_codes_map: trackingCodeMap,
                configuration: {
                    feed: syncBankFeeds,
                    expenses: syncExpenses,
                    all_cards: allCards
                },
                prepaid_card_ids: selectedCards // is "cards" on getConfig res.
            });
        },
        mutationKey: '_set_all_accounting_config',
        onMutate: () => {
            setState({loading: true});
        },
        onSuccess: (data) => {
            setState({loading: false});

            if(data?.response?.status === 400) {
                throw Error(data);
            }

            queryClient.invalidateQueries({ queryKey: ['_get_accounting_config_all'], exact: true});

            notification.success({message: 'Your Accounting Configuration was saved successfully!', placement: 'top'});
        },
        onError: (error) => {
            notification.error({ message: 'Something Went Wrong', description: 'There was an issue saving your configuration.', placement: 'top' });
        },
    });

    return mutation;
}

export { useAccountingConfigMutation };