import { Button, Col, Row, Typography } from "antd";
import { colors } from "Constants/Style";
import CustomTable from "App/Components/CustomTable";
import PageDescription from "App/Components/PageDescription";
import { FaTrashAlt } from "react-icons/fa";

function BankAccounts() {
    const columns = [
        {
            title: 'Bank Name',
            dataIndex: 'bankName',
            key: 'bankName',
            sorter: (a, b) => a.bankName && b.bankName && a.bankName.localeCompare(b.bankName)
        },
        {
            title: 'Account Name',
            dataIndex: 'accountName',
            key: 'accountName',
            sorter: (a, b) => a.accountName && b.accountName && a.accountName.localeCompare(b.accountName)
        },
        {
            title: 'Account Number',
            dataIndex: 'accountNumber',
            key: 'accountNumber',
            sorter: (a, b) => a.accountNumber && b.accountNumber && a.accountNumber.localeCompare(b.accountNumber)
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            sorter: (a, b) => a.balance && b.balance && a.balance.localeCompare(b.balance)
        },
        {
            title: 'Verification Status',
            dataIndex: 'verificationStatus',
            key: 'verificationStatus',
            sorter: (a, b) => a.verificationStatus && b.verificationStatus && a.verificationStatus.localeCompare(b.verificationStatus),
            render: text => <Typography.Text type={text === 'Active' ? 'success' : 'danger'}>{text}</Typography.Text>
        },
        {
            title: 'Manage',
            dataIndex: 'manage',
            key: 'manage',
            sorter: (a, b) => a.manage && b.manage && a.manage.localeCompare(b.manage)
        },
        {
            title: <Button type="primary">Add Account</Button>,
            dataIndex: 'key',
            key: 'action',
            render: () => <FaTrashAlt style={{ color: colors.danger }} className='pointer' />,
            align: 'center'
        }
    ];
    const data = [
        {
            bankName: 'Barclays',
            accountName: 'Jane Dustan',
            accountNumber: '*****411',
            balance: 'N/a',
            verificationStatus: 'Unverified',
            manage: 'Verify Account',
            key: 0
        },
        {
            bankName: 'Santander',
            accountName: 'Jane Dustan',
            accountNumber: '*****231',
            balance: '£76,210',
            verificationStatus: 'Active',
            manage: 'Default Account',
            key: 1
        }
    ];

    return (
        <>
            <Row>
                <Col span={24}>
                    <PageDescription title='Funding Accounts' text='- View and manage your funding accounts' />
                </Col>
            </Row>
            <Row className="m-t-10">
                <Col span={24}>
                    <CustomTable columns={columns} dataSource={data} />
                </Col>
            </Row>
        </>
    );
}

export default BankAccounts;