import { useQuery } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";

const useUsersQuery = () => {
  const apiRequest = useApiRequest();

  const fetchQuery = async () => {
    const res = await apiRequest(`${APIROUTES.get.users_manage}&statusIdIn=1`);
    return res?.data || [];
  }

  const query = useQuery({
    queryKey: ['_users_list', undefined, undefined, undefined, undefined, '1'],
    queryFn: fetchQuery
  });

  return query;
}

const useApproversQuery = () => {
  const apiRequest = useApiRequest();

  const fetchQuery = async () => {
    const res = await apiRequest(`${APIROUTES.get.approvers}`);
    return res?.data || [];
  }

  const query = useQuery({
    queryKey: ['_approvers_list'],
    queryFn: fetchQuery
  });

  return query;
}

const useApprovalUsersQuery = () => {
  const apiRequest = useApiRequest();

  const fetchQuery = async () => {
    const res = await apiRequest(`${APIROUTES.get.approval_users}`);
    return res?.data || [];
  }

  const query = useQuery({
    queryKey: ['_approval_users_list'],
    queryFn: fetchQuery
  });

  return query;
}

const useApprovalRulesQuery = () => {
  const apiRequest = useApiRequest();

  const fetchQuery = async () => {
    const res = await apiRequest(`${APIROUTES.get.approval_rules}`);
    return res?.data || [];
  }

  const query = useQuery({
    queryKey: ['_rules_list'],
    queryFn: fetchQuery
  });

  return query;
}

export {
  useApproversQuery,
  useUsersQuery,
  useApprovalRulesQuery,
  useApprovalUsersQuery
};