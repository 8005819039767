import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApiRequest } from "Hooks/API";
import { APIROUTES } from "Constants/ApiRoutes";
// eslint-disable-next-line
import { notification } from "antd";

const useResendActivationCode = () => {
    const apiRequest = useApiRequest();
    const queryClient= useQueryClient()

    const mutation = useMutation({
        mutationFn: ({user_id}) => {

            return apiRequest(APIROUTES.post.business_user_resend_activation_code, 'POST', { user_id: user_id });
        },
        mutationKey: ['_user_resend_activation_code'],
        onMutate: () => {},
        onSuccess: (data) => {
            if(data?.success) {
                queryClient.invalidateQueries(['_users_list'])
                notification.success({ placement: 'top', message: 'Activation Code Resent', description: 'Activation Code Resent Successfully!' });
            } else {
                notification.error({ placement: 'top', message: data?.response?.data?.error?.message });
            }
        },
        onError: (error) => {
            notification.error({ placement: 'top', message: 'Something went wrong', description: 'Please try again.' });
        },
    });

    return mutation;
}

export { useResendActivationCode };